import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const academicsliderApi = createApi({
   reducerPath: "academicsliderApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["academicsliderApi"],
   endpoints: (builder) => ({
      getAcademicBooks: builder.query({
         query: (page) => `api/academic/all?page=${page}`,
         providesTags: ["academicsliderApi"],
      }),
   }),
})

export const { useGetAcademicBooksQuery } = academicsliderApi
