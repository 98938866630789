export const employeeFromLocal = JSON.parse(localStorage.getItem("ich-user"))
export const userAccount = JSON.parse(localStorage.getItem("ich-account"))

export const employeeInfoFunc = (userData, item, item2) => {
   const exit = userData?.employee?.userPermission?.find(
      (data) => data?.title === item
   )
   return exit
}

export const getOrderInfo = (orders) => {
   const approvedData = orders?.filter(
      (order) => order?.orderStatus === "approved"
   )
   const dispatchedData = orders?.filter(
      (order) => order?.orderStatus === "dispatched"
   )
   const deliveryProgress = orders?.filter(
      (order) => order?.orderStatus === "delivery in progress"
   )
   const deliveredData = orders?.filter(
      (order) => order?.orderStatus === "delivered"
   )
   const fullfilledData = orders?.filter(
      (order) => order?.orderStatus === "fullfilled"
   )
   const canceledData = orders?.filter(
      (order) => order?.orderStatus === "canceled"
   )
   const orderHoldData = orders?.filter(
      (order) => order?.orderStatus === "order on hold"
   )
   const dispatchHoldData = orders?.filter(
      (order) => order?.orderStatus === "dispatch on hold"
   )
   const returnedData = orders?.filter(
      (order) => order?.orderStatus === "returned"
   )
   const refundedData = orders?.filter(
      (order) => order?.orderStatus === "refunded"
   )
   const refundedReturnedData = orders?.filter(
      (order) => order?.orderStatus === "refunded & returned"
   )
   const returnProgressData = orders?.filter(
      (order) => order?.orderStatus === "return in progress"
   )
   const refundProgressData = orders?.filter(
      (order) => order?.orderStatus === "refund in progress"
   )
   const refundReturnProgressData = orders?.filter(
      (order) => order?.orderStatus === "refund & return in progress"
   )

   return {
      approvedData,
      dispatchedData,
      deliveryProgress,
      deliveredData,
      fullfilledData,
      canceledData,
      orderHoldData,
      dispatchHoldData,
      returnedData,
      refundedData,
      refundedReturnedData,
      returnProgressData,
      refundProgressData,
      refundReturnProgressData,
   }
}
