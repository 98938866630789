import moment from "moment"
import logo from "../../assets/images/logo-ich.png"
import Barcode from "react-barcode"
import { Typography } from "@material-tailwind/react"
import { getDiscountAmountFromPercentage } from "../../utils/orderInformation"

const InvoiceSlip = ({ order }) => {
   let paymentDiscountAmount = getDiscountAmountFromPercentage(order)

   return (
      <div className="space-y-2">
         <div className=" flex items-center justify-between w-full border-b border-blue-gray-100 pb-2">
            <div>
               <div className="w-28 h-12">
                  <img
                     src={logo}
                     alt="ichchapuron-logo"
                     className="w-full h-full"
                  />
               </div>
               <div className="text-xs pl-2">
                  <span>
                     {moment
                        .utc(order?.createdAt)
                        .utcOffset("+06:00")
                        .format("DD MMMM YYYY, h:mm a")}
                  </span>
                  <div>
                     <span className="font-medium">Customer Name : </span>
                     <span className="capitalize">
                        {order?.customer?.contactInformation?.name}
                     </span>
                  </div>
                  <div>
                     <span className="font-medium">Shipping Address : </span>
                     <span className="capitalize">
                        {" "}
                        {order?.customer?.shippingAddress?.address} ,
                        {order?.customer?.shippingAddress?.thana}
                     </span>
                  </div>
                  <div>
                     <span className="font-medium">Phone : </span>{" "}
                     {order?.customer?.shippingAddress?.phone || "N/A"}
                  </div>
               </div>
            </div>
            <div className="space-y-2 text-xs">
               <div className="flex flex-col items-center justify-center gap-1">
                  <span>Order Id : {order?.orderId}</span>
                  <Barcode
                     format="CODE128"
                     lineColor="#000000"
                     background="#FFFFFF"
                     height={20}
                     value={order?.orderId}
                     width={0.9}
                     fontSize={10}
                     margin={0}
                     padding={0}
                     displayValue={false}
                     className="m-auto pl-2"
                  />
               </div>
               <div className="flex flex-col items-center justify-center gap-1">
                  <span>Invoice Id : IN2089666</span>
                  <Barcode
                     format="CODE128"
                     lineColor="#000000"
                     background="#FFFFFF"
                     height={20}
                     value={order?.orderId}
                     width={0.9}
                     fontSize={10}
                     margin={0}
                     padding={0}
                     displayValue={false}
                     className="m-auto pl-2"
                  />
               </div>
            </div>
         </div>

         <table className="w-full min-w-max table-auto text-left border border-blue-gray-100 text-xs">
            <thead>
               <tr>
                  {["Product", "Quantity", "Price", "Custom Discount"].map(
                     (head) => (
                        <th
                           key={head}
                           className="border-b border-blue-gray-100 bg-blue-100  px-4 py-1"
                        >
                           <Typography
                              variant="small"
                              className="font-medium leading-none opacity-70 text-black"
                           >
                              {head}
                           </Typography>
                        </th>
                     )
                  )}
               </tr>
            </thead>
            <tbody>
               {order?.orderedProducts?.map((x, index) => {
                  const isLast = index === order?.orderedProducts.length - 1
                  const classes = isLast
                     ? "px-4 py-1"
                     : "px-4 py-1 border-b border-blue-gray-200 text-xs"

                  return (
                     <tr key={x?._id}>
                        <td className={classes}>
                           <span className="font-medium ">
                              {x?.title?.length > 36
                                 ? x?.title.substr(0, 36)
                                 : x?.title}
                              {x?.title?.length > 36 && "..."}
                           </span>
                        </td>
                        <td className={classes}>
                           <span className="font-medium pl-2">
                              {" "}
                              {x?.cartQuantity}
                           </span>
                        </td>
                        <td className={`${classes} `}>
                           <div className={`flex item-start gap-2`}>
                              <span className="font-medium line-through">
                                 {" "}
                                 {Math.round(x?.price) * x?.cartQuantity}
                              </span>
                              <span className="font-medium ">
                                 {" "}
                                 {Math.round(x?.discountedPrice) *
                                    x?.cartQuantity}{" "}
                                 TK.
                              </span>
                           </div>
                        </td>
                        <td className={classes}>
                           <span className="font-medium ">
                              {x?.custom_discount?.amount * x?.cartQuantity}
                              {x?.custom_discount?.discountType === "percentage"
                                 ? " %"
                                 : " TK."}
                           </span>
                           {x?.custom_discount?.amount === 0 ? null : (
                              <span className="text-xs text-gray-500">
                                 Reason: {x?.custom_discount?.reason}
                              </span>
                           )}
                        </td>
                     </tr>
                  )
               })}
            </tbody>
         </table>
         <div className="flex flex-col items-end text-xs">
            <div className="border border-blue-gray-100 w-6/12">
               <div className="flex justify-between items-center border-b border-blue-gray-100 px-4 py-1 w-full">
                  <span>Subtotal</span>
                  <span> {order?.subTotal} TK.</span>
               </div>
               {order?.paymentDiscount?.value !== 0 && (
                  <div className="border-b border-blue-gray-100 px-4 py-1 w-full">
                     <div className="flex justify-between items-center ">
                        <span>Additional Discount</span>
                        <div className="flex justify-end gap-3">
                           <span>
                              {order?.paymentDiscount?.value || 0}{" "}
                              {order?.paymentDiscount?.discountType ===
                              "percentage"
                                 ? " %"
                                 : "TK."}
                           </span>
                        </div>
                     </div>
                     <div className="text-xs text-gray-600 flex-col">
                        {order?.paymentDiscount?.reason && (
                           <span className="block">
                              Reason : {order?.paymentDiscount?.reason}
                           </span>
                        )}
                        {order?.paymentDiscount?.value !== 0 && (
                           <span className="block">
                              After Discount :
                              {order?.subTotal - paymentDiscountAmount} TK.
                           </span>
                        )}
                     </div>
                  </div>
               )}
               <div className=" border-b border-blue-gray-100 px-4 py-1 w-full">
                  <div className="flex  justify-between items-center">
                     <span>Delivery Fee</span>
                     <span>{order?.paymentShipping?.shipping_cost} TK.</span>
                  </div>
                  <span>
                     {" "}
                     {order?.shipping?.shipping_type ? (
                        <span className="text-xs text-gray-600">
                           {order?.shipping?.shipping_type}
                        </span>
                     ) : null}
                  </span>
               </div>

               <div className="flex justify-between items-center border-b border-blue-gray-100 px-4 py-1 w-full">
                  <span>Total</span>
                  <span>
                     {order?.totalPrice} TK. ({order?.isPaid ? "Paid" : "Due"}){" "}
                  </span>
               </div>

               <div className="flex justify-between items-center border-b border-blue-gray-100 px-4 py-1 w-full">
                  <span>Payment Method</span>
                  <span>{order?.paymentMethod}</span>
               </div>

               <div className="flex justify-between items-center px-4 py-1 w-full">
                  <span>Customer Payable</span>
                  <span>
                     {order?.isPaid == true ? 0 : order?.totalPrice} TK.
                  </span>
               </div>
            </div>
         </div>
      </div>
   )
}

export default InvoiceSlip
