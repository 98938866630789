import React from 'react';

const DeleteCustomer = () => {

    return (
        <div>
            <input type="checkbox" id="deleteCustomer" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative pb-0 dark:bg-dark">
                    <label htmlFor="deleteCustomer" className="absolute right-2 top-2 text-[20px] mr-2 cursor-pointer">✕</label>
                    <h3 className="text-lg font-semibold border-b pb-4 mb-5">Delete Customer</h3>
                    <div className='text-[15px]'>
                        <form action="" className='space-y-4'>
                            <div className='flex flex-col w-full text-sm'>
                                <label htmlFor="notes" className=" font-semibold mb-3">Reason</label>
                                <input type="text" className='dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none' />
                                <span className="mt-1">Only you and other staff can see this reason.</span>
                            </div>
                            <div className='text-right py-5 border-t'>
                                <button type='submit' className="py-2 px-4 border bg-[#d82c0d] text-white rounded-lg cursor-pointer mr-2" >Delete</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteCustomer;
