import { Link, useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { DashboardContext } from "../../context/DashboardProvider"
import AgGridTable from "../../components/AgGridTable"
import { Avatar } from "rsuite"
import {
   useApproveReviewMutation,
   useDeleteReviewMutation,
   useAddTopTenReviewMutation,
   useGetReviewQuery,
} from "../../services/reviewVarify"
import { toast } from "react-hot-toast"

const VerifyReviews = () => {
   const { employeeInfo } = useContext(DashboardContext)
   const permission = employeeInfo?.find((data) => data?.permission)
   const [tableData, setTableData] = useState(null)
   const navigate = useNavigate()
   const [deleteReview] = useDeleteReviewMutation()
   const [approveReview] = useApproveReviewMutation()
   const [addTopTenReview] = useAddTopTenReviewMutation()
   const { data } = useGetReviewQuery()

   const deleteReviewHandle = (data) => {
      deleteReview({
         id: data?._id,
         approved: true,
         productId: data?.productId,
      })
      toast.success("Review delete successfull", { duration: 1000 })
   }

   const approveReviewHandle = (data) => {
      approveReview({
         id: data?._id,
         approved: true,
         productId: data?.productId,
      })
      toast.success("Review approved successfull", { duration: 1000 })
   }

   const addTopTenHandle = (data) => {
      addTopTenReview({
         reviewId: data?._id,
         topTen: !data?.topTen,
         productId: data?.productId,
      })
      toast.success("TopTen added successfull", { duration: 1000 })
   }

   const columnDef = [
      {
         headerName: "Image",
         field: "image",
         cellRenderer: (x) => (
            <div className="px-4 block !no-underline">
               <Avatar src={x?.data?.product?.images[0]} alt={x?.data?.title} />
            </div>
         ),
      },
      {
         headerName: "User",
         field: "user",
         cellRenderer: function (x) {
            return (
               <div className="px-4 block !no-underline">
                  {x?.data?.userName}
               </div>
            )
         },
      },
      {
         headerName: "Product",
         field: "product",
         cellRenderer: function (x) {
            return (
               <div className="px-4 block !no-underline">
                  {x?.data?.product?.title > 36
                     ? x?.data?.product?.title.substr(0, 36) + "..."
                     : x?.data?.product?.title}
               </div>
            )
         },
      },
      {
         headerName: "Comment",
         field: "comment",
         cellRenderer: function (x) {
            return (
               <div className="px-4 block !no-underline">
                  {x?.data?.comment > 36
                     ? x?.data?.comment.substr(0, 36) + "..."
                     : x?.data?.comment}
               </div>
            )
         },
      },
      {
         headerName: "Rating",
         field: "rating",
         cellRenderer: function (x) {
            return (
               <div className="px-6 block !no-underline">
                  {x?.data?.rating}
               </div>
            )
         },
      },
      // {
      //    headerName: "Top Ten",
      //    field: "topTen",
      // },
      {
         headerName: "Actions",
         field: "_id",
         cellRenderer: (x) => (
            <div className="text-sm z-50 px-4">
               {permission?.permission !== "View Only" ? (
                  <ul>
                     <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white z-50 flex gap-2">
                        {x?.data?.approved ? (
                           <>
                              <button className="bg-green-200 text-green-600 px-3 py-1 rounded-lg text-sm">
                                 <svg
                                    width="28px"
                                    height="28px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    color="#1f9e28"
                                    stroke-width="1.5"
                                 >
                                    <path
                                       fill-rule="evenodd"
                                       clip-rule="evenodd"
                                       d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM7.53044 11.9697C7.23755 11.6768 6.76268 11.6768 6.46978 11.9697C6.17689 12.2626 6.17689 12.7374 6.46978 13.0303L9.46978 16.0303C9.76268 16.3232 10.2376 16.3232 10.5304 16.0303L17.5304 9.03033C17.8233 8.73744 17.8233 8.26256 17.5304 7.96967C17.2375 7.67678 16.7627 7.67678 16.4698 7.96967L10.0001 14.4393L7.53044 11.9697Z"
                                       fill="#1f9e28"
                                    ></path>
                                 </svg>
                              </button>

                              <button
                                 onClick={() => addTopTenHandle(x?.data)}
                                 className={`  px-3 py-1 rounded-lg text-sm `}
                              >
                                 {x?.data?.topTen == true ? (
                                    <svg
                                       width="28px"
                                       height="28px"
                                       viewBox="0 0 24 24"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                       color="#55a018"
                                       stroke-width="1.5"
                                    >
                                       <path
                                          d="M8.58737 8.23597L11.1849 3.00376C11.5183 2.33208 12.4817 2.33208 12.8151 3.00376L15.4126 8.23597L21.2215 9.08017C21.9668 9.18848 22.2638 10.0994 21.7243 10.6219L17.5217 14.6918L18.5135 20.4414C18.6409 21.1798 17.8614 21.7428 17.1945 21.3941L12 18.678L6.80547 21.3941C6.1386 21.7428 5.35909 21.1798 5.48645 20.4414L6.47825 14.6918L2.27575 10.6219C1.73617 10.0994 2.03322 9.18848 2.77852 9.08017L8.58737 8.23597Z"
                                          fill="#55a018"
                                          stroke="#55a018"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                       ></path>
                                    </svg>
                                 ) : (
                                    <svg
                                       width="28px"
                                       height="28px"
                                       stroke-width="1.5"
                                       viewBox="0 0 24 24"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                       color="#55a018"
                                    >
                                       <path
                                          d="M8.58737 8.23597L11.1849 3.00376C11.5183 2.33208 12.4817 2.33208 12.8151 3.00376L15.4126 8.23597L21.2215 9.08017C21.9668 9.18848 22.2638 10.0994 21.7243 10.6219L17.5217 14.6918L18.5135 20.4414C18.6409 21.1798 17.8614 21.7428 17.1945 21.3941L12 18.678L6.80547 21.3941C6.1386 21.7428 5.35909 21.1798 5.48645 20.4414L6.47825 14.6918L2.27575 10.6219C1.73617 10.0994 2.03322 9.18848 2.77852 9.08017L8.58737 8.23597Z"
                                          stroke="#55a018"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                       ></path>
                                    </svg>
                                 )}
                              </button>
                           </>
                        ) : (
                           <button
                              onClick={() => approveReviewHandle(x?.data)}
                              className="bg-blue-200 text-blue-600 px-3 py-1 rounded-lg text-sm"
                           >
                              <svg
                                 width="28px"
                                 height="28px"
                                 stroke-width="1.5"
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                                 color="#2d93d2"
                              >
                                 <path
                                    d="M7 12.5L10 15.5L17 8.5"
                                    stroke="#2d93d2"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                 ></path>
                                 <path
                                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                    stroke="#2d93d2"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                 ></path>
                              </svg>
                           </button>
                        )}
                        <button
                           onClick={() => deleteReviewHandle(x?.data)}
                           className="py-1"
                        >
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 cursor-pointer text-red-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                           >
                              <path
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                                 d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              ></path>
                           </svg>
                        </button>
                     </li>
                  </ul>
               ) : (
                  <p className="py-3">N/A</p>
               )}
            </div>
         ),
      },
   ]

   useEffect(() => {
      setTableData(data)
   }, [data])
   return (
      <section>
         <div className="border-b border-gray-200 md:mx-10 mx-4 py-5  flex items-center justify-between">
            <div className="sm:flex gap-4 items-center">
               <div className="flex items-center text-darkText">
                  <span>
                     <svg
                        width="18"
                        height="18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M1.045 4.488h15.91"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinejoin="round"
                        ></path>
                        <path
                           d="M4.325 1.164h9.589c.423 0 .824.187 1.095.512l1.617 1.94c.213.257.33.58.33.913v10.408a1.9 1.9 0 0 1-1.9 1.9H3.183a1.9 1.9 0 0 1-1.9-1.9V4.529c0-.333.117-.656.33-.912L3.23 1.676c.271-.325.672-.512 1.095-.512zM6.98 7.812h4.275M9.12 1.4v2.85"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        ></path>
                     </svg>
                  </span>
                  <button
                     type="button"
                     onClick={() => navigate("/users")}
                     className="text-sm sm:text-lg px-2 sm:px-4 hover:text-black"
                  >
                     All Reviews
                  </button>
               </div>
            </div>
         </div>
         <div className="flex items-start gap-5 flex-col overflow-hidden mx-4 md:mx-6">
            <div className="flex gap-10 mt-7 mb-3">
               <h3 className="text-sm sm:text-[17px] md:text-[18px] text-[#9191A1]">
                  Total Review: {tableData?.length}
               </h3>
            </div>
            {tableData?.length === 0 ? (
               <div className="text-red-600 flex items-center justify-center  h-[60vh]">
                  No User Available!
               </div>
            ) : (
               <AgGridTable
                  data={tableData}
                  columnDef={columnDef}
                  height="70vh"
               />
            )}
         </div>
      </section>
   )
}

export default VerifyReviews
