import React, { useContext, useRef, useState } from "react"
import { Link } from "react-router-dom"
import DeleteItem from "../Actions/DeleteItem"
import { useParams } from "react-router-dom"
import { useGetBookQuery } from "../../../../services/books"
import Barcode from "react-barcode"
import { useBarcode } from "@createnextapp/react-barcode"
import { DashboardContext } from "../../../../context/DashboardProvider"
import { userAccount } from "../../../../utils/utils"
import { useReactToPrint } from "react-to-print"

const GeneralBooksView = () => {
   const { id } = useParams()
   const { data: product } = useGetBookQuery(id)
   const [qr, setqr] = useState(null)
   const [productName, setProductName] = useState("")
   const componentRef = useRef()
   const handlePrint = useReactToPrint({
      content: () => componentRef.current,
   })

   // print bar code
   const combineFn = (e, data) => {
      e.preventDefault()
      if (data?.barcode === undefined) {
         alert("No barcode found with this product")
         setqr(null)
      } else {
         setqr(data?.barcode)
         setProductName(data?.title)
         setTimeout(() => {
            handlePrint()
         }, 1000)
      }
   }

   const { employeeInfo } = useContext(DashboardContext)
   const permission = employeeInfo?.find((data) => data?.permission)

   const inputStyle =
      "px-2 bg-white shadow py-2.5 rounded-md duration-300 outline-none placeholder:font-thin text-sm dark:bg-gray-50 dark:text-dark placeholder:text-darkText"
   const textAreaStyle =
      "px-4 bg-white py-2 rounded-lg dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin h-[120px] text-sm dark:text-dark"
   const boxStyle = "flex flex-col w-full border p-2 rounded"
   const labelStyle = "text-palette10 select-none"

   return (
      <div className="p-5 rounded-lg dark:text-white">
         <div className="flex justify-between items-center">
            <h3 className="text-2xl uppercase text-red-600 mb-5">
               General Books
            </h3>
            <div className="dropdown dropdown-end">
               {permission?.permission !== "View Only" && (
                  <label
                     tabIndex={0}
                     className="m-1 flex cursor-pointer dark:text-white"
                  >
                     More actions
                     <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg_375hu w-5 dark:text-[#ffffff]"
                        focusable="false"
                        aria-hidden="true"
                     >
                        <path d="M13.098 8h-6.196c-.751 0-1.172.754-.708 1.268l3.098 3.432c.36.399 1.055.399 1.416 0l3.098-3.433c.464-.513.043-1.267-.708-1.267Z"></path>
                     </svg>
                  </label>
               )}
               <ul
                  tabIndex={0}
                  className="dropdown-content p-2 shadow bg-base-100 rounded-box w-60 dark:bg-dark"
               >
                  <li className="py-3 px-3 rounded-md hover:bg-gray-100 hover:text-dark ">
                     <Link
                        to={`/edit-general/${product?.product?._id}`}
                        className="flex items-center "
                     >
                        <svg
                           viewBox="0 0 20 20"
                           className="Polaris-Icon__Svg_375hu w-4 mr-4"
                           focusable="false"
                           aria-hidden="true"
                        >
                           <path d="M7.5 2a1.5 1.5 0 0 0-1.5 1.5v9.5a1 1 0 0 0 1 1h9.5a1.5 1.5 0 0 0 1.5-1.5v-9a1.5 1.5 0 0 0-1.5-1.5h-9zm-4 4h.5v10h10v.5a1.5 1.5 0 0 1-1.5 1.5h-9a1.5 1.5 0 0 1-1.5-1.5v-9a1.5 1.5 0 0 1 1.5-1.5z"></path>
                        </svg>
                        <h3 className="text-sm capitalize">edit</h3>
                     </Link>
                  </li>

                  {/* <label htmlFor="deleteOrder" className='flex items-center py-3 px-3 rounded-md hover:bg-gray-100 w-full cursor-pointer hover:text-dark'>
                            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu  w-4 mr-4" focusable="false" aria-hidden="true"><path d="M6.707 5.293a1 1 0 0 0-1.414 1.414l3.293 3.293-3.293 3.293a1 1 0 1 0 1.414 1.414l3.293-3.293 3.293 3.293a1 1 0 0 0 1.414-1.414l-3.293-3.293 3.293-3.293a1 1 0 0 0-1.414-1.414l-3.293 3.293-3.293-3.293Z"></path></svg>
                            cancel
                        </label> */}
                  <li
                     onClick={(e) => combineFn(e, product?.product)}
                     className="py-3 px-3 rounded-md hover:bg-gray-100 hover:text-dark cursor-pointer"
                  >
                     <p className="flex items-center">
                        <svg
                           viewBox="0 0 20 20"
                           className="Polaris-Icon__Svg_375hu w-4 mr-4"
                           focusable="false"
                           aria-hidden="true"
                        >
                           <path
                              fillRule="evenodd"
                              d="M14 11h2v-2h-2v2zm-7-4h6v-3h-6v3zm0 9h6v-2h-6v2zm8-9v-3.5a1.5 1.5 0 0 0-1.5-1.5h-7a1.5 1.5 0 0 0-1.5 1.5v3.5h-1.5a1.5 1.5 0 0 0-1.5 1.5v6a1.5 1.5 0 0 0 1.5 1.5h1.5v.5a1.5 1.5 0 0 0 1.5 1.5h7a1.5 1.5 0 0 0 1.5-1.5v-.5h1.5a1.5 1.5 0 0 0 1.5-1.5v-6a1.5 1.5 0 0 0-1.5-1.5h-1.5z"
                           ></path>
                        </svg>
                        <h3 className="text-sm capitalize">Print Barcode</h3>
                     </p>
                  </li>
                  <li className="hidden">
                     <div
                        ref={componentRef}
                        className="flex justify-center items-center h-full pb-2 pt-2.5 w-[99vw] m-0"
                     >
                        <div className="flex flex-col justify-center items-center ">
                           <small className="text-[8px]">
                              ichchapuron.com.bd
                           </small>

                           <Barcode
                              format="CODE128"
                              lineColor="#000000"
                              background="#FFFFFF"
                              height={40}
                              value={qr}
                              width={0.9}
                              fontSize={10}
                              margin={0}
                              padding={0}
                           />
                           <p className="text-[8px]">
                              {productName?.split(" ")?.length > 2
                                 ? `${productName
                                      ?.split(" ")
                                      .slice(0, 2)
                                      .join(" ")}...`
                                 : productName}
                           </p>
                        </div>
                     </div>
                  </li>
               </ul>
               <DeleteItem></DeleteItem>
            </div>
         </div>

         <div className="flex gap-8 mb-3 items-start justify-between">
            <div className={`${boxStyle} w-full h-full`}>
               <p className={labelStyle}>Ichchapuron ID</p>
               <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                  {product?.product?.barcode || "N/A"}
               </p>
            </div>
            <Barcode
               value={product?.product?.barcode}
               options={{
                  width: 1.85,
                  height: 50,
                  format: "CODE128",
                  displayValue: true,
                  font: "monospace",
                  textAlign: "center",
                  textPosition: "bottom",
                  textMargin: 2,
                  fontSize: 20,
                  background: "#ffffff",
                  lineColor: "#000000",
               }}
            />
         </div>
         <div className="flex justify-between gap-8 w-full">
            <div className="space-y-3 w-full">
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>Product Name</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark w-[300px]">
                        {product?.product?.title}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>Category</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.category}
                     </p>
                  </div>
               </div>
               {/* <div className={ boxStyle }>
                        <div className={ `${boxStyle} w-full h-full` }>
                            <p className={ labelStyle }>Schools</p>
                            <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">{ product?.product?.schools?.length === 0 ? "n/a" : product?.product?.schools }</p>
                        </div>

                    </div> */}
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>Sub Category</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.subCategory}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>Type</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.type}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>Price</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.price}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>publisher</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.publisher}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>isbn</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.isbn}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>published</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.published}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>edition</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.edition}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>language</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.language}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>count In Stock</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.countInStock}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>discount</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {userAccount === "gyankosh"
                           ? product?.product?.gyankoshDiscount
                           : product?.product?.discount}{" "}
                        {}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>Cost per item</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.costPerItem === 0
                           ? "n/a"
                           : product?.product?.costPerItem}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>Page Number</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.pageNumber === 0
                           ? "n/a"
                           : product?.product?.pageNumber}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>Product Status</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.status === true
                           ? "Active"
                           : "Inactive"}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>onlineStore</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.onlinestore === "online store"
                           ? "True"
                           : "False"}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>pointOfSale</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.pointofsale === "point of sale"
                           ? "True"
                           : "False"}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>Todays Deal</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.todaysDeal === true
                           ? "True"
                           : "False"}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>Trending</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.trending === true ? "True" : "False"}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>Popular Now</p>
                     <p className=" w-full p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.popularNow === true
                           ? "True"
                           : "False"}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>
                        Book Fair {new Date().getFullYear()}
                     </p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.bookfair === true ? "True" : "False"}
                     </p>
                  </div>
               </div>
            </div>
            {/* right side input box */}
            <div className="space-y-3 w-full">
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>Author Name</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.author?.name}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>Author Description</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.author?.description}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>Author Image</p>
                     <img
                        src={product?.product?.authorImage}
                        alt=""
                        className="w-36 h-36"
                     />
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>subWriter Name</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.subWriter
                           ? product?.product?.subWriter
                           : "n/a"}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>SubWriter Description</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.subWriterDescription
                           ? product?.product?.subWriterDescription
                           : "n/a"}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>subWriter Image</p>
                     <img
                        src={product?.product?.subWriterImage}
                        alt=""
                        className="w-36 h-36"
                     />
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>Ebook</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.ebook
                           ? "Available"
                           : "Not Available"}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>Ebook Price</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.ebookPrice}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>ebook Discount</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.ebookDiscount}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>Product Image</p>
                     <img
                        src={product?.product?.images[0]}
                        alt=""
                        className="w-full h-full h-36"
                     />
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>Product Description</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.description}
                     </p>
                  </div>
               </div>
               <div className={boxStyle}>
                  <div className={`${boxStyle} w-full h-full`}>
                     <p className={labelStyle}>order Type</p>
                     <p className="p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                        {product?.product?.orderType
                           ? product?.product?.orderType
                           : "n/a"}
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default GeneralBooksView
