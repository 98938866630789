import React from 'react';
import Menubar from './Menubar/Menubar';

const Frontend = () => {
    return (
        <div>
            <Menubar></Menubar>
        </div>
    );
};

export default Frontend;