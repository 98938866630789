import React from 'react';
import { useNavigate } from 'react-router-dom';

    

const ReportItem = ({report}) => {
    const navigate = useNavigate()

    const additemreport=(report)=>{
        navigate('/reportItemDetails')
    }
   
    return (
            <div onClick={()=>additemreport(report)}  className="w-full bg-base-100 duration-300 hover:shadow-xl rounded-2xl mb-6 dark:bg-dark dark:text-white">
                <div className="flex px-5 items-center gap-5 py-3">
                    <div className='w-[40px]'>
                    {report.icon}
                    </div>
                    <div>
                        <p className='text-[15px] mt-3'>{report.description}</p>
                    </div>
                </div>
            </div>
    );
};

export default ReportItem;

