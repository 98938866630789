import React from "react"
import { useState } from "react"
import { FaAngleDown } from "react-icons/fa"
import { inventoryData } from "../Inventory/SubmenuData"

const Search = () => {
   const [selectFolder, setSelectFolder] = useState(true)
   return (
      <div>
         <h3 className="text-[22px] font-semibold py-5">Filters</h3>
         <div className="flex justify-between items-start">
            <div>
               <span
                  onClick={() => setSelectFolder(!selectFolder)}
                  className=" m-1 flex items-center gap-1 font-semibold"
               >
                  <FaAngleDown></FaAngleDown>
                  Folders
               </span>
               <ul className={`p-2 w-52 ${selectFolder && "hidden"}`}>
                  <li>
                     <form action="" className="flex flex-col justify-start">
                        {inventoryData?.map((item) => {
                           const { name } = item
                           return (
                              <div className="my-2">
                                 <input
                                    type="checkbox"
                                    id={`${name}`}
                                    className="border mr-2"
                                 />
                                 <label htmlFor={`${name}`}>{name}</label>
                              </div>
                           )
                        })}
                     </form>
                  </li>
               </ul>
            </div>
            <div>
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 mt-2"
               >
                  <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
               </svg>
            </div>
         </div>
      </div>
   )
}

export default Search
