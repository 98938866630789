import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const electronicsApi = createApi({
   reducerPath: "electronicsApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["ElectronicsApi"],
   endpoints: (builder) => ({
      getElectronics: builder.query({
         query: () => `api/electronics/all`,
         providesTags: ["ElectronicsApi"],
      }),
      getOneElectronic: builder.query({
         query: (id) => `api/electronics/one/${id}`,
         providesTags: ["ElectronicsApi"],
      }),
      createElectronic: builder.mutation({
         query: (value) => ({
            url: "api/electronics",
            method: "POST",
            body: value,
         }),
         invalidatesTags: ["ElectronicsApi"],
      }),
      editElectronic: builder.mutation({
         query: ({ id, ...rest }) => ({
            url: `api/electronics/${id}`,
            method: "put",
            body: rest,
         }),
         invalidatesTags: ["ElectronicsApi"],
      }),
      deleteElectronic: builder.mutation({
         query: (id) => ({
            url: `api/electronics/${id}`,

            method: "DELETE",
         }),
         invalidatesTags: ["ElectronicsApi"],
      }),
   }),
})

export const {
   useCreateElectronicMutation,
   useGetElectronicsQuery,
   useGetOneElectronicQuery,
   useEditElectronicMutation,
   useDeleteElectronicMutation,
} = electronicsApi
