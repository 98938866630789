import { useState } from "react"
import { Link } from "react-router-dom"
import LeavePage from "../../../../../components/LeavePage"
import ShippingRequired from "./ShippingRequired"

const OrderReturn = () => {
   const [quantity, setQuantity] = useState(0)
   const [refund, setRefund] = useState("")
   const [method, setMethod] = useState("cash")
   const [shippingOption, setshippingOption] = useState("")

   return (
      <>
         <div className="p-2 mb-5 m-5 dark:text-white">
            <div className="py-3 flex items-center">
               <label
                  htmlFor="leavePage"
                  className="text-[#5c5f62] p-2 mr-3 rounded-md border border-gray-300 cursor-pointer"
               >
                  <svg
                     viewBox="0 0 20 20"
                     className="Polaris-Icon__Svg_375hu w-5 h-5"
                     focusable="false"
                     aria-hidden="true"
                  >
                     <path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path>
                  </svg>
               </label>
               <div>
                  <h3 className="font-semibold text-xl">
                     Order Return & Refund
                  </h3>
                  <h3 className="font-semibold text-xl">#1017</h3>
               </div>
               <LeavePage></LeavePage>
            </div>
            <div className="grid grid-cols-3 gap-4">
               <div className="col-span-2 ">
                  <div className="bg-white dark:bg-dark dark:border-gray-600 dark:text-white w-full border rounded-lg p-5 shadow">
                     {/* product start */}
                     <div className="text-xs flex justify-between py-5">
                        <div className="flex gap-4 mr-28">
                           <div>
                              <img
                                 src="https://i.ibb.co/WNhXjBf/IMG-1352-40x40.webp"
                                 alt="product"
                                 className="border px-2"
                              />
                           </div>
                           <div>
                              <h3 className="font-semibold">Humayon Ahmed</h3>
                              <h3>Medium</h3>
                              <h3>SKU: 9846549</h3>
                           </div>
                        </div>
                        <div className="relative w-[94px]">
                           <input
                              type="text"
                              value={quantity}
                              className="dark:text-dark border rounded-lg py-2 pl-4 w-full outline-none border-gray-300"
                           />
                           <div className="absolute right-0 space-y-1 top-0 text-[10px] text-gray-400">
                              <button
                                 onClick={() => setQuantity(quantity + 1)}
                                 className="block "
                              >
                                 <svg
                                    viewBox="0 0 20 20"
                                    className="Polaris-Icon__Svg_375hu w-4 text-gray-400 bg-gray-300 rounded-lg"
                                    focusable="false"
                                    aria-hidden="true"
                                 >
                                    <path d="M6.902 12h6.196c.751 0 1.172-.754.708-1.268l-3.098-3.432c-.36-.399-1.055-.399-1.416 0l-3.098 3.433c-.464.513-.043 1.267.708 1.267Z"></path>
                                 </svg>
                              </button>
                              <button onClick={() => setQuantity(quantity - 1)}>
                                 <svg
                                    viewBox="0 0 20 20"
                                    className="Polaris-Icon__Svg_375hu w-4 text-gray-400 bg-gray-300 rounded-lg"
                                    focusable="false"
                                    aria-hidden="true"
                                 >
                                    <path d="M13.098 8h-6.196c-.751 0-1.172.754-.708 1.268l3.098 3.432c.36.399 1.055.399 1.416 0l3.098-3.433c.464-.513.043-1.267-.708-1.267Z"></path>
                                 </svg>
                              </button>
                           </div>
                           <div className="absolute right-8 space-y-2 top-2 text-[15px]">
                              <span>/ 1</span>
                           </div>
                        </div>
                        <span>৳60.00</span>
                     </div>
                     <div className="flex flex-col w-full text-sm">
                        <label htmlFor="reason">Select a return reason</label>
                        <select className="border dark:text-dark border-gray-300 rounded-lg px-3 py-2 w-full mt-1 outline-none text-sm">
                           <option>Unknown</option>
                           <option>Size was too small</option>
                           <option>Size was too large</option>
                           <option>Customer changed their mind</option>
                           <option>Item not as described</option>
                           <option>Received the wrong item</option>
                           <option>Damaged or defective</option>
                           <option>Style</option>
                           <option>Color</option>
                           <option>Other</option>
                        </select>
                     </div>
                  </div>
                  <div className="mt-5">
                     <label htmlFor="refundPayment" className="cursor-pointer">
                        <input
                           type="checkbox"
                           defaultChecked
                           id="refundPayment"
                           name="refund"
                           className="mr-2"
                        />
                        <span>Restock items</span>
                     </label>
                  </div>
                  <div className="bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 shadow mt-4 space-y-2">
                     <p className="font-semibold">Return shipping options</p>
                     <div>
                        <label
                           htmlFor="noShipping"
                           className="cursor-pointer text-sm"
                        >
                           <input
                              onClick={(e) => setshippingOption(e.target.value)}
                              type="radio"
                              id="noShipping"
                              value={"noShipping"}
                              name="returnShipping"
                              className="mr-2"
                           />
                           <span>No shipping required</span>
                        </label>
                     </div>
                     <div>
                        <label
                           htmlFor="shippingRequired"
                           className="cursor-pointer text-sm"
                        >
                           <input
                              onClick={(e) => setshippingOption(e.target.value)}
                              type="radio"
                              id="shippingRequired"
                              value={"shippingRequired"}
                              name="returnShipping"
                              className="mr-2"
                           />
                           Shipping Required
                        </label>
                     </div>
                     {/* shipping information */}
                     <div
                        className={`bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 shadow text-[15px] ${
                           shippingOption === "shippingRequired"
                              ? "block"
                              : "hidden"
                        }`}
                     >
                        <h3 className="text-lg font-semibold pb-4">
                           Shipping Information
                        </h3>
                        <form action="" className="space-y-3">
                           <div className="flex flex-col w-full">
                              <label htmlFor="carrier">Shipping carrier</label>
                              <input
                                 type="text"
                                 className="dark:text-dark w-6/12 px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                              />
                           </div>
                           <div className="flex flex-col w-full">
                              <label htmlFor="cost">Shipping cost</label>
                              <input
                                 type="text"
                                 className="dark:text-dark w-6/12 px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                              />
                           </div>
                           <div className="flex flex-col w-full">
                              <label htmlFor="cost">Reason</label>
                              <input
                                 type="text"
                                 className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                              />
                              <span>Your customer can see this reason</span>
                           </div>
                           <div className="text-right py-5 border-t">
                              <button
                                 onClick={() => setshippingOption("")}
                                 htmlFor="shippingRequired"
                                 className="py-2 px-4 border border-gray-300 rounded-lg cursor-pointer mr-2"
                              >
                                 Cancel
                              </button>
                              <button
                                 type="submit"
                                 className="py-2 px-4 bg-[#008060] rounded-lg text-white"
                              >
                                 Save
                              </button>
                           </div>
                        </form>
                     </div>
                  </div>
                  <div className="my-4">
                     <div>
                        <label
                           htmlFor="applicable"
                           className="cursor-pointer text-sm"
                        >
                           <input
                              onChange={(e) => setRefund(e.target.value)}
                              type="radio"
                              value={"applicable"}
                              id="applicable"
                              name="returnShipping"
                              className="mr-2"
                           />
                           <span>Refund is Applicable</span>
                        </label>
                     </div>
                     <div
                        className={`bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 shadow mt-4 space-y-2 mb-5 text-[15px] ${
                           refund === "applicable" ? "block" : "hidden"
                        }`}
                     >
                        <div>
                           <label
                              htmlFor="refundPayment"
                              className="cursor-pointer"
                           >
                              Refund Amount
                              <input
                                 type="text"
                                 value={"$20"}
                                 id="refundPayment"
                                 className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 mt-1 outline-none text-sm w-28 ml-5"
                              />
                           </label>
                        </div>
                        <div>
                           <label
                              htmlFor="refundPayment"
                              className="cursor-pointer"
                           >
                              Refund Method
                              <select
                                 onChange={(e) => setMethod(e.target.value)}
                                 className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 mt-1 outline-none text-sm w-28 ml-5"
                              >
                                 <option value={"cash"}>cash</option>
                                 <option value={"card"}>card</option>
                                 <option value={"bkash"}>bKash</option>
                              </select>
                           </label>
                        </div>
                        <div
                           className={`flex flex-col w-full ${
                              method === "cash" ? "hidden" : "block"
                           }`}
                        >
                           <label htmlFor="discountType">
                              Refund Transaction Reference Number
                           </label>
                           <input
                              type="text"
                              className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                           />
                        </div>
                        <div className="flex flex-col w-full">
                           <label htmlFor="discountType">Refund Reason</label>
                           <input
                              type="text"
                              className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                           />
                        </div>
                     </div>
                     <div>
                        <label
                           htmlFor="notApplicable"
                           className="cursor-pointer text-sm"
                        >
                           <input
                              onChange={(e) => setRefund(e.target.value)}
                              type="radio"
                              id="notApplicable"
                              name="returnShipping"
                              className="mr-2"
                           />
                           <span>Refund is not Applicable</span>
                        </label>
                     </div>
                  </div>
                  <div className="space-y-5 mt-5 text-right">
                     <Link
                        to="/order/id"
                        className="py-2 px-5 bg-main rounded-lg text-white"
                     >
                        Confirm $20
                     </Link>
                     <label htmlFor="restock" className="cursor-pointer block">
                        <input
                           type="checkbox"
                           defaultChecked
                           id="restock"
                           name="refund"
                           className="mr-2"
                        />
                        <span>Send a Notification to the number</span>
                     </label>
                  </div>
               </div>
               <div className="col-span-1 bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 shadow">
                  <h3 className="font-semibold mb-3">Summary</h3>
                  <div className="">
                     <ul className="list-disc ml-4 text-sm">
                        <li>
                           <p>Returning 1 item</p>
                           <p>Reason: Unknown</p>
                        </li>
                        <li>No return shipping</li>
                     </ul>
                     <div className="mt-5 pt-5 border-t">
                        <button className="py-2 text-center bg-main rounded-md w-full text-sm font-semibold text-white">
                           Update order
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default OrderReturn
