import LeavePage from "../../../components/LeavePage"

const EditCustomer = () => {
   const inputBox =
      "w-full px-3 py-2 border border-gray-300 rounded-lg outline-none dark:text-dark"

   return (
      <>
         <div className="p-2 mb-5 m-5 mx-16 dark:text-white">
            <div className="py-3 flex items-center">
               <label
                  htmlFor="leavePage"
                  className="text-[#5c5f62] p-2 mr-4 rounded-md border border-gray-300"
               >
                  <svg
                     viewBox="0 0 20 20"
                     className="Polaris-Icon__Svg_375hu w-5 h-5"
                     focusable="false"
                     fill="gray"
                     aria-hidden="true"
                  >
                     <path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path>
                  </svg>
               </label>
               <h3 className="font-semibold text-xl">Edit customer</h3>
            </div>
            <LeavePage></LeavePage>
            <div className="grid grid-cols-3 gap-4 pt-5 border-t dark:border-gray-600">
               <div className="col-span-1 ">
                  <h3 className="pt-5  text-black dark:text-white">
                     Customer overview
                  </h3>
               </div>
               <div className="col-span-2 bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 shadow">
                  <form action="" className="space-y-4 relative text-[15px]">
                     {/* first name and last name */}
                     <div className="flex items-center justify-between gap-2">
                        <div className="flex flex-col w-full ">
                           <label htmlFor="discountType">First Name</label>
                           <input
                              type="text"
                              className={inputBox}
                              placeholder="First name"
                           />
                        </div>
                        <div className="flex flex-col w-full">
                           <label htmlFor="discountType">Last Name</label>
                           <input
                              type="text"
                              className={inputBox}
                              placeholder="Last name"
                           />
                        </div>
                     </div>
                     {/* date of birth and customer source*/}
                     <div className="flex items-center justify-between gap-2">
                        <div className="flex flex-col w-full ">
                           <label htmlFor="dateOfBirth">Date Of Birth</label>
                           <input type="date" className={inputBox} />
                        </div>
                        <div className="flex flex-col w-full">
                           <label htmlFor="customerSource">
                              Customer Source
                           </label>
                           <input
                              type="text"
                              className={inputBox}
                              placeholder="Customer source"
                           />
                        </div>
                     </div>
                     {/* email */}
                     <div className="flex flex-col w-full">
                        <label htmlFor="discountType">Email</label>
                        <input
                           type="email"
                           className={`${inputBox} mt-1`}
                           placeholder="Enter your email"
                        />
                     </div>
                     {/* phone */}
                     <div className="flex flex-col w-full">
                        <label htmlFor="discountType">Phone number</label>
                        <input
                           type="phone"
                           className={`${inputBox} mt-1`}
                           placeholder="Phone"
                        />
                     </div>
                     <div className="space-y-5 text-sm">
                        <div>
                           <label htmlFor="email" className="cursor-pointer">
                              <input
                                 type="checkbox"
                                 id="email"
                                 name="refund"
                                 className="mr-2"
                              />
                              <span>
                                 Customer agreed to receive marketing emails.
                              </span>
                           </label>
                        </div>
                        <div>
                           <label htmlFor="messages" className="cursor-pointer">
                              <input
                                 type="checkbox"
                                 id="messages"
                                 name="refund"
                                 className="mr-2"
                              />
                              <span>
                                 Customer agreed to receive SMS marketing text
                                 messages.
                              </span>
                           </label>
                        </div>
                        <p className="text-gray-400">
                           You should ask your customers for permission before
                           you subscribe them to your marketing emails or SMS.
                        </p>
                     </div>
                  </form>
               </div>
            </div>
            <div className="grid grid-cols-3 gap-4 pt-5 mt-5 border-t dark:border-gray-600">
               <div className="col-span-1">
                  <h3 className="pt-5 text-black dark:text-white">Address</h3>
                  <span className="pt-5 text-sm">
                     The primary address of this customer
                  </span>
               </div>
               <div className="col-span-2 bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 shadow">
                  <form action="" className="space-y-4 relative text-[15px]">
                     {/* shipping address */}
                     <div className="space-y-3">
                        {/* address */}
                        <div className="flex flex-col w-full">
                           <label htmlFor="discountType">Address</label>
                           <input
                              type="text"
                              className={`${inputBox} mt-1`}
                              placeholder="Address"
                           />
                        </div>
                        {/* district and divistion */}
                        <div className="flex items-center justify-between gap-2">
                           <div className="flex flex-col w-full">
                              <label htmlFor="discountType">District</label>
                              <input
                                 type="text"
                                 className={inputBox}
                                 placeholder="District"
                              />
                           </div>
                           <div className="flex flex-col w-full">
                              <label htmlFor="discountType">Division</label>
                              <select className="border border-gray-300 rounded-lg px-3 py-2 w-full mt-1 outline-none text-sm">
                                 <option>Dhaka</option>
                                 <option>Chittagong</option>
                                 <option>Khulna</option>
                                 <option>Rajshahi</option>
                                 <option>Barisal</option>
                                 <option>Rangpur</option>
                                 <option>Sylhet</option>
                              </select>
                           </div>
                        </div>
                        {/* postcode and area */}
                        <div className="flex items-center justify-between gap-2">
                           <div className="flex flex-col w-full">
                              <label htmlFor="discountType">Postcode</label>
                              <input
                                 type="text"
                                 className={inputBox}
                                 placeholder="Postcode"
                              />
                           </div>
                           <div className="flex flex-col w-full">
                              <label htmlFor="discountType">Area</label>
                              <input
                                 type="text"
                                 className={inputBox}
                                 placeholder="Area"
                              />
                           </div>
                        </div>
                        {/* phone */}
                        <div className="flex flex-col w-full">
                           <label htmlFor="discountType">Phone</label>
                           <input
                              type="phone"
                              className={`${inputBox} mt-1`}
                              placeholder="Phone"
                           />
                        </div>
                     </div>
                  </form>
               </div>
            </div>
            <div className="grid grid-cols-3 gap-4 pt-5 mt-5 border-t dark:border-gray-600">
               <div className="col-span-1 ">
                  <h3 className="pt-5 text-black dark:text-white">Notes</h3>
                  <span className="pt-5 text-sm">
                     Add notes about your customer.
                  </span>
               </div>
               <div className="col-span-2 bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 shadow">
                  <h3 className=" font-semibold mb-3">Notes</h3>
                  <div className="py-1 px-2 pl-4 w-full flex items-center justify-between text-[#4F5366] bg-white rounded-lg border border-gray-300">
                     <input
                        type="text"
                        className="py-1 bg-white outline-none placeholder:font-thin"
                     />
                  </div>
               </div>
            </div>
            <div className="text-[15px] py-5 text-right capitalize space-x-4">
               <button className="py-2 px-5 bg-main text-white rounded-lg text-[15px]">
                  Update
               </button>
            </div>
         </div>
      </>
   )
}

export default EditCustomer
