import { Link, useNavigate } from "react-router-dom"
import LeavePage from "../../../components/LeavePage"
import { useFormik } from "formik"
import axios from "axios"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
   useEditEmployeeInfoMutation,
   useGetEmployeeQuery,
} from "../../../services/employee"
import { useEffect, useState } from "react"
import EmployeePermission from "./EmployeePermission"
import { AiOutlineSearch } from "react-icons/ai"
import { setEmployeePermission } from "../../../services/Features/employeeSlice"
import { toast } from "react-hot-toast"

const EditEmployee = () => {
   const [open, setOpen] = useState(false)
   const [editEmployeeInfo, { isSuccess, isError }] =
      useEditEmployeeInfoMutation()
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const { id } = useParams()
   const { data } = useGetEmployeeQuery(id)
   // const [data,setData] = useState(singleEmployee?.employee)
   const { userInfo } = useSelector((state) => state.login)
   const { permissions } = useSelector((state) => state.employeeAction)
   const [employeeImage, setEmployeeImage] = useState("")
   const [uploadEmployeeImage, setUploadEmployeeImage] = useState("")
   const [typeSearchValue, setTypeSearchValue] = useState("")
   const user = JSON.parse(localStorage.getItem("ich-user"))
   const [authorizedInfo, setAuthorizedInfo] = useState(
      data?.employee?.authorized
   )
   const [account, setAccount] = useState("")

   useEffect(() => {
      if (data?.employee?.userPermission?.length > 0) {
         dispatch(setEmployeePermission(data?.employee?.userPermission))
      }
      setAccount(data?.employee?.userAccount)
   }, [data?.employee, dispatch])

   const handleAuthorized = (e) => {
      setAuthorizedInfo(e.target.checked)
   }

   const onEmployeeImageChangePdf = (e) => {
      setEmployeeImage(e.target.files[0])
   }

   const onSubmitEmployeeImage = async (event) => {
      event.preventDefault()
      const formData = new FormData()
      formData.append("writersImageUpload", employeeImage)

      try {
         if (!employeeImage)
            return alert(
               "Please upload an image first then create this product!"
            )
         await axios
            .post("/api/author-image", formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            })
            .then((res) => {
               setUploadEmployeeImage(res.data)
               alert("Employee Image uploaded")
            })
            .catch((err) => {
               alert(err?.response?.data?.message)
            })
      } catch (err) {
         if (err.response.status === 500) {
            console.log(err)
         } else {
            console.log(err.response.data.msg)
         }
      }
   }

   useEffect(() => {
      if (isSuccess) {
         toast.success("Employee updated", { duration: 1000 })
      }
   }, [isSuccess])

   const accessInfo = [
      {
         id: 1,
         title: "dashboard",
         path: "/dashboard",
      },
      {
         id: 2,
         title: "inventory",
         path: "/inventory",
      },
      {
         id: 3,
         title: "users",
         path: "/users",
      },
      {
         id: 4,
         title: "search",
         path: "/search",
      },
      {
         id: 5,
         title: "reports",
         path: "/reports",
      },
      {
         id: 6,
         title: "orders",
         path: "/orders",
      },
      {
         id: 7,
         title: "Drafts",
         path: "/drafts",
      },
      {
         id: 8,
         title: "dispatch",
         path: "/dispatch",
      },
      {
         id: 9,
         title: "delivery",
         path: "/delivery",
      },
      {
         id: 10,
         title: "delivered",
         path: "/delivered",
      },
      {
         id: 11,
         title: "fullfilled",
         path: "/fullfilled",
      },
      {
         id: 12,
         title: "canceled",
         path: "/canceled",
      },
      {
         id: 13,
         title: "Refund & Return",
         path: "/refund-&-return",
      },
      {
         id: 14,
         title: "employee",
         path: "/employee",
      },
      {
         id: 15,
         title: "customers",
         path: "/customers",
      },
      {
         id: 16,
         title: "top-banner",
         path: "/top-banner",
      },

      {
         id: 17,
         title: "category",
         path: "/category",
      },

      {
         id: 18,
         title: "verify reviews",
         path: "/",
      },
      {
         id: 19,
         title: "email marketing",
         path: "/",
      },
      {
         id: 20,
         title: "tips",
         path: "/",
      },
   ]

   const userAccountHandle = (value) => {
      setAccount(value)
      setOpen(false)
   }

   // employee data submit
   const employee = useFormik({
      initialValues: {
         name: data?.employee?.name,
         email: data?.employee?.email,
         barcode: data?.employee?.barcode,
         address: data?.employee?.address,
         contactNumber: data?.employee?.contactNumber,
         password: data?.employee?.password,
         nid: data?.employee?.nid,
         joiningDate: data?.employee?.joiningDate,
         employmentStatus: data?.employee?.employmentStatus,
         resigningDate: data?.employee?.resigningDate,
         designation: data?.employee?.designation,
         image: uploadEmployeeImage,
         emergencyContactName: data?.employee?.emergencyContactName,
         emergencyContactNumber: data?.emergencyContactNumber,
         emergencyRelationshipInfo: data?.employee?.emergencyRelationshipInfo,
         bankName: data?.employee?.bankName,
         bankAccountNumber: data?.employee?.bankAccountNumber,
         bankRelationship: data?.employee?.bankRelationship,
         userPermission: data?.employee?.userPermission,
         authorized: data?.employee?.authorized,
         userAccount: data?.employee?.userAccount,
         dob: data?.employee?.dob,
         blood: data?.employee?.blood,
      },

      onSubmit: async (values, { resetForm }) => {
         try {
            const employeeObj = {
               name: values.name,
               email: values.email,
               barcode: values.barcode,
               address: values.address,
               contactNumber: values.contactNumber,
               password: values.password,
               nid: values.nid,
               joiningDate: values.joiningDate,
               employmentStatus: values.employmentStatus,
               resigningDate: values.resigningDate,
               designation: values.designation,
               image: uploadEmployeeImage,
               emergencyContactName: values.emergencyContactName,
               emergencyContactNumber: values.emergencyContactNumber,
               emergencyRelationshipInfo: values.emergencyRelationshipInfo,
               bankName: values.bankName,
               bankAccountNumber: values.bankAccountNumber,
               bankRelationship: values.bankRelationship,
               userPermission: permissions,
               authorized: authorizedInfo,
               userAccount: account,
               dob: values.dob,
               blood: values.blood,
            }

            await axios
               .patch(
                  `/api/employee/${id}`,
                  Object.fromEntries(
                     Object.entries(employeeObj).filter(([_, v]) => v)
                  ),
                  {
                     headers: {
                        "Content-Type": "application/json",
                     },
                  }
               )
               .then((response) => {
                  localStorage.setItem("ich-user", JSON.stringify(user))
                  alert(response?.data?.message)
               })
            window.location.reload()
         } catch (err) {
            if (err.response.status === 500) {
               console.log(err)
            } else {
               console.log(err.response.data.msg)
            }
         }
      },
   })

   const inputStyle =
      "px-4 py-2 rounded-lg mt-1 border border-[#ddd] hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:bg-gray-50 dark:text-dark placeholder:text-darkText"
   const labelStyle = "text-sm ml-1"
   const boxStyle = "flex flex-col w-full"

   return (
      <>
         <div className="p-2 m-5 px-20 dark:text-white">
            <div className="py-3 flex items-center text-darkText dark:text-white">
               <Link
                  to="/employee"
                  className="p-2 mr-3 rounded-md border border-gray-300 cursor-pointer"
               >
                  <svg
                     viewBox="0 0 20 20"
                     className="Polaris-Icon__Svg_375hu w-5 h-5"
                     focusable="false"
                     aria-hidden="true"
                  >
                     <path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path>
                  </svg>
               </Link>
               <h3 className="font-semibold text-xl">Edit Employee</h3>
               <LeavePage></LeavePage>
            </div>
            <form onSubmit={employee.handleSubmit}>
               <div className="flex gap-8 mb-3 items-end">
                  <div className={boxStyle}>
                     <label htmlFor="ichchapuronId" className={labelStyle}>
                        Employee ID
                     </label>
                     <input
                        type="text"
                        name="ichchapuronId"
                        defaultValue={data?.employee?.barcode}
                        readOnly
                        className={inputStyle}
                     />
                  </div>
                  <div className="w-full">
                     <button
                        type="button"
                        className="flex gap-2 border rounded-lg px-3 py-2 border-gray-400"
                     >
                        BARCODE
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           width="24"
                           height="24"
                           fill="none"
                           viewBox="0 0 24 24"
                        >
                           <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeMiterlimit="10"
                              strokeWidth="1.373"
                              d="M9.193 19.118V4.876M16.535 19.118V4.876M19.12 19.118V4.876M4.88 19.118V4.876"
                           ></path>
                           <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeMiterlimit="10"
                              strokeWidth=".915"
                              d="M6.818 19.337V4.663"
                           ></path>
                           <path
                              fill="currentColor"
                              d="M14.156 19.769h-2.59c-.238 0-.431-.194-.431-.432V4.663c0-.238.193-.432.431-.432h2.59c.238 0 .431.194.431.432v14.674c0 .238-.193.432-.431.432z"
                           ></path>
                        </svg>
                     </button>
                  </div>
               </div>
               <div className="space-y-3 w-full">
                  <div className="bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 my-4 shadow space-y-4 ">
                     <div className="flex justify-between w-full items-center gap-5">
                        <div className={boxStyle}>
                           <label htmlFor="name" className={labelStyle}>
                              Name
                           </label>
                           <input
                              type="text"
                              name="name"
                              value={employee.values.name}
                              onChange={employee.handleChange}
                              onBlur={employee.handleBlur}
                              placeholder={data?.employee?.name}
                              className={`${inputStyle}, lowercase`}
                           />
                           {/* { employee.touched.name && employee.errors.name && (
                                        <small className="text-palette6 my-0.5">
                                            { employee.errors.name }
                                        </small>
                                    ) } */}
                        </div>
                        <div className={boxStyle}>
                           <label htmlFor="address" className={labelStyle}>
                              Address
                           </label>
                           <input
                              type="text"
                              name="address"
                              value={employee.values.address}
                              onChange={employee.handleChange}
                              placeholder={data?.employee?.address}
                              className={inputStyle}
                           />
                        </div>
                     </div>
                     <div className="flex justify-between w-full items-center gap-5">
                        <div className={boxStyle}>
                           <label
                              htmlFor="contactNumber"
                              className={labelStyle}
                           >
                              Contact Number
                           </label>
                           <input
                              type="text"
                              name="contactNumber"
                              value={employee.values.contactNumber}
                              onChange={employee.handleChange}
                              placeholder={data?.employee?.contactNumber}
                              className={inputStyle}
                           />
                        </div>
                        <div className={boxStyle}>
                           <label htmlFor="nid" className={labelStyle}>
                              NID
                           </label>
                           <input
                              type="text"
                              name="nid"
                              value={employee.values.nid}
                              onChange={employee.handleChange}
                              placeholder={data?.employee?.nid}
                              className={inputStyle}
                           />
                        </div>
                     </div>
                     <div className="flex justify-between w-full items-center gap-5">
                        <div className={boxStyle}>
                           <label
                              htmlFor="contactNumber"
                              className={labelStyle}
                           >
                              Date Of Birth
                           </label>
                           <input
                              type="date"
                              name="dob"
                              value={employee.values.dob}
                              onChange={employee.handleChange}
                              placeholder={data?.employee?.dob}
                              className={inputStyle}
                           />
                        </div>
                        <div className={boxStyle}>
                           <label htmlFor="nid" className={labelStyle}>
                              Blood Group
                           </label>
                           <input
                              type="text"
                              name="blood"
                              value={employee.values.blood}
                              onChange={employee.handleChange}
                              placeholder={data?.employee?.blood}
                              className={inputStyle}
                           />
                        </div>
                     </div>
                     <div className="flex justify-between w-full items-center gap-5">
                        <div className={boxStyle}>
                           <label htmlFor="joiningDate" className={labelStyle}>
                              Joining Date
                           </label>
                           <input
                              type="text"
                              name="joiningDate"
                              value={employee.values.joiningDate}
                              onChange={employee.handleChange}
                              placeholder={data?.employee?.joiningDate}
                              className={inputStyle}
                           />
                        </div>
                        <div className={boxStyle}>
                           <label
                              htmlFor="employmentStatus"
                              className={labelStyle}
                           >
                              Employment Status (Current / Resigned){" "}
                           </label>
                           <input
                              type="text"
                              name="employmentStatus"
                              value={employee.values.employmentStatus}
                              onChange={employee.handleChange}
                              placeholder={data?.employee?.employmentStatus}
                              className={inputStyle}
                           />
                        </div>
                     </div>
                     <div className="flex justify-between w-full items-center gap-5">
                        <div className={boxStyle}>
                           <label
                              htmlFor="resigningDate"
                              className={labelStyle}
                           >
                              Resigning Date
                           </label>
                           <input
                              type="text"
                              name="resigningDate"
                              value={employee.values.resigningDate}
                              onChange={employee.handleChange}
                              placeholder={data?.employee?.resigningDate}
                              className={inputStyle}
                           />
                        </div>
                        <div className={boxStyle}>
                           <label htmlFor="designation" className={labelStyle}>
                              Designation
                           </label>
                           <input
                              type="text"
                              name="designation"
                              value={employee.values.designation}
                              onChange={employee.handleChange}
                              placeholder={data?.employee?.designation}
                              className={inputStyle}
                           />
                        </div>
                     </div>
                     <div className="flex justify-between gap-5">
                        <div className={boxStyle}>
                           <label htmlFor="email" className={labelStyle}>
                              Email
                           </label>
                           <input
                              type="text"
                              name="email"
                              value={employee.values.email}
                              onChange={employee.handleChange}
                              placeholder={data?.employee?.email}
                              className={inputStyle}
                           />
                        </div>
                        <div className={boxStyle}>
                           <label htmlFor="authorImage" className={labelStyle}>
                              Image
                           </label>
                           <input
                              type="file"
                              id="file"
                              name="image"
                              onChange={onEmployeeImageChangePdf}
                              className={inputStyle}
                           />
                           <div className="flex items-center gap-1 my-3 text-sm">
                              <button
                                 onClick={onSubmitEmployeeImage}
                                 className="bg-[#5E5E5E] px-7 py-3 text-[#dddddd]  rounded-md"
                              >
                                 Add image
                              </button>
                              <span>(only jpg, png allowed!)</span>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 my-4 shadow space-y-4">
                     <div className="flex justify-between w-full items-center gap-5">
                        <div className={boxStyle}>
                           <label
                              htmlFor="emergencyContactName"
                              className={labelStyle}
                           >
                              Emergency Contact Name
                           </label>
                           <input
                              type="text"
                              name="emergencyContactName"
                              value={employee.values.emergencyContactName}
                              onChange={employee.handleChange}
                              placeholder={data?.employee?.emergencyContactName}
                              className={inputStyle}
                           />
                        </div>
                        <div className={boxStyle}>
                           <label
                              htmlFor="emergencyContactNumber"
                              className={labelStyle}
                           >
                              Emergency Contact Number
                           </label>
                           <input
                              type="text"
                              name="emergencyContactNumber"
                              value={employee.values.emergencyContactNumber}
                              onChange={employee.handleChange}
                              placeholder={
                                 data?.employee?.emergencyContactNumber
                              }
                              className={inputStyle}
                           />
                        </div>
                     </div>
                     <div className={`${boxStyle} w-6/12`}>
                        <label
                           htmlFor="emergencyRelationshipInfo"
                           className={labelStyle}
                        >
                           Relationship With The Person
                        </label>
                        <input
                           type="text"
                           name="emergencyRelationshipInfo"
                           value={employee.values.emergencyRelationshipInfo}
                           onChange={employee.handleChange}
                           placeholder={
                              data?.employee?.emergencyRelationshipInfo
                           }
                           className={inputStyle}
                        />
                     </div>
                  </div>
                  <div className="bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 my-4 shadow space-y-4">
                     <div className="flex justify-between w-full items-center gap-5">
                        <div className={boxStyle}>
                           <label htmlFor="bankName" className={labelStyle}>
                              Bank Name
                           </label>
                           <input
                              type="text"
                              name="bankName"
                              value={employee.values.bankName}
                              onChange={employee.handleChange}
                              placeholder={data?.employee?.bankName}
                              className={inputStyle}
                           />
                        </div>
                        <div className={boxStyle}>
                           <label
                              htmlFor="bankAccountNumber"
                              className={labelStyle}
                           >
                              Bank A/C Number
                           </label>
                           <input
                              type="text"
                              name="bankAccountNumber"
                              value={employee.values.bankAccountNumber}
                              onChange={employee.handleChange}
                              placeholder={data?.employee?.bankAccountNumber}
                              className={inputStyle}
                           />
                        </div>
                     </div>
                     <div className={`${boxStyle} w-6/12`}>
                        <label
                           htmlFor="bankRelationship"
                           className={labelStyle}
                        >
                           Relationship With The Person
                        </label>
                        <input
                           type="text"
                           name="bankRelationship"
                           value={employee.values.bankRelationship}
                           onChange={employee.handleChange}
                           placeholder={data?.employee?.bankRelationship}
                           className={inputStyle}
                        />
                     </div>
                  </div>
                  <div className="flex gap-3 py-3 ">
                     <label
                        htmlFor="authorized"
                        className="text-[17px] font-semibold cursor-pointer"
                     >
                        Authorized
                     </label>
                     <input
                        defaultChecked={data?.employee?.authorized}
                        onChange={handleAuthorized}
                        type="checkbox"
                        name="authorized"
                        id="authorized"
                        className="cursor-pointer checked:bg-green-600 border-[#000000af] rounded-sm ring-0 "
                     />
                  </div>
                  <div className="bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 my-4 shadow space-y-4">
                     <h3 className="text-lg">Folders User Can Access</h3>
                     <p>
                        Add folders the user can access and select{" "}
                        <b>View Only</b> or <b>View and Edit</b> permissions.
                     </p>
                     <div className={`bg-white mt-2 pb-3 w-8/12`}>
                        <div className="bg-white  sticky top-0 w-full">
                           <div className="flex items-center py-1 px-4 rounded-xl border relative gap-2">
                              <AiOutlineSearch className="text-[20px] text-gray-700"></AiOutlineSearch>
                              <input
                                 value={typeSearchValue}
                                 onChange={(e) =>
                                    setTypeSearchValue(
                                       e.target.value.toLowerCase()
                                    )
                                 }
                                 type="text"
                                 placeholder="Search folder..."
                                 className="placeholder:text-gray-7 outline-none w-full border-none border focus:ring-0 py-3"
                              />
                           </div>
                        </div>
                        <ul className="space-y-2 mt-5">
                           <li className="flex justify-between pb-2 text-gray-500">
                              <div className="flex gap-3 items-center">
                                 <svg
                                    width="22"
                                    height="18"
                                    viewBox="0 0 30 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                 >
                                    <path
                                       d="M0 3C0 1.34315 1.34315 0 3 0H10L14 4H27C28.6569 4 30 5.34315 30 7V22C30 23.6569 28.6569 25 27 25H3C1.34315 25 0 23.6569 0 22V3Z"
                                       fill="#A5A9BE"
                                    ></path>
                                 </svg>
                                 <h3 className="text-[15px] capitalize">
                                    User Account
                                 </h3>
                              </div>
                              <div className={`relative`}>
                                 <button
                                    onClick={() => setOpen(!open)}
                                    type="button"
                                    className="capitalize border border-gray-200  px-8 py-3 font-semibold  rounded-md text-[13px] hover:bg-gray-100 top-0 right-0"
                                 >
                                    {account ? account : "ADD"}
                                 </button>
                                 <div
                                    className={`absolute top-12 left-0 z-50 bg-white `}
                                 >
                                    <div
                                       className={` rounded-md w-44 shadow-lg cursor-pointer ${
                                          open ? "block" : "hidden"
                                       }`}
                                    >
                                       <input
                                          onClick={(e) =>
                                             userAccountHandle(e.target.value)
                                          }
                                          className={`py-3 px-4 border hover:bg-gray-100 rounded-md outline-none cursor-pointer capitalize ${
                                             account === "ichchapuron" &&
                                             "bg-gray-200"
                                          }`}
                                          value="ichchapuron"
                                       />
                                       <input
                                          onClick={(e) =>
                                             userAccountHandle(e.target.value)
                                          }
                                          className={`py-3 px-4 hover:bg-gray-100 border rounded-md border-t-0 outline-none cursor-pointer capitalize ${
                                             account === "gyankosh" &&
                                             "bg-gray-200"
                                          }`}
                                          value="gyankosh"
                                       />
                                       <input
                                          onClick={(e) =>
                                             userAccountHandle(e.target.value)
                                          }
                                          className={`py-3 px-4 hover:bg-gray-100 border rounded-md border-t-0 outline-none cursor-pointer capitalize ${
                                             account ===
                                                "ichchapuron & gyankosh" &&
                                             "bg-gray-200"
                                          }`}
                                          value="ichchapuron & gyankosh"
                                       />
                                    </div>
                                 </div>
                              </div>
                           </li>
                           {accessInfo?.map((permissionData) => {
                              if (
                                 permissionData?.title
                                    ?.toLowerCase()
                                    .startsWith(typeSearchValue)
                              ) {
                                 return (
                                    <EmployeePermission
                                       key={permissionData.id}
                                       data={permissionData}
                                       employeeInfo={data?.employee}
                                    ></EmployeePermission>
                                 )
                              }
                           })}
                        </ul>
                     </div>
                  </div>
               </div>
               <div className="text-[15px] py-5 text-right pt-6 border-t dark:border-gray-600 mt-7 space-x-3">
                  <button
                     disabled={!employee.values}
                     type="submit"
                     className="py-2 px-5 bg-main text-white rounded-lg text-[15px] capitalize "
                  >
                     Confirm
                  </button>
               </div>
            </form>
         </div>
      </>
   )
}

export default EditEmployee
