import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const brandApi = createApi({
   reducerPath: "brandApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["brand"],
   endpoints: (builder) => ({
      getBrands: builder.query({
         query: () => "api/brand",
         providesTags: ["brand"],
      }),
      createBrand: builder.mutation({
         query: (data) => ({
            url: `api/brand`,
            method: "POST",
            body: data,
         }),
         invalidatesTags: ["brand"],
      }),
      updateBrand: builder.mutation({
         query: ({ id, ...rest }) => ({
            url: `api/brand/${id}`,
            method: "PATCH",
            body: rest,
         }),
         invalidatesTags: ["brand"],
      }),
      deleteBrand: builder.mutation({
         query: (id) => ({
            url: `api/brand/${id}`,
            method: "DELETE",
         }),
         invalidatesTags: ["brand"],
      }),
   }),
})

export const {
   useGetBrandsQuery,
   useCreateBrandMutation,
   useUpdateBrandMutation,
   useDeleteBrandMutation,
} = brandApi
