import { useReactToPrint } from "react-to-print"
import React, { useRef, useState } from "react"
import PackagingSlip from "./PackagingSlip"

const PrintPackagingSlip = ({ data, icon }) => {
   const componentRef = useRef()

   const [qr, setqr] = useState(null)
   const [orderInfo, setOrderInfo] = useState("")

   const handlePrint = useReactToPrint({
      content: () => componentRef.current,
   })

   // print
   const combineFn = (data) => {
      if (data?.barcode === undefined) {
         alert("No barcode found with this product")
         setqr(null)
      } else {
         setOrderInfo(data)
         setTimeout(() => {
            handlePrint()
         }, 1000)
      }
   }

   return (
      <div>
         <button
            onClick={(e) => combineFn(data)}
            className=" cursor-pointer hover:bg-[#968a8a1e] rounded-xl z-50 w-full"
         >
            {icon === true ? (
               <p className="flex items-center gap-2 text-[#4F5366] text-sm bg-white hover:bg-gray-100 rounded-lg py-3 px-2">
                  <svg
                     viewBox="0 0 20 20"
                     className="Polaris-Icon__Svg_375hu w-4"
                     focusable="false"
                     aria-hidden="true"
                  >
                     <path
                        fillRule="evenodd"
                        d="M14 11h2v-2h-2v2zm-7-4h6v-3h-6v3zm0 9h6v-2h-6v2zm8-9v-3.5a1.5 1.5 0 0 0-1.5-1.5h-7a1.5 1.5 0 0 0-1.5 1.5v3.5h-1.5a1.5 1.5 0 0 0-1.5 1.5v6a1.5 1.5 0 0 0 1.5 1.5h1.5v.5a1.5 1.5 0 0 0 1.5 1.5h7a1.5 1.5 0 0 0 1.5-1.5v-.5h1.5a1.5 1.5 0 0 0 1.5-1.5v-6a1.5 1.5 0 0 0-1.5-1.5h-1.5z"
                     ></path>
                  </svg>
               </p>
            ) : (
               <div className="flex items-center py-3 px-3 rounded-md hover:bg-gray-100 hover:dark:text-dark ">
                  <svg
                     viewBox="0 0 20 20"
                     className="Polaris-Icon__Svg_375hu w-4 mr-2 sm:mr-4"
                     focusable="false"
                     aria-hidden="true"
                  >
                     <path
                        fillRule="evenodd"
                        d="M14 11h2v-2h-2v2zm-7-4h6v-3h-6v3zm0 9h6v-2h-6v2zm8-9v-3.5a1.5 1.5 0 0 0-1.5-1.5h-7a1.5 1.5 0 0 0-1.5 1.5v3.5h-1.5a1.5 1.5 0 0 0-1.5 1.5v6a1.5 1.5 0 0 0 1.5 1.5h1.5v.5a1.5 1.5 0 0 0 1.5 1.5h7a1.5 1.5 0 0 0 1.5-1.5v-.5h1.5a1.5 1.5 0 0 0 1.5-1.5v-6a1.5 1.5 0 0 0-1.5-1.5h-1.5z"
                     ></path>
                  </svg>
                  <h3 className="text-sm capitalize">print packing slips</h3>
               </div>
            )}
         </button>
         <div className="hidden">
            <div
               ref={componentRef}
               className="flex flex-col justify-center items-center h-full pb-2 pt-2.5 w-[99vw] m-0"
            >
               <PackagingSlip order={orderInfo} />
            </div>
         </div>
      </div>
   )
}

export default PrintPackagingSlip
