import React from "react"
import UserLayout from "./UserLayout"
import IchLayout from "./IchLayout"

const Main = () => {
   const routeName =
      window.location?.pathname === "/"
         ? true
         : window.location?.pathname === "/user/auth/register"
         ? true
         : window.location?.pathname === "/user/auth/login"
         ? true
         : window.location?.pathname === "/user/account"
         ? true
         : false

   return (
      <div className="max-h-screen max-w-screen-2xl mx-auto ">
         {routeName ? <UserLayout></UserLayout> : <IchLayout></IchLayout>}
      </div>
   )
}

export default Main
