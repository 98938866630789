import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const employeeApi = createApi({
   reducerPath: "employeeApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["employeeApi"],
   endpoints: (builder) => ({
      loginEmployee: builder.mutation({
         query: (data) => ({
            url: "api/employee/login",
            method: "POST",
            body: data,
         }),
         invalidatesTags: ["employeeApi"],
      }),
      createEmployee: builder.mutation({
         query: (data) => ({
            url: "api/employee/register",
            method: "POST",
            body: data,
         }),
         invalidatesTags: ["employeeApi"],
      }),
      getemployees: builder.query({
         query: () => `api/employee`,
         providesTags: ["employeeApi"],
      }),
      getEmployee: builder.query({
         query: (id) => `api/employee/${id}`,
         providesTags: ["employeeApi"],
      }),
      deleteEmployee: builder.mutation({
         query: (id) => ({
            url: `api/employee/${id}`,
            method: "DELETE",
         }),
         invalidatesTags: ["employeeApi"],
      }),
      editEmployeeInfo: builder.mutation({
         query: ({ id, ...data }) => ({
            url: `api/employee/${id}`,
            method: "PATCH",
            body: data,
         }),
         invalidatesTags: ["employeeApi"],
      }),
   }),
})

export const {
   useLoginEmployeeMutation,
   useCreateEmployeeMutation,
   useDeleteEmployeeMutation,
   useGetemployeesQuery,
   useGetEmployeeQuery,
   useEditEmployeeInfoMutation,
} = employeeApi
