import { Link, useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { DashboardContext } from "../../context/DashboardProvider"
import { useGetUsersQuery } from "../../services/user"
import AgGridTable from "../../components/AgGridTable"
import { Avatar } from "rsuite"

const AllUsers = () => {
   const { employeeInfo } = useContext(DashboardContext)
   const permission = employeeInfo?.find((data) => data?.permission)
   const [tableData, setTableData] = useState(null)
   const navigate = useNavigate()

   const { data: users } = useGetUsersQuery()

   const columnDef = [
      {
         headerName: "Image",
         field: "image",
         cellRenderer: (x) => (
            <Link
               to={`/users/${x?.data?._id}`}
               className="px-4 block !no-underline"
            >
               <Avatar src={x?.data?.image} alt={x?.data?.title} />
            </Link>
         ),
      },
      {
         headerName: "Name",
         field: "name",
         cellRenderer: function (x) {
            return (
               <Link
                  to={`/users/${x?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {x?.data?.name}
               </Link>
            )
         },
      },
      {
         headerName: "Phone",
         field: "phone",
         cellRenderer: function (x) {
            return (
               <Link
                  to={`/users/${x?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {x?.data?.phone}
               </Link>
            )
         },
      },
      {
         headerName: "Email",
         field: "email",
         cellRenderer: function (x) {
            return (
               <Link
                  to={`/users/${x?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {x?.data?.email}
               </Link>
            )
         },
      },
      {
         headerName: "Actions",
         field: "_id",
         cellRenderer: (x) => (
            <div className="text-sm z-50 px-4">
               {permission?.permission !== "View Only" ? (
                  <ul className="flex">
                     <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50">
                        <Link
                           to={`/edit-academic/${x?.data?._id}`}
                           className="flex items-center gap-2 text-[#4F5366] text-sm"
                        >
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 cursor-pointer text-green-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                           >
                              <path
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                                 d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              ></path>
                           </svg>
                        </Link>
                     </li>
                  </ul>
               ) : (
                  <p className="py-3">N/A</p>
               )}
            </div>
         ),
      },
   ]

   useEffect(() => {
      setTableData(users)
   }, [users])
   return (
      <section>
         <div className="border-b border-gray-200 md:mx-10 mx-4 py-5  flex items-center justify-between">
            <div className="sm:flex gap-4 items-center">
               <div className="flex items-center text-darkText">
                  <span>
                     <svg
                        width="18"
                        height="18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M1.045 4.488h15.91"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinejoin="round"
                        ></path>
                        <path
                           d="M4.325 1.164h9.589c.423 0 .824.187 1.095.512l1.617 1.94c.213.257.33.58.33.913v10.408a1.9 1.9 0 0 1-1.9 1.9H3.183a1.9 1.9 0 0 1-1.9-1.9V4.529c0-.333.117-.656.33-.912L3.23 1.676c.271-.325.672-.512 1.095-.512zM6.98 7.812h4.275M9.12 1.4v2.85"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        ></path>
                     </svg>
                  </span>
                  <button
                     type="button"
                     onClick={() => navigate("/users")}
                     className="text-sm sm:text-lg px-2 sm:px-4 hover:text-black"
                  >
                     All Users
                  </button>
               </div>
            </div>
         </div>
         <div className="flex items-start gap-5 flex-col overflow-hidden mx-4 md:mx-6">
            <div className="flex gap-10 mt-7 mb-3">
               <h3 className="text-sm sm:text-[17px] md:text-[18px] text-[#9191A1]">
                  Total User: {users?.length}
               </h3>
            </div>
            {users?.length === 0 ? (
               <div className="text-red-600 flex items-center justify-center  h-[60vh]">
                  No User Available!
               </div>
            ) : (
               <AgGridTable
                  data={tableData}
                  columnDef={columnDef}
                  height="70vh"
               />
            )}
         </div>
      </section>
   )
}

export default AllUsers
