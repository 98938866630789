import { useNavigate } from "react-router-dom"
import LeavePage from "../../components/LeavePage"
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { useBarcode } from "@createnextapp/react-barcode"
import { useCreateEmployeeMutation } from "../../services/employee"
import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"

const AddEmployee = () => {
   const [createEmployee, { isError, isSuccess, error, data }] =
      useCreateEmployeeMutation()
   const navigate = useNavigate()
   const small_id = new Date().valueOf().toString().slice(8, 13)
   const [employeeImage, setEmployeeImage] = useState("")
   const [uploadEmployeeImage, setUploadEmployeeImage] = useState("")

   const onEmployeeImageChangePdf = (e) => {
      setEmployeeImage(e.target.files[0])
   }

   const onSubmitEmployeeImage = async (event) => {
      event.preventDefault()
      const formData = new FormData()
      formData.append("writersImageUpload", employeeImage)

      try {
         if (!employeeImage)
            return alert(
               "Please upload an image first then create this product!"
            )
         await axios
            .post("/api/author-image", formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            })
            .then((res) => {
               setUploadEmployeeImage(res.data)
               alert("Employee Image uploaded")
            })
            .catch((err) => {
               alert(err?.response?.data?.message)
            })
      } catch (err) {
         if (err.response.status === 500) {
            console.log(err)
         } else {
            console.log(err.response.data.msg)
         }
      }
   }

   useEffect(() => {
      if (isError) {
         console.log("error", error)
         toast.error(`${error?.data?.message}`, { duration: 1000 })
      }
      if (isSuccess) {
         navigate("/employee")
         toast.success("Registration successfull", { duration: 1000 })
      }
   }, [isError, isSuccess, navigate])

   const { inputRef } = useBarcode({
      value: "IEID" + small_id,
      options: {
         width: 1.85,
         height: 50,
         format: "CODE39",
         displayValue: true,
         font: "monospace",
         textAlign: "center",
         textPosition: "bottom",
         textMargin: 2,
         fontSize: 20,
         background: "#ffffff",
         lineColor: "#000000",
      },
   })
   const employee = useFormik({
      initialValues: {
         name: "",
         email: "",
         barcode: "",
         address: "",
         contactNumber: "",
         password: "",
         nid: "",
         joiningDate: "",
         employmentStatus: "",
         resigningDate: "",
         designation: "",
         emergencyContactName: "",
         emergencyContactNumber: "",
         emergencyRelationshipInfo: "",
         bankName: "",
         bankAccountNumber: "",
         bankRelationship: "",
         userPermission: "",
         dob: "",
         blood: "",
      },
      validationSchema: Yup.object({
         name: Yup.string().required("This field required"),
      }),
      onSubmit: (values, { resetForm }) => {
         const user = {
            name: values.name,
            email: values.email,
            address: values.address,
            contactNumber: values.contactNumber,
            nid: values.nid,
            joiningDate: values.joiningDate,
            employmentStatus: values.employmentStatus,
            resigningDate: values.resigningDate,
            designation: values.designation,
            image: uploadEmployeeImage,
            emergencyContactName: values.emergencyContactName,
            emergencyContactNumber: values.emergencyContactNumber,
            emergencyRelationshipInfo: values.emergencyRelationshipInfo,
            bankName: values.bankName,
            bankAccountNumber: values.bankAccountNumber,
            bankRelationship: values.bankRelationship,
            userPermission: values.userPermission,
            barcode: "IEID" + small_id,
            dob: values.dob,
            blood: values.blood,
         }
         let validEmail = /\S+@\ichchapuron.com.bd/
         const isTrue = values.email.match(validEmail)
         if (!isTrue) {
            return toast.error("Only ichchapuron email can be used!")
         }

         createEmployee(user)
         // axios
         //     .post( "/api/employee", data )
         //     .then( ( response ) => alert( response?.data?.message ) )
         // setTimeout( () => window.location.reload(), 2000 )
      },
   })
   const inputStyle =
      "px-4 py-2 rounded-lg mt-1 border border-[#ddd] hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:bg-gray-50 dark:text-dark placeholder:text-darkText"
   const labelStyle = "text-sm ml-1"
   const boxStyle = "flex flex-col w-full"

   return (
      <>
         <div className="p-2 m-5 px-0 md:px-4 lg:px-20 dark:text-white">
            <div className="py-3 flex items-center">
               <label
                  htmlFor="leavePage"
                  className="text-[#5c5f62] p-2 mr-3 rounded-md border border-gray-300 cursor-pointer"
               >
                  <svg
                     viewBox="0 0 20 20"
                     className="Polaris-Icon__Svg_375hu w-5 h-5"
                     focusable="false"
                     aria-hidden="true"
                  >
                     <path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path>
                  </svg>
               </label>
               <h3 className="font-semibold text-xl">Add Employee</h3>
               <LeavePage></LeavePage>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3 lg:gap-5">
               <div className={boxStyle}>
                  <label htmlFor="ichchapuronId" className={labelStyle}>
                     Employee ID
                  </label>
                  <input
                     type="text"
                     name="ichchapuronId"
                     defaultValue="SB7YST0012"
                     readOnly
                     className={inputStyle}
                  />
               </div>
               <div className="w-full">
                  <img ref={inputRef} alt="" />
               </div>
            </div>

            <div className="space-y-3 w-full">
               <div className="bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 my-4 shadow space-y-4 ">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3 lg:gap-5">
                     <div className={boxStyle}>
                        <label htmlFor="name" className={labelStyle}>
                           Name
                        </label>
                        <input
                           type="text"
                           name="name"
                           value={employee.values.name}
                           onChange={employee.handleChange}
                           onBlur={employee.handleBlur}
                           placeholder="name"
                           className={`${inputStyle}, lowercase`}
                        />
                        {employee.touched.name && employee.errors.name && (
                           <small className="text-palette6 my-0.5">
                              {employee.errors.name}
                           </small>
                        )}
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="address" className={labelStyle}>
                           Address
                        </label>
                        <input
                           value={employee.values.address}
                           onChange={employee.handleChange}
                           type="text"
                           name="address"
                           placeholder="Address"
                           className={inputStyle}
                        />
                     </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3 lg:gap-5">
                     <div className={boxStyle}>
                        <label htmlFor="contactNumber" className={labelStyle}>
                           Contact Number
                        </label>
                        <input
                           value={employee.values.contactNumber}
                           onChange={employee.handleChange}
                           type="text"
                           name="contactNumber"
                           placeholder="Contact number"
                           className={inputStyle}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="nid" className={labelStyle}>
                           NID
                        </label>
                        <input
                           value={employee.values.nid}
                           onChange={employee.handleChange}
                           type="text"
                           name="nid"
                           placeholder="NID Number"
                           className={inputStyle}
                        />
                     </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3 lg:gap-5">
                     <div className={boxStyle}>
                        <label htmlFor="dob" className={labelStyle}>
                           Dath Of Birth
                        </label>
                        <input
                           value={employee.values.dob}
                           onChange={employee.handleChange}
                           type="date"
                           name="dob"
                           className={inputStyle}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="blood" className={labelStyle}>
                           Blood Group
                        </label>
                        <input
                           value={employee.values.blood}
                           onChange={employee.handleChange}
                           type="text"
                           name="blood"
                           placeholder="Blood group"
                           className={inputStyle}
                        />
                     </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3 lg:gap-5">
                     <div className={boxStyle}>
                        <label htmlFor="joiningDate" className={labelStyle}>
                           Joining Date
                        </label>
                        <input
                           value={employee.values.joiningDate}
                           onChange={employee.handleChange}
                           type="text"
                           name="joiningDate"
                           placeholder="Joining Date"
                           className={inputStyle}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label
                           htmlFor="employmentStatus"
                           className={labelStyle}
                        >
                           Employment Status (Current / Resigned){" "}
                        </label>
                        <input
                           value={employee.values.employmentStatus}
                           onChange={employee.handleChange}
                           type="text"
                           name="employmentStatus"
                           placeholder="Employment Status"
                           className={inputStyle}
                        />
                     </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3 lg:gap-5">
                     <div className={boxStyle}>
                        <label htmlFor="resigningDate" className={labelStyle}>
                           Resigning Date
                        </label>
                        <input
                           value={employee.values.resigningDate}
                           onChange={employee.handleChange}
                           type="text"
                           name="resigningDate"
                           placeholder="Resigning date"
                           className={inputStyle}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="designation" className={labelStyle}>
                           Designation
                        </label>
                        <input
                           value={employee.values.designation}
                           onChange={employee.handleChange}
                           type="text"
                           name="designation"
                           placeholder="Designation"
                           className={inputStyle}
                        />
                     </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3 lg:gap-5">
                     <div className={boxStyle}>
                        <label htmlFor="email" className={labelStyle}>
                           Email
                        </label>
                        <input
                           value={employee.values.email}
                           onChange={employee.handleChange}
                           type="text"
                           name="email"
                           placeholder="Email"
                           className={inputStyle}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="authorImage" className={labelStyle}>
                           Image
                        </label>
                        <input
                           type="file"
                           id="file"
                           name="employeeImage"
                           onChange={onEmployeeImageChangePdf}
                           className={inputStyle}
                        />
                        <div className="flex items-center gap-1 my-3 text-sm">
                           <button
                              onClick={onSubmitEmployeeImage}
                              className="bg-[#5E5E5E] px-7 py-3 text-[#dddddd]  rounded-md"
                           >
                              Add image
                           </button>
                           <span>(only jpg, png allowed!)</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 my-4 shadow space-y-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3 lg:gap-5">
                     <div className={boxStyle}>
                        <label
                           htmlFor="emergencyContactName"
                           className={labelStyle}
                        >
                           Emergency Contact Name
                        </label>
                        <input
                           value={employee.values.emergencyContactName}
                           onChange={employee.handleChange}
                           type="text"
                           name="emergencyContactName"
                           placeholder="Emergency Contact Name"
                           className={inputStyle}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label
                           htmlFor="emergencyContactNumber"
                           className={labelStyle}
                        >
                           Emergency Contact Number
                        </label>
                        <input
                           value={employee.values.emergencyContactNumber}
                           onChange={employee.handleChange}
                           type="text"
                           name="emergencyContactNumber"
                           placeholder="Emergency Contact Number"
                           className={inputStyle}
                        />
                     </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3 lg:gap-5">
                     <div className={`${boxStyle}`}>
                        <label htmlFor="relationship" className={labelStyle}>
                           Relationship With The Person
                        </label>
                        <input
                           value={employee.values.emergencyRelationshipInfo}
                           onChange={employee.handleChange}
                           type="text"
                           name="emergencyRelationshipInfo"
                           placeholder="Relationship with the person"
                           className={inputStyle}
                        />
                     </div>
                  </div>
               </div>
               <div className="bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 my-4 shadow space-y-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3 lg:gap-5">
                     <div className={boxStyle}>
                        <label htmlFor="bankName" className={labelStyle}>
                           Bank Name
                        </label>
                        <input
                           value={employee.values.bankName}
                           onChange={employee.handleChange}
                           type="text"
                           name="bankName"
                           placeholder="Bank Name"
                           className={inputStyle}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label
                           htmlFor="backAcountNumber"
                           className={labelStyle}
                        >
                           Bank A/C Number
                        </label>
                        <input
                           value={employee.values.bankAccountNumber}
                           onChange={employee.handleChange}
                           type="text"
                           name="bankAccountNumber"
                           placeholder="Bank A/C Number"
                           className={inputStyle}
                        />
                     </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3 lg:gap-5">
                     <div className={`${boxStyle}`}>
                        <label
                           htmlFor="bankRelationship"
                           className={labelStyle}
                        >
                           Relationship With The Person
                        </label>
                        <input
                           value={employee.values.bankRelationship}
                           onChange={employee.handleChange}
                           type="text"
                           name="bankRelationship"
                           placeholder="Relationship with the person"
                           className={inputStyle}
                        />
                     </div>
                  </div>
               </div>
            </div>
            <button
               onClick={employee.handleSubmit}
               disabled={!employee.values.name}
               className="py-2 mt-5 px-5 bg-main text-white rounded-lg text-[15px] capitalize "
            >
               create
            </button>

            {/* <div className='text-[15px] py-5 text-right pt-6 border-t dark:border-gray-600 mt-7 space-x-3' >
                        <button className='py-2 px-5 border border-gray-300 rounded-lg text-[15px] capitalize '>Cancel</button>
                    </div> */}
         </div>
      </>
   )
}

export default AddEmployee
