import React, { useState, useEffect, useRef, useContext } from "react"
import { useReactToPrint } from "react-to-print"
import { Link } from "react-router-dom"
import {
   useDeleteEmployeeMutation,
   useGetemployeesQuery,
} from "../../services/employee"
import { toast } from "react-hot-toast"
import { DashboardContext } from "../../context/DashboardProvider"
import AgGridTable from "../../components/AgGridTable"
import EmployeeIDCard from "../../components/Employee/employeeIDCard"

const AcedemicItems = () => {
   const { data } = useGetemployeesQuery()
   const [deleteEmployee, { isSuccess }] = useDeleteEmployeeMutation()

   useEffect(() => {
      if (isSuccess) {
         toast.success("Employee delete successfull")
      }
   }, [isSuccess])

   const [tableData, setTableData] = useState(null)
   const [qr, setqr] = useState(null)

   useEffect(() => {
      setTableData(data?.employees)
   }, [data])

   const deleteEmployeeHandle = (id) => {
      deleteEmployee(id)
   }

   const componentRef = useRef()
   const [employeeData, setEmployeeData] = useState("")

   const handlePrint = useReactToPrint({
      content: () => componentRef.current,
   })

   // print
   const combineFn = (data) => {
      setEmployeeData(data)
      setTimeout(() => {
         handlePrint()
      }, 1000)
   }

   const columnDef = [
      {
         headerName: "Employee Id",
         field: "barcode",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/employee/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params?.data?.barcode}
               </Link>
            )
         },
      },
      {
         headerName: "Name",
         field: "name",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/employee/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params?.data?.name}
               </Link>
            )
         },
      },
      {
         headerName: "Contact Number",
         field: "contactNumber",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/employee/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params?.data?.contactNumber}
               </Link>
            )
         },
      },
      {
         headerName: "Designation",
         field: "designation",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/employee/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params?.data?.designation}
               </Link>
            )
         },
      },
      {
         headerName: "Actions",
         field: "_id",
         cellRenderer: (x) => (
            <div className="text-[15px] z-50">
               <ul className="flex">
                  {permission?.permission !== "View Only" ? (
                     <>
                        <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50">
                           <button
                              onClick={() => deleteEmployeeHandle(x?.data?._id)}
                              className="flex items-center gap-2 text-[#4F5366] text-sm"
                           >
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 class="h-5 w-5 cursor-pointer text-red-600"
                                 fill="none"
                                 viewBox="0 0 24 24"
                                 stroke="currentColor"
                                 strokeWidth="2"
                              >
                                 <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                 ></path>
                              </svg>
                           </button>
                        </li>
                        <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50">
                           <Link
                              to={`/editEmployee/${x?.data?._id}`}
                              className="flex items-center gap-2 text-[#4F5366] text-sm"
                           >
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 class="h-5 w-5 cursor-pointer text-green-600"
                                 fill="none"
                                 viewBox="0 0 24 24"
                                 stroke="currentColor"
                                 strokeWidth="2"
                              >
                                 <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                 ></path>
                              </svg>
                           </Link>
                        </li>
                        <button
                           onClick={(e) => combineFn(x?.data)}
                           className=" cursor-pointer hover:bg-[#968a8a1e] rounded-xl z-50 w-full"
                        >
                           <p className="flex items-center gap-2 text-[#4F5366] text-sm bg-white hover:bg-gray-100 rounded-lg py-3 px-2">
                              <svg
                                 viewBox="0 0 20 20"
                                 className="Polaris-Icon__Svg_375hu w-4"
                                 focusable="false"
                                 aria-hidden="true"
                              >
                                 <path
                                    fillRule="evenodd"
                                    d="M14 11h2v-2h-2v2zm-7-4h6v-3h-6v3zm0 9h6v-2h-6v2zm8-9v-3.5a1.5 1.5 0 0 0-1.5-1.5h-7a1.5 1.5 0 0 0-1.5 1.5v3.5h-1.5a1.5 1.5 0 0 0-1.5 1.5v6a1.5 1.5 0 0 0 1.5 1.5h1.5v.5a1.5 1.5 0 0 0 1.5 1.5h7a1.5 1.5 0 0 0 1.5-1.5v-.5h1.5a1.5 1.5 0 0 0 1.5-1.5v-6a1.5 1.5 0 0 0-1.5-1.5h-1.5z"
                                 ></path>
                              </svg>
                           </p>
                        </button>
                        <div className="hidden">
                           <div
                              ref={componentRef}
                              className="flex flex-col justify-center items-center h-full pb-2 pt-2.5 w-[99vw] m-0"
                           >
                              <EmployeeIDCard employee={employeeData} />
                           </div>
                        </div>
                     </>
                  ) : (
                     "N/A"
                  )}
                  {!x?.data?.authorized && (
                     <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white py-1 z-50">
                        <button className="flex items-center gap-2 px-2 py-1 rounded-lg bg-red-200 text-red-700 text-sm">
                           Pending
                        </button>
                     </li>
                  )}
               </ul>
            </div>
         ),
      },
   ]

   const { employeeInfo } = useContext(DashboardContext)
   const permission = employeeInfo?.find((data) => data?.permission)

   return (
      <div className="p-2 mb-5 m-2 sm:m-5 dark:text-white">
         <div className="flex flex-col sm:flex-row gap-3 justify-between items-center py-5">
            <h3 className="font-semibold text-xl">Employee</h3>
            {permission?.permission !== "View Only" && (
               <Link
                  to="/addEmployee"
                  className="py-2 px-2 sm:px-5 bg-main text-white rounded-lg text-[15px]"
               >
                  Add Employee
               </Link>
            )}
         </div>
         {/* table */}
         <section className="flex items-start gap-5 flex-col">
            <div className="flex mt-7 mb-3">
               <h3 className="text-sm sm:text-[17px] md:text-[18px] text-[#9191A1]">
                  Total Employees: {data?.employees?.length}
               </h3>
            </div>
            {data?.employees?.length === 0 ? (
               <div className="text-red-600 flex items-center justify-center w-full h-[60vh]">
                  No Employees Available!
               </div>
            ) : (
               <AgGridTable
                  data={tableData}
                  columnDef={columnDef}
                  height="70vh"
               />
            )}
         </section>
         {/* table */}
      </div>
   )
}

export default AcedemicItems
