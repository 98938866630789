import React from "react"
import { Link, useParams } from "react-router-dom"
import UserOrder from "./UserOrder"
import { useGetMyOrdersQuery } from "../../../../services/order"
import { useGetUserQuery } from "../../../../services/user"
import { useGetEmployeeQuery } from "../../../../services/employee"
import { employeeInfoFunc } from "../../../../utils/utils"
import UserSidebar from "../UserSidebar"
import UserOrderSummery from "./UserOrderSummery"

const UserOrders = () => {
   const { id } = useParams()
   const { data: userInfo } = useGetUserQuery(id)
   const { data: orders, isLoading } = useGetMyOrdersQuery(userInfo)
   const employeeFromLocal = JSON.parse(localStorage.getItem("ich-user"))
   const { data: user } = useGetEmployeeQuery(employeeFromLocal?.id)
   const permission = employeeInfoFunc(user, "users")

   if (isLoading) {
      return <p>Loading...</p>
   }

   return (
      <div className=" grid grid-cols-8 justify-between relative">
         <div className="col-span-7 p-2 sm:p-10">
            {orders?.length > 0 ? (
               <>
                  <UserOrderSummery orders={orders} />
                  <div className="p-3 sm:p-10 rounded-lg shadow bg-white dark:bg-dark m-auto">
                     <div className="flex flex-col divide-y space-y-4">
                        {orders?.map((order, index) => (
                           <UserOrder key={index} order={order} />
                        ))}
                     </div>
                  </div>
               </>
            ) : (
               <h3 className="text-center text-red-500 text-base font-semibold">
                  No orders found
               </h3>
            )}
         </div>
         <div className="order-1 sm:order-2">
            <UserSidebar user={userInfo}></UserSidebar>
         </div>
      </div>
   )
}

export default UserOrders
