import Barcode from "react-barcode"
import logo from "../../assets/images/logo-bangla.png"
import employeeImage from "../../assets/images/employee.jpg"

const EmployeeIDCard = ({ employee }) => {
   return (
      <div className="flex gap-5">
         <div className="w-[230px] h-[350px] overflow-hidden border rounded-lg grid grid-cols-1 content-between">
            <div>
               <div className="h-6 bg-custom_blue border-b-4 border-custom_lightBlue"></div>
               <div className="relative w-full h-[130px] ">
                  <img
                     src="https://ichchapuron.nyc3.cdn.digitaloceanspaces.com/ichchapuron/ichchapuron-bangla-logo.png"
                     alt=""
                     width={"50%"}
                     className="m-auto mt-1"
                  />
                  <div className="absolute inset-x-0 bottom-0  w-20 h-20 rounded-full bg-white border-2 border-custom_lightBlue mx-auto my-auto z-50">
                     <img
                        src={employee?.image || employeeImage}
                        alt="employee picture"
                        className="rounded-full w-full h-full object-contain"
                     />
                  </div>
                  <div className="h-1 w-full bg-custom_blue  absolute bottom-6 rounded-"></div>
               </div>
               <div className="font-medium  text-center flex items-center flex-col text-black ">
                  <span className="text-base capitalize">{employee?.name}</span>
                  <span className="text-sm capitalize">
                     {employee?.designation}
                  </span>
                  <span className="text-xs">
                     Employee Id : {employee?.barcode}
                  </span>
                  <div className="flex gap-1 text-[11px] py-2">
                     <div className="flex flex-col items-start">
                        <span>DOB</span>
                        <span>Blood Group</span>
                        <span>Email</span>
                     </div>
                     <div className="flex flex-col col-span-2 items-start">
                        <span>: {employee?.dob}</span>
                        <span>: {employee?.blood}</span>
                        <span className="lowercase">: {employee?.email}</span>
                     </div>
                  </div>
                  <Barcode
                     format="CODE128"
                     lineColor="#000000"
                     background="#FFFFFF"
                     height={20}
                     value={employee?.barcode}
                     width={0.9}
                     fontSize={10}
                     margin={0}
                     padding={0}
                     displayValue={false}
                  />
               </div>
            </div>
            <div className="h-6 bg-custom_blue border-t-4 border-custom_skyBlue "></div>
         </div>
         <div className="w-[230px] h-[350px] overflow-hidden border rounded-lg grid grid-cols-1 content-between">
            <div>
               <div className="h-6 bg-custom_blue border-b-4 border-custom_lightBlue"></div>
               <img
                  src="https://ichchapuron.nyc3.cdn.digitaloceanspaces.com/ichchapuron/ichchapuron-bangla-logo.png"
                  alt=""
                  width={"20%"}
                  className="m-auto mt-2"
               />
               <div className="text-[8px] text-black text-center space-y-2 px-5 py-3">
                  <p>
                     THIS CARD IS THE PROPERTY OF <br /> ICHCHAPURON LIMITED AND
                     IS NOT <br /> TRANSFERABLE.It must be carried for working{" "}
                     <br />
                     purpuse only and produce on demand by <br /> authorised
                     persons.
                  </p>
                  <p>
                     If found , Please contact ICHCHAPURON <br /> LIMITED on
                     admin@ichchapuron.com.bd
                  </p>
                  <p>
                     This card is valid for the duration of a <br /> employee's
                     period of employment
                  </p>
               </div>
            </div>
            <div className="h-6 bg-custom_blue border-t-4 border-custom_skyBlue "></div>
         </div>
      </div>
   )
}

export default EmployeeIDCard
