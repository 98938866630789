import { createSlice } from "@reduxjs/toolkit"

const initialState = {
   permissions: [],
   employeeInfo: {},
}

const employeeSlice = createSlice({
   name: "employeeAction",
   initialState,
   reducers: {
      permissionHandle: (state, action) => {
         const exit = state.permissions.find(
            (data) => data?.title === action.payload.title
         )
         const remaining = state.permissions.filter(
            (data) => data?.title !== action.payload.title
         )

         if (action.payload?.permission === "Remove") {
            state.permissions = [...remaining]
         } else if (exit) {
            state.permissions = [...remaining, action.payload]
         } else {
            state.permissions = [...state.permissions, action.payload]
         }
      },
      setEmployeePermission: (state, action) => {
         state.permissions = [...action.payload]
      },
      setEmployeeInfo: (state, action) => {
         state.employeeInfo = action?.payload
      },
   },
})

export const { permissionHandle, setEmployeePermission, setEmployeeInfo } =
   employeeSlice.actions
export default employeeSlice.reducer
