import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    products: localStorage.getItem('ReturnProduct')
        ? JSON.parse(localStorage.getItem('ReturnProduct'))
        : []
}

export const createReturnProductSlice = createSlice({
    name: 'ReturnProduct',
    initialState,
    reducers: {

        addProduct(state, action) {
            state.products = action.payload
            localStorage.setItem('ReturnProduct', JSON.stringify(state.products))
        },

        decreaseProductQuantity(state, action) {
            const itemIndex = state.products.findIndex(
                (item) => item?._id === action.payload?._id
            )
            if (state.products[itemIndex].returnQty > 1) {
                state.products[itemIndex].returnQty -= 1
            } else if (state.products[itemIndex].returnQty === 1) {
                state.products[itemIndex].returnQty = 0
            }
            localStorage.setItem('ReturnProduct', JSON.stringify(state.products))
        },

        increaseProductQuantity(state, action) {
            const product = state.products.findIndex(
                (item) => item._id === action.payload._id
            )
            if (state.products[product].cartQuantity === state.products[product].returnQty) {
                console.log("Range exceed")
            } else {
                state.products[product].returnQty += 1
            }
            localStorage.setItem('ReturnProduct', JSON.stringify(state.products))
        },

    },
})

export const {
    addProduct,
    decreaseProductQuantity,
    increaseProductQuantity,
} = createReturnProductSlice.actions

export const ReturnProductReducer = createReturnProductSlice.reducer
