import { Link, useParams, useNavigate } from "react-router-dom"
import { useGetOrderByIdQuery } from "../../services/order"
import { Card, Typography, Button } from "@material-tailwind/react"
import { useUpdateOrderMutation } from "../../services/order"
import { enqueueSnackbar } from "notistack"
import OrderCustomerInfo from "../../components/Customers/OrderCustomerInfo"
import { useEffect, useState } from "react"
import moment from "moment"
import { FaBookOpen } from "react-icons/fa"
import { BsFillCheckCircleFill } from "react-icons/bs"
import { Avatar, Modal } from "rsuite"
import {
   getDiscountAmountFromPercentage,
   totalBuyingPrice,
} from "../../utils/orderInformation"
import { packagingCost } from "../../utils/const-info"
import CustomSpinner from "../../components/CustomSpinner"
import RefundAndReturnModal from "../../components/Modal/RefundAndReturnModal"
import { useGetEmployeeQuery } from "../../services/employee"
import { employeeInfoFunc } from "../../utils/utils"
import PrintPackagingSlip from "../../components/PrintPackging/PrintPackagingSlip"

const SingleDelivered = () => {
   const { id } = useParams()
   const { data: order, isLoading } = useGetOrderByIdQuery(id)
   const [openTotalCost, setOpenTotalCost] = useState(false)
   const [buyingPrice, setBuyingPrice] = useState(0)
   const [extraCost, setExtraCost] = useState(packagingCost)
   const [deliveryCost, setDeliveryCost] = useState(0)
   const [totalCost, setTotalCost] = useState(0)
   const [balance, setBalance] = useState(0)
   const [updateOrder] = useUpdateOrderMutation()
   let paymentDiscountAmount = getDiscountAmountFromPercentage(order)

   const employeeFromLocal = JSON.parse(localStorage.getItem("ich-user"))
   const { data: user } = useGetEmployeeQuery(employeeFromLocal?.id)
   const permission = employeeInfoFunc(user, "delivered")

   const [or, setOr] = useState(false)
   const navigate = useNavigate()

   // INITIAL DATA SET
   useEffect(() => {
      setBuyingPrice(totalBuyingPrice(order))
      setDeliveryCost(order?.paymentShipping?.shipping_cost)
      setTotalCost(order?.totalCost)
      setBalance(order?.balance)
   }, [order])

   // TOTAL COST EDIT
   const handleTotalCostEdit = () => {
      const totalCost =
         Number(buyingPrice) + parseInt(deliveryCost) + Number(extraCost)
      const balance = parseFloat(order?.totalPrice - totalCost).toFixed(2)
      setTotalCost(totalCost)
      setBalance(balance)
      setOpenTotalCost(false)
   }

   // UPDATE CURRENT STATUS TO FULLFILLED
   const updateStatus = async () => {
      await updateOrder({
         id: order?._id,
         orderStatus: "fullfilled",
         fullfilledAt: Date.now(),
         totalCost,
         balance,
         netPayment: order?.totalPrice - deliveryCost,
         actualBuyingPrice: buyingPrice,
         actualShippingCost: deliveryCost,
      })
      enqueueSnackbar("Order Fullfilled", { variant: "info" })
      navigate("/fullfilled")
   }

   // TOTAL COST CANCEL BUTTON
   const handleCancelButton = () => {
      setOpenTotalCost(false)
      setExtraCost(packagingCost)
      setBuyingPrice(totalBuyingPrice(order))
      setDeliveryCost(order?.paymentShipping?.shipping_cost)
      setTotalCost(order?.totalCost)
   }

   //  CLOSE REFUND AND RETURN MODAL
   const closeModal = () => {
      setOr(!or)
   }

   if (isLoading) {
      return <CustomSpinner />
   }

   return (
      <div className="p-2 mb-5 border-b m-5">
         <div className="flex justify-between items-center w-full">
            <div className="py-3 flex items-start">
               <Link
                  to="/delivered"
                  className="text-[#5c5f62] p-2 mr-3 rounded-md border border-gray-300 cursor-pointer"
               >
                  <svg
                     viewBox="0 0 20 20"
                     className="Polaris-Icon__Svg_375hu w-5 h-5"
                     focusable="false"
                     aria-hidden="true"
                  >
                     <path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path>
                  </svg>
               </Link>
               <div className="dark:text-white space-y-2">
                  <h3 className="font-semibold text-xl">#{order?.orderId}</h3>
                  <p className="text-sm">
                     Date:{" "}
                     {moment
                        .utc(order?.createdAt)
                        .utcOffset("+06:00")
                        .format("YYYY-MM-DD HH:mm:ss")}
                  </p>
               </div>
            </div>
            <div className="flex items-center  mb-5">
               <div className="dropdown dropdown-end ">
                  {permission?.permission !== "View Only" && (
                     <label
                        tabIndex={0}
                        className="m-1 flex cursor-pointer hover:bg-gray-200 rounded-md px-3 py-2 border duration-200"
                     >
                        More actions
                        <svg
                           viewBox="0 0 20 20"
                           className="Polaris-Icon__Svg_375hu w-5"
                           focusable="false"
                           aria-hidden="true"
                        >
                           <path d="M13.098 8h-6.196c-.751 0-1.172.754-.708 1.268l3.098 3.432c.36.399 1.055.399 1.416 0l3.098-3.433c.464-.513.043-1.267-.708-1.267Z"></path>
                        </svg>
                     </label>
                  )}

                  <ul
                     tabIndex={0}
                     className="dropdown-content p-2 shadow bg-base-100  dark:text-dark dark:bg-dark rounded-box w-60"
                  >
                     <li className="py-3 px-3 cursor-pointer rounded-md hover:bg-gray-100 dark:text-white hover:dark:text-dark">
                        <span
                           onClick={() => setOr(true)}
                           className="flex items-center"
                        >
                           <svg
                              viewBox="0 0 20 20"
                              className="Polaris-Icon__Svg_375hu w-4 mr-4"
                              focusable="false"
                              aria-hidden="true"
                           >
                              <path d="M7.5 2a1.5 1.5 0 0 0-1.5 1.5v9.5a1 1 0 0 0 1 1h9.5a1.5 1.5 0 0 0 1.5-1.5v-9a1.5 1.5 0 0 0-1.5-1.5h-9zm-4 4h.5v10h10v.5a1.5 1.5 0 0 1-1.5 1.5h-9a1.5 1.5 0 0 1-1.5-1.5v-9a1.5 1.5 0 0 1 1.5-1.5z"></path>
                           </svg>
                           <h3 className="text-sm capitalize">order return</h3>
                        </span>
                     </li>
                     {or && (
                        <RefundAndReturnModal
                           or={or}
                           order={order}
                           close={closeModal}
                        />
                     )}
                     <li className="py-3 px-3 rounded-md hover:bg-gray-100 dark:text-white hover:dark:text-dark">
                        <Link
                           to={`/order-invoice/${order?._id}`}
                           className="flex items-center"
                        >
                           <svg
                              viewBox="0 0 20 20"
                              className="Polaris-Icon__Svg_375hu w-4 mr-4 "
                              focusable="false"
                              aria-hidden="true"
                           >
                              <path d="M7.5 2a1.5 1.5 0 0 0-1.5 1.5v9.5a1 1 0 0 0 1 1h9.5a1.5 1.5 0 0 0 1.5-1.5v-9a1.5 1.5 0 0 0-1.5-1.5h-9zm-4 4h.5v10h10v.5a1.5 1.5 0 0 1-1.5 1.5h-9a1.5 1.5 0 0 1-1.5-1.5v-9a1.5 1.5 0 0 1 1.5-1.5z"></path>
                           </svg>
                           <h3 className="text-sm capitalize">Order Invoice</h3>
                        </Link>
                     </li>
                     <li>
                        <PrintPackagingSlip data={order} icon={false} />
                     </li>
                  </ul>
                  {/* <CancelOrder ></CancelOrder> */}
               </div>
               <div className="ml-3 flex items-center">
                  <Link
                     to="/delivered"
                     className="p-1 sm:px-2 sm:py-2 border rounded-md block hover:bg-gray-200 duration-200"
                  >
                     <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg_375hu w-4 sm:w-5"
                        focusable="false"
                        aria-hidden="true"
                     >
                        <path d="M12 16a.997.997 0 0 1-.707-.293l-5-5a.999.999 0 0 1 0-1.414l5-5a.999.999 0 1 1 1.414 1.414l-4.293 4.293 4.293 4.293a.999.999 0 0 1-.707 1.707z"></path>
                     </svg>
                  </Link>
                  <button className="p-1 sm:px-2 sm:py-2 border rounded-md hover:bg-gray-200 duration-200">
                     <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg_375hu w-4 sm:w-5"
                        focusable="false"
                        aria-hidden="true"
                     >
                        <path d="M8 16a.999.999 0 0 1-.707-1.707l4.293-4.293-4.293-4.293a.999.999 0 1 1 1.414-1.414l5 5a.999.999 0 0 1 0 1.414l-5 5a.997.997 0 0 1-.707.293z"></path>
                     </svg>
                  </button>
               </div>
            </div>
         </div>
         <div className="grid grid-cols-3 gap-4 ">
            <div className="col-span-2 ">
               <Card>
                  <table className="w-full min-w-max table-auto text-left">
                     <thead>
                        <tr>
                           {[
                              "Product",
                              "Quantity",
                              "Price",
                              "Custom Discount",
                           ].map((head) => (
                              <th
                                 key={head}
                                 className="border-b border-blue-gray-100 bg-blue-100  p-4"
                              >
                                 <Typography
                                    variant="small"
                                    className="font-semibold leading-none opacity-70 text-black"
                                 >
                                    {head}
                                 </Typography>
                              </th>
                           ))}
                        </tr>
                     </thead>
                     <tbody>
                        {order?.orderedProducts?.map((x, index) => {
                           const isLast =
                              index === order?.orderedProducts.length - 1
                           const classes = isLast
                              ? "p-4"
                              : "p-4 border-b border-blue-gray-50"

                           return (
                              <tr key={x?._id}>
                                 <td className={classes}>
                                    <div className="flex gap-2.5 w-1/2">
                                       {x?.images?.length > 0 ? (
                                          <Avatar
                                             size="md"
                                             src={x?.images?.[0]}
                                             alt={x?.title}
                                             className="!rounded-none"
                                          />
                                       ) : (
                                          <FaBookOpen
                                             size={20}
                                             className="text-custom_lightBlue"
                                          />
                                       )}

                                       <div className="flex flex-col items-start justify-between flex-grow">
                                          <h1 className="font-semibold !text-sm">
                                             {x?.title?.length > 36
                                                ? x?.title.substr(0, 36)
                                                : x?.title}
                                             {x?.title?.length > 36 && "..."}
                                          </h1>
                                       </div>
                                    </div>
                                 </td>
                                 <td className={classes}>
                                    <Typography
                                       variant="small"
                                       color="blue-gray"
                                       className="font-normal"
                                    >
                                       {x?.cartQuantity}
                                    </Typography>
                                 </td>
                                 <td className={classes}>
                                    <span className="line-through">
                                       {Math.round(x?.price) * x?.cartQuantity}{" "}
                                       TK.
                                    </span>
                                    <Typography
                                       variant="small"
                                       color="blue-gray"
                                       className="font-medium"
                                    >
                                       {Math.round(x?.discountedPrice) *
                                          x?.cartQuantity}{" "}
                                       TK.
                                    </Typography>
                                 </td>
                                 <td className={classes}>
                                    <Typography
                                       variant="small"
                                       color="blue-gray"
                                       className="font-normal"
                                    >
                                       {x?.custom_discount?.amount *
                                          x?.cartQuantity}
                                       {x?.custom_discount?.discountType ===
                                       "percentage"
                                          ? " %"
                                          : " TK."}
                                    </Typography>
                                    {x?.custom_discount?.amount === 0 ? null : (
                                       <span className="text-xs text-gray-500">
                                          Reason: {x?.custom_discount?.reason}
                                       </span>
                                    )}
                                 </td>
                              </tr>
                           )
                        })}
                     </tbody>
                  </table>
               </Card>
               <div className="bg-white dark:bg-dark dark:text-white dark:border-gray-600 w-full border rounded-lg p-5 my-4 shadow">
                  <div className="text-black dark:text-white mb-4 flex items-center text-sm space-x-2">
                     <span className="border border-main rounded-full">
                        <BsFillCheckCircleFill
                           size={16}
                           className="bg-main rounded-full"
                        />
                     </span>
                     <h3>
                        Payment ({order?.isPaid === true ? "Paid" : "Due"})
                     </h3>
                  </div>
                  <div className="text-sm space-y-1">
                     <div className="flex justify-between items-center">
                        <h3>Payment Method</h3>
                        <span>{order?.paymentMethod}</span>
                     </div>
                     <div className="flex justify-between items-center">
                        <h3>Subtotal</h3>
                        <span> {order?.subTotal} TK.</span>
                     </div>
                     {order?.paymentDiscount?.value !== 0 && (
                        <div className="flex justify-between items-center">
                           <h3>
                              Additional Discount
                              {order?.paymentDiscount?.reason && (
                                 <span className="text-sm text-gray-500">
                                    ({order?.paymentDiscount?.reason})
                                 </span>
                              )}
                           </h3>
                           <div className="flex justify-end gap-3">
                              <span>
                                 {order?.paymentDiscount?.value || 0}{" "}
                                 {order?.paymentDiscount?.discountType ===
                                 "percentage"
                                    ? " %"
                                    : "TK."}
                              </span>
                              {order?.paymentDiscount?.value !== 0 && (
                                 <span className="text-sm text-gray-500">
                                    (After Discount-{" "}
                                    {order?.subTotal - paymentDiscountAmount}{" "}
                                    TK.)
                                 </span>
                              )}
                           </div>
                        </div>
                     )}
                     <div className="flex justify-between items-center">
                        <h3>Delivery fee</h3>
                        <span>
                           {order?.paymentShipping?.shipping_cost}
                           TK.
                           {order?.paymentShipping?.shipping_type ? (
                              <span className="text-sm text-gray-500">
                                 {" "}
                                 ({order?.paymentShipping?.shipping_type})
                              </span>
                           ) : null}{" "}
                        </span>
                     </div>

                     <div className="flex justify-between items-center  py-2 border-t ">
                        <h3>Total</h3>
                        <span> {order?.totalPrice} TK.</span>
                     </div>
                     <div className="flex justify-between items-center py-2 border-t">
                        <h3>Total Cost</h3>
                        <div className="flex justify-between items-center gap-2">
                           <span>
                              {totalCost}
                              TK.
                           </span>
                           <div className="space-x-2">
                              <button
                                 type="button"
                                 onClick={() => setOpenTotalCost(true)}
                                 className="cursor-pointer bg-custom_lightBlue text-white rounded-md text-sm px-3 py-2"
                              >
                                 Change
                              </button>
                           </div>
                           <Modal open={openTotalCost}>
                              <Modal.Body>
                                 <div className="relative pb-0 dark:bg-dark  text-base">
                                    <h3 className="text-lg font-semibold border-b pb-4 mb-5">
                                       Edit Total Cost
                                    </h3>
                                    <div>
                                       <form action="" className="space-y-4">
                                          <div className="flex justify-between w-full ">
                                             <label htmlFor="discountTypes">
                                                Buying Price
                                             </label>
                                             <input
                                                type="number"
                                                value={buyingPrice}
                                                onChange={(e) =>
                                                   setBuyingPrice(
                                                      e.target.value
                                                   )
                                                }
                                                className="dark:text-dark border border-gray-300 rounded-lg w-auto px-3 py-2 outline-none "
                                             />
                                          </div>
                                          <div className="flex justify-between w-full ">
                                             <label htmlFor="discountTypes">
                                                Shipping Cost
                                             </label>
                                             <input
                                                type="number"
                                                onChange={(e) =>
                                                   setDeliveryCost(
                                                      e.target.value
                                                   )
                                                }
                                                value={deliveryCost}
                                                className="dark:text-dark border border-gray-300 rounded-lg w-auto px-3 py-2 outline-none"
                                             />
                                          </div>
                                          <div className="flex justify-between w-full ">
                                             <label htmlFor="discountTypes">
                                                Packaging Cost
                                             </label>
                                             <input
                                                value={extraCost}
                                                onChange={(e) =>
                                                   setExtraCost(e.target.value)
                                                }
                                                type="number"
                                                className="dark:text-dark border border-gray-300 rounded-lg w-auto px-3 py-2 outline-none"
                                             />
                                          </div>
                                       </form>
                                    </div>
                                 </div>
                              </Modal.Body>
                              <Modal.Footer>
                                 <div className="text-right py-5 border-t">
                                    <button
                                       onClick={handleCancelButton}
                                       className="py-2 px-4 border border-gray-300 rounded-lg cursor-pointer mr-2"
                                    >
                                       Cancel
                                    </button>
                                    <button
                                       onClick={handleTotalCostEdit}
                                       className="py-2 px-4 bg-[#008060] rounded-lg text-white"
                                    >
                                       Apply
                                    </button>
                                 </div>
                              </Modal.Footer>
                           </Modal>
                        </div>
                     </div>
                  </div>
                  <div className="text-sm flex justify-between items-center py-2 border-t">
                     <h3>Net Payment</h3>
                     <span>{order?.totalPrice - deliveryCost} TK.</span>
                  </div>
                  <div className="text-sm flex justify-between items-center py-2 border-t">
                     <h3>Balance</h3>
                     <span>{balance} TK.</span>
                  </div>
               </div>
            </div>
            <div className="col-span-1 ">
               <OrderCustomerInfo customer={order?.customer} />
               {permission?.permission !== "View Only" && (
                  <div className="flex items-center justify-end gap-3 mt-10">
                     <Button
                        onClick={() => {
                           updateStatus()
                        }}
                        size="md"
                        className="bg-custom_green hover:bg-custom_lightGreen"
                     >
                        FullFilled
                     </Button>
                  </div>
               )}
            </div>
         </div>
      </div>
   )
}

export default SingleDelivered
