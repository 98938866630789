import { useGetAcademicsQuery } from "../../services/academic"
import { useGetBooksQuery } from "../../services/books"
import { useGetStationaryQuery } from "../../services/stationary"
import ReactApexChart from "react-apexcharts"

const Home = () => {
   const { data: academic } = useGetAcademicsQuery() //data?.products
   const { data: book } = useGetBooksQuery() //data?.products
   const { data: stationary } = useGetStationaryQuery() //data

   // Data for the chart
   const series = [
      {
         name: "Sales",
         data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
      },
   ]

   // Options for the chart
   const options = {
      chart: {
         height: 350,
         type: "line",
         zoom: {
            enabled: false,
         },
      },
      xaxis: {
         categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
         ],
      },
   }

   return (
      <main class="p-6 sm:p-10 space-y-6">
         <section class="grid md:grid-cols-2 xl:grid-cols-4 gap-6">
            <div class="flex items-center p-8 bg-white shadow rounded-lg">
               <div class="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-blue-600 bg-blue-100 rounded-full mr-6">
                  <svg
                     aria-hidden="true"
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke="currentColor"
                     class="h-6 w-6"
                  >
                     <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                     />
                  </svg>
               </div>
               <div>
                  <span class="block text-2xl font-bold">
                     {academic?.products?.length}
                  </span>
                  <span class="block text-gray-500">Academic Books</span>
               </div>
            </div>
            <div class="flex items-center p-8 bg-white shadow rounded-lg">
               <div class="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-blue-600 bg-blue-100 rounded-full mr-6">
                  <svg
                     aria-hidden="true"
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke="currentColor"
                     class="h-6 w-6"
                  >
                     <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                     />
                  </svg>
               </div>
               <div>
                  <span class="block text-2xl font-bold">
                     {book?.products?.length}
                  </span>
                  <span class="block text-gray-500">General Books</span>
               </div>
            </div>
            <div class="flex items-center p-8 bg-white shadow rounded-lg">
               <div class="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-blue-600 bg-blue-100 rounded-full mr-6">
                  <svg
                     aria-hidden="true"
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke="currentColor"
                     class="h-6 w-6"
                  >
                     <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                     />
                  </svg>
               </div>
               <div>
                  <span class="inline-block text-2xl font-bold">
                     {stationary?.length}
                  </span>
                  <span class="block text-gray-500">Stationary Items</span>
               </div>
            </div>
         </section>
         <section class="grid md:grid-cols-2 xl:grid-cols-4 xl:grid-rows-3 xl:grid-flow-col gap-6">
            <div class="flex flex-col md:col-span-2 md:row-span-2 bg-white shadow rounded-lg">
               <div class="px-6 py-5 font-semibold border-b border-gray-100">
                  Sales
               </div>
               <div class="p-4 flex-grow">
                  <div class="flex items-center justify-center h-full px-4 py-16 text-gray-400 text-3xl font-semibold bg-gray-100 border-2 border-gray-200 border-dashed rounded-md">
                     <ReactApexChart
                        options={options}
                        series={series}
                        type="line"
                        height={350}
                     />
                  </div>
               </div>
            </div>
            {/* <div class="flex items-center p-8 bg-white shadow rounded-lg">
          <div class="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-yellow-600 bg-yellow-100 rounded-full mr-6">
            <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6">
              <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
              <path fill="#fff" d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
            </svg>
          </div>
          <div>
            <span class="block text-2xl font-bold">25</span>
            <span class="block text-gray-500">Lections left</span>
          </div>
        </div> */}
            {/* <div class="flex items-center p-8 bg-white shadow rounded-lg">
          <div class="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-teal-600 bg-teal-100 rounded-full mr-6">
            <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <div>
            <span class="block text-2xl font-bold">139</span>
            <span class="block text-gray-500">Hours spent on lections</span>
          </div>
        </div> */}
            <div class="row-span-3 bg-white shadow rounded-lg">
               <div class="flex items-center justify-between px-6 py-5 font-semibold border-b border-gray-100">
                  <span>Our users</span>
               </div>
               <div class="overflow-y-auto">
                  <ul class="p-6 space-y-6">
                     <li class="flex items-center">
                        <div class="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                           <img
                              src="https://randomuser.me/api/portraits/women/82.jpg"
                              alt="Annette Watson profile picture"
                           />
                        </div>
                        <span class="text-gray-600">Annette Watson</span>
                     </li>
                     <li class="flex items-center">
                        <div class="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                           <img
                              src="https://randomuser.me/api/portraits/men/81.jpg"
                              alt="Calvin Steward profile picture"
                           />
                        </div>
                        <span class="text-gray-600">Calvin Steward</span>
                     </li>
                     <li class="flex items-center">
                        <div class="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                           <img
                              src="https://randomuser.me/api/portraits/men/80.jpg"
                              alt="Ralph Richards profile picture"
                           />
                        </div>
                        <span class="text-gray-600">Ralph Richards</span>
                     </li>
                     <li class="flex items-center">
                        <div class="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                           <img
                              src="https://randomuser.me/api/portraits/men/79.jpg"
                              alt="Bernard Murphy profile picture"
                           />
                        </div>
                        <span class="text-gray-600">Bernard Murphy</span>
                     </li>
                     <li class="flex items-center">
                        <div class="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                           <img
                              src="https://randomuser.me/api/portraits/women/78.jpg"
                              alt="Arlene Robertson profile picture"
                           />
                        </div>
                        <span class="text-gray-600">Arlene Robertson</span>
                     </li>
                     <li class="flex items-center">
                        <div class="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                           <img
                              src="https://randomuser.me/api/portraits/women/77.jpg"
                              alt="Jane Lane profile picture"
                           />
                        </div>
                        <span class="text-gray-600">Jane Lane</span>
                     </li>
                     <li class="flex items-center">
                        <div class="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                           <img
                              src="https://randomuser.me/api/portraits/men/76.jpg"
                              alt="Pat Mckinney profile picture"
                           />
                        </div>
                        <span class="text-gray-600">Pat Mckinney</span>
                     </li>
                     <li class="flex items-center">
                        <div class="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                           <img
                              src="https://randomuser.me/api/portraits/men/75.jpg"
                              alt="Norman Walters profile picture"
                           />
                        </div>
                        <span class="text-gray-600">Norman Walters</span>
                     </li>
                  </ul>
               </div>
            </div>
            <div class="flex flex-col row-span-3 bg-white shadow rounded-lg">
               <div class="px-6 py-5 font-semibold border-b border-gray-100">
                  Sales Graph
               </div>
               <div class="p-4 flex-grow">
                  <div class="flex items-center justify-center h-full px-4 py-24 text-gray-400 text-3xl font-semibold bg-gray-100 border-2 border-gray-200 border-dashed rounded-md">
                     {" "}
                     <ReactApexChart
                        options={options}
                        series={series}
                        type="area"
                        height={350}
                     />
                  </div>
               </div>
            </div>
         </section>
         {/* <section class="text-right font-semibold text-gray-500">
        <a href="#" class="text-blue-600 hover:underline">Recreated on Codepen</a> with <a href="https://tailwindcss.com/" class="text-teal-400 hover:underline">Tailwind CSS</a> by Sinan AYDOĞAN, <a href="https://dribbble.com/shots/10711741-Free-UI-Kit-for-Figma-Online-Courses-Dashboard" class="text-blue-600 hover:underline">original design</a> made by Chili Labs and <a href="https://codepen.io/azrikahar/pen/abZzaga" class="text-blue-600 hover:underline" target="_blank">abZzaga</a>
      </section> */}
      </main>
   )
}

export default Home
