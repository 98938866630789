import React, { useEffect, useState } from "react"
import LeavePage from "../../../../../components/LeavePage"
import { Avatar, Modal } from "rsuite"
import useScanDetection from "use-scan-detection"
import { Chip, Button, Input } from "@material-tailwind/react"
import { useGetSearchKeywordQuery } from "../../../../../services/Search"
import { useDispatch, useSelector } from "react-redux"
import {
   addProduct,
   addProductDiscount,
   removeProductDiscount,
   removeOrderProduct,
   decreaseProductQuantity,
   increaseProductQuantity,
   getTotals,
   clearProduct,
} from "../../../../../services/order/orderProductSlice"
import { enqueueSnackbar } from "notistack"
import { useParams } from "react-router-dom"
import {
   useGetOrderByIdQuery,
   useUpdateOrderMutation,
} from "../../../../../services/order"

const moneyIcon = (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6 mr-2"
   >
      <path
         strokeLinecap="round"
         strokeLinejoin="round"
         d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
   </svg>
)

const collectPayments = [
   {
      name: "Pay by cash",
      icon: moneyIcon,
   },
   {
      name: "Pay by card",
      icon: moneyIcon,
   },
   {
      name: "Cash on delivery",
      icon: moneyIcon,
   },
]

const EditOrder = () => {
   const { id } = useParams()
   const { data: order } = useGetOrderByIdQuery(id)
   const [updateOrder] = useUpdateOrderMutation()

   const dispatch = useDispatch()
   const [cp, setCp] = useState([])

   // CART ORDERED PRODUCTS
   const { products, totalAmount } = useSelector((state) => state.orderProduct)
   const nowOr = [...cp, ...products]
   const uniqueOp = nowOr.reduce((accumulator, current) => {
      if (!accumulator.find((item) => item._id === current._id)) {
         accumulator.push(current)
      }
      return accumulator
   }, [])
   // console.log('uniqueOp', uniqueOp)
   //PATMENT METHOD
   const [paymentMethod, setPaymentMethod] = useState("")
   //PRODUCT SEARCH
   const [search, setsearch] = useState("")
   //SCANNER
   const [scanSearch, setScanSearch] = useState("")
   //SEARCH PRODUCT DATA
   const { data } = useGetSearchKeywordQuery(search || scanSearch)

   //PAYMENT DISCOUNT
   const [paymentDiscount, setPaymentDiscount] = useState({
      reasons: "",
      discountTypes: "amount",
      amounts: 0,
   })

   const [openPaymentDiscount, setOpenPaymentDiscount] = useState(false)
   const handleOpenPaymentDiscount = () => setOpenPaymentDiscount(true)
   const handleClosePaymentDiscount = () => setOpenPaymentDiscount(false)

   //paymentShipping
   const [shipping, setShipping] = useState({
      shipping_carrier: "",
      shipping_type: "",
   })

   //paymentShipping
   const [openAddshipping, setOpenAddshipping] = useState(false)
   const handleOpenAddshipping = () => setOpenAddshipping(true)
   const handleCloseAddshipping = () => setOpenAddshipping(false)

   //paymentAdjustmentCost
   const [shipppingAdjustment, setShipppingAdjustment] = useState({
      reason: "",
      discountType: "amount",
      amount: 0,
   })

   const [openShipppingAdjustment, setOpenShippingAdjustment] = useState(false)
   const handleOpenShipppingAdjustment = () => setOpenShippingAdjustment(true)
   const handleCloseShipppingAdjustment = () => setOpenShippingAdjustment(false)

   const [productDiscount, setProductDiscount] = useState({
      reason: "",
      discountType: "amount",
      amount: 0,
   })

   const [openProductDiscount, setOpenProductDiscount] = useState(false)
   const [productID, setProductID] = useState("")
   const handleOpenProductDiscount = (id) => {
      setOpenProductDiscount(true)
      setProductID(id)
      setProductDiscount({
         reason: "",
         discountType: "amount",
         amount: 0,
      })
   }
   const handleCloseProductDiscount = () => setOpenProductDiscount(false)

   useScanDetection({
      onComplete: setScanSearch,
      minLength: 3,
   })

   const subtotal = products?.reduce(
      (total, item) => item?.discountedPrice + total,
      0
   )

   const handleOrder = () => {
      const updatedOrder = {
         paymentMethod: paymentMethod,
         totalPrice:
            totalAmount +
            (shipping.shipping_type === "Standard shipping"
               ? 50
               : shipping.shipping_type === "Priority shipping"
               ? 60
               : shipping.shipping_type === "Express shipping"
               ? 75
               : shipping.shipping_type === "Click & Collect"
               ? 0
               : 0) +
            Number(shipppingAdjustment.amount) -
            Number(paymentDiscount.amounts),
         subTotal: subtotal,
         orderedProducts: uniqueOp,
         paymentDiscount: {
            discountType: paymentDiscount.discountTypes,
            value: Number(paymentDiscount.amounts),
            reason: paymentDiscount.reasons,
         },
         shipping_adjustment_cost: {
            discountType: shipppingAdjustment.discountType,
            value: Number(shipppingAdjustment.amount),
            reason: shipppingAdjustment.reason,
         },
         paymentShipping: {
            shipping_carrier: shipping.shipping_carrier,
            shipping_type: shipping.shipping_type,
         },
      }
      updateOrder({ id: id, ...updatedOrder })
      enqueueSnackbar("updated", { variant: "default" })
      dispatch(clearProduct())
   }

   useEffect(() => {
      if (order?.orderedProducts) {
         setCp(order?.orderedProducts)
      } else {
         setCp([])
      }
      dispatch(getTotals())
   }, [dispatch, order?.orderedProducts])

   return (
      <>
         <div className="p-2 mb-5 m-5 dark:text-white">
            <div className="py-3 flex items-center">
               <label
                  htmlFor="leavePage"
                  className="text-[#5c5f62] p-2 mr-3 rounded-md border border-gray-300 cursor-pointer"
               >
                  <svg
                     viewBox="0 0 20 20"
                     className="Polaris-Icon__Svg_375hu w-5 h-5"
                     focusable="false"
                     aria-hidden="true"
                  >
                     <path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path>
                  </svg>
               </label>
               <h3 className="font-semibold text-xl">Edit order</h3>
               <LeavePage></LeavePage>
            </div>
            <div className="grid grid-cols-3 gap-4 ">
               <div className="col-span-1 lg:col-span-2 ">
                  <div className="bg-white w-full border rounded-lg p-2 sm:p-5 shadow">
                     <div className="flex flex-col sm:flex-row justify-start sm:items-center w-full gap-1">
                        <form className="relative w-full">
                           <label
                              htmlFor="default-search"
                              className="text-sm font-medium text-gray-900 sr-only"
                           >
                              Search
                           </label>
                           <div className="relative">
                              <div className="absolute inset-y-0 left-0 flex items-center pl-2.5 pointer-events-none">
                                 <svg
                                    aria-hidden="true"
                                    className="w-5 h-5 text-gray-400"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                 >
                                    <path
                                       strokeLinecap="round"
                                       strokeLinejoin="round"
                                       strokeWidth="2"
                                       d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                    ></path>
                                 </svg>
                              </div>
                              <input
                                 name="search"
                                 value={search}
                                 onChange={(e) => setsearch(e.target.value)}
                                 className="pl-8 !text-base focus:ring-0 focus:outline-none focus:border-gray-300 leading-none text-left text-gray-500 px-4 py-2 w-full border rounded border-gray-300 outline-none"
                                 placeholder="Enter title or barcode to search"
                                 required
                              />
                              <p className="text-white cursor-pointer absolute right-0 bg-gradient-to-r from-[#ff7b00] to-[#ffa200] font-medium top-0 bottom-0 flex items-center justify-center text-center text-sm px-5">
                                 Search
                              </p>
                           </div>
                           {data?.length > 0 && (
                              <div className="absolute bottom-0 left-0 right-0 top-10 z-50 h-max overflow-y-scroll max-h-64 shadow-lg">
                                 {data?.map((x, i) => (
                                    <div
                                       key={i}
                                       onClick={() => {
                                          if (x?.countInStock === 0) {
                                             enqueueSnackbar(
                                                "Contact us for product request",
                                                { variant: "warning" }
                                             )
                                          } else {
                                             enqueueSnackbar(
                                                `${x?.title?.substr(
                                                   0,
                                                   20
                                                )} was added to the shopping cart.`,
                                                { variant: "success" }
                                             )
                                             dispatch(addProduct(x))
                                             setsearch("")
                                          }
                                       }}
                                       className="flex h-14 w-full cursor-pointer p-2 items-center justify-between gap-1 overflow-hidden bg-white shadow hover:bg-gray-100 hover:text-custom-orange"
                                    >
                                       <div className="flex items-center gap-1">
                                          <div className="h-10 w-10">
                                             <Avatar
                                                circle
                                                src={x?.images[0]}
                                                alt={x?.title}
                                             />
                                          </div>
                                          <div className="flex flex-col items-start">
                                             <span className="block truncate text-sm font-medium">
                                                {x?.title?.length > 24
                                                   ? x?.title.substr(0, 24)
                                                   : x?.title}
                                                {x?.title?.length > 24 && "..."}
                                             </span>
                                             <span className="text-custom-blue text-xs">
                                                {x?.author?.name?.length > 24
                                                   ? x?.author?.name.substr(
                                                        0,
                                                        24
                                                     )
                                                   : x?.author?.name}
                                                {x?.author?.name?.length > 24 &&
                                                   "..."}
                                             </span>
                                          </div>
                                       </div>
                                       <div className="flex items-center gap-5 px-3">
                                          {x?.countInStock === 0 ? (
                                             <span className="text-sm text-custom-hot">
                                                Out of stock
                                             </span>
                                          ) : (
                                             <span className="text-sm text-custom-green">
                                                In stock ({x?.countInStock})
                                             </span>
                                          )}
                                          {x?.discount === 0 ? null : (
                                             <span className="text-sm text-custom-blue">
                                                ({Math.ceil(x?.discount)}% Off)
                                             </span>
                                          )}

                                          <div className="flex items-center">
                                             {x?.discount === 0 ? (
                                                <span className="RT inline-block text-sm">
                                                   Tk.{x?.price}
                                                </span>
                                             ) : (
                                                <div className="flex items-center justify-center gap-1">
                                                   <span className="text-sm text-custom-green">
                                                      TK.{" "}
                                                      {Math.round(
                                                         x?.discountedPrice
                                                      )}
                                                   </span>
                                                   <div className="text-sm text-custom-gray line-through">
                                                      TK. {x?.price}
                                                   </div>
                                                </div>
                                             )}
                                          </div>
                                       </div>
                                    </div>
                                 ))}
                              </div>
                           )}
                        </form>
                     </div>
                     {products?.length > 0 && (
                        <div className="flex flex-col gap-2">
                           <div className="w-full bg-white p-3 mt-3">
                              <p className="py-2 text-sm text-gray-500">
                                 Newly Added Products
                              </p>
                              <div className="flex items-center justify-between shadow w-full border-b-4 border-dotted bg-yellow-200 border-white px-2 py-2.5">
                                 <h3 className="font-semibold text-gray-600 text-xs uppercase w-56">
                                    Product Details
                                 </h3>
                                 <div className="flex items-center justify-between w-1/2">
                                    <h3 className="font-semibold text-center text-gray-600 text-xs uppercase">
                                       Quantity
                                    </h3>
                                    <h3 className="font-semibold text-center text-gray-600 text-xs uppercase">
                                       Price
                                    </h3>
                                    <h3 className="font-semibold text-center text-gray-600 text-xs uppercase">
                                       Add Discount
                                    </h3>
                                 </div>
                              </div>
                              <div className="flex flex-col gap-3 mt-1">
                                 {products?.map((x, index) => (
                                    <div
                                       key={index}
                                       className="bg-white shadow py-2 px-1.5"
                                    >
                                       <div className="flex items-center justify-between w-full gap-2">
                                          <div className="flex gap-2.5 w-1/2">
                                             <Avatar
                                                size="lg"
                                                src={x?.images?.[0]}
                                                alt={x?.title}
                                                className="!rounded-none"
                                             />
                                             <div className="flex flex-col items-start justify-between flex-grow">
                                                <h1 className="font-semibold !text-sm">
                                                   {x?.title?.length > 36
                                                      ? x?.title.substr(0, 36)
                                                      : x?.title}
                                                   {x?.title?.length > 36 &&
                                                      "..."}
                                                </h1>
                                                <button
                                                   onClick={() =>
                                                      dispatch(
                                                         removeOrderProduct(x)
                                                      )
                                                   }
                                                   className="font-semibold hover:text-red-500 text-gray-500 text-xs"
                                                >
                                                   Remove
                                                </button>
                                             </div>
                                          </div>

                                          <div className="flex items-center justify-between w-1/2">
                                             <div className="flex flex-col items-center">
                                                <svg
                                                   onClick={() =>
                                                      dispatch(
                                                         decreaseProductQuantity(
                                                            x
                                                         )
                                                      )
                                                   }
                                                   className="cursor-pointer fill-current text-gray-600 w-3"
                                                   viewBox="0 0 448 512"
                                                >
                                                   <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                                                </svg>
                                                <p className="my-1.5 border text-center w-8">
                                                   {x?.cartQuantity}
                                                </p>
                                                <svg
                                                   onClick={() =>
                                                      dispatch(
                                                         increaseProductQuantity(
                                                            x
                                                         )
                                                      )
                                                   }
                                                   className="cursor-pointer fill-current text-gray-600 w-3"
                                                   viewBox="0 0 448 512"
                                                >
                                                   <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                                                </svg>
                                             </div>
                                             <span className="text-center font-semibold text-sm">
                                                TK.{" "}
                                                {Math.round(
                                                   x?.discountedPrice
                                                ) * x?.cartQuantity}
                                             </span>
                                             <div>
                                                {Number(
                                                   x?.custom_discount?.amount
                                                ) > 0 ? (
                                                   x?.custom_discount
                                                      ?.discountType ===
                                                   "amount" ? (
                                                      <div className="flex gap-2 items-center">
                                                         <Chip
                                                            value={`${Number(
                                                               x
                                                                  ?.custom_discount
                                                                  ?.amount
                                                            )}Tk. off`}
                                                         />
                                                         <Button
                                                            onClick={() =>
                                                               dispatch(
                                                                  removeProductDiscount(
                                                                     {
                                                                        product:
                                                                           x,
                                                                        productDiscount:
                                                                           {
                                                                              reason:
                                                                                 "",
                                                                              discountType:
                                                                                 "amount",
                                                                              amount: 0,
                                                                           },
                                                                     }
                                                                  )
                                                               )
                                                            }
                                                         >
                                                            x
                                                         </Button>
                                                      </div>
                                                   ) : (
                                                      <div className="flex gap-2 items-center">
                                                         <Chip
                                                            value={`${Number(
                                                               x
                                                                  ?.custom_discount
                                                                  ?.amount
                                                            )}% off`}
                                                         />
                                                         <Button
                                                            onClick={() =>
                                                               dispatch(
                                                                  removeProductDiscount(
                                                                     {
                                                                        product:
                                                                           x,
                                                                        productDiscount:
                                                                           {
                                                                              reason:
                                                                                 "",
                                                                              discountType:
                                                                                 "amount",
                                                                              amount: 0,
                                                                           },
                                                                     }
                                                                  )
                                                               )
                                                            }
                                                         >
                                                            x
                                                         </Button>
                                                      </div>
                                                   )
                                                ) : (
                                                   <Button
                                                      onClick={() =>
                                                         handleOpenProductDiscount(
                                                            x?._id
                                                         )
                                                      }
                                                   >
                                                      Click here
                                                   </Button>
                                                )}
                                             </div>
                                             <Modal
                                                open={openProductDiscount}
                                                onClose={
                                                   handleCloseProductDiscount
                                                }
                                             >
                                                <Modal.Body>
                                                   <div className="elative pb-0 dark:bg-dark">
                                                      <h3 className="text-lg font-semibold border-b pb-4 mb-5">
                                                         Add Discount
                                                      </h3>
                                                      <div>
                                                         <form
                                                            action=""
                                                            className="space-y-4"
                                                         >
                                                            <div className="flex items-center justify-between gap-2">
                                                               <div className="flex flex-col w-full ">
                                                                  <label htmlFor="discountType">
                                                                     Discount
                                                                     Type
                                                                  </label>
                                                                  <select
                                                                     onChange={(
                                                                        e
                                                                     ) =>
                                                                        setProductDiscount(
                                                                           {
                                                                              ...productDiscount,
                                                                              discountType:
                                                                                 e
                                                                                    .target
                                                                                    .value,
                                                                           }
                                                                        )
                                                                     }
                                                                     className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 w-full mt-1 outline-none"
                                                                  >
                                                                     <option
                                                                        value="amount"
                                                                        defaultChecked
                                                                     >
                                                                        Amount
                                                                     </option>
                                                                     <option value="percentage">
                                                                        Percentage
                                                                     </option>
                                                                  </select>
                                                               </div>
                                                               <div className="flex flex-col w-full">
                                                                  {/* <label htmlFor="discountType">Discount Value</label> */}
                                                                  <div className="relative mt-1">
                                                                     <Input
                                                                        size="md"
                                                                        label={`Discount Value ${
                                                                           productDiscount.discountType ===
                                                                           "amount"
                                                                              ? "Tk."
                                                                              : "%"
                                                                        }`}
                                                                        type="text"
                                                                        name="amount"
                                                                        value={
                                                                           productDiscount.amount
                                                                        }
                                                                        onChange={(
                                                                           e
                                                                        ) =>
                                                                           setProductDiscount(
                                                                              {
                                                                                 ...productDiscount,
                                                                                 amount:
                                                                                    Number(
                                                                                       e
                                                                                          .target
                                                                                          .value
                                                                                    ),
                                                                              }
                                                                           )
                                                                        }
                                                                        // className="dark:text-dark w-full px-3 py-2 border border-gray-300 rounded-lg outline-none"
                                                                        // placeholder="Tk. 0.00"
                                                                     />
                                                                     {/* <span className="absolute right-2 top-2">
                                                                        {productDiscount.discountType === "amount" ? "Tk." : "%"}
                                                                     </span> */}
                                                                  </div>
                                                               </div>
                                                            </div>
                                                            <div className="flex flex-col w-full">
                                                               {/* <label htmlFor="discountType">Reason</label> */}
                                                               <Input
                                                                  size="md"
                                                                  label="Reason"
                                                                  type="text"
                                                                  value={
                                                                     productDiscount.reason
                                                                  }
                                                                  name="reason"
                                                                  onChange={(
                                                                     e
                                                                  ) =>
                                                                     setProductDiscount(
                                                                        {
                                                                           ...productDiscount,
                                                                           reason:
                                                                              e
                                                                                 .target
                                                                                 .value,
                                                                        }
                                                                     )
                                                                  }
                                                                  // className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                                                               />
                                                               <span>
                                                                  Your customer
                                                                  can see this
                                                                  reason
                                                               </span>
                                                            </div>
                                                         </form>
                                                      </div>
                                                   </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                   <div className="text-right py-5 border-t">
                                                      <button
                                                         onClick={
                                                            handleCloseProductDiscount
                                                         }
                                                         className="py-2 px-4 border border-gray-300 rounded-lg cursor-pointer mr-2"
                                                      >
                                                         Cancel
                                                      </button>
                                                      <button
                                                         onClick={() => {
                                                            dispatch(
                                                               addProductDiscount(
                                                                  {
                                                                     product:
                                                                        productID,
                                                                     productDiscount:
                                                                        productDiscount,
                                                                  }
                                                               )
                                                            )
                                                            setOpenProductDiscount(
                                                               false
                                                            )
                                                         }}
                                                         className="py-2 px-4 bg-[#008060] rounded-lg text-white"
                                                      >
                                                         Apply
                                                      </button>
                                                   </div>
                                                </Modal.Footer>
                                             </Modal>
                                          </div>
                                       </div>
                                    </div>
                                 ))}
                              </div>
                           </div>
                        </div>
                     )}
                     {cp?.length > 0 && (
                        <div className="flex flex-col gap-2">
                           <div className="w-full bg-white p-3 mt-3">
                              <p className="py-2 text-sm text-gray-500">
                                 Previous Products
                              </p>
                              <div className="flex items-center justify-between shadow w-full border-b-4 border-dotted bg-yellow-200 border-white px-2 py-2.5">
                                 <h3 className="font-semibold text-gray-600 text-xs uppercase w-56">
                                    Product Details
                                 </h3>
                                 <div className="flex items-center justify-between w-1/2">
                                    <h3 className="font-semibold text-center text-gray-600 text-xs uppercase">
                                       Price
                                    </h3>
                                    <h3 className="font-semibold text-center text-gray-600 text-xs uppercase">
                                       Add to existing
                                    </h3>
                                 </div>
                              </div>
                              <div className="flex flex-col gap-3 mt-1">
                                 {cp?.map((x, index) => (
                                    <div
                                       key={index}
                                       className="bg-white shadow py-2 px-1.5"
                                    >
                                       <div className="flex items-center justify-between w-full gap-2">
                                          <div className="flex gap-2.5 w-1/2">
                                             <Avatar
                                                size="lg"
                                                src={x?.images?.[0]}
                                                alt={x?.title}
                                                className="!rounded-none"
                                             />
                                             <div className="flex flex-col items-start justify-between flex-grow">
                                                <h1 className="font-semibold !text-sm">
                                                   {x?.title?.length > 36
                                                      ? x?.title.substr(0, 36)
                                                      : x?.title}
                                                   {x?.title?.length > 36 &&
                                                      "..."}
                                                </h1>
                                                <button
                                                   onClick={() =>
                                                      dispatch(
                                                         removeOrderProduct(x)
                                                      )
                                                   }
                                                   className="font-semibold hover:text-red-500 text-gray-500 text-xs"
                                                >
                                                   Remove
                                                </button>
                                             </div>
                                          </div>

                                          <div className="flex items-center justify-between w-1/2">
                                             <span className="text-center font-semibold text-sm">
                                                TK.{" "}
                                                {Math.round(
                                                   x?.discountedPrice
                                                ) * x?.cartQuantity}
                                             </span>
                                             <Button
                                                onClick={() => {
                                                   dispatch(addProduct(x))
                                                   enqueueSnackbar(
                                                      `${x?.title?.substr(
                                                         0,
                                                         20
                                                      )} has added to your cart.`,
                                                      { variant: "default" }
                                                   )
                                                }}
                                             >
                                                Add
                                             </Button>
                                          </div>
                                       </div>
                                    </div>
                                 ))}
                              </div>
                           </div>
                        </div>
                     )}
                  </div>
                  <div className="bg-white flex flex-col gap-2 w-full border rounded-lg p-5 my-4 shadow">
                     <h2 className="font-semibold">Select Payment Method </h2>
                     {collectPayments.map((payment) => (
                        <label
                           key={payment.name}
                           htmlFor={payment.name}
                           className="cursor-pointer text-sm flex items-center gap-2"
                        >
                           <input
                              type="radio"
                              id={payment.name}
                              value={payment.name}
                              name="paymentMethod"
                              onClick={() => setPaymentMethod(payment.name)}
                           />
                           <span>{payment.name}</span>
                        </label>
                     ))}
                  </div>
                  <div className="bg-white flex flex-col gap-2 w-full border rounded-lg p-5 my-4 shadow">
                     <h3 className="font-semibold">Payment</h3>
                     <div className="text-sm space-y-3">
                        <div className="flex justify-between items-center">
                           <h3>Subtotal</h3>
                           <span>Tk. {totalAmount}</span>
                        </div>
                        <div className="flex justify-between items-center">
                           <div
                              onClick={handleOpenPaymentDiscount}
                              className="text-[#576CBC] cursor-pointer"
                           >
                              Add Discount
                           </div>
                           <span>Tk. {Number(paymentDiscount.amounts)}</span>

                           <Modal
                              open={openPaymentDiscount}
                              onClose={handleClosePaymentDiscount}
                           >
                              <Modal.Body>
                                 <div className="elative pb-0 dark:bg-dark">
                                    <h3 className="text-lg font-semibold border-b pb-4 mb-5">
                                       Add Discount
                                    </h3>
                                    <div>
                                       <form action="" className="space-y-4">
                                          <div className="flex items-center justify-between gap-2">
                                             <div className="flex flex-col w-full ">
                                                <label htmlFor="discountTypes">
                                                   Discount Type
                                                </label>
                                                <select
                                                   onChange={(e) =>
                                                      setPaymentDiscount({
                                                         ...paymentDiscount,
                                                         discountTypes:
                                                            e.target.value,
                                                      })
                                                   }
                                                   className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 w-full mt-1 outline-none"
                                                >
                                                   <option
                                                      value="amount"
                                                      defaultChecked
                                                   >
                                                      Amount
                                                   </option>
                                                   <option value="percentage">
                                                      Percentage
                                                   </option>
                                                </select>
                                             </div>
                                             <div className="flex flex-col w-full">
                                                <div className="relative mt-1">
                                                   <Input
                                                      label={`Discount Value ${
                                                         paymentDiscount.discountTypes ===
                                                         "amount"
                                                            ? "Tk."
                                                            : "%"
                                                      }`}
                                                      size="md"
                                                      type="text"
                                                      name="amounts"
                                                      value={
                                                         paymentDiscount.amounts
                                                      }
                                                      onChange={(e) =>
                                                         setPaymentDiscount({
                                                            ...paymentDiscount,
                                                            amounts: Number(
                                                               e.target.value
                                                            ),
                                                         })
                                                      }
                                                   />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="flex flex-col w-full">
                                             {/* <label htmlFor="discountType">Reason</label> */}
                                             <Input
                                                label="Reason"
                                                type="text"
                                                value={paymentDiscount.reasons}
                                                name="reasons"
                                                onChange={(e) =>
                                                   setPaymentDiscount({
                                                      ...paymentDiscount,
                                                      reasons: e.target.value,
                                                   })
                                                }
                                                // className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                                             />
                                             <span>
                                                Your customer can see this
                                                reason
                                             </span>
                                          </div>
                                       </form>
                                    </div>
                                 </div>
                              </Modal.Body>
                              <Modal.Footer>
                                 <div className="text-right py-5 border-t">
                                    <button
                                       onClick={handleClosePaymentDiscount}
                                       className="py-2 px-4 border border-gray-300 rounded-lg cursor-pointer mr-2"
                                    >
                                       Cancel
                                    </button>
                                    <button
                                       onClick={handleClosePaymentDiscount}
                                       className="py-2 px-4 bg-[#008060] rounded-lg text-white"
                                    >
                                       Apply
                                    </button>
                                 </div>
                              </Modal.Footer>
                           </Modal>
                        </div>

                        <div className="flex justify-between items-center">
                           <div
                              onClick={handleOpenAddshipping}
                              className="text-[#576CBC] cursor-pointer"
                           >
                              Add Shipping
                           </div>
                           <span>
                              Tk.{" "}
                              {shipping.shipping_type === "Standard shipping"
                                 ? 50
                                 : shipping.shipping_type ===
                                   "Priority shipping"
                                 ? 60
                                 : shipping.shipping_type === "Express shipping"
                                 ? 75
                                 : shipping.shipping_type === "Click & Collect"
                                 ? 0
                                 : 0}
                           </span>
                           <Modal
                              open={openAddshipping}
                              onClose={handleCloseAddshipping}
                           >
                              <Modal.Body>
                                 <div className=" relative pb-0 dark:bg-dark">
                                    <div
                                       onClick={handleClosePaymentDiscount}
                                       className="absolute right-2 top-2 text-[20px] mr-2 cursor-pointer"
                                    >
                                       ✕
                                    </div>
                                    <h3 className="text-lg font-semibold border-b pb-4 mb-5">
                                       Add Shipping
                                    </h3>
                                    <div className="text-[15px]">
                                       <form>
                                          <div className="flex flex-col w-full text-sm mb-5">
                                             <label
                                                htmlFor="notes"
                                                className=" font-semibold mb-1"
                                             >
                                                Shipping carrier
                                             </label>
                                             <Input
                                                label="Shipping Career"
                                                size="md"
                                                type="text"
                                                name="shipping_carrier"
                                                value={
                                                   shipping.shipping_carrier
                                                }
                                                onChange={(e) =>
                                                   setShipping({
                                                      ...shipping,
                                                      shipping_carrier:
                                                         e.target.value,
                                                   })
                                                }
                                             />
                                          </div>
                                          <div className="space-y-2">
                                             <div className="flex flex-col sm:flex-row sm:items-center justify-between">
                                                <label
                                                   htmlFor="standard"
                                                   className="cursor-pointer"
                                                >
                                                   <input
                                                      onChange={(e) =>
                                                         setShipping({
                                                            ...shipping,
                                                            shipping_type:
                                                               e.target.value,
                                                         })
                                                      }
                                                      type="radio"
                                                      id="standard"
                                                      name="shipping_type"
                                                      value="Standard shipping"
                                                      className="mr-2"
                                                   />
                                                   Standard Shipping (4 - 5
                                                   Days)
                                                </label>
                                                <span className="text-right">
                                                   Tk. 50
                                                </span>
                                             </div>
                                             <div className="flex flex-col sm:flex-row sm:items-center justify-between">
                                                <label
                                                   htmlFor="priority"
                                                   className="cursor-pointer"
                                                >
                                                   <input
                                                      onChange={(e) =>
                                                         setShipping({
                                                            ...shipping,
                                                            shipping_type:
                                                               e.target.value,
                                                         })
                                                      }
                                                      type="radio"
                                                      id="priority"
                                                      name="shipping_type"
                                                      value="Priority shipping"
                                                      className="mr-2"
                                                   />
                                                   Priority Shipping (2 - 3
                                                   Days)
                                                </label>
                                                <span className="text-right">
                                                   Tk. 60
                                                </span>
                                             </div>
                                             <div className="flex flex-col sm:flex-row sm:items-center justify-between">
                                                <label
                                                   htmlFor="express"
                                                   className="cursor-pointer"
                                                >
                                                   <input
                                                      onChange={(e) =>
                                                         setShipping({
                                                            ...shipping,
                                                            shipping_type:
                                                               e.target.value,
                                                         })
                                                      }
                                                      type="radio"
                                                      id="express"
                                                      name="shipping_type"
                                                      value="Express shipping"
                                                      className="mr-2"
                                                   />
                                                   <span>
                                                      Express Shipping (1 - 2
                                                      Days)
                                                   </span>
                                                </label>
                                                <span className="text-right">
                                                   Tk. 75
                                                </span>
                                             </div>
                                             <div className="flex items-center justify-between">
                                                <label
                                                   htmlFor="click&collect"
                                                   className="cursor-pointer"
                                                >
                                                   <input
                                                      onChange={(e) =>
                                                         setShipping({
                                                            ...shipping,
                                                            shipping_type:
                                                               e.target.value,
                                                         })
                                                      }
                                                      type="radio"
                                                      id="click&collect"
                                                      name="shipping_type"
                                                      value="Click & collect"
                                                      className="mr-2"
                                                   />
                                                   <span>
                                                      Clilck & Collect{" "}
                                                   </span>
                                                </label>
                                                <span>Tk. 0</span>
                                             </div>
                                          </div>
                                       </form>
                                    </div>
                                 </div>
                              </Modal.Body>
                              <Modal.Footer>
                                 <div className="text-right py-5 border-t">
                                    <button
                                       onClick={handleCloseAddshipping}
                                       className="py-2 px-4 border border-gray-300 rounded-lg cursor-pointer mr-2"
                                    >
                                       Cancel
                                    </button>
                                    <button
                                       onClick={handleCloseAddshipping}
                                       className="py-2 px-4 bg-[#008060] rounded-lg text-white"
                                    >
                                       Apply
                                    </button>
                                 </div>
                              </Modal.Footer>
                           </Modal>
                        </div>
                        <div className="flex justify-between items-center">
                           <label
                              onClick={handleOpenShipppingAdjustment}
                              className="text-[#576CBC] cursor-pointer"
                           >
                              Shipping Adjustment cost
                           </label>
                           <span>Tk. {Number(shipppingAdjustment.amount)}</span>
                           <Modal
                              open={openShipppingAdjustment}
                              onClose={handleCloseShipppingAdjustment}
                           >
                              <Modal.Body>
                                 <div className="relative pb-0 dark:bg-dark">
                                    <div
                                       onClick={handleCloseShipppingAdjustment}
                                       className="absolute right-2 top-2 text-[20px] mr-2 cursor-pointer"
                                    >
                                       ✕
                                    </div>
                                    <h3 className="text-lg font-semibold border-b pb-4 mb-5">
                                       Shipping Adjustment Cost
                                    </h3>
                                    <div className="text-[15px]">
                                       <form action="" className="space-y-4">
                                          <div className="flex items-center justify-between gap-2">
                                             <div className="flex flex-col w-full ">
                                                <label htmlFor="discountType">
                                                   Discount Type
                                                </label>
                                                <select
                                                   onChange={(e) =>
                                                      setShipppingAdjustment({
                                                         ...shipppingAdjustment,
                                                         discountType:
                                                            e.target.value,
                                                      })
                                                   }
                                                   className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 w-full mt-1 outline-none"
                                                >
                                                   <option
                                                      value="amount"
                                                      defaultChecked
                                                   >
                                                      Amount
                                                   </option>
                                                   <option value="percentage">
                                                      Percentage
                                                   </option>
                                                </select>
                                             </div>
                                             <div className="flex flex-col w-full">
                                                {/* <label htmlFor="discountType">Discount Value</label> */}
                                                <div className="relative mt-1">
                                                   <Input
                                                      size="md"
                                                      label={`Discount value - ${
                                                         shipppingAdjustment.discountType ===
                                                         "amount"
                                                            ? "Tk."
                                                            : "%"
                                                      }`}
                                                      type="text"
                                                      name="amounts"
                                                      value={
                                                         shipppingAdjustment.amount
                                                      }
                                                      onChange={(e) =>
                                                         setShipppingAdjustment(
                                                            {
                                                               ...shipppingAdjustment,
                                                               amount: Number(
                                                                  e.target.value
                                                               ),
                                                            }
                                                         )
                                                      }
                                                      // className="dark:text-dark w-full px-3 py-2 border border-gray-300 rounded-lg outline-none"
                                                      // placeholder="Tk. 0.00"
                                                   />
                                                   {/* <span className="absolute right-2 top-2">
                                                      {shipppingAdjustment.discountType === "amount" ? "Tk." : "%"}
                                                   </span> */}
                                                </div>
                                             </div>
                                          </div>
                                          <div className="flex flex-col w-full">
                                             {/* <label htmlFor="discountType">Reason</label> */}
                                             <Input
                                                size="md"
                                                label="Reason"
                                                type="text"
                                                value={
                                                   shipppingAdjustment.reason
                                                }
                                                name="reasons"
                                                onChange={(e) =>
                                                   setShipppingAdjustment({
                                                      ...shipppingAdjustment,
                                                      reason: e.target.value,
                                                   })
                                                }
                                                // className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                                             />
                                             <span>
                                                Your customer can see this
                                                reason
                                             </span>
                                          </div>
                                       </form>
                                    </div>
                                 </div>
                              </Modal.Body>
                              <Modal.Footer>
                                 <div className="text-right py-5 border-t">
                                    <button
                                       onClick={handleCloseShipppingAdjustment}
                                       className="py-2 px-4 border border-gray-300 rounded-lg cursor-pointer mr-2"
                                    >
                                       Cancel
                                    </button>
                                    <button
                                       onClick={handleCloseShipppingAdjustment}
                                       className="py-2 px-4 bg-[#008060] rounded-lg text-white"
                                    >
                                       Apply
                                    </button>
                                 </div>
                              </Modal.Footer>
                           </Modal>
                        </div>
                        <div className="flex justify-between items-center">
                           <h3>Total</h3>
                           <span>
                              Tk.{" "}
                              {totalAmount +
                                 (shipping.shipping_type === "Standard shipping"
                                    ? 50
                                    : shipping.shipping_type ===
                                      "Priority shipping"
                                    ? 60
                                    : shipping.shipping_type ===
                                      "Express shipping"
                                    ? 75
                                    : shipping.shipping_type ===
                                      "Click & Collect"
                                    ? 0
                                    : 0) +
                                 Number(shipppingAdjustment.amount) -
                                 Number(paymentDiscount.amounts)}
                           </span>
                        </div>
                     </div>
                  </div>
                  <Button onClick={handleOrder}>Update</Button>
               </div>
            </div>
         </div>
      </>
   )
}

export default EditOrder
