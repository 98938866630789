import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import LeavePage from "../../components/LeavePage"
import { Button } from "rsuite"
import Select from "react-select"
import {
   useCreateCustomerMutation,
   useGetBDGeoQuery,
} from "../../services/customer"
import { enqueueSnackbar } from "notistack"
import { useGetCategoryQuery } from "../../services/category"
import { userAccount } from "../../utils/utils"

const AddCustomer = () => {
   const navigate = useNavigate()
   const [createCustomer, { isSuccess, isError, error }] =
      useCreateCustomerMutation()

   const { data: categories } = useGetCategoryQuery()
   const { data: bdGeoData } = useGetBDGeoQuery()
   const [thana, setThana] = useState([])

   const [CI, setCI] = useState({
      name: "",
      email: "",
      phone: "",
      dathOfBirth: "",
      customerTag: [],
   })

   const [SA, setSA] = useState({
      phone: "",
      address: "",
      area: "",
      district: "",
      thana: "",
   })

   // all tag data
   const tagOptions = categories?.map((item) => {
      return { value: `${item?.subCategory}`, label: `${item?.subCategory}` }
   })

   // multiple tag handle
   const handleCustomerTag = (item) => {
      setCI({ ...CI, customerTag: [...item] })
   }

   const data = {
      contactInformation: {
         name: CI.name,
         email: CI.email,
         phone: CI.phone,
         dathOfBirth: CI.dathOfBirth,
         customerTag: CI.customerTag,
      },
      shippingAddress: {
         phone: SA.phone,
         address: SA.address,
         area: SA.area,
         district: SA.district,
         thana: SA.thana,
      },
   }

   useEffect(() => {
      if (isSuccess) {
         enqueueSnackbar("Customer Created", { variant: "success" })
      }
      if (isError) {
         enqueueSnackbar(`${error?.data?.message}`, { variant: "error" })
      }
   }, [isSuccess, isError])

   useEffect(() => {
      const data = bdGeoData?.find((item) => item?.city === SA.district)

      setThana(data?.thana)
   }, [SA.district])

   const createACustomer = async (e) => {
      e.preventDefault()
      if (
         userAccount === "ichchapuron" &&
         (!data?.contactInformation?.phone || !data?.contactInformation?.name)
      ) {
         return enqueueSnackbar(`Customer Full Information not provided!`, {
            variant: "error",
         })
      }

      await createCustomer(data)
      setCI({
         name: "",
         email: "",
         phone: "",
         dathOfBirth: "",
         customerTag: [],
      })
      setSA({
         phone: "",
         address: "",
         area: "",
         district: "",
         thana: "",
      })
   }

   return (
      <>
         <div className="flex justify-between bg-black px-2 sm:px-6 py-2 text-white items-center sticky top-0 w-full z-50">
            <h3 className="text-sm">Unsaved draft order</h3>
            <div className="space-x-2">
               <button
                  onClick={() => navigate("/customers")}
                  className="px-1 sm:px-3 py-1 sm:py-2 rounded-md border hover:bg-[#ffffff42] duration-150 border-gray-300 text-xs sm:text-sm"
               >
                  Discard
               </button>
               <Button
                  onClick={(e) => createACustomer(e)}
                  className="px-1 sm:px-3 py-1 sm:py-2 rounded-md border border-green-800 bg-green-800 hover:border-green-900 hover:bg-green-900 text-white text-xs sm:text-sm "
               >
                  Save
               </Button>
            </div>
         </div>
         <div className="p-2 border-b my-5 mx-2 sm:mx-10 md:mx-6 lg:mx-16">
            <div className="py-3 flex items-center">
               <label
                  htmlFor="leavePage"
                  className="text-[#5c5f62] p-2 mr-4 rounded-md border border-gray-300"
               >
                  <svg
                     viewBox="0 0 20 20"
                     className="Polaris-Icon__Svg_375hu w-5 h-5"
                     focusable="false"
                     fill="gray"
                     aria-hidden="true"
                  >
                     <path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path>
                  </svg>
               </label>
               <h3 className="font-semibold text-xl">New customer</h3>
            </div>
            <LeavePage></LeavePage>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-2 lg:gap-4 pt-0 sm:pt-5 border-t">
               <div className="col-span-1 ">
                  <h3 className="pt-5  text-black">Customer overview</h3>
               </div>
               <div className="col-span-2 bg-white w-full border rounded-lg p-5 shadow">
                  <form action="" className="space-y-4 relative text-[15px]">
                     <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                        {/* user name*/}
                        <div className="flex flex-col w-full ">
                           <label htmlFor="name">
                              User Name{" "}
                              <span className="text-red-600 text-base">*</span>{" "}
                           </label>
                           <input
                              name="name"
                              value={CI.name}
                              onChange={(e) =>
                                 setCI({ ...CI, name: e.target.value })
                              }
                              type="text"
                              className="dark:text-dark w-full px-3 py-2 border border-gray-300 rounded-lg outline-none"
                              placeholder="User Name"
                           />
                        </div>
                        <div className="flex flex-col w-full">
                           <label htmlFor="discountType">Email</label>
                           <input
                              name="email"
                              value={CI.email}
                              onChange={(e) =>
                                 setCI({ ...CI, email: e.target.value })
                              }
                              type="email"
                              className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                              placeholder="Enter your email"
                           />
                        </div>
                     </div>
                     <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                        {/* Phone */}
                        <div className="flex flex-col w-full">
                           <label htmlFor="discountType">
                              Phone{" "}
                              <span className="text-red-600 text-base">*</span>
                           </label>
                           <input
                              name="phone"
                              value={CI.phone}
                              onChange={(e) =>
                                 setCI({ ...CI, phone: e.target.value })
                              }
                              type="text"
                              className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                              placeholder="Enter your phone"
                           />
                        </div>
                        {/* date of birth  */}
                        <div className="flex flex-col w-full">
                           <label htmlFor="dathOfBirth">Dath of birth</label>
                           <input
                              name="dathOfBirth"
                              value={CI.dathOfBirth}
                              onChange={(e) =>
                                 setCI({ ...CI, dathOfBirth: e.target.value })
                              }
                              type="date"
                              className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                              placeholder="Dath of birth"
                           />
                        </div>
                     </div>
                     {/* customer tag */}
                     <div className="flex flex-col w-full">
                        <label htmlFor="customerTag" className="mb-1 ">
                           Customer Tag
                        </label>
                        <div className="w-full text-gray-600">
                           <Select
                              options={tagOptions}
                              value={CI.customerTag}
                              onChange={handleCustomerTag}
                              placeholder="Select customer tag"
                              isMulti
                              isLoading={false}
                              isClearable={false}
                              isDisabled={false}
                              isSearchable={true}
                              noOptionsMessage={() => "no user found"}
                              styles={{
                                 textTransform: "capitalize",
                                 placeholder: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: "none",
                                    width: "300px",
                                 }),
                              }}
                           />
                        </div>
                     </div>
                     <div className="space-y-5 text-sm">
                        <div>
                           <label htmlFor="email" className="cursor-pointer">
                              <input
                                 onChange={(e) =>
                                    setSA({ ...SA, last_name: e.target.value })
                                 }
                                 defaultChecked={true}
                                 type="checkbox"
                                 id="email"
                                 name="refund"
                                 className="mr-2"
                              />
                              <span>
                                 Customer agreed to receive marketing emails.
                              </span>
                           </label>
                        </div>
                        <div>
                           <label htmlFor="messages" className="cursor-pointer">
                              <input
                                 defaultChecked={true}
                                 type="checkbox"
                                 id="messages"
                                 name="refund"
                                 className="mr-2"
                              />
                              <span>
                                 Customer agreed to receive SMS marketing text
                                 messages.
                              </span>
                           </label>
                        </div>
                        <p className="text-gray-400">
                           You should ask your customers for permission before
                           you subscribe them to your marketing emails or SMS.
                        </p>
                     </div>
                  </form>
               </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-2 lg:gap-4 pt-0 sm:pt-5 mt-5 border-t">
               <div className="col-span-1 ">
                  <h3 className="pt-5 text-black">Address</h3>
                  <span className="pt-5 text-sm">
                     The primary address of this customer
                  </span>
               </div>
               <div className="col-span-2 bg-white w-full border rounded-lg p-5 shadow">
                  <form action="" className="space-y-3 relative text-[15px]">
                     {/* district and divistion */}
                     <div className="flex items-center justify-between gap-2">
                        <div className="flex flex-col w-full">
                           <label htmlFor="district">District</label>
                           <select
                              name="district"
                              value={SA.district}
                              onChange={(e) => {
                                 setSA({ ...SA, district: e.target.value })
                              }}
                              className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 w-full mt-1 outline-none text-sm"
                           >
                              <option value="">Select District</option>
                              {bdGeoData?.map((item) => {
                                 return (
                                    <option value={item?.city}>
                                       {item?.city}
                                    </option>
                                 )
                              })}
                           </select>
                        </div>
                        {SA?.district && (
                           <div className="flex flex-col w-full">
                              <label htmlFor="thana">Thana</label>
                              <select
                                 name="thana"
                                 onChange={(e) =>
                                    setSA({ ...SA, thana: e.target.value })
                                 }
                                 className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 w-full mt-1 outline-none text-sm"
                              >
                                 <option value="">Select District</option>
                                 {thana?.map((data) => {
                                    return <option value={data}>{data}</option>
                                 })}
                              </select>
                           </div>
                        )}
                     </div>
                     {/* postcode and area */}
                     <div className="flex items-center justify-between gap-2">
                        <div className="flex flex-col w-full">
                           <label htmlFor="area">Area</label>
                           <input
                              name="area"
                              value={SA.area}
                              onChange={(e) =>
                                 setSA({ ...SA, area: e.target.value })
                              }
                              type="text"
                              className="dark:text-dark  w-full px-3 py-2 border border-gray-300 rounded-lg outline-none"
                              placeholder="Area"
                           />
                        </div>
                        <div className="flex flex-col w-full">
                           <label htmlFor="address">Address</label>
                           <input
                              name="address"
                              value={SA.address}
                              onChange={(e) =>
                                 setSA({ ...SA, address: e.target.value })
                              }
                              type="text"
                              className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                              placeholder="Address"
                           />
                        </div>
                     </div>
                     {/* address */}
                     <div className="flex flex-col w-full">
                        <label htmlFor="phone">Phone</label>
                        <input
                           name="phone"
                           value={SA.phone}
                           onChange={(e) =>
                              setSA({ ...SA, phone: e.target.value })
                           }
                           type="phone"
                           className="dark:text-dark  w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                           placeholder="Phone"
                        />
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </>
   )
}

export default AddCustomer
