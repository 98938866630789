import React, { useEffect, useState } from "react"
import { Modal } from "rsuite"
import { useEditCustomerInfoMutation } from "../../services/customer"
import { useDispatch, useSelector } from "react-redux"
import { editSelectedCustomer } from "../../services/Features/customerSlice"
import { useGetCategoryQuery } from "../../services/category"
import Select from "react-select"

const EditContactInfo = ({ open, handleClose }) => {
   const { customer } = useSelector((state) => state.customer)
   const [editCustomerInfo] = useEditCustomerInfoMutation()
   const { data: categories } = useGetCategoryQuery()
   const dispatch = useDispatch()

   // all tag data
   const tagOptions = categories?.map((item) => {
      return { value: `${item?.subCategory}`, label: `${item?.subCategory}` }
   })

   const [CI, setCI] = useState({
      name: "",
      email: "",
      phone: "",
      dathOfBirth: "",
      customerTag: [],
   })

   useEffect(() => {
      setCI({
         ...customer?.contactInformation,
      })
   }, [customer])

   // multiple tag handle
   const handleCustomerTag = (item) => {
      setCI({ ...CI, customerTag: [...item] })
   }

   const newContactInfo = {
      name: CI.name || customer?.contactInformation?.name,
      phone: CI.phone || customer?.contactInformation?.phone,
      email: CI.email || customer?.contactInformation?.email,
      dathOfBirth: CI.dathOfBirth || customer?.contactInformation?.dathOfBirth,
      customerTag: CI.customerTag || customer?.contactInformation?.customerTag,
   }

   const editCustomer = async (e) => {
      e.preventDefault()

      await editCustomerInfo({
         id: customer?._id,
         contactInformation: newContactInfo,
      })
      dispatch(editSelectedCustomer({ contactInformation: newContactInfo }))
      handleClose()
   }
   return (
      <Modal open={open}>
         <Modal.Body>
            <div className="relative pb-0 dark:bg-dark">
               <div
                  onClick={handleClose}
                  className="absolute right-2 top-2 text-[20px] mr-2 cursor-pointer"
               >
                  ✕
               </div>
               <h3 className="text-lg font-semibold border-b pb-4 mb-5">
                  Edit contact information
               </h3>
               <div className="text-[15px]">
                  <form action="" className="space-y-4">
                     <div className="flex flex-col sm:flex-row items-center justify-between gap-2">
                        <div className="flex flex-col w-full ">
                           <label htmlFor="discountType">User Name</label>
                           <input
                              name="name"
                              value={CI.name}
                              onChange={(e) =>
                                 setCI({ ...CI, name: e.target.value })
                              }
                              type="text"
                              className="dark:text-dark w-full px-3 py-2 border border-gray-300 rounded-lg outline-none"
                              placeholder="User name"
                           />
                        </div>
                        {/* email */}
                        <div className="flex flex-col w-full">
                           <label htmlFor="discountType">Email</label>
                           <input
                              name="email"
                              value={CI.email}
                              onChange={(e) =>
                                 setCI({ ...CI, email: e.target.value })
                              }
                              type="email"
                              className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                              placeholder="Enter your email"
                           />
                        </div>
                     </div>
                     <div className="flex flex-col sm:flex-row items-center justify-between gap-2">
                        <div className="flex flex-col w-full">
                           <label htmlFor="dathOfBirth">Dath of birth</label>
                           <input
                              name="dathOfBirth"
                              value={CI.dathOfBirth}
                              onChange={(e) =>
                                 setCI({ ...CI, dathOfBirth: e.target.value })
                              }
                              type="date"
                              className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                              placeholder="Dath of birth"
                           />
                        </div>{" "}
                        {/* Phone */}
                        <div className="flex flex-col w-full">
                           <label htmlFor="discountType">Phone</label>
                           <input
                              name="phone"
                              value={CI.phone}
                              onChange={(e) =>
                                 setCI({ ...CI, phone: e.target.value })
                              }
                              type="text"
                              className={`dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none `}
                              placeholder="Enter your phone"
                           />
                        </div>
                     </div>
                     {/* customer tag */}
                     <div className="flex flex-col w-full">
                        <label htmlFor="customerTag" className="mb-1 ">
                           Customer Tag
                        </label>
                        <div className="w-full text-gray-600">
                           <Select
                              options={tagOptions}
                              value={CI.customerTag}
                              onChange={handleCustomerTag}
                              placeholder="Select customer tag"
                              isMulti
                              isLoading={false}
                              isClearable={false}
                              isDisabled={false}
                              isSearchable={true}
                              noOptionsMessage={() => "no user found"}
                              styles={{
                                 textTransform: "capitalize",
                                 placeholder: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: "none",
                                    width: "300px",
                                 }),
                              }}
                           />
                        </div>
                     </div>
                     <div className="text-right py-5 border-t">
                        <button
                           type="button"
                           onClick={handleClose}
                           className="py-2 px-4 border border-gray-300 hover:bg-gray-100 rounded-lg cursor-pointer mr-2"
                        >
                           Cancel
                        </button>
                        <button
                           type="submit"
                           onClick={editCustomer}
                           className="py-2 px-4 rounded-md border border-green-800 bg-green-800 hover:border-green-900 hover:bg-green-900  duration-150 text-white "
                        >
                           Update
                        </button>
                     </div>
                  </form>
               </div>
            </div>
         </Modal.Body>
      </Modal>
   )
}

export default EditContactInfo
