import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const customerApi = createApi({
   reducerPath: "CustomerApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["CustomerApi"],
   endpoints: (builder) => ({
      createCustomer: builder.mutation({
         query: (data) => ({
            url: "api/customer",
            method: "POST",
            body: data,
         }),
         invalidatesTags: ["CustomerApi"],
      }),
      getCustomers: builder.query({
         query: () => `api/customer`,
         providesTags: ["CustomerApi"],
      }),
      getCustomer: builder.query({
         query: (phone) => `api/customer/${phone}`,
         providesTags: ["CustomerApi"],
      }),
      editCustomerInfo: builder.mutation({
         query: ({ id, ...data }) => ({
            url: `api/customer/${id}`,
            method: "PATCH",
            body: data,
         }),
         invalidatesTags: ["CustomerApi"],
      }),
      getBDGeo: builder.query({
         query: () => `api/bdGeo`,
         providesTags: ["CustomerApi"],
      }),
   }),
})

export const {
   useCreateCustomerMutation,
   useGetCustomersQuery,
   useEditCustomerInfoMutation,
   useGetBDGeoQuery,
   useGetCustomerQuery,
} = customerApi
