import React from "react"
import { userTotalAmountSpent } from "../../../../utils/orderInformation"

const UserOrderSummery = ({ orders }) => {
   const userAmountSpent = userTotalAmountSpent(orders)

   return (
      <div className="grid grid-cols-3 items-center gap-4 bg-white dark:bg-dark dark:text-white border rounded-lg px-5 py-7 mb-4 shadow">
         <div className="border-r">
            <p className="text-sm">Amount spent</p>
            <h3 className="sm:text-xl mt-2 text-black dark:text-white">
               ৳ <span className="font-semibold">{userAmountSpent || 0} </span>
            </h3>
         </div>
         <div className="border-r">
            <p className="text-sm">Orders</p>
            <h3 className="sm:text-xl font-semibold mt-2 text-black dark:text-white">
               {orders?.length}
            </h3>
         </div>
         <div>
            <p className="text-sm">Average order value</p>
            <h3 className="sm:text-xl mt-2 text-black dark:text-white">
               ৳
               <span className="font-semibold">
                  {Number(userAmountSpent / Number(orders?.length)) > 0
                     ? Number(userAmountSpent / Number(orders?.length)).toFixed(
                          2
                       )
                     : 0}
               </span>
            </h3>
         </div>
      </div>
   )
}

export default UserOrderSummery
