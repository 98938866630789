import React, { useState } from "react"
import { Modal } from "rsuite"
import Barcode from "react-barcode"
import { v4 as uuid } from "uuid"
import { useDispatch } from "react-redux"
import { removeBarcode, setBarcode } from "../../services/Features/barcode"
import { RiBarcodeLine } from "react-icons/ri"
import { CiBarcode } from "react-icons/ci"

const BarcodeGenerate = ({ openBarcodeModal, handleCloseBarcodeModal }) => {
   const UUID = uuid()
   const small_id = UUID.slice(0, 6)
   const [productBarcode, setProductBarcode] = useState("")
   const [linkExisting, setLinkExisting] = useState(false)
   const dispatch = useDispatch()

   const addBarcode = (value) => {
      dispatch(setBarcode(value))
      setLinkExisting(false)
      setProductBarcode("")
   }

   return (
      <Modal
         size={"sm"}
         open={openBarcodeModal}
         aria-labelledby="contained-modal-title-vcenter"
         centered
      >
         <Modal.Body>
            <div className="relative pb-0 dark:bg-dark text-base space-y-4 min-h-72">
               <h3 className="text-lg font-semibold border-b pb-4">
                  Add QR / Barcode
               </h3>

               {!productBarcode && !linkExisting && (
                  <>
                     <div
                        onClick={() => {
                           setProductBarcode("IP" + small_id)
                        }}
                        className="flex justify-between items-center border-2 border-transparent p-3 hover:border-custom_skyBlue rounded-lg duration-200 cursor-pointer"
                     >
                        <div>
                           <h3 className="text-base font-semibold">
                              Create New{" "}
                           </h3>
                           <p className="text-gray-500  text-sm">
                              Create a unique barcode
                           </p>
                        </div>
                        <RiBarcodeLine size={25} />
                     </div>
                     <div
                        onClick={(e) => setLinkExisting(true)}
                        className="flex justify-between items-center border-2 border-transparent p-3 hover:border-custom_skyBlue rounded-lg duration-200 cursor-pointer"
                     >
                        <div>
                           <h3 className="text-base font-semibold">
                              Link Existing
                           </h3>
                           <p className="text-gray-500  text-sm">
                              Scan any Qr code or barcode using scanner
                           </p>
                        </div>
                        <CiBarcode size={25} />
                     </div>
                  </>
               )}
               {linkExisting && (
                  <div className="flex items-center justify-center flex-col ">
                     <h3 className="text-base font-semibold mb-2">
                        Scan your QR / Barcode
                     </h3>
                     <input
                        type="text"
                        name="title"
                        value={productBarcode}
                        onChange={(e) => setProductBarcode(e.target.value)}
                        placeholder="Scan your QR / Barcode"
                        className={`border-2 border-custom_skyBlue rounded-lg p-3 outline-none`}
                     />
                  </div>
               )}
               {productBarcode && (
                  <div className="flex justify-center my-10 py-10">
                     <Barcode value={productBarcode} height={50} width={1.0} />
                  </div>
               )}
            </div>
         </Modal.Body>
         <Modal.Footer>
            <div className="text-right py-5 border-t">
               <button
                  onClick={() => {
                     handleCloseBarcodeModal()
                     setLinkExisting(false)
                     setProductBarcode("")
                     dispatch(removeBarcode())
                  }}
                  className="py-2 w-24 bg-gray-200 hover:bg-gray-300 duration-200 text-base font-medium border border-gray-300 rounded-lg cursor-pointer mr-2"
               >
                  Cancel
               </button>
               {productBarcode && (
                  <button
                     onClick={() => {
                        addBarcode(productBarcode)
                        handleCloseBarcodeModal()
                     }}
                     className="py-2 w-24 bg-green-800 hover:bg-green-900 duration-200 text-white text-base font-medium border border-gray-300 rounded-lg cursor-pointer mr-2"
                  >
                     Add
                  </button>
               )}
            </div>
         </Modal.Footer>
      </Modal>
   )
}

export default BarcodeGenerate
