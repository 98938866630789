import React from "react"
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
import ExcelJS from "exceljs"

export const Exportfile = ({ csvData, fileName }) => {
   const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
   const fileExtension = ".xlsx"

   const exportToCSV = async (e, csvData, fileName) => {
      e.preventDefault()

      const workbook = new ExcelJS.Workbook()
      // const worksheet = workbook.addWorksheet("data")

      const worksheet = workbook.addWorksheet("data", {
         views: [{ state: "frozen", ySplit: 1 }],
      })

      // Add headers
      const headers = Object.keys(csvData[0])
      const headerRow = worksheet.addRow(headers)

      // Apply conditional formatting and set borders for header cells
      headerRow.eachCell((cell) => {
         cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFdfd8ed" }, // Orange background for header
         }
         cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
         }
      })

      csvData.forEach((data) => {
         const row = worksheet.addRow(Object.values(data))

         // Apply conditional formatting based on order status
         const orderStatus = data["Order Status"]
         if (orderStatus === "return in progress") {
            row.eachCell({ includeEmpty: true }, (cell) => {
               cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "FFafadb3" },
               }
            })
         } else if (orderStatus === "approved") {
            row.eachCell({ includeEmpty: true }, (cell) => {
               cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "FF039487" },
               }
            })
         } else if (orderStatus === "dispatched") {
            row.eachCell({ includeEmpty: true }, (cell) => {
               cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "D2F99C" },
               }
            })
         } else if (orderStatus === "delivery in progress") {
            row.eachCell({ includeEmpty: true }, (cell) => {
               cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "B3E1F7" },
               }
            })
         } else if (orderStatus === "delivered") {
            row.eachCell({ includeEmpty: true }, (cell) => {
               cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "78FAC6" },
               }
            })
         } else if (orderStatus === "fullfilled") {
            row.eachCell({ includeEmpty: true }, (cell) => {
               cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "94d252" },
               }
            })
         } else if (orderStatus === "canceled") {
            row.eachCell({ includeEmpty: true }, (cell) => {
               cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "F57786" },
               }
            })
         } else if (orderStatus === "order on hold") {
            row.eachCell({ includeEmpty: true }, (cell) => {
               cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "FAF781" },
               }
            })
         } else if (orderStatus === "dispatch on hold") {
            row.eachCell({ includeEmpty: true }, (cell) => {
               cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "DFB3F7" },
               }
            })
         } else if (orderStatus === "returned") {
            row.eachCell({ includeEmpty: true }, (cell) => {
               cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "D15865" },
               }
            })
         } else if (orderStatus === "refunded") {
            row.eachCell({ includeEmpty: true }, (cell) => {
               cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "FE4D4D" },
               }
            })
         } else if (orderStatus === "refunded & returned") {
            row.eachCell({ includeEmpty: true }, (cell) => {
               cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "FA4848" },
               }
            })
         } else if (orderStatus === "return in progress") {
            row.eachCell({ includeEmpty: true }, (cell) => {
               cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "D1ADB1" },
               }
            })
         } else if (orderStatus === "refund in progress") {
            row.eachCell({ includeEmpty: true }, (cell) => {
               cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "FED9D9" },
               }
            })
         } else if (orderStatus === "refund & return in progress") {
            row.eachCell({ includeEmpty: true }, (cell) => {
               cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "F98888" },
               }
            })
         }

         row.eachCell((cell) => {
            cell.border = {
               top: { style: "thin" },
               left: { style: "thin" },
               bottom: { style: "thin" },
               right: { style: "thin" },
            }
         })
         // Add more conditions as needed
      })

      const buffer = await workbook.xlsx.writeBuffer()
      const data = new Blob([buffer], { type: fileType })
      FileSaver.saveAs(data, fileName + fileExtension)
   }

   return (
      <button
         onClick={(e) => exportToCSV(e, csvData, fileName)}
         className="py-2 px-5 border border-gray-300 shadow-md bg-white hover:bg-light-blue-100 hover:text-black duration-100 rounded-lg"
      >
         Export
      </button>
   )
}

// const exportToCSV = (e, csvData, fileName) => {
//    e.preventDefault()
//    const ws = XLSX.utils.json_to_sheet(csvData)
//    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
//    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
//    const data = new Blob([excelBuffer], { type: fileType })
//    FileSaver.saveAs(data, fileName + fileExtension)
// }
