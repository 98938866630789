import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const bannerApi = createApi({
   reducerPath: 'bannerApi',
   baseQuery: fetchBaseQuery({ baseUrl: '/' }),
   tagTypes: ['Banner'],
   endpoints: (builder) => ({
      getBanner: builder.query({
         query: () => 'api/banner-image/getImage',
         providesTags: ['Banner'],
      }),
   }),
})

export const { useGetBannerQuery } = bannerApi
