import React, { useState } from 'react';
import { Modal } from 'rsuite';

const ContactCustomer = ({open, handleClose}) => {
    const [type,setType] = useState("")

    return (
        <Modal
        open={open}
        onClose={handleClose}
        >
        <Modal.Body>
        <div className="relative pb-0">
            <label onClick={handleClose} className="absolute right-2 top-2 text-[20px] mr-2 cursor-pointer">✕</label>
            <h3 className="text-lg font-semibold border-b pb-4 mb-5">Contact customer</h3>
            <div className='text-[15px]'>
                <form action="" className='space-y-4'>
                    <div className='flex flex-col sm:flex-row items-center justify-between gap-2'>
                        <div className='flex flex-col w-full'>
                            <label htmlFor="discountType">To</label>
                            <input type="text" value={`${"mitaly@gmail.com"}`} className='w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none' />
                        </div>
                        <div className='flex flex-col w-full'>
                            <label htmlFor="discountType">From</label>
                            <input type="text" className='w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none' />
                        </div>
                    </div>
                    <div className='py-2'>
                        <p>Send bcc to:</p>
                        <input type="checkbox" name="bcc" id="bcc" className='mr-2' />
                        <label htmlFor="bcc">wahid.tarafder@yahoo.com</label>
                    </div>
                    <div className='flex flex-col w-full'>
                        <label htmlFor="discountType">Subject</label>
                        <input type="text" className='w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none' placeholder='Message from {{ shop.name }}'/>
                    </div>
                    <div className='flex flex-col w-full'>
                        <label htmlFor="discountType">Subject</label>
                        <textarea name="" id="" cols="30" rows="3" className='w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none'>
                        </textarea>
                        <span className='text-xs mt-1'>This template can be edited in notifications</span>
                    </div>
                    <div className='text-right py-5 border-t'>
                        <label  onClick={handleClose}  className="py-2 px-4 border border-gray-300 rounded-lg cursor-pointer mr-2">Cancel</label>
                        <button type='submit' className='py-2 px-4 bg-[#008060] rounded-lg text-white'>Review Email</button>
                    </div>
                </form>
            </div>
        </div>
        </Modal.Body>
        </Modal>
    );
};

export default ContactCustomer;


