import React, { useState, useEffect } from "react"
import { getNetPaymentAndBalance } from "../../utils/orderInformation"
import {
   decreaseProductQuantity,
   increaseProductQuantity,
} from "../../services/order/returnProduct"
import { Avatar, Button } from "@material-tailwind/react"
import { useUpdateOrderMutation } from "../../services/order"
import { Modal } from "rsuite"
import { useDispatch, useSelector } from "react-redux"
import { enqueueSnackbar } from "notistack"

const RefundAndReturnModal = ({ or, order, close }) => {
   const dispatch = useDispatch()
   const { products } = useSelector((state) => state.returnProduct)

   const [updateOrder] = useUpdateOrderMutation()

   const [iop, setIop] = useState([])

   // REFUND
   const [refundDefaultOption, setrefundDefaultOption] = useState("")
   const [refundAmount, setrefundAmount] = useState("")
   const [refundMethod, setrefundMethod] = useState("")
   const [refundReason, setrefundReason] = useState("")
   const [trNumber, settrNumber] = useState("")

   const [shippingOption, setshippingOption] = useState("")
   const [shippingCarrier, setshippingCarrier] = useState("")
   const [shippingCost, setshippingCost] = useState("")
   const [returnReason, setreturnReason] = useState("")
   const [reason, setreason] = useState("")
   const fxBr = products?.filter((x) => x?.returnQty > 0)

   const compareTwoArrayOfObjects = (first, second) => {
      return first?.map((element_1) =>
         second?.find((element_2) => element_1?._id === element_2?._id)
      )
   }

   const exRePro = compareTwoArrayOfObjects(products, iop).filter(Boolean)
   const tiopo = [...exRePro, ...products]
   const uniqueOp = tiopo.reduce((accumulator, current) => {
      if (!accumulator.find((item) => item._id === current._id)) {
         accumulator.push(current)
      }
      return accumulator
   }, [])

   const returned = {
      returnedProducts: [...exRePro, ...fxBr],
      returnOptions: {
         shippingOption,
         shippingCarrier,
         shippingCost,
         returnReason,
      },
      refundOptions: {
         refundDefaultOption,
         refundAmount,
         refundMethod,
         refundReason,
         trNumber,
      },
      reason,
   }

   const updateNewOrder = () => {
      const orderInfo =
         returned?.refundOptions?.refundAmount &&
         returned?.returnOptions?.shippingCost
            ? {
                 orderStatus: "refund & return in progress",
                 refundReturnInProgressAt: Date.now(),
              }
            : returned?.refundOptions?.refundAmount
            ? {
                 orderStatus: "refund in progress",
                 refundInProgressAt: Date.now(),
              }
            : returned?.returnOptions?.shippingCost
            ? {
                 orderStatus: "return in progress",
                 returnInProgressAt: Date.now(),
              }
            : {
                 orderStatus: order?.orderStatus,
              }

      if (orderInfo?.orderStatus === order?.orderStatus) {
         return enqueueSnackbar("Select your information!", {
            variant: "alert",
         })
      }
      const { netPayment, balance } = getNetPaymentAndBalance(
         orderInfo?.orderStatus,
         order,
         returned
      )
      updateOrder({
         id: order?._id,
         returned,
         ...orderInfo,
         netPayment: parseFloat(netPayment).toFixed(2),
         balance: parseFloat(balance).toFixed(2),
      })
      close()
      window.location.href = "/refund-&-return"
   }
   useEffect(() => {
      setIop(order?.returned?.returnedProducts)
   }, [order?.orderedProducts])
   return (
      <Modal size="lg" open={or}>
         <Modal.Header>
            <div>
               <h3 className="font-semibold text-xl">#{order?.orderId}</h3>
               <h3 className="font-semibold text-xl">Order Return & Refund</h3>
            </div>
         </Modal.Header>
         <Modal.Body>
            <div className="p-2 mb-5 m-5 dark:text-white">
               <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-2 ">
                     <div className="bg-white dark:bg-dark dark:border-gray-600 dark:text-white w-full border rounded-lg p-5 shadow">
                        {uniqueOp?.map((x, i) => {
                           return (
                              <div key={i}>
                                 <div className="text-xs flex justify-between py-5">
                                    <div className="flex gap-4 mr-28">
                                       <Avatar
                                          size="md"
                                          src={x?.images?.[0]}
                                          alt={x?.title}
                                          className="!rounded-none"
                                       />

                                       <h3 className="font-semibold">
                                          {x?.title}
                                       </h3>
                                    </div>
                                    <div className="relative w-[94px]">
                                       <input
                                          type="text"
                                          value={x?.returnQty}
                                          className="dark:text-dark border rounded-lg py-2 pl-4 w-full outline-none border-gray-300"
                                       />
                                       <div className="absolute right-0 space-y-1 top-0 text-[10px] text-gray-400">
                                          <button
                                             className="block"
                                             onClick={() =>
                                                dispatch(
                                                   increaseProductQuantity(x)
                                                )
                                             }
                                          >
                                             <svg
                                                viewBox="0 0 20 20"
                                                className="Polaris-Icon__Svg_375hu w-4 text-gray-400 bg-gray-300 rounded-lg"
                                                focusable="false"
                                                aria-hidden="true"
                                             >
                                                <path d="M6.902 12h6.196c.751 0 1.172-.754.708-1.268l-3.098-3.432c-.36-.399-1.055-.399-1.416 0l-3.098 3.433c-.464.513-.043 1.267.708 1.267Z"></path>
                                             </svg>
                                          </button>
                                          <button
                                             className="block"
                                             onClick={() =>
                                                dispatch(
                                                   decreaseProductQuantity(x)
                                                )
                                             }
                                          >
                                             <svg
                                                viewBox="0 0 20 20"
                                                className="Polaris-Icon__Svg_375hu w-4 text-gray-400 bg-gray-300 rounded-lg"
                                                focusable="false"
                                                aria-hidden="true"
                                             >
                                                <path d="M13.098 8h-6.196c-.751 0-1.172.754-.708 1.268l3.098 3.432c.36.399 1.055.399 1.416 0l3.098-3.433c.464-.513.043-1.267-.708-1.267Z"></path>
                                             </svg>
                                          </button>
                                       </div>
                                       <div className="absolute right-8 space-y-2 top-2 text-sm">
                                          <span>/ {x?.cartQuantity}</span>
                                       </div>
                                    </div>
                                    <span>
                                       {x?.discountedPrice * x?.returnQty} TK.
                                    </span>
                                 </div>
                              </div>
                           )
                        })}
                     </div>
                     <div className="flex flex-col w-full text-sm my-5">
                        <label htmlFor="reason">Select a return reason</label>
                        <select
                           onChange={(e) => setreason(e.target.value)}
                           className="border dark:text-dark border-gray-300 rounded-lg px-3 py-2 w-full mt-1 outline-none text-sm"
                        >
                           <option value="Unknown">Unknown</option>
                           <option value="Size was too small">
                              Size was too small
                           </option>
                           <option value="Size was too large">
                              Size was too large
                           </option>
                           <option value="Customer changed their mind">
                              Customer changed their mind
                           </option>
                           <option value="Item not as described">
                              Item not as described
                           </option>
                           <option value="Received the wrong item">
                              Received the wrong item
                           </option>
                           <option value="Damaged or defective">
                              Damaged or defective
                           </option>
                           <option value="Style">Style</option>
                           <option value="Color">Color</option>
                           <option value="Other">Other</option>
                        </select>
                     </div>
                     <label htmlFor="refundPayment" className="cursor-pointer">
                        <input
                           type="checkbox"
                           defaultChecked
                           id="refundPayment"
                           name="refund"
                           className="mr-2"
                        />
                        <span>Restock items</span>
                     </label>
                     <div className="bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 shadow mt-4 space-y-2">
                        <p className="font-semibold">Return shipping options</p>
                        <div>
                           <label
                              htmlFor="noShipping"
                              className="cursor-pointer text-sm"
                           >
                              <input
                                 onClick={(e) =>
                                    setshippingOption(e.target.value)
                                 }
                                 type="radio"
                                 id="noShipping"
                                 value="noShipping"
                                 name="shippingOption"
                                 className="mr-2"
                              />
                              <span>No shipping required</span>
                           </label>
                        </div>
                        <div>
                           <label
                              htmlFor="shippingRequired"
                              className="cursor-pointer text-sm"
                           >
                              <input
                                 onClick={(e) =>
                                    setshippingOption(e.target.value)
                                 }
                                 type="radio"
                                 id="shippingRequired"
                                 value="shippingRequired"
                                 name="shippingOption"
                                 className="mr-2"
                              />
                              Shipping Required
                           </label>
                        </div>
                        {/* shipping information */}
                        <div
                           className={`bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 shadow text-sm ${
                              shippingOption === "shippingRequired"
                                 ? "block"
                                 : "hidden"
                           }`}
                        >
                           <h3 className="text-lg font-semibold pb-4">
                              Shipping Information
                           </h3>
                           <form action="" className="space-y-3">
                              <div className="flex flex-col w-full text-base mb-5">
                                 <label
                                    htmlFor="notes"
                                    className=" font-semibold mb-1 cursor-pointer"
                                 >
                                    Shipping carrier
                                 </label>
                                 <select
                                    name="shipping_carrier"
                                    value={shippingCarrier}
                                    onChange={(e) =>
                                       setshippingCarrier(e.target.value)
                                    }
                                    className="dark:text-dark w-6/12 px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none cursor-pointer"
                                 >
                                    <option>Select Shipping Carrier</option>
                                    <option value="Delivery Tiger">
                                       Delivery Tiger
                                    </option>
                                    <option value="Redx">Redx</option>
                                    <option value="Sundarban Courier">
                                       Sundarban Courier
                                    </option>
                                    <option value="cash on delivery">
                                       Cash on delivery
                                    </option>
                                 </select>
                              </div>
                              <div className="flex flex-col w-full">
                                 <label htmlFor="cost">Shipping cost</label>
                                 <input
                                    name="shippingCost"
                                    value={shippingCost}
                                    onChange={(e) =>
                                       setshippingCost(e.target.value)
                                    }
                                    type="text"
                                    className="dark:text-dark w-6/12 px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                                 />
                              </div>
                              <div className="flex flex-col w-full">
                                 <label htmlFor="cost">Reason</label>
                                 <input
                                    type="text"
                                    name="returnReason"
                                    value={returnReason}
                                    onChange={(e) =>
                                       setreturnReason(e.target.value)
                                    }
                                    className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                                 />
                                 <span>Your customer can see this reason</span>
                              </div>
                              {/* <div className='text-right py-5 border-t'>
                                                                    <button onClick={() => setshippingOption('')} htmlFor="shippingRequired" className="py-2 px-4 border border-gray-300 rounded-lg cursor-pointer mr-2">Cancel</button>
                                                                    <button type='submit' className='py-2 px-4 bg-[#008060] rounded-lg text-white'>Save</button>
                                                                </div> */}
                           </form>
                        </div>
                     </div>
                     <div className="my-4">
                        <div>
                           <label
                              htmlFor="applicable"
                              className="cursor-pointer text-sm"
                           >
                              <input
                                 type="radio"
                                 id="applicable"
                                 value="applicable"
                                 onChange={(e) =>
                                    setrefundDefaultOption(e.target.value)
                                 }
                                 name="refundDefaultOption"
                                 className="mr-2"
                              />
                              <span>Refund is Applicable</span>
                           </label>
                        </div>
                        <div>
                           <label
                              htmlFor="notApplicable"
                              className="cursor-pointer text-sm"
                           >
                              <input
                                 type="radio"
                                 id="notApplicable"
                                 onChange={(e) =>
                                    setrefundDefaultOption(e.target.value)
                                 }
                                 value="not applicable"
                                 name="refundDefaultOption"
                                 className="mr-2"
                              />
                              <span>Refund is not Applicable</span>
                           </label>
                        </div>

                        <div
                           className={`bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 shadow mt-4 space-y-2 mb-5 text-sm ${
                              refundDefaultOption === "applicable"
                                 ? "block"
                                 : "hidden"
                           }`}
                        >
                           <div>
                              <label
                                 htmlFor="refundPayment"
                                 className="cursor-pointer"
                              >
                                 Refund Amount
                                 <input
                                    type="text"
                                    id="refundPayment"
                                    value={refundAmount}
                                    onChange={(e) =>
                                       setrefundAmount(e.target.value)
                                    }
                                    name="refundAmount"
                                    className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 mt-1 outline-none text-sm w-28 ml-5"
                                 />
                              </label>
                           </div>
                           <div>
                              <label
                                 htmlFor="refundPayment"
                                 className="cursor-pointer mt-4"
                              >
                                 Refund Method
                                 <select
                                    value={refundMethod}
                                    onChange={(e) =>
                                       setrefundMethod(e.target.value)
                                    }
                                    className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 mt-1 outline-none text-sm w-28 ml-5"
                                 >
                                    <option value="">Select Method</option>
                                    <option value="Cash">Cash</option>
                                    <option value="Card">Card</option>
                                    <option value="Bkash">Bkash</option>
                                    <option value="Nagad">Nagad</option>
                                 </select>
                              </label>
                           </div>

                           <div
                              className={`flex flex-col w-full ${
                                 refundMethod ? "block" : "hidden"
                              }`}
                           >
                              <label htmlFor="trNumber">
                                 Refund Transaction Reference Number
                              </label>
                              <input
                                 type="text"
                                 value={trNumber}
                                 onChange={(e) => settrNumber(e.target.value)}
                                 name="trNumber"
                                 className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                              />
                           </div>
                           <div className="flex flex-col w-full">
                              <label htmlFor="discountType">
                                 Refund Reason
                              </label>
                              <input
                                 type="text"
                                 value={refundReason}
                                 onChange={(e) =>
                                    setrefundReason(e.target.value)
                                 }
                                 name="refundReason"
                                 className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                              />
                           </div>
                        </div>
                     </div>
                     <div className="space-y-5 mt-5 text-right">
                        <button
                           onClick={updateNewOrder}
                           className="py-2 px-5 bg-green-800  hover:bg-green-900 duration-200 rounded-lg text-white"
                        >
                           Confirm Tk. {refundAmount || 0}
                        </button>
                        <label
                           htmlFor="restock"
                           className="cursor-pointer block"
                        >
                           <input
                              type="checkbox"
                              defaultChecked
                              id="restock"
                              name="refund"
                              className="mr-2"
                           />
                           <span>Send a Notification to the number</span>
                        </label>
                     </div>
                  </div>
                  <div className="col-span-1 bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 shadow">
                     <h3 className="font-semibold mb-3">Summary</h3>
                     <div className="">
                        <ul className="list-disc ml-4 text-sm">
                           <li>
                              <p>Returning {fxBr?.length || 0} item</p>
                              <p>Reason: {reason || "N/A"}</p>
                           </li>
                           <li>{shippingOption}</li>
                        </ul>
                        {/* <div className="mt-5 pt-5 border-t">
                                                            <button className="py-2 text-center bg-main rounded-md w-full text-sm font-semibold text-white">Update order</button>
                                                        </div> */}
                     </div>
                  </div>
               </div>
            </div>
         </Modal.Body>
         <Modal.Footer>
            <Button
               onClick={() => close()}
               color="yellow"
               className="mt-2 mr-2 bg-custom_yellow hover:bg-yellow-700 duration-150 text-black"
            >
               Cancel
            </Button>
         </Modal.Footer>
      </Modal>
   )
}

export default RefundAndReturnModal
