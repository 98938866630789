import { createSlice, current } from "@reduxjs/toolkit"

const initialState = {
   customer: {},
}

const customerSlice = createSlice({
   name: "customer",
   initialState,
   reducers: {
      setACustomer: (state, action) => {
         state.customer = action.payload
      },
      editSelectedCustomer: (state, action) => {
         const newCustomer = { ...current(state).customer, ...action.payload }

         state.customer = newCustomer
      },
   },
})

export const { setACustomer, editSelectedCustomer } = customerSlice.actions
export default customerSlice.reducer
