import { createSlice } from "@reduxjs/toolkit"

const initialState = {
   paymentMethod: "",
   paymentInformation: {
      trNumber: "",
      amountReceived: 0,
      change: 0,
   },
}
export const paymentSlice = createSlice({
   name: "payment",
   initialState,
   reducers: {
      setPaymentMethod: (state, action) => {
         state.paymentMethod = action.payload
      },
      setPaymentInformation: (state, action) => {
         state.paymentInformation = action.payload
      },
   },
})

export const { setPaymentMethod, setPaymentInformation } = paymentSlice.actions
export default paymentSlice.reducer
