import moment from "moment"
import logo from "../../assets/images/logo-ich.png"
import Barcode from "react-barcode"
import InvoiceSlip from "./InvoiceSlip"

const PackagingSlip = ({ order }) => {
   return (
      <div className="w-full space-x-4 px-10 h-auto">
         <InvoiceSlip order={order} />
         <div className="mt-3 pt-3 border-t-2 border-dashed border-black">
            <div className=" flex items-start justify-between w-full border-b border-blue-gray-100 pb-2">
               <div>
                  <div className="w-28 h-12">
                     <img
                        src={logo}
                        alt="ichchapuron-logo"
                        className="w-full h-full"
                     />
                  </div>
                  <div className="text-xs pl-2">
                     <span>
                        {moment
                           .utc(order?.createdAt)
                           .utcOffset("+06:00")
                           .format("DD MMMM YYYY, h:mm a")}
                     </span>
                  </div>
               </div>
               <div className="space-y-2 text-xs">
                  <div className="flex flex-col items-center justify-center gap-1">
                     <span>Order Id : {order?.orderId}</span>
                     <Barcode
                        format="CODE128"
                        lineColor="#000000"
                        background="#FFFFFF"
                        height={20}
                        value={order?.orderId}
                        width={0.9}
                        fontSize={10}
                        margin={0}
                        padding={0}
                        displayValue={false}
                        className="m-auto pl-2"
                     />
                  </div>
                  <div className="flex flex-col items-center justify-center gap-1">
                     <span>Invoice Id : IN2089666</span>
                     <Barcode
                        format="CODE128"
                        lineColor="#000000"
                        background="#FFFFFF"
                        height={20}
                        value={order?.orderId}
                        width={0.9}
                        fontSize={10}
                        margin={0}
                        padding={0}
                        displayValue={false}
                        className="m-auto pl-2"
                     />
                  </div>
               </div>
            </div>
            <div className="mt-2 space-y-1 text-xs border-b border-blue-gray-100 pb-3 bg-white dark:bg-dark dark:border-gray-600 dark:text-white w-full border px-5">
               <h3 className="font-semibold text-sm border-b border-blue-gray-100 mb-2 py-2">
                  Shipping Information
               </h3>
               <div>
                  <span className="font-semibold">Customer Name : </span>
                  <span className="capitalize">
                     {order?.customer?.contactInformation?.name}
                  </span>
               </div>
               <div>
                  <span className="font-semibold">Phone : </span>{" "}
                  {order?.customer?.shippingAddress?.phone || "N/A"}
               </div>{" "}
               <div>
                  <span className="font-semibold"> Address : </span>{" "}
                  {order?.customer?.shippingAddress?.address || "N/A"}
               </div>{" "}
               <div>
                  <span className="font-semibold">Area : </span>{" "}
                  {order?.customer?.shippingAddress?.area || "N/A"}
               </div>{" "}
               <div>
                  <span className="font-semibold"> Thana : </span>{" "}
                  {order?.customer?.shippingAddress?.thana || "N/A"}
               </div>
               <div>
                  <span className="font-semibold"> District : </span>{" "}
                  {order?.customer?.shippingAddress?.district || "N/A"}
               </div>
               <div>
                  <span className="font-semibold">
                     Subtotal / Product Price :{" "}
                  </span>{" "}
                  <span> {order?.subTotal} TK.</span>
               </div>
               <div>
                  <span className="font-semibold">Delivery Fee : </span>{" "}
                  <span>
                     {order?.paymentShipping?.shipping_cost || "N/A"} TK.
                  </span>
               </div>
               <div>
                  <span className="font-semibold">Total : </span>{" "}
                  {order?.totalPrice} TK.
               </div>
               <div>
                  <span className="font-semibold">Collection Amount : </span>{" "}
                  {order?.isPaid == true ? 0 : order?.totalPrice} TK.
               </div>
            </div>
         </div>
      </div>
   )
}

export default PackagingSlip
