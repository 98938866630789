import React, { useContext } from "react"
import { DashboardContext } from "../../context/DashboardProvider"
import DashboardItem from "./DashboardItem"
import { TiDeleteOutline } from "react-icons/ti"
import { Link } from "react-router-dom"
import logo from "../../assets/images/logo-bangla.png"
import logo_evertale from "../../assets/images/evertale.png"
import { userAccount } from "../../utils/utils"

const Dashboard = () => {
   const { setSidebarOpen, responsiveSidebar, setResponsiveSidebar } =
      useContext(DashboardContext)

   return (
      <div
         className={` bg-slate-800 bg-[#001529] text-white h-screen overflow-x-hidden overflow-y-scroll no-scrollbar ${
            !responsiveSidebar ? "hidden" : "block"
         }`}
      >
         <div className={`sticky top-0 bg-[#001529] text-white  pb-3 w-full `}>
            <div className="flex justify-center items-center w-full mt-3 md:mt-0">
               <button
                  onClick={() => {
                     setResponsiveSidebar(!responsiveSidebar)
                     setSidebarOpen(false)
                  }}
                  className="md:hidden"
               >
                  <TiDeleteOutline size={30}></TiDeleteOutline>
               </button>
               <div className="hidden md:block htext-center w-[100px] h-[40px] my-5 ">
                  <Link to="/home">
                     <img src={logo} alt="" width={"100%"} />
                  </Link>
               </div>
            </div>
         </div>
         <DashboardItem></DashboardItem>
      </div>
   )
}

export default Dashboard
