import React, { useState, useRef, useEffect } from "react"
import { Avatar, Popover, Whisper } from "rsuite"
import { Link, useNavigate } from "react-router-dom"
import { useGetAcademicsQuery } from "../../../../services/academic"
import { useReactToPrint } from "react-to-print"
import Barcode from "react-barcode"
import axios from "axios"
import useScanDetection from "use-scan-detection"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import AgGridTable from "../../../../components/AgGridTable"
import { FaPlus } from "react-icons/fa"
import { employeeInfoFunc, userAccount } from "../../../../utils/utils"
import { useGetEmployeeQuery } from "../../../../services/employee"

const AcademicItems = () => {
   const navigate = useNavigate()
   const { data } = useGetAcademicsQuery()
   const [tableData, setTableData] = useState(null)
   const [qr, setqr] = useState(null)
   const [productName, setProductName] = useState("")
   const componentRef = useRef()
   const employeeFromLocal = JSON.parse(localStorage.getItem("ich-user"))
   const { data: user } = useGetEmployeeQuery(employeeFromLocal?.id)
   const permission = employeeInfoFunc(user, "inventory")

   const handlePrint = useReactToPrint({
      content: () => componentRef.current,
   })

   const combineFn = (e, data) => {
      e.preventDefault()
      if (data?.barcode === undefined) {
         alert("No barcode found with this product")
         setqr(null)
      } else {
         setqr(data?.barcode)
         setProductName(data?.title)
         setTimeout(() => {
            handlePrint()
         }, 1000)
      }
   }

   const [barcodeScan, setBarcodeScan] = useState("")
   useScanDetection({
      onComplete: setBarcodeScan,
      minLength: 3,
   })

   const handleAcademicProductDelete = async (id) => {
      const confirmation = window.confirm("Do you want to delete this product?")
      if (confirmation) {
         await axios.delete(`/api/academic/${id}`)
         window.location.reload()
      } else {
         console.log("Ignore to delete this product")
      }
   }

   const columnDef = [
      {
         headerName: "ID",
         field: "barcode",
         checkboxSelection: true,
         cellRenderer: function (x) {
            return (
               <Link
                  to={`/academic-view/${x?.data?._id}`}
                  className="block !no-underline"
               >
                  {x.data.barcode ? x.data.barcode : "N/A"}
               </Link>
            )
         },
      },
      {
         headerName: "Image",
         field: "images",
         cellRenderer: (x) => (
            <Link
               to={`/academic-view/${x?.data?._id}`}
               className="px-4 block !no-underline"
            >
               <Avatar src={x?.data?.images?.[0]} alt={x?.data?.title} />
            </Link>
         ),
      },
      {
         headerName: "School",
         width: "300",
         field: "schools",
         cellRenderer: (x) => (
            <Whisper
               followCursor
               speaker={
                  <Popover>
                     <div className="flex flex-col text-xs gap-0.5">
                        {x?.data?.schools?.map((x, i) => (
                           <div key={x?.school} className="flex item-center">
                              <span>
                                 {i + 1}
                                 {"."}
                              </span>
                              <span className="underline">{x?.school}</span>
                           </div>
                        ))}
                     </div>
                  </Popover>
               }
            >
               <Link
                  to={`/academic-view/${x?.data?._id}`}
                  className="px-4 flex flex-col text-xs !no-underline h-full"
               >
                  {x?.data?.schools?.map((x, i) => (
                     <span key={x?.school}>{x?.school}</span>
                  ))}
               </Link>
            </Whisper>
         ),
      },
      {
         headerName: "Class",
         width: "200",
         field: "schools",
         cellRenderer: (x) => (
            <Whisper
               followCursor
               speaker={
                  <Popover>
                     <div className="flex flex-col text-xs gap-0.5">
                        {x?.data?.schools?.map((x, i) => (
                           <div key={x?.school} className="flex item-center">
                              <span>
                                 {i + 1}
                                 {"."}
                              </span>
                              <span>
                                 {x.class?.map((p) => (
                                    <span key={x?.school + p} className="px-1">
                                       {p}
                                    </span>
                                 ))}
                              </span>
                           </div>
                        ))}
                     </div>
                  </Popover>
               }
            >
               <Link
                  to={`/academic-view/${x?.data?._id}`}
                  className="px-4 flex flex-col text-xs !no-underline h-full"
               >
                  {x?.data?.schools?.map((x) => (
                     <span>
                        {x.class?.map((p) => (
                           <span key={x?.school + p} className="px-1 ">
                              {p}
                           </span>
                        ))}
                     </span>
                  ))}
               </Link>
            </Whisper>
         ),
      },
      {
         headerName: "Title",
         width: "200",
         field: "title",
         cellRenderer: (x) => (
            <Link
               to={`/academic-view/${x?.data?._id}`}
               className="px-4 block !no-underline"
            >
               <Whisper
                  followCursor
                  speaker={
                     <Popover>
                        <span className="text-sm">{x?.data?.title}</span>
                     </Popover>
                  }
               >
                  <span className="text-sm">{x?.data?.title}</span>
               </Whisper>
            </Link>
         ),
      },
      {
         headerName: "Stock",
         width: "200",
         field: "countInStock",
         cellRenderer: (x) => (
            <Link
               to={`/academic-view/${x?.data?._id}`}
               className="px-4 block !no-underline"
            >
               {x?.data?.countInStock}
            </Link>
         ),
      },
      {
         headerName: "Writer",
         field: "author.name",
         cellRenderer: (x) => (
            <Link
               to={`/academic-view/${x?.data?._id}`}
               className="px-4 block !no-underline"
            >
               {x?.data?.author?.name}
            </Link>
         ),
      },
      {
         headerName: "Category",
         field: "category",
         cellRenderer: (x) => (
            <Link
               to={`/academic-view/${x?.data?._id}`}
               className="px-4 block !no-underline"
            >
               {x?.data?.category}
            </Link>
         ),
      },
      {
         headerName: "price",
         field: "price",
         cellRenderer: function (x) {
            return (
               <Link
                  to={`/academic-view/${x?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {x?.data?.price}
               </Link>
            )
         },
      },
      {
         headerName: "Discount%",
         field: "discount",
         cellRenderer: (x) => (
            <Link
               to={`/academic-view/${x?.data?._id}`}
               className="px-4 block !no-underline"
            >
               {userAccount === "gyankosh"
                  ? x?.data?.gyankoshDiscount || 0
                  : x?.data?.discount || 0}
            </Link>
         ),
      },
      {
         headerName: "Discount Price",
         field: "discountedPrice",
         cellRenderer: function (x) {
            return (
               <Link
                  to={`/academic-view/${x?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {userAccount === "gyankosh"
                     ? x?.data?.gyankoshDiscountedPrice || x?.data?.price
                     : x?.data?.discountedPrice || x?.data?.price}
               </Link>
            )
         },
      },

      {
         headerName: "Actions",
         field: "_id",
         cellRenderer: (x) => (
            <div className="text-sm z-50 px-4">
               {permission?.permission !== "View Only" ? (
                  <ul className="flex">
                     <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50">
                        <Link
                           to={`/edit-academic/${x?.data?._id}`}
                           className="flex items-center gap-2 text-[#4F5366] text-sm"
                        >
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 cursor-pointer text-green-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                           >
                              <path
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                                 d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              ></path>
                           </svg>
                        </Link>
                     </li>
                     <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50">
                        <button
                           onClick={() =>
                              handleAcademicProductDelete(x?.data?._id)
                           }
                           className="flex items-center gap-2 text-[#4F5366] text-sm"
                        >
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 cursor-pointer text-red-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                           >
                              <path
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                                 d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              ></path>
                           </svg>
                        </button>
                     </li>

                     <li
                        onClick={(e) => combineFn(e, x?.data)}
                        className="px-2 cursor-pointer hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50"
                     >
                        <Link className="flex items-center gap-2 text-[#4F5366] text-sm">
                           <svg
                              viewBox="0 0 20 20"
                              className="Polaris-Icon__Svg_375hu w-4 text-blue-500"
                              focusable="false"
                              aria-hidden="true"
                           >
                              <path
                                 fillRule="evenodd"
                                 d="M14 11h2v-2h-2v2zm-7-4h6v-3h-6v3zm0 9h6v-2h-6v2zm8-9v-3.5a1.5 1.5 0 0 0-1.5-1.5h-7a1.5 1.5 0 0 0-1.5 1.5v3.5h-1.5a1.5 1.5 0 0 0-1.5 1.5v6a1.5 1.5 0 0 0 1.5 1.5h1.5v.5a1.5 1.5 0 0 0 1.5 1.5h7a1.5 1.5 0 0 0 1.5-1.5v-.5h1.5a1.5 1.5 0 0 0 1.5-1.5v-6a1.5 1.5 0 0 0-1.5-1.5h-1.5z"
                              ></path>
                           </svg>
                        </Link>
                     </li>
                     <li className="hidden">
                        <div
                           ref={componentRef}
                           className="flex justify-center items-center h-full pb-2 pt-2.5 w-[99vw] m-0"
                        >
                           <div className="flex flex-col justify-center items-center ">
                              <small className="text-[8px]">
                                 ichchapuron.com.bd
                              </small>

                              <Barcode
                                 format="CODE128"
                                 lineColor="#000000"
                                 background="#FFFFFF"
                                 height={40}
                                 value={qr}
                                 width={0.9}
                                 fontSize={10}
                                 margin={0}
                                 padding={0}
                              />
                              <p className="text-[8px]">
                                 {productName?.split(" ")?.length > 2
                                    ? `${productName
                                         ?.split(" ")
                                         .slice(0, 2)
                                         .join(" ")}...`
                                    : productName}
                              </p>
                           </div>
                        </div>
                     </li>
                  </ul>
               ) : (
                  <p className="py-3">N/A</p>
               )}
            </div>
         ),
      },
   ]

   useEffect(() => {
      setTableData(data?.products)
   }, [data])

   return (
      <section>
         <div className="border-b border-gray-200 md:mx-10 mx-4 py-5  flex items-center justify-between">
            <div className="sm:flex gap-4 items-center">
               <div className="flex items-center text-darkText">
                  <span>
                     <svg
                        width="18"
                        height="18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M1.045 4.488h15.91"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinejoin="round"
                        ></path>
                        <path
                           d="M4.325 1.164h9.589c.423 0 .824.187 1.095.512l1.617 1.94c.213.257.33.58.33.913v10.408a1.9 1.9 0 0 1-1.9 1.9H3.183a1.9 1.9 0 0 1-1.9-1.9V4.529c0-.333.117-.656.33-.912L3.23 1.676c.271-.325.672-.512 1.095-.512zM6.98 7.812h4.275M9.12 1.4v2.85"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        ></path>
                     </svg>
                  </span>
                  <button
                     type="button"
                     onClick={() => navigate("/inventory")}
                     className="text-sm sm:text-lg px-2 sm:px-4 hover:text-black"
                  >
                     All Items
                  </button>
                  <span>
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        className="Chevron__Svg-sc-zctemm-0 gOuVYO"
                     >
                        <path
                           fill="currentColor"
                           fillRule="evenodd"
                           clipRule="evenodd"
                           d="M6.07303 11.9509C6.25207 12.118 6.48481 12.2 6.71446 12.2C6.94411 12.2 7.17686 12.118 7.35589 11.9509L10.9271 8.6176C11.291 8.27787 11.291 7.72227 10.9271 7.38254L7.35589 4.04928C6.99978 3.71689 6.42914 3.71689 6.07303 4.04928C5.70905 4.389 5.70905 4.94461 6.07303 5.28434L8.9826 8.00007L6.07303 10.7158C5.70905 11.0555 5.70905 11.6111 6.07303 11.9509Z"
                        ></path>
                     </svg>
                  </span>
               </div>
               <h3 className="text-lg dark:text-white">Academic Books</h3>
            </div>
            <div className="flex gap-2">
               {permission && permission?.permission !== "View Only" && (
                  <Link
                     to="/academic-books/new"
                     className="flex items-center bg-[#dd2a3b] opacity-[0.8] px-2 sm:px-4  py-2 rounded-md font-semibold text-white text-xs"
                  >
                     <button
                        type="button"
                        className=" bg-[#ffffff3b] p-2 mr-2 rounded-sm"
                     >
                        <FaPlus className="font-thin"></FaPlus>
                     </button>
                     ADD NEW
                  </Link>
               )}
            </div>
         </div>
         <div className="flex items-start gap-5 flex-col overflow-hidden mx-4 md:mx-6">
            <div className="flex gap-10 mt-7 mb-3">
               <h3 className="text-sm sm:text-[17px] md:text-[18px] text-[#9191A1]">
                  Total Items: {data?.products?.length}
               </h3>
            </div>
            {data?.products?.length === 0 ? (
               <div className="text-red-600 flex items-center justify-center  h-[60vh]">
                  No Products Available!
               </div>
            ) : (
               <AgGridTable
                  data={tableData}
                  columnDef={columnDef}
                  height="70vh"
               />
            )}
         </div>
      </section>
   )
}

export default AcademicItems
