import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const subWriterApi = createApi({
   reducerPath: "subWriterApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["subWriter"],
   endpoints: (builder) => ({
      getSubWriter: builder.query({
         query: () => "api/sub-writer",
         providesTags: ["subWriter"],
      }),
      createSubWriter: builder.mutation({
         query: (data) => ({
            url: `api/sub-writer`,
            method: "POST",
            body: data,
         }),
         invalidatesTags: ["subWriter"],
      }),
      updateSubWriter: builder.mutation({
         query: ({ id, ...rest }) => ({
            url: `api/sub-writer/${id}`,
            method: "PATCH",
            body: rest,
         }),
         invalidatesTags: ["subWriter"],
      }),
      deleteSubWriter: builder.mutation({
         query: (id) => ({
            url: `api/sub-writer/${id}`,
            method: "DELETE",
         }),
         invalidatesTags: ["subWriter"],
      }),
   }),
})

export const {
   useGetSubWriterQuery,
   useCreateSubWriterMutation,
   useUpdateSubWriterMutation,
   useDeleteSubWriterMutation,
} = subWriterApi
