import React from "react"
import { Card, Typography, Avatar } from "@material-tailwind/react"
import { FaBookOpen } from "react-icons/fa"

const UserOrder = ({ order }) => {
   return (
      <div className="border shadow p-2 sm:p-5 rounded-xl w-full text-sm dark:bg-lightDark dark:text-white mb-5">
         <div className="flex flex-col md:flex-row gap-3 justify-between ">
            <h3 className="text-[#576CBC] ">Oder ID: {order?.orderId}</h3>
            <h3 className="text-[#576CBC]">
               Total : <span>{order?.totalPrice} TK.</span>
            </h3>
         </div>
         <Card>
            <table className="w-full min-w-max table-auto text-left">
               <thead>
                  <tr>
                     {["Product", "Quantity", "Price", "Custom Discount"].map(
                        (head) => (
                           <th
                              key={head}
                              className="border-b border-blue-gray-100 bg-blue-100  p-4"
                           >
                              <Typography
                                 variant="small"
                                 className="font-semibold leading-none opacity-70 text-black"
                              >
                                 {head}
                              </Typography>
                           </th>
                        )
                     )}
                  </tr>
               </thead>
               <tbody>
                  {order?.orderedProducts?.map((x, index) => {
                     const isLast = index === order?.orderedProducts.length - 1
                     const classes = isLast
                        ? "p-4 pr-0"
                        : "p-4 border-b border-blue-gray-50"

                     return (
                        <tr key={x?._id}>
                           <td className={classes}>
                              <div className="flex gap-2.5 w-1/2">
                                 {x?.images?.length > 0 ? (
                                    <Avatar
                                       size="md"
                                       src={x?.images?.[0]}
                                       alt={x?.title}
                                       className="!rounded-none"
                                    />
                                 ) : (
                                    <FaBookOpen
                                       size={20}
                                       className="text-custom_lightBlue"
                                    />
                                 )}
                                 <div className="flex flex-col items-start justify-between flex-grow">
                                    <h1 className="font-semibold !text-sm">
                                       {x?.title?.length > 36
                                          ? x?.title.substr(0, 36)
                                          : x?.title}
                                       {x?.title?.length > 36 && "..."}
                                    </h1>
                                 </div>
                              </div>
                           </td>
                           <td className={classes}>
                              <Typography
                                 variant="small"
                                 color="blue-gray"
                                 className="font-normal"
                              >
                                 {x?.cartQuantity}
                              </Typography>
                           </td>
                           <td className={classes}>
                              <span className="line-through">
                                 {" "}
                                 {Math.round(x?.price) * x?.cartQuantity} TK.
                              </span>
                              <Typography
                                 variant="small"
                                 color="blue-gray"
                                 className="font-medium"
                              >
                                 {Math.round(x?.discountedPrice) *
                                    x?.cartQuantity}{" "}
                                 TK.
                              </Typography>
                           </td>
                           <td className={classes}>
                              <Typography
                                 variant="small"
                                 color="blue-gray"
                                 className="font-normal"
                              >
                                 {x?.custom_discount?.amount * x?.cartQuantity}
                                 {x?.custom_discount?.discountType ===
                                 "percentage"
                                    ? " %"
                                    : " TK."}
                              </Typography>
                              {x?.custom_discount?.amount === 0 ? null : (
                                 <span className="text-xs text-gray-500">
                                    Reason: {x?.custom_discount?.reason}
                                 </span>
                              )}
                           </td>
                        </tr>
                     )
                  })}
               </tbody>
            </table>
         </Card>
         <div className="space-y-2 mt-3">
            <div>
               ORDER TYPE :{" "}
               <span className="text-[#576CBC] ">{order?.orderType}</span>
            </div>
            <div>
               PAYMENT STATUS :{" "}
               <span className="text-[#576CBC] ">
                  {order?.isPaid ? "Paid" : "Due"}
               </span>
            </div>
            <div>
               ORDER STATUS :{" "}
               <span className="text-[#576CBC] ">{order?.orderStatus}</span>
            </div>
            <div>
               PAYMENT METHOD:{" "}
               <span className="text-[#576CBC] capitalize">
                  {order?.paymentMethod}
               </span>
            </div>
            <div>
               DELIVERY COST:{" "}
               <span className="text-[#576CBC] capitalize">
                  {order?.paymentShipping?.shipping_cost} TK. (
                  {order?.paymentShipping?.shipping_type})
               </span>
            </div>
         </div>
      </div>
   )
}

export default UserOrder
