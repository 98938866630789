import React, { useState } from "react"
import { Dropdown } from "rsuite"
import { useGetCustomersQuery } from "../../services/customer"
import { useDispatch } from "react-redux"
import { setACustomer } from "../../services/Features/customerSlice"
import { AiOutlineSearch, AiOutlineUserAdd } from "react-icons/ai"
import { RiDeleteBack2Line } from "react-icons/ri"

const CustomerList = () => {
   const { data: dbcustomers } = useGetCustomersQuery()
   const dispatch = useDispatch()
   const [customerListOpen, setCustomerListOpen] = useState(false)
   const [searchValue, setSearchValue] = useState("")

   return (
      <div>
         <button
            type="button"
            onClick={() => setCustomerListOpen(true)}
            className="flex gap-3 items-center border p-3 shadow-sm rounded-md w-full bg-white hover:bg-gray-200 duration-150"
         >
            <AiOutlineUserAdd className="text-xl cursor-pointer"></AiOutlineUserAdd>
            <span className="cursor-pointer">Select Customer</span>
         </button>
         <div
            className={`bg-white mt-2 pb-3 shadow-md rounded-lg overflow-y-auto max-h-96 w-full
       ${customerListOpen ? "block" : "hidden"}`}
         >
            <div className="bg-white px-4 sticky top-0 w-full">
               <div className="text-right">
                  <button
                     type="button"
                     onClick={() => setCustomerListOpen(false)}
                  >
                     <RiDeleteBack2Line size={23}></RiDeleteBack2Line>
                  </button>
               </div>
               <div className="flex items-center py-2 px-4 gap-2 rounded-md bg-gray-200 relative">
                  <AiOutlineSearch className="text-[18px] text-gray-700"></AiOutlineSearch>
                  <input
                     value={searchValue}
                     onChange={(e) =>
                        setSearchValue(e.target.value?.toLowerCase())
                     }
                     type="text"
                     placeholder="Type a name"
                     className="placeholder:text-gray-7 outline-none w-full border-none focus:border-none bg-gray-200 focus:ring-0"
                  />
               </div>
            </div>
            <ul className="px-4">
               <h3 className="text-[15px] font-medium ">Suggestions</h3>
               {dbcustomers?.Customers?.map((user, index) => (
                  <li
                     key={index}
                     onClick={() => {
                        dispatch(setACustomer(user))
                        setCustomerListOpen(false)
                     }}
                     className={`text-sm hover:bg-gray-100 cursor-pointer rounded-md px-2
                   ${
                      user?.contactInformation?.name
                         ?.toLowerCase()
                         .startsWith(searchValue)
                         ? "block"
                         : "hidden"
                   }`}
                  >
                     <div className="flex items-center gap-2">
                        <div className="bg-gray-400 rounded-full w-8 h-8 p-1 text-center leading-6 text-white capitalize">
                           {user?.contactInformation?.name?.charAt(0)}
                        </div>
                        <h3 className="capitalize">
                           {user?.contactInformation?.name}
                        </h3>
                     </div>
                  </li>
               ))}
            </ul>
         </div>
      </div>
   )
}

export default CustomerList
