import React, { useState } from "react"
import { FaPlusCircle } from "react-icons/fa"
import CreateNewCustomer from "./CreateNewCustomer"
import GetCustomer from "./GetCustomer"
import CustomerList from "./CustomerList"
const Customers = ({ route, orderPermission, darftPermission }) => {
   const [openCreateCustomer, setOpenCreateCustomer] = useState(false)
   const handleOpenCreateCustomer = () => setOpenCreateCustomer(true)

   const handleCloseCreateCustomer = () => {
      setOpenCreateCustomer(false)
   }
   return (
      <div>
         {/* <h2 className=" font-semibold">Customer</h2> */}
         {((route === `draft` && darftPermission?.permission !== "View Only") ||
            (route === "create" &&
               orderPermission?.permission !== "View Only")) && (
            <CustomerList />
         )}
         <div className="bg-white mb-2 dark:bg-dark dark:border-gray-600 border dark:text-white shadow rounded-lg mt-2">
            {((route === `draft` &&
               darftPermission?.permission !== "View Only") ||
               (route === "create" &&
                  orderPermission?.permission !== "View Only")) && (
               <div
                  onClick={handleOpenCreateCustomer}
                  className="bg-custom_lightBlue hover:bg-[#395c90] duration-200 py-3 text-center w-full text-white rounded-md flex justify-center items-center gap-2 cursor-pointer"
               >
                  <FaPlusCircle></FaPlusCircle>
                  Create a new customer
               </div>
            )}
            <CreateNewCustomer
               open={openCreateCustomer}
               handleCloseCreateCustomer={handleCloseCreateCustomer}
            />
         </div>
         <GetCustomer />
      </div>
   )
}

export default Customers
