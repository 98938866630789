import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCarts = createAsyncThunk("getCarts/getCarts", async () => {
  const { data } = await axios.get(`/api/carts`);
  return data;
});

export const addToCart = createAsyncThunk("addToCart/addToCart", async (obj) => {
  const { data } = await axios.post(`/api/carts`, obj);
  return data;
});
  
export const deleteCart = createAsyncThunk("deleteCart/deleteCart", async (id) => {
  const { data } = await axios.delete(`/api/carts/${id}`);
  return data;
});
  