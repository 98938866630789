import React, { useContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { darkMode, lightMode } from "../services/Features/darkModeSlice"
import "../css/extras/switch.css"
import { AiOutlineMenu } from "react-icons/ai"
import { DashboardContext } from "../context/DashboardProvider"
import { userAccount } from "../utils/utils"

const Navbar = () => {
   const { responsiveSidebar, setResponsiveSidebar } =
      useContext(DashboardContext)
   const dispatch = useDispatch()
   const [isDark, setIsDark] = useState(false)

   const handleMode = (e) => {
      e.preventDefault()
      setIsDark(!isDark)
      if (isDark) {
         dispatch(lightMode())
      } else {
         dispatch(darkMode())
      }
   }

   return (
      <div className="bg-white dark:bg-dark border-b w-full right-0 top-0 flex gap-2 px-4 items-center justify-between h-[70px]">
         {/* dark and light theme toggle button */}
         {/* <div className='border rounded-md border-primary'>
                <button onClick={ () => dispatch( lightMode() ) } className='p-2 bg-[#0c2556] dark:bg-dark text-white rounded-l-md'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 dark:text-white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
                    </svg>
                </button>
                <button onClick={ () => dispatch( darkMode() ) } className='p-2 bg-white dark:bg-[#0c2556] dark:text-white rounded-r-md'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 dark:text-white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
                    </svg>
                </button>
            </div> */}
         <div>
            <button
               onClick={() => {
                  setResponsiveSidebar(!responsiveSidebar)
               }}
               className={`md:hidden ${responsiveSidebar && "hidden"}`}
            >
               <AiOutlineMenu></AiOutlineMenu>
            </button>
         </div>
         {/* <div className="button b2" id="button-11" onClick={handleMode}>
            <input
               type="checkbox"
               className={`${isDark ? "dark checkbox" : "checkbox"}`}
            />
            <div className="knobs">
               <span></span>
            </div>
         </div> */}
         <div className="flex gap-4 items-center ">
            {userAccount && (
               <h3 className="capitalize text-base font-semibold text-custom_blue bg-gray-200 px-4 py-2 rounded-lg">
                  {userAccount}
               </h3>
            )}
            <div className="border rounded-md border-primary">
               <button
                  className="p-2 bg-[#0c2556] dark:bg-lightDark text-white rounded-l-md"
                  onClick={handleMode}
               >
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24"
                     strokeWidth="1.5"
                     stroke="currentColor"
                     className="w-5 h-5 dark:text-white"
                  >
                     <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                     />
                  </svg>
               </button>
               <button
                  className="p-2 dark:bg-[#0c2556] dark:text-white rounded-r-md "
                  onClick={handleMode}
               >
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24"
                     strokeWidth="1.5"
                     stroke="currentColor"
                     className="w-5 h-5 dark:text-white"
                  >
                     <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
                     />
                  </svg>
               </button>
            </div>
         </div>
      </div>
   )
}

export default Navbar
