import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const trendingApi = createApi({
   reducerPath: "trendingApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["trendingApi"],
   endpoints: (builder) => ({
      gettrending: builder.query({
         query: () => `api/trending`,
         providesTags: ["trendingApi"],
      }),
   }),
})

export const { useGettrendingQuery } = trendingApi
