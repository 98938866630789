import { createSlice } from '@reduxjs/toolkit'

export const promoSlice = createSlice({
   name: 'promo',
   initialState: {
      value: 0,
      msg: '',
   },
   reducers: {
      savePromo: (state, action) => {
         state.value = action?.payload?.value
            ? action?.payload?.value
            : state.value
         state.msg = action.payload.msg
      },
   },
})

export const { savePromo } = promoSlice.actions

export const promoReducer = promoSlice.reducer
