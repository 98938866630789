export const inventoryData = [
   {
      id: 1,
      name: "Academic Books",
      path: "/academic-books",
   },
   {
      id: 2,
      name: "General Books",
      path: "/general-books",
   },
   {
      id: 4,
      name: "Stationaries",
      path: "/stationaries",
   },
   {
      id: 5,
      name: "Electronics",
      path: "/electronics",
   },
   {
      id: 6,
      name: "Todays Deal",
      path: "/todays-deal",
   },
   {
      id: 7,
      name: "Trending Now",
      path: "/trending-now",
   },
   {
      id: 8,
      name: "Book Fair",
      path: "/bookfair",
   },
   {
      id: 9,
      name: "Popular Now",
      path: "/popular-now",
   },
]
export const evertalenventoryData = [
   {
      id: 1,
      name: "Men",
      path: "/academic-books",
   },
   {
      id: 2,
      name: "Womem",
      path: "/general-books",
   },
   {
      id: 3,
      name: "kid",
      path: "/stationaries",
   },
]
