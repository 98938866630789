import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const todaysDealApi = createApi({
   reducerPath: "todaysDealApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["todaysDealApi"],
   endpoints: (builder) => ({
      gettodaysDeal: builder.query({
         query: (page) => `api/todays-deal`,
         providesTags: ["todaysDealApi"],
      }),
   }),
})

export const { useGettodaysDealQuery } = todaysDealApi
