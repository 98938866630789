import React from "react"
import CustomerTotalOrder from "./CustomerTotalOrder"
import { Link } from "react-router-dom"

const OrderCustomerInfo = ({ customer }) => {
   return (
      <div className="bg-white dark:bg-dark dark:border-gray-600 dark:text-white w-full border rounded-lg shadow px-5">
         <h3 className=" font-semibold text-[15px]  pt-5 ">Customer</h3>
         <div className="border-b py-3 mb-5 text-sm">
            <label className="text-[#576CBC] mb-1 cursor-pointer flex gap-0.5">
               <Link to={`/customer/${customer?.contactInformation?.phone}`}>
                  {customer?.contactInformation?.name}
               </Link>
            </label>
            <label className="text-[#576CBC] mb-1 cursor-pointer block">
               <CustomerTotalOrder customer={customer} />
            </label>
         </div>
         <div className="flex justify-between text-[15px] border-b  pb-3 mb-5">
            <div className="text-sm">
               <h3 className="font-semibold">Contact information</h3>
               <div className="text-[#576CBC] mb-1 mt-3 cursor-pointer block">
                  {customer?.contactInformation?.email}
               </div>
               <span>
                  Phone : {customer?.contactInformation?.phone || "N/A"}
               </span>
            </div>
         </div>
         <div className="flex justify-between text-[15px] border-b  pb-3 mb-5">
            <div className="text-sm">
               <h3 className="font-semibold">Shipping address</h3>
               <p>Phone: {customer?.shippingAddress?.phone || "N/A"}</p>
               <p>District: {customer?.shippingAddress?.district || "N/A"}</p>
               <p>Thana: {customer?.shippingAddress?.thana || "N/A"}</p>
               <p>Area : {customer?.shippingAddress?.area || "N/A"}</p>
               <p>Address : {customer?.shippingAddress?.address || "N/A"}</p>
            </div>
         </div>
      </div>
   )
}

export default OrderCustomerInfo
