import React, { useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { DashboardContext } from "../../context/DashboardProvider"
import { useGetFullfilledOrdersQuery } from "../../services/order"
import moment from "moment"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "react-toastify/dist/ReactToastify.css"
import AgGridTable from "../../components/AgGridTable"
import { userAccount } from "../../utils/utils"
import PrintPackagingSlip from "../../components/PrintPackging/PrintPackagingSlip"

const AllFullfilled = () => {
   const { data: result } = useGetFullfilledOrdersQuery(userAccount)
   const [dateRange, setDateRange] = useState([])
   const [startDate, endDate] = dateRange

   const orders = result?.filter((order) => {
      const orderDate = order?.updatedAt && moment(order?.updatedAt)
      const startDateInfo = startDate && moment(startDate)
      const endDateInfo = endDate && moment(endDate)

      if (!startDate && !endDate) {
         return true
      } else if (
         orderDate &&
         startDateInfo &&
         endDateInfo &&
         orderDate.isSameOrAfter(startDateInfo.startOf("day")) &&
         orderDate.isSameOrBefore(endDateInfo.endOf("day"))
      ) {
         return true
      }
      return false
   })

   const navigate = useNavigate()
   const { employeeInfo } = useContext(DashboardContext)
   const permission = employeeInfo?.find((data) => data?.permission)

   const columnDef = [
      {
         headerName: "ORDER ID",
         field: "orderId",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/fullfilled/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params?.data?.orderId}
               </Link>
            )
         },
      },
      {
         headerName: "ORDER DATE",
         field: "date",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/fullfilled/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {moment
                     .utc(params.data.createdAt)
                     .utcOffset("+06:00")
                     .format("DD-MM-YYYY")}
               </Link>
            )
         },
      },
      {
         headerName: "FULLFILLED DATE",
         field: "date",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/fullfilled/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {moment
                     .utc(params.data.updatedAt)
                     .utcOffset("+06:00")
                     .format("DD-MM-YYYY")}
               </Link>
            )
         },
      },
      {
         headerName: "ORDER STATUS",
         field: "orderStatus",
         cellRenderer: function (params) {
            return (
               <div
                  onClick={() => navigate(`/fullfilled/${params?.data?._id}`)}
                  className="cursor-pointer px-4"
               >
                  <span
                     className={`px-4 py-1 !no-underline text-center rounded-full shadow-md capitalize text-sm font-medium
                     bg-[#94d252] text-[#3c8d42]`}
                  >
                     {params.data?.orderStatus || "-"}
                  </span>
               </div>
            )
         },
      },
      {
         headerName: "CUSTOMER",
         field: "customer.contactInformation.name",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/fullfilled/${params?.data?._id}`}
                  className="px-4 block !no-underline capitalize"
               >
                  {params.data?.customer?.contactInformation?.name}
               </Link>
            )
         },
      },
      {
         headerName: "CUSTOMER PHONE",
         field: "customer.shippingAddress.phone",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/fullfilled/${params?.data?._id}`}
                  className="px-4 block !no-underline capitalize"
               >
                  {params.data?.customer?.shippingAddress.phone}
               </Link>
            )
         },
      },
      {
         headerName: "ORDER TAKEN BY",
         field: "orderTakenBy",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/fullfilled/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.orderTakenBy || "Website"}
               </Link>
            )
         },
      },
      {
         headerName: "TOTAL",
         field: "totalPrice",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/fullfilled/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.totalPrice || "-"}
               </Link>
            )
         },
      },
      {
         headerName: "PAYMENT STATUS",
         field: "isPaid",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/fullfilled/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.isPaid === true ? "Paid" : "Not paid"}
               </Link>
            )
         },
      },
      {
         headerName: "ORDER TYPE",
         field: "orderType",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/fullfilled/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.orderType || "-"}
               </Link>
            )
         },
      },

      {
         headerName: "PAYMENT METHOD",
         field: "paymentMethod",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/fullfilled/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.paymentMethod}
               </Link>
            )
         },
      },
      {
         headerName: "SHIPPING BY",
         field: "paymentShipping.shipping_carrier",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/fullfilled/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.paymentShipping?.shipping_carrier || "-"}
               </Link>
            )
         },
      },
      {
         headerName: "SHIPPING TYPE",
         field: "paymentShipping.shipping_type",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/fullfilled/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.paymentShipping?.shipping_type} (
                  {params.data?.paymentShipping?.shipping_cost})
               </Link>
            )
         },
      },
      {
         headerName: "ACTION",
         field: "_id",
         cellRenderer: (params) => (
            <div className="text-[15px] z-50 px-4">
               <ul tabIndex={0} className="flex  ">
                  {permission?.permission !== "View Only" ? (
                     <li>
                        <PrintPackagingSlip data={params.data} icon={true} />
                     </li>
                  ) : (
                     "N/A"
                  )}
               </ul>
            </div>
         ),
      },
   ]
   return (
      <section className="p-2 mb-5 m-2 sm:m-5 ">
         <div className="flex flex-col sm:flex-row gap-3 justify-between items-center pb-5 dark:text-white">
            <h3 className="font-semibold text-xl">Fullfilled Order</h3>
            {permission?.permission !== "View Only" && (
               <div className="space-x-2 flex gap-1">
                  <DatePicker
                     selectsRange={true}
                     startDate={startDate}
                     endDate={endDate}
                     onChange={(update) => {
                        setDateRange(update)
                     }}
                     isClearable={true}
                     className="date-picker  shadow-md hover:shadow-lg"
                     placeholderText="Select Date"
                  />
               </div>
            )}
         </div>
         <div className="w-full border rounded-lg p-5 bg-white dark:bg-dark dark:text-white ">
            <div className="flex items-center gap-5 pb-3 ">
               <h3 className="text-sm sm:text-base md:text-lg text-[#9191A1]">
                  Total Orders: {orders?.length}
               </h3>
            </div>

            <div className="flex gap-10 mb-3"></div>
            {orders?.length === 0 ? (
               <div className="text-red-600 flex items-center justify-center  w-full h-[60vh]">
                  No Products Available!
               </div>
            ) : (
               <AgGridTable data={orders} columnDef={columnDef} height="70vh" />
            )}
         </div>
      </section>
   )
}

export default AllFullfilled
