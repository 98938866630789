import React, { useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { DashboardContext } from "../../context/DashboardProvider"
import { useGetDraftOrdersQuery } from "../../services/order"
import moment from "moment"
import {
   addProducts,
   clearProduct,
} from "../../services/order/orderProductSlice"
import { useDispatch } from "react-redux"
import AgGridTable from "../../components/AgGridTable"
import { userAccount } from "../../utils/utils"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "react-toastify/dist/ReactToastify.css"

const AllDrafts = () => {
   const [dateRange, setDateRange] = useState([])
   const [startDate, endDate] = dateRange
   const navigate = useNavigate()
   const { data: result } = useGetDraftOrdersQuery(userAccount)

   const draftOrders = result?.filter((order) => {
      const orderDate = order?.createdAt && moment(order?.createdAt)
      const startDateInfo = startDate && moment(startDate)
      const endDateInfo = endDate && moment(endDate)

      if (!startDate && !endDate) {
         return true
      } else if (
         orderDate &&
         startDateInfo &&
         endDateInfo &&
         orderDate.isSameOrAfter(startDateInfo.startOf("day")) &&
         orderDate.isSameOrBefore(endDateInfo.endOf("day"))
      ) {
         return true
      }

      return false
   })

   const dispatch = useDispatch()

   const { employeeInfo } = useContext(DashboardContext)
   const permission = employeeInfo?.find((data) => data?.permission)

   const selectDraftOrderHandle = (product) => {
      dispatch(clearProduct())
      dispatch(addProducts(product?.orderedProducts))

      navigate(`/draft/${product?._id}`, {
         state: {
            route: "draft",
         },
      })
   }

   const columnDef = [
      {
         headerName: "Draft Id",
         field: "draftID",
         cellRenderer: function (params) {
            return (
               <div
                  className="px-4 cursor-pointer"
                  onClick={() => selectDraftOrderHandle(params?.data)}
               >
                  {params?.data?.draftID}
               </div>
            )
         },
      },
      {
         headerName: "DATE",
         field: "date",
         cellRenderer: function (params) {
            return (
               <div
                  className="px-4 cursor-pointer"
                  onClick={() => selectDraftOrderHandle(params?.data)}
               >
                  {moment
                     .utc(params.data.createdAt)
                     .utcOffset("+06:00")
                     .format("DD-MM-YYYY")}
               </div>
            )
         },
      },
      {
         headerName: "ORDER STATUS",
         field: "orderStatus",
         cellRenderer: function (params) {
            return (
               <div
                  className="px-4 cursor-pointer"
                  onClick={() => selectDraftOrderHandle(params?.data)}
               >
                  <span
                     className={`px-4 py-1 !no-underline text-center rounded-full shadow-md capitalize text-sm font-medium
                     bg-gray-200 text-gray-700 hover:text-gray-700`}
                  >
                     {params.data?.orderStatus || "-"}
                  </span>
               </div>
            )
         },
      },
      {
         headerName: "CUSTOMER",
         field: "customer.contactInformation.name",
         cellRenderer: function (params) {
            return (
               <div
                  className="px-4 cursor-pointer"
                  onClick={() => selectDraftOrderHandle(params?.data)}
               >
                  {params.data?.customer?.contactInformation?.name}
               </div>
            )
         },
      },
      {
         headerName: "CUSTOMER PHONE",
         field: "customer.shippingAddress.phone",
         cellRenderer: function (params) {
            return (
               <div
                  onClick={() => selectDraftOrderHandle(params?.data)}
                  className="px-4 cursor-pointer"
               >
                  {params.data?.customer?.shippingAddress.phone}
               </div>
            )
         },
      },
      {
         headerName: "ORDER TAKEN BY",
         field: "orderTakenBy",
         cellRenderer: function (params) {
            return (
               <div
                  className="px-4 cursor-pointer"
                  onClick={() => selectDraftOrderHandle(params?.data)}
               >
                  {params.data?.orderTakenBy || "Website"}
               </div>
            )
         },
      },
      {
         headerName: "TOTAL",
         field: "totalPrice",
         cellRenderer: function (params) {
            return (
               <div
                  className="px-4 cursor-pointer"
                  onClick={() => selectDraftOrderHandle(params?.data)}
               >
                  {params.data?.totalPrice || "-"}
               </div>
            )
         },
      },
      {
         headerName: "PAYMENT STATUS",
         field: "isPaid",
         cellRenderer: function (params) {
            return (
               <div
                  className="px-4 cursor-pointer"
                  onClick={() => selectDraftOrderHandle(params?.data)}
               >
                  {params.data?.isPaid === true ? "Paid" : "Not paid"}
               </div>
            )
         },
      },
      {
         headerName: "ORDER TYPE",
         field: "orderType",
         cellRenderer: function (params) {
            return (
               <div
                  className="px-4 cursor-pointer"
                  onClick={() => selectDraftOrderHandle(params?.data)}
               >
                  {params.data?.orderType || "-"}
               </div>
            )
         },
      },

      {
         headerName: "PAYMENT METHOD",
         field: "paymentMethod",
         cellRenderer: function (params) {
            return (
               <div
                  className="px-4 cursor-pointer"
                  onClick={() => selectDraftOrderHandle(params?.data)}
               >
                  {params.data?.paymentMethod}
               </div>
            )
         },
      },
      {
         headerName: "SHIPPING BY",
         field: "paymentShipping.shipping_carrier",
         cellRenderer: function (params) {
            return (
               <div
                  className="px-4 cursor-pointer"
                  onClick={() => selectDraftOrderHandle(params?.data)}
               >
                  {params.data?.paymentShipping?.shipping_carrier || "-"}
               </div>
            )
         },
      },
      {
         headerName: "SHIPPING TYPE",
         field: "paymentShipping.shipping_type",
         cellRenderer: function (params) {
            return (
               <div
                  className="px-4 cursor-pointer"
                  onClick={() => selectDraftOrderHandle(params?.data)}
               >
                  {params.data?.paymentShipping?.shipping_type} (
                  {params.data?.paymentShipping?.shipping_cost})
               </div>
            )
         },
      },
   ]
   return (
      <section className="p-2 mb-5 m-2 sm:m-5 ">
         <div className="flex flex-col sm:flex-row gap-3 justify-between items-center py-5 dark:text-white">
            <h3 className="font-semibold text-xl">All Drafts</h3>
            <div className="space-x-2 flex gap-1">
               <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                     setDateRange(update)
                  }}
                  isClearable={true}
                  className="date-picker  shadow-md hover:shadow-lg"
                  placeholderText="Select Order Date"
                  dateFormat={"dd/MM/yyyy"}
               />
            </div>
         </div>

         <div className="w-full border rounded-lg p-5 bg-white dark:bg-dark dark:text-white ">
            <div className="flex items-center gap-5 pb-3 ">
               <h3 className="text-sm sm:text-base md:text-lg text-[#9191A1]">
                  Total Drafts: {draftOrders?.length}
               </h3>
            </div>

            <div className="flex gap-10 mb-3"></div>
            {draftOrders?.length === 0 ? (
               <div className="text-red-600 flex items-center justify-center  w-full h-[60vh]">
                  No Products Available!
               </div>
            ) : (
               <AgGridTable
                  data={draftOrders}
                  columnDef={columnDef}
                  height="70vh"
               />
            )}
         </div>
      </section>
   )
}

export default AllDrafts
