import {createAsyncThunk} from "@reduxjs/toolkit"
import axios from "axios"

export const getUsers = createAsyncThunk("getUsers/getUsers", async () => {
  const {data} = await axios.get(`/api/user/all-users`)
  return data
})

export const getSingleUser = createAsyncThunk(
  "getSingleUser/getSingleUser",
  async (userId) => {
    const {data} = await axios.get(`/api/user/user-info/${userId}`)
    return data
  }
)

export const createUser = createAsyncThunk(
  "createUser/createUser",
  async (user) => {
    const {data} = await axios.post(`/api/user/register`, user)
    if (data) window.location.href = "/login"
    return data
  }
)

export const updateUser = createAsyncThunk(
  "updateUser/updateUser",
  async (user, {getState}) => {
    const {
      loginUser: {userInfo},
    } = getState()

    const config = {
      headers: {
        Authorization: `${userInfo.refreshToken}`,
      },
    }
    const {data} = await axios.patch(`/api/user/update_user`, user, config)
    return data
  }
)

export const updateUserRole = createAsyncThunk(
  "updateUserRole/updateUserRole",
  async (user) => {
    const {userId} = user
    const {role} = user
    const {data} = await axios.patch(`/api/user/update_role/${userId}`, {
      role,
    })
    return data
  }
)

export const deleteUser = createAsyncThunk(
  "deleteUser/deleteUser",
  async (userId) => {
    const {data} = await axios.delete(`/api/user/delete-user/${userId}`)
    return data
  }
)

export const loginUser = createAsyncThunk(
  "loginUser/loginUser",
  async (user) => {
    const {data} = await axios.post(`/api/user/login`, user)
    localStorage.setItem("userInfo", JSON.stringify(data))
    if (data) window.location.href = "/"
    return data
  }
)

export const logoutUser = createAsyncThunk(
  "logoutUser/logoutUser",
  async () => {
    await axios.post(`/api/user/logout`)
    localStorage.removeItem("userInfo")
    window.location.href = "/"
  }
)
