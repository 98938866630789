import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const orderApi = createApi({
   reducerPath: "orderApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["Order"],
   endpoints: (builder) => ({
      getOrder: builder.query({
         query: (userAccount) => `api/order?userAccount=${userAccount}`,
         providesTags: ["Order"],
      }),
      getDraftOrders: builder.query({
         query: (userAccount) => `api/order/draft?userAccount=${userAccount}`,
         providesTags: ["Order"],
      }),
      getDispatchOrders: builder.query({
         query: (userAccount) =>
            `api/order/dispatch?userAccount=${userAccount}`,
         providesTags: ["Order"],
      }),
      getDeliveryOrders: builder.query({
         query: (userAccount) =>
            `api/order/delivery?userAccount=${userAccount}`,
         providesTags: ["Order"],
      }),
      getDeliveredOrders: builder.query({
         query: (userAccount) =>
            `api/order/delivered?userAccount=${userAccount}`,
         providesTags: ["Order"],
      }),
      getFullfilledOrders: builder.query({
         query: (userAccount) =>
            `api/order/fullfilled?userAccount=${userAccount}`,
         providesTags: ["Order"],
      }),
      getRefundAndReturnOrders: builder.query({
         query: ({ userAccount, status }) =>
            `api/order/returnAndrefund?userAccount=${userAccount}&status=${status}`,
         providesTags: ["Order"],
      }),
      getCanceledOrders: builder.query({
         query: (userAccount) =>
            `api/order/canceled?userAccount=${userAccount}`,
         providesTags: ["Order"],
      }),
      getOrderById: builder.query({
         query: (id) => `api/order/${id}`,
         providesTags: ["Order"],
      }),
      getMyOrders: builder.query({
         query: (user) =>
            `api/order/myorders?email=${user?.email}&phone=${user?.phone}`,
         providesTags: ["Order"],
      }),
      getcustomerOrders: builder.query({
         query: (phone) => `api/order/customerOrders?phone=${phone}`,
         providesTags: ["Order"],
      }),
      getMyPurchaseOrders: builder.query({
         query: (user) =>
            `api/order/purchase-orders?email=${user?.email}&phone=${user?.phone}`,
         providesTags: ["Order"],
      }),
      createOrder: builder.mutation({
         query: (value) => ({
            url: `api/order`,
            method: "POST",
            body: value,
         }),
         invalidatesTags: ["Order"],
      }),
      updateOrder: builder.mutation({
         query: ({ id, ...values }) => ({
            url: `api/order/update/${id}`,
            method: "PATCH",
            body: values,
         }),
         invalidatesTags: ["Order"],
      }),
      updateOrderToDelivered: builder.mutation({
         query: ({ id, ...values }) => ({
            url: `api/order/${id}/deliver`,
            method: "PATCH",
            body: values,
         }),
         invalidatesTags: ["Order"],
      }),
      updateOrderToDispatch: builder.mutation({
         query: ({ id, ...values }) => ({
            url: `api/order/${id}/dispatch`,
            method: "PATCH",
            body: values,
         }),
         invalidatesTags: ["Order"],
      }),
      deleteOrder: builder.mutation({
         query: (id) => ({
            url: `api/order/delete/${id}`,
            method: "DELETE",
         }),
         invalidatesTags: ["Order"],
      }),
      orderConfirmation: builder.mutation({
         query: ({ id, ...values }) => ({
            url: `api/order/order-confirm/${id}`,
            method: "PATCH",
            body: values,
         }),
         invalidatesTags: ["Order"],
      }),
   }),
})

export const {
   useGetOrderQuery,
   useGetOrderByIdQuery,
   useCreateOrderMutation,
   useUpdateOrderMutation,
   useDeleteOrderMutation,
   useUpdateOrderToDispatchMutation,
   useUpdateOrderToDeliveredMutation,
   useOrderConfirmationMutation,
   useGetMyPurchaseOrdersQuery,
   useGetMyOrdersQuery,
   useGetDispatchOrdersQuery,
   useGetDraftOrdersQuery,
   useGetDeliveryOrdersQuery,
   useGetDeliveredOrdersQuery,
   useGetFullfilledOrdersQuery,
   useGetRefundAndReturnOrdersQuery,
   useGetCanceledOrdersQuery,
   useGetcustomerOrdersQuery,
} = orderApi
