import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const booksApi = createApi({
   reducerPath: "booksApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["booksApi"],
   endpoints: (builder) => ({
      getBooks: builder.query({
         query: () => `api/general/all`,
         providesTags: ["booksApi"],
      }),
      getBook: builder.query({
         query: (id) => `api/general/one/${id}`,
         providesTags: ["booksApi"],
      }),
   }),
})

export const { useGetBooksQuery,useGetBookQuery } = booksApi
