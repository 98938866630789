import React from "react"
import UpdateUser from "../../Action/UpdateUser"
import UserSidebar from "../UserSidebar"

const UserVouchers = () => {
   return (
      <div className="grid grid-cols-8 justify-between ">
         <div className="col-span-7 bg-white dark:bg-dark min-h-[90vh] m-8 rounded-lg shadow">
            <div className="relative">
               <div className="flex gap-5 w-12/12 m-auto ">
                  <img
                     src="https://png.pngtree.com/background/20210709/original/pngtree-golden-gift-background-ball-picture-image_326502.jpg"
                     alt="user"
                     className="w-full rounded-lg"
                  />
               </div>
               <div className="w-8/12 py-12 absolute top-28 left-[17%]  text-center">
                  <h3 className="text-2xl font-semibold text-secondary">
                     Total $220
                  </h3>
               </div>
               <div className="w-8/12 py-12 absolute -bottom-24 left-[17%] border bg-white dark:bg-dark dark:text-white shadow  rounded-lg p-5">
                  <h3 className="text-lg font-semibold mb-3">
                     Add a new Voucher
                  </h3>
                  <input
                     type="number"
                     className="w-8/12 py-3 outline-none rounded-md px-2 border border-orange-400 dark:text-dark"
                  />
                  <button className="w-4/12 bg-secondary py-3 text-center rounded-md text-white">
                     Add Voucher
                  </button>
               </div>
            </div>
            <UpdateUser></UpdateUser>
         </div>
         <div className="col-span-1">
            <UserSidebar></UserSidebar>
         </div>
      </div>
   )
}

export default UserVouchers
