import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const reviewAPi = createApi({
   reducerPath: "reviewAPi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["Review"],
   endpoints: (builder) => ({
      getReview: builder.query({
         query: () => "api/review-varify/all",
         providesTags: ["Review"],
      }),
      getUserReviews: builder.query({
         query: (id) => `api/review-varify/${id}`,
         providesTags: ["Review"],
      }),
      approveReview: builder.mutation({
         query: ({ id, ...values }) => ({
            url: `api/review-varify/${id}`,
            method: "PATCH",
            body: values,
         }),
         invalidatesTags: ["Review"],
      }),
      addTopTenReview: builder.mutation({
         query: ({ productId, reviewId, topTen }) => ({
             url: `api/review-varify/products/${productId}/reviews/${reviewId}/topten`,
             method: "PATCH",
             body: { topTen },
         }),
         invalidatesTags: ["Review"],
     }),
      deleteReview: builder.mutation({
         query: ({ id, ...values }) => ({
            url: `api/review-varify/${id}`,
            method: "DELETE",
            body: values,
         }),
         invalidatesTags: ["Review"],
      }),
   }),
})

export const {
   useGetReviewQuery,
   useAddTopTenReviewMutation,
   useApproveReviewMutation,
   useDeleteReviewMutation,
   useGetUserReviewsQuery,
} = reviewAPi
