import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { searchApi } from "./Search"
import { bannerApi } from "./banner"
import { newsletterApi } from "./newsletter"
import { topBannerApi } from "./topBanner"
import { shoppingCardReducer } from "./shoppingCardSlice"
import { sidebarReducer } from "./sidebarSlice"
import { cartReducer } from "./cart"
import { promoReducer } from "./promo"
import createUserReducer from "./auth/createUserSlice"
import loginUserReducer from "./auth/loginUserSlice"
import { authApi } from "./auth/auth"
import { reviewAPi } from "./reviewVarify"
import { orderApi } from "./order"
import { academicApi } from "./academic"
import { allbooksApi } from "./allBooks"
import { authorApi } from "./authors"
import { publicationsApi } from "./publications"
import { searchItemsApi } from "./searchItems"
import { academicsliderApi } from "./academicslider"
import { booksApi } from "./books"
import { todaysDealApi } from "./todaysDeal"
import { trendingApi } from "./trending"
import { bookfairApi } from "./bookfair"
import { employeeApi } from "./employee"
import { customerApi } from "./customer"
import { categoryAPI } from "./category"
//new

import cartListReducer from "./Features/carts/getCartsSlice"
import addToCartReducer from "./Features/carts/addToCartSlice"
import registerUserReducer from "./Features/users/createUserSlice"
import userListReducer from "./Features/users/getUsersSlice"
import userDetailsReducer from "./Features/users/getSingleUserSlice"
import darkModeReducer from "./Features/darkModeSlice"
import { OrderProductReducer } from "./order/orderProductSlice"
import { ReturnProductReducer } from "./order/returnProduct"
import productSearchReducer from "./Features/productSearchSlice"
import employeeReducer from "./Features/employeeSlice"
import customerReducer from "./Features/customerSlice"
import barcodeReducer from "./Features/barcode"
import { academicSchoolApi } from "./academicSchool"
import { stationaryApi } from "./stationary"
import { shippingApi } from "./shipping"
import paymentReducer from "./Features/paymentSlice"
import { subWriterApi } from "./sub-writer"
import { brandApi } from "./brand"
import { electronicsApi } from "./electronics"
import { userApi } from "./user"

export const store = configureStore({
   reducer: {
      login: loginUserReducer,
      register: createUserReducer,
      shoppingCard: shoppingCardReducer,
      sidebar: sidebarReducer,
      cart: cartReducer,
      promo: promoReducer,
      //dark
      darkMode: darkModeReducer,
      // cart
      cartList: cartListReducer,
      addToCart: addToCartReducer,
      // user
      userList: userListReducer,
      registerUser: registerUserReducer,
      userDetails: userDetailsReducer,
      // search order product
      orderProduct: OrderProductReducer,
      returnProduct: ReturnProductReducer,
      // barcode search code
      productSearch: productSearchReducer,
      // employee permission
      employeeAction: employeeReducer,
      customer: customerReducer,
      barcode: barcodeReducer,
      payment: paymentReducer,
      // new end
      [searchApi.reducerPath]: searchApi.reducer,
      [topBannerApi.reducerPath]: topBannerApi.reducer,
      [bannerApi.reducerPath]: bannerApi.reducer,
      [newsletterApi.reducerPath]: newsletterApi.reducer,
      [authApi.reducerPath]: authApi.reducer,
      [orderApi.reducerPath]: orderApi.reducer,
      [reviewAPi.reducerPath]: reviewAPi.reducer,
      [academicApi.reducerPath]: academicApi.reducer,
      [authorApi.reducerPath]: authorApi.reducer,
      [subWriterApi.reducerPath]: subWriterApi.reducer,
      [allbooksApi.reducerPath]: allbooksApi.reducer,
      [publicationsApi.reducerPath]: publicationsApi.reducer,
      [searchItemsApi.reducerPath]: searchItemsApi.reducer,
      [academicsliderApi.reducerPath]: academicsliderApi.reducer,
      [booksApi.reducerPath]: booksApi.reducer,
      [todaysDealApi.reducerPath]: todaysDealApi.reducer,
      [trendingApi.reducerPath]: trendingApi.reducer,
      [bookfairApi.reducerPath]: bookfairApi.reducer,
      [employeeApi.reducerPath]: employeeApi.reducer,
      [customerApi.reducerPath]: customerApi.reducer,
      [categoryAPI.reducerPath]: categoryAPI.reducer,
      [academicSchoolApi.reducerPath]: academicSchoolApi.reducer,
      [stationaryApi.reducerPath]: stationaryApi.reducer,
      [shippingApi.reducerPath]: shippingApi.reducer,
      [brandApi.reducerPath]: brandApi.reducer,
      [electronicsApi.reducerPath]: electronicsApi.reducer,
      [userApi.reducerPath]: userApi.reducer,
   },
   middleware: (gDM) =>
      gDM().concat([
         searchApi.middleware,
         bannerApi.middleware,
         topBannerApi.middleware,
         newsletterApi.middleware,
         authApi.middleware,
         orderApi.middleware,
         reviewAPi.middleware,
         academicApi.middleware,
         authorApi.middleware,
         subWriterApi.middleware,
         allbooksApi.middleware,
         publicationsApi.middleware,
         searchItemsApi.middleware,
         academicsliderApi.middleware,
         booksApi.middleware,
         todaysDealApi.middleware,
         trendingApi.middleware,
         bookfairApi.middleware,
         employeeApi.middleware,
         customerApi.middleware,
         categoryAPI.middleware,
         academicSchoolApi.middleware,
         stationaryApi.middleware,
         shippingApi.middleware,
         brandApi.middleware,
         electronicsApi.middleware,
         userApi.middleware,
      ]),
})

setupListeners(store.dispatch)
