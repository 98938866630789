import React from "react"
import { useState } from "react"
import { createContext } from "react"

export const DashboardContext = createContext()
const DashboardProvider = ({ children }) => {
   const [sidebarOpen, setSidebarOpen] = useState(true)
   const [showSidebar, setShowSidebar] = useState(false)
   const [showDrawer, setShowDrawer] = useState(false)
   const [sidebarContent, setSidebarContent] = useState("")
   const [scanningMode, setScanningMode] = useState(false)
   const [responsiveSidebar, setResponsiveSidebar] = useState(true)
   const [employeeInfo, setEmployeeInfo] = useState([])

   const value = {
      sidebarOpen,
      setSidebarOpen,
      showSidebar,
      setShowSidebar,
      showDrawer,
      setShowDrawer,
      sidebarContent,
      setSidebarContent,
      scanningMode,
      setScanningMode,
      responsiveSidebar,
      setResponsiveSidebar,
      employeeInfo,
      setEmployeeInfo,
   }

   return (
      <div>
         <DashboardContext.Provider value={value}>
            {children}
         </DashboardContext.Provider>
      </div>
   )
}

export default DashboardProvider
