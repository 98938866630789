import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const topBannerApi = createApi({
   reducerPath: "topBannerApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["TopBanner"],
   endpoints: (builder) => ({
      getTopBanner: builder.query({
         query: () => "api/top-banner-image",
         providesTags: ["TopBanner"],
      }),
      createTopBanner: builder.mutation({
         query: (data) => ({
            url: `api/top-banner-image`,
            method: "POST",
            body: data,
         }),
         invalidatesTags: ["TopBanner"],
      }),
      updateTopBanner: builder.mutation({
         query: ({ id, ...data }) => ({
            url: `api/top-banner-image/${id}`,
            method: "PATCH",
            body: data,
         }),
         invalidatesTags: ["TopBanner"],
      }),
      deleteTopBanner: builder.mutation({
         query: (id) => ({
            url: `api/top-banner-image/${id}`,
            method: "DELETE",
         }),
         invalidatesTags: ["TopBanner"],
      }),
   }),
})

export const {
   useGetTopBannerQuery,
   useCreateTopBannerMutation,
   useUpdateTopBannerMutation,
   useDeleteTopBannerMutation,
} = topBannerApi
