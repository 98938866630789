import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import { useGetOrderQuery } from "../../../services/order"
import moment from "moment-timezone"
import { DashboardContext } from "../../../context/DashboardProvider"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "react-toastify/dist/ReactToastify.css"
import {
   addProducts,
   clearProduct,
} from "../../../services/order/orderProductSlice"
import { useDispatch } from "react-redux"
import CreateOrderButton from "../../../components/CreateOrderButton"
import AgGridTable from "../../../components/AgGridTable"
import { getOrderInfo, userAccount } from "../../../utils/utils"
import PrintPackagingSlip from "../../../components/PrintPackging/PrintPackagingSlip"
import { Exportfile } from "../../../utils/Exportfile"
import { packagingCost } from "../../../utils/const-info"

const AllOrders = () => {
   const [dateRange, setDateRange] = useState([])
   const [orderAt, setOrderAt] = useState("createdAt")
   const [startDate, endDate] = dateRange
   const { data: result } = useGetOrderQuery(userAccount)
   const [csData, setCsData] = useState([])

   const fOrder = result?.filter((order) => {
      const orderDate =
         order?.[`${orderAt}`] &&
         moment.utc(order?.[`${orderAt}`]).utcOffset("+06:00")
      const startDateInfo = startDate && moment(startDate)
      const endDateInfo = endDate && moment(endDate)

      const isOrder = order?.draftOrder === false

      if (isOrder) {
         if (!startDate && !endDate) {
            return true
         } else if (
            orderDate &&
            startDateInfo &&
            endDateInfo &&
            orderDate.isSameOrAfter(startDateInfo.startOf("day")) &&
            orderDate.isSameOrBefore(endDateInfo.endOf("day"))
         ) {
            return true
         }
      }

      return false
   })

   const {
      approvedData,
      dispatchedData,
      deliveryProgress,
      deliveredData,
      fullfilledData,
      canceledData,
      orderHoldData,
      dispatchHoldData,
      returnedData,
      refundedData,
      refundedReturnedData,
      returnProgressData,
      refundProgressData,
      refundReturnProgressData,
   } = getOrderInfo(fOrder, "dispatched")

   // Get selected date order for excel document
   async function findOrderByDate() {
      let tempEvents = []
      fOrder?.forEach((element) => {
         const barcode = element?.orderedProducts?.map((item) => item?.barcode)
         const redata = {
            "Order Id": element?.orderId,
            "Order Status": element?.orderStatus,
            Customer: element?.customer?.contactInformation?.name || "N/A",
            Mobile: element?.customer?.contactInformation?.phone || "N/A",
            Address: element?.customer?.shippingAddress?.address || "N/A",
            "Product Barcode": `${[...barcode]}`,
            "Buying price":
               element?.orderStatus === "return in progress" ||
               element?.orderStatus === "returned" ||
               element?.orderStatus === "canceled"
                  ? 0
                  : element?.actualBuyingPrice,
            Subtotal:
               element?.orderStatus === "return in progress" ||
               element?.orderStatus === "returned" ||
               element?.orderStatus === "canceled"
                  ? 0
                  : element?.subTotal,
            "Additional Discount":
               element?.orderStatus === "return in progress" ||
               element?.orderStatus === "returned" ||
               element?.orderStatus === "canceled"
                  ? 0
                  : element?.paymentDiscount?.value || 0,
            "After Additional Discount":
               element?.orderStatus === "return in progress" ||
               element?.orderStatus === "returned" ||
               element?.orderStatus === "canceled"
                  ? 0
                  : element?.subTotal - element?.paymentDiscount?.value || 0,
            "Delivery Cost":
               element?.orderStatus === "canceled"
                  ? 0
                  : element?.orderStatus === "return in progress" ||
                    element?.orderStatus === "returned"
                  ? element?.returned?.returnOptions?.shippingCost
                  : element?.actualShippingCost,
            "Total Cost":
               element?.orderStatus === "canceled"
                  ? 0
                  : element?.orderStatus === "return in progress" ||
                    element?.orderStatus === "returned"
                  ? element?.returned?.returnOptions?.shippingCost +
                    Number(packagingCost)
                  : element?.totalCost,
            "Sell Price":
               element?.orderStatus === "return in progress" ||
               element?.orderStatus === "returned" ||
               element?.orderStatus === "canceled"
                  ? 0
                  : element?.totalPrice,
            Balance:
               element?.orderStatus === "canceled"
                  ? 0
                  : element?.orderStatus === "return in progress" ||
                    element?.orderStatus === "returned"
                  ? -Math.abs(
                       element?.returned?.returnOptions?.shippingCost +
                          Number(packagingCost)
                    )
                  : element?.balance,
            "Order Taken By": element?.orderTakenBy,
            "Shipping By": element?.paymentShipping?.shipping_carrier || "N/A",
         }

         tempEvents.push(redata)
         setCsData(tempEvents)
      })
   }

   const { employeeInfo } = useContext(DashboardContext)
   const permission = employeeInfo?.find((data) => data?.permission)
   const dispatch = useDispatch()
   const navigate = useNavigate()

   // edit order button
   const handleEditOrder = (product) => {
      dispatch(clearProduct())
      dispatch(addProducts(product?.orderedProducts))

      navigate(`/update/${product?._id}`, {
         state: {
            route: "update",
         },
      })
   }

   const columnDef = [
      {
         headerName: "ORDER ID",
         field: "orderId",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/order/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params?.data?.orderId}
               </Link>
            )
         },
      },
      {
         headerName: "ORDER DATE",
         field: "date",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/order/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {moment
                     .utc(params.data.createdAt)
                     .tz("Asia/Dhaka")
                     .format("DD-MM-YYYY")}
                  {/* {moment
                     .utc(params.data.createdAt)
                     .utcOffset("+06:00")
                     .format("DD-MM-YYYY")} */}
               </Link>
            )
         },
      },
      {
         headerName: "ORDER STATUS",
         field: "orderStatus",
         cellRenderer: function (params) {
            return (
               <div
                  onClick={() => navigate(`/order/${params?.data?._id}`)}
                  className="cursor-pointer px-4"
               >
                  <span
                     className={`px-4 py-1 text-white !no-underline text-center rounded-full shadow-md capitalize text-sm font-medium
                  ${
                     params.data?.orderStatus === "In progress"
                        ? "bg-gray-100 text-gray-700 hover:text-gray-700"
                        : params.data?.orderStatus === "approved"
                        ? "bg-[#039487] "
                        : params.data?.orderStatus === "dispatched"
                        ? "bg-[#D2F99C] text-[#5e8032] "
                        : params.data?.orderStatus === "delivery in progress"
                        ? "bg-[#B3E1F7] text-[#4b7b8f]"
                        : params.data?.orderStatus === "delivered"
                        ? "bg-[#78FAC6] text-[#348a67]"
                        : params.data?.orderStatus === "fullfilled"
                        ? "bg-[#94d252] text-[#3c8d42]"
                        : params.data?.orderStatus === "canceled"
                        ? "bg-[#F57786] text-[#753038] "
                        : params.data?.orderStatus === "order on hold"
                        ? "bg-[#FAF781] text-orange-900 hover:text-orange-900"
                        : params.data?.orderStatus === "dispatch on hold"
                        ? "bg-[#DFB3F7] text-[#623c77]"
                        : params.data?.orderStatus === "returned"
                        ? "bg-[#D15865] text-[#6e2c32]"
                        : params.data?.orderStatus === "refunded"
                        ? "bg-[#FE4D4D] text-[#923a3a]"
                        : params.data?.orderStatus === "refunded & returned"
                        ? "bg-[#FA4848] text-[#9c3737]"
                        : params.data?.orderStatus === "return in progress"
                        ? "bg-[#D1ADB1] text-[#6e4247]"
                        : params.data?.orderStatus === "refund in progress"
                        ? "bg-[#FED9D9] text-[#a04545]"
                        : params.data?.orderStatus ===
                          "refund & return in progress"
                        ? "bg-[#F98888] text-[#9b3434]"
                        : "bg-gray-100 text-gray-900 hover:text-gray-900"
                  }`}
                  >
                     {params.data?.orderStatus || "-"}
                  </span>
               </div>
            )
         },
      },
      {
         headerName: "CUSTOMER",
         field: "customer.contactInformation.name",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/order/${params?.data?._id}`}
                  className="px-4 block !no-underline capitalize"
               >
                  {params.data?.customer?.contactInformation?.name}
               </Link>
            )
         },
      },
      {
         headerName: "CUSTOMER PHONE",
         field: "customer.shippingAddress.phone",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/order/${params?.data?._id}`}
                  className="px-4 block !no-underline capitalize"
               >
                  {params.data?.customer?.shippingAddress.phone}
               </Link>
            )
         },
      },
      {
         headerName: "ORDER TAKEN BY",
         field: "orderTakenBy",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/order/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.orderTakenBy || "Website"}
               </Link>
            )
         },
      },
      {
         headerName: "TOTAL",
         field: "totalPrice",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/order/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.totalPrice || "-"}
               </Link>
            )
         },
      },
      {
         headerName: "PAYMENT STATUS",
         field: "isPaid",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/order/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.isPaid === true ? "Paid" : "Not paid"}
               </Link>
            )
         },
      },
      {
         headerName: "ORDER TYPE",
         field: "orderType",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/order/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.orderType || "-"}
               </Link>
            )
         },
      },

      {
         headerName: "PAYMENT METHOD",
         field: "paymentMethod",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/order/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.paymentMethod}
               </Link>
            )
         },
      },
      {
         headerName: "SHIPPING BY",
         field: "paymentShipping.shipping_carrier",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/order/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.paymentShipping?.shipping_carrier || "-"}
               </Link>
            )
         },
      },
      {
         headerName: "SHIPPING TYPE",
         field: "paymentShipping.shipping_type",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/order/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.paymentShipping?.shipping_type} (
                  {params.data?.paymentShipping?.shipping_cost})
               </Link>
            )
         },
      },
      {
         headerName: "ACTION",
         field: "_id",
         cellRenderer: (params) => (
            <div className="text-[15px] z-50 px-4">
               <ul tabIndex={0} className="flex  ">
                  {permission && permission?.permission !== "View Only" ? (
                     <>
                        <li>
                           <PrintPackagingSlip data={params.data} icon={true} />
                        </li>
                        {params?.data?.paymentMethod === "cash on delivery" &&
                           params?.data?.orderStatus !== "fullfilled" && (
                              <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50">
                                 <button
                                    onClick={() =>
                                       handleEditOrder(params?.data)
                                    }
                                    className="flex items-center gap-2 text-[#4F5366] text-sm"
                                 >
                                    <svg
                                       xmlns="http://www.w3.org/2000/svg"
                                       className="h-5 w-5 cursor-pointer text-green-600"
                                       fill="none"
                                       viewBox="0 0 24 24"
                                       stroke="currentColor"
                                       strokeWidth="2"
                                    >
                                       <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                       ></path>
                                    </svg>
                                 </button>
                              </li>
                           )}
                     </>
                  ) : (
                     "N/A"
                  )}
               </ul>
            </div>
         ),
      },
   ]

   return (
      <section className="p-2 mb-5 m-2 sm:m-5 ">
         <div className="flex flex-col sm:flex-row gap-3 justify-between items-center py-5 dark:text-white">
            <h3 className="font-semibold text-xl">All Orders</h3>
            <div className="space-x-2 flex gap-1">
               <select
                  onChange={(e) => {
                     setOrderAt(e.target.value)
                     setDateRange([])
                  }}
                  className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 outline-none cursor-pointer hover:shadow-md duration-200"
               >
                  <option value="createdAt">All Orders</option>
                  <option value="createdAt">Order Created</option>
                  <option value="approvedAt">Approved</option>
                  <option value="orderOnHoldAt">Order On Hold</option>
                  <option value="dispatchedAt">Dispatched</option>
                  <option value="dispatchOnHoldAt">Dispatch On Hold</option>
                  <option value="deliveryInProgressAt">
                     Delivery In Progress
                  </option>
                  <option value="deliveredAt">Delivered</option>
                  <option value="fullfilledAt">Fullfilled</option>
                  <option value="canceledAt">Canceled</option>
                  <option value="returnedAt">Returned</option>
                  <option value="refundedAt">Refunded</option>
                  <option value="refundedReturnedAt">
                     Refunded & Returned
                  </option>
                  <option value="returnInProgressAt">Return In Progress</option>
                  <option value="refundInProgressAt">
                     Rrefund In Progress
                  </option>
                  <option value="refundReturnInProgressAt">
                     Refund & Return In Progress
                  </option>
               </select>
               <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                     setDateRange(update)
                  }}
                  onCalendarClose={() => findOrderByDate()}
                  isClearable={true}
                  className="date-picker  shadow-md hover:shadow-lg"
                  placeholderText="Select Order Date"
                  dateFormat={"dd/MM/yyyy"}
                  timeIntervals={15}
                  utcOffset="+06:00" // Set the UTC offset to +6:00 for Bangladesh timezone
                  utc={true}
               />
               {permission && permission?.permission !== "View Only" && (
                  <>
                     <Exportfile csvData={csData} fileName="orders" />
                     <CreateOrderButton />
                  </>
               )}
            </div>
         </div>
         <div className="w-full border rounded-lg p-5 bg-white dark:bg-dark dark:text-white ">
            <div className="grid grid-cols-2 md:grid-cols-4 items-center gap-5 pb-3 ">
               <h3 className="text-sm sm:text-sm shadow-md border border-gray-100 px-5 py-2 sm:py-3 rounded-lg gap-5 text-[#9191A1]">
                  Total Orders: {fOrder?.length}
               </h3>
               <h3 className="text-sm sm:text-sm shadow-md border border-gray-100 px-5 py-2 sm:py-3 rounded-lg gap-5 text-[#9191A1]">
                  approved: {approvedData?.length}
               </h3>
               <h3 className="text-sm sm:text-sm shadow-md border border-gray-100 px-5 py-2 sm:py-3 rounded-lg gap-5 text-[#9191A1]">
                  Dispatched: {dispatchedData?.length}
               </h3>
               <h3 className="text-sm sm:text-sm shadow-md border border-gray-100 px-5 py-2 sm:py-3 rounded-lg gap-5 text-[#9191A1]">
                  Delivery In Progress: {deliveryProgress?.length}
               </h3>
               <h3 className="text-sm sm:text-sm shadow-md border border-gray-100 px-5 py-2 sm:py-3 rounded-lg gap-5 text-[#9191A1]">
                  Delivered: {deliveredData?.length}
               </h3>
               <h3 className="text-sm sm:text-sm shadow-md border border-gray-100 px-5 py-2 sm:py-3 rounded-lg gap-5 text-[#9191A1]">
                  Fullfilled: {fullfilledData?.length}
               </h3>
               <h3 className="text-sm sm:text-sm shadow-md border border-gray-100 px-5 py-2 sm:py-3 rounded-lg gap-5 text-[#9191A1]">
                  Canceled: {canceledData?.length}
               </h3>
               <h3 className="text-sm sm:text-sm shadow-md border border-gray-100 px-5 py-2 sm:py-3 rounded-lg gap-5 text-[#9191A1]">
                  Order On Hold: {orderHoldData?.length}
               </h3>
               <h3 className="text-sm sm:text-sm shadow-md border border-gray-100 px-5 py-2 sm:py-3 rounded-lg gap-5 text-[#9191A1]">
                  Dispatch On Hold: {dispatchHoldData?.length}
               </h3>
               <h3 className="text-sm sm:text-sm shadow-md border border-gray-100 px-5 py-2 sm:py-3 rounded-lg gap-5 text-[#9191A1]">
                  refunded: {refundedData?.length}
               </h3>
               <h3 className="text-sm sm:text-sm shadow-md border border-gray-100 px-5 py-2 sm:py-3 rounded-lg gap-5 text-[#9191A1]">
                  Returned: {returnedData?.length}
               </h3>
               <h3 className="text-sm sm:text-sm shadow-md border border-gray-100 px-5 py-2 sm:py-3 rounded-lg gap-5 text-[#9191A1]">
                  refunded & Returned: {refundedReturnedData?.length}
               </h3>
               <h3 className="text-sm sm:text-sm shadow-md border border-gray-100 px-5 py-2 sm:py-3 rounded-lg gap-5 text-[#9191A1]">
                  Return In Progress: {returnProgressData?.length}
               </h3>
               <h3 className="text-sm sm:text-sm shadow-md border border-gray-100 px-5 py-2 sm:py-3 rounded-lg gap-5 text-[#9191A1]">
                  Refund In Progress: {refundProgressData?.length}
               </h3>
               <h3 className="text-sm sm:text-sm shadow-md border border-gray-100 px-5 py-2 sm:py-3 rounded-lg gap-5 text-[#9191A1]">
                  Refund & Return Progress: {refundReturnProgressData?.length}
               </h3>
            </div>

            <div className="flex gap-10 mb-3"></div>
            {fOrder?.length === 0 ? (
               <div className="text-red-600 flex items-center justify-center  w-full h-[60vh]">
                  No Products Available!
               </div>
            ) : (
               <AgGridTable data={fOrder} columnDef={columnDef} height="70vh" />
            )}
         </div>
      </section>
   )
}

export default AllOrders
