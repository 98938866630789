import React from 'react';

const Tips = () => {
    return (
        <div>
            tips page create
        </div>
    );
};

export default Tips;