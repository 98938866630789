import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import { useSelector } from "react-redux"
import { useEditStationaryMutation } from "../../../../services/stationary"
import Barcode from "react-barcode"
import { v4 as uuid } from "uuid"
import toast from "react-hot-toast"
import { useGetOneStationaryQuery } from "../../../../services/stationary"
import UpdateBarcode from "../../../../components/Modal/UpdateBarcode"
import { ImCross } from "react-icons/im"
import Select from "react-select"
import { useGetAcademicSchoolsQuery } from "../../../../services/academicSchool"
import { useGetSubCategoryQuery } from "../../../../services/category"
import { SelectPicker } from "rsuite"

const StationariesItemUpdate = () => {
   // Define the initial state for the selected option
   const [openBarcodeModal, setOpenBarcodeModal] = useState(false)

   const { id } = useParams()
   const { userInfo } = useSelector((state) => state.login)
   const { data: product } = useGetOneStationaryQuery(id)
   const [editStationary] = useEditStationaryMutation(id)
   const [selectedClass, setSelectedClass] = useState([])
   const [selectedSchool, setSelectedSchool] = useState({})
   const [schools, setSchools] = useState("")
   const [classType, setClassType] = useState([])
   const [schoolClass, setSchoolClass] = useState([])
   const [schoolOptions, setSchoolOptions] = useState([])
   const [classTypes, setClassTypes] = useState([])
   const { data: academicSchools } = useGetAcademicSchoolsQuery()
   const { data: subCategories } = useGetSubCategoryQuery("stationary")
   const [subCategoryName, setSubCategoryName] = useState()

   const UUID = uuid()
   const small_id = UUID.slice(0, 6)
   const [orderType, setOrderType] = useState("")
   const [onlinestore, setonlinestore] = useState("")
   const [pointofsale, setpointofsale] = useState("")

   const subCategoryData = subCategories?.map((item) => ({
      label: item.subCategory,
      value: item.subCategory,
   }))

   const inputStyle =
      "px-2 shadow py-2.5 rounded-md duration-300 outline-none placeholder:font-thin text-sm dark:bg-gray-50 dark:text-dark placeholder:text-darkText"
   const labelStyle = "text-palette10 select-none"
   const boxStyle = "flex flex-col w-full border p-2 rounded"
   const textAreaStyle =
      "px-4 py-2 rounded-lg dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin h-[120px] text-sm dark:text-dark"

   const [tags1, setTags1] = useState([])
   const [tags2, setTags2] = useState([])

   let tagInput1
   let tagInput2

   const removeTag1 = (i) => {
      const newTags = [...tags1]
      newTags.splice(i, 1)

      setTags1(newTags)
   }
   const removeTag2 = (i) => {
      const newTags = [...tags2]
      newTags.splice(i, 1)
      setTags2(newTags)
   }
   const inputKeyDown1 = (e) => {
      const val = e.target.value
      if (e.key === "Enter" && val) {
         e.preventDefault()
         if (tags1.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
            return
         }
         setTags1([...tags1, val])
         tagInput1.value = null
      } else if (e.key === "Backspace" && !val) {
         removeTag1(tags1.length - 1)
      }
   }
   const inputKeyDown2 = (e) => {
      const val = e.target.value
      if (e.key === "Enter" && val) {
         e.preventDefault()
         if (tags2.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
            return
         }
         setTags2([...tags2, val])
         tagInput2.value = null
      } else if (e.key === "Backspace" && !val) {
         removeTag2(tags2.length - 1)
      }
   }

   const [files, setFiles] = useState([])

   const onChange = (e) => {
      setFiles(e.target.files)
   }
   const [uploadData, setUploadData] = useState()

   const onSubmit = async (event) => {
      event.preventDefault()
      const formData = new FormData()
      Object.values(files).forEach((file) => {
         formData.append("uploadImages", file)
      })

      try {
         if (files.length === 0)
            return alert("Please upload an image first the create a product!")
         await axios
            .post("/api/image-upload", formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            })
            .then((res) => {
               setUploadData(res.data)
               alert("Image uploaded")
            })
            .catch((err) => {
               alert(err?.response?.data?.message)
            })
      } catch (err) {
         if (err.response.status === 500) {
            console.log(err)
         } else {
            console.log(err.response.data.msg)
         }
      }
   }
   const [stationary, setstationary] = useState({
      category: product?.product?.category,
      type: product?.product?.type,
      title: product?.product?.title,
      brandDescription: product?.product?.brandDescription,
      brand: product?.product?.brand,
      price: product?.product?.price,
      description: product?.product?.description,
      trending: product?.product?.trending,
      todaysDeal: product?.product?.todaysDeal,
      popularNow: product?.product?.popularNow,
      countInStock: product?.product?.countInStock,
      discount: product?.product?.discount,
      gyankoshDiscount: product?.product?.gyankoshDiscount,
      status: Boolean,
      costPerItem: product?.product?.costPerItem,
   })

   const handleChange = (e) => {
      const { name, value } = e.target
      setstationary({
         ...stationary,
         [name]: value,
      })
   }

   const handleStationaries = async (e) => {
      e.preventDefault()
      try {
         const productObj = {
            category: stationary.category,
            subCategory: subCategoryName,
            type: stationary.type,
            title: stationary.title,

            images: uploadData,
            brandDescription: stationary.brandDescription,
            brandSpecification: {
               name: tags1,
               value: tags2,
            },
            brand: stationary.brand,
            schools: schoolClass,
            description: stationary.description,
            price: stationary.price,
            trending: stationary?.trending,
            todaysDeal: stationary?.todaysDeal,
            popularNow: stationary?.popularNow,
            countInStock: stationary.countInStock,
            discount: stationary.discount,
            gyankoshDiscount: stationary.gyankoshDiscount,
            status: stationary.status,
            pointofsale: pointofsale,
            onlinestore: onlinestore,
            orderType: orderType,
            costPerItem: stationary.costPerItem,
            slug: `${(stationary?.category || product?.product?.category)
               ?.trim()
               .replace(/\s+/g, "-")
               .toLowerCase()}-${(stationary?.type || product?.product?.type)
               ?.trim()
               .replace(/\s+/g, "-")
               .toLowerCase()}-${(stationary?.title || product?.product?.title)
               ?.trim()
               .replace(/\s+/g, "-")
               .toLowerCase()}-${(stationary?.brand || "")
               .trim()
               .replace(/\s+/g, "-")
               .toLowerCase()}-${product?.product?.barcode
               ?.trim()
               .replace(/\s+/g, "-")}`,
         }

         await axios
            .patch(
               `/api/stationary/${id}`,
               Object.fromEntries(
                  Object.entries(productObj).filter(([_, v]) => v)
               ),
               {
                  headers: {
                     "Content-Type": "application/json",
                     Authorization: `${userInfo.refreshToken}`,
                  },
               }
            )
            .then((response) => {
               alert(response?.data?.message)
               window.location.reload()
            })
      } catch (err) {
         if (err) {
            console.log(err)
         } else {
            alert(err.response.data.msg)
         }
      }
   }
   // CLOSE BARCODE SELECT MODAL
   const closeBarcodeModal = () => {
      setOpenBarcodeModal(false)
   }

   const classTypeHandle = (e) => {
      setSelectedClass(e)
      let typeData = e.map((data) => {
         return data?.value
      })
      setClassType(typeData)
   }
   const schoolsHandle = (e) => {
      setSelectedSchool(e)
      setSchools(e.value)
   }
   const submitSchoolAndClass = () => {
      const alreadyAdded = schoolClass?.find((data) => data?.school === schools)

      if (schools && classType?.length > 0 && !alreadyAdded?.school) {
         const data = {
            school: schools,
            class: classType,
         }
         setSchoolClass([...schoolClass, data])
      }

      if (alreadyAdded?.school) toast.error("already added")

      setSelectedClass(null)
      setSelectedSchool(null)
      setSchools(null)
      setClassType(null)
   }
   const removeFromSchoolAndClass = (data) => {
      setSchoolClass(
         schoolClass.filter((schooldata) => schooldata?.school !== data)
      )
   }

   useEffect(() => {
      const classData = academicSchools?.allClass?.map((item) => {
         return { value: item, label: item }
      })
      const schoolData = academicSchools?.schools?.map((item) => {
         return { value: item?.name, label: item?.name }
      })

      setSchoolOptions(schoolData)
      setClassTypes(classData)
   }, [academicSchools])

   useEffect(() => {
      setTags1(product?.product?.brandSpecification?.name)
      setTags2(product?.product?.brandSpecification?.value)
      setSubCategoryName(product?.product?.subCategory)
   }, [product])

   return (
      <div className="p-5 rounded-lg dark:text-white">
         <h3 className="text-2xl uppercase text-red-600 mb-5">
            Stationaries Update
         </h3>
         <div className="flex justify-between items-start">
            <div className={`${boxStyle} w-[300px] `}>
               <p className={labelStyle}>Ichchapuron ID</p>
               <p className="p-4 rounded-lg bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                  {product?.product?.barcode || "N/A"}
               </p>
            </div>
            <div className="flex gap-2 items-start">
               <Barcode
                  value={product?.product?.barcode}
                  height={50}
                  width={1.0}
               />
               {!product?.product?.barcode?.includes("IP") && (
                  <button
                     onClick={(e) => setOpenBarcodeModal(true)}
                     type="button"
                  >
                     <svg
                        width="21px"
                        height="21px"
                        viewBox="0 0 24 24"
                        stroke-width="2.7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        color="#a89f9f"
                     >
                        <path
                           d="M14.3632 5.65156L15.8431 4.17157C16.6242 3.39052 17.8905 3.39052 18.6716 4.17157L20.0858 5.58579C20.8668 6.36683 20.8668 7.63316 20.0858 8.41421L18.6058 9.8942M14.3632 5.65156L4.74749 15.2672C4.41542 15.5993 4.21079 16.0376 4.16947 16.5054L3.92738 19.2459C3.87261 19.8659 4.39148 20.3848 5.0115 20.33L7.75191 20.0879C8.21972 20.0466 8.65806 19.8419 8.99013 19.5099L18.6058 9.8942M14.3632 5.65156L18.6058 9.8942"
                           stroke="#a89f9f"
                           stroke-width="2.7"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                        ></path>
                     </svg>
                  </button>
               )}
               <UpdateBarcode
                  openBarcodeModal={openBarcodeModal}
                  handleCloseBarcodeModal={closeBarcodeModal}
                  product={product?.product}
               />
            </div>
         </div>
         <form onSubmit={handleStationaries}>
            <div className="flex justify-between gap-8 w-full">
               <div className="space-y-3 w-full">
                  <div className={boxStyle}>
                     <label htmlFor="productName" className={labelStyle}>
                        Product Name
                     </label>
                     <input
                        type="text"
                        name="title"
                        value={stationary.title}
                        onChange={handleChange}
                        placeholder={product?.product?.title}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="category" className={labelStyle}>
                        Category
                     </label>
                     <input
                        type="text"
                        name="category"
                        value={stationary.category}
                        onChange={handleChange}
                        placeholder={product?.product?.category}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="subCategory" className={labelStyle}>
                        Sub Category
                     </label>
                     <SelectPicker
                        value={subCategoryName}
                        onChange={(item) => setSubCategoryName(item)}
                        placeholder={product?.product?.subCategory}
                        className={`${inputStyle}, lowercase border-none `}
                        data={subCategoryData}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="brand" className={labelStyle}>
                        Brand
                     </label>
                     <input
                        type="text"
                        name="brand"
                        value={stationary.brand}
                        onChange={handleChange}
                        placeholder={product?.product?.brand}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="brandDescription" className={labelStyle}>
                        Brand Description
                     </label>
                     <textarea
                        type="text"
                        name="brandDescription"
                        value={stationary.brandDescription}
                        onChange={handleChange}
                        placeholder={product?.product?.brandDescription}
                        className={`${inputStyle}, lowercase`}
                     ></textarea>
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="type" className={labelStyle}>
                        Type
                     </label>
                     <input
                        type="text"
                        name="type"
                        value={stationary.type}
                        onChange={handleChange}
                        placeholder={product?.product?.type}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="price" className={labelStyle}>
                        Price
                     </label>
                     <input
                        type="text"
                        name="price"
                        value={stationary.price}
                        onChange={handleChange}
                        placeholder={product?.product?.price}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="countInStock" className={labelStyle}>
                        Count in Stock
                     </label>
                     <input
                        type="text"
                        name="countInStock"
                        value={stationary.countInStock}
                        onChange={handleChange}
                        placeholder={product?.product?.countInStock}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="discount" className={labelStyle}>
                        Discount
                     </label>
                     <input
                        type="text"
                        name="discount"
                        value={stationary.discount}
                        onChange={handleChange}
                        placeholder={product?.product?.discount}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="gyankoshDiscount" className={labelStyle}>
                        Gyankosh Discount
                     </label>
                     <input
                        type="text"
                        name="gyankoshDiscount"
                        value={stationary.gyankoshDiscount}
                        onChange={handleChange}
                        placeholder={product?.product?.gyankoshDiscount}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="costPerItem" className={labelStyle}>
                        Cost per item
                     </label>
                     <input
                        type="number"
                        name="costPerItem"
                        value={stationary.costPerItem}
                        onChange={handleChange}
                        placeholder={product?.product?.costPerItem}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="trending" className={labelStyle}>
                        Trending Now
                     </label>
                     <input
                        type="text"
                        name="trending"
                        placeholder={
                           product?.product?.trending === true
                              ? "true"
                              : "false"
                        }
                        value={stationary.trending}
                        onChange={handleChange}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="todaysDeal" className={labelStyle}>
                        Todays Deal
                     </label>

                     <input
                        type="text"
                        name="todaysDeal"
                        placeholder={
                           product?.product?.todaysDeal === true
                              ? "true"
                              : "false"
                        }
                        value={stationary.todaysDeal}
                        onChange={handleChange}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="popularNow" className={labelStyle}>
                        Popular Now
                     </label>
                     <input
                        type="text"
                        name="popularNow"
                        placeholder={
                           product?.product?.popularNow === true
                              ? "true"
                              : "false"
                        }
                        value={stationary.popularNow}
                        onChange={handleChange}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
               </div>
               {/* right side input box */}
               <div className="space-y-3 w-full">
                  <div className="bg-white dark:bg-dark p-5 rounded-lg w-full shadow">
                     <h3>Product Status</h3>
                     <select
                        name="status"
                        onClick={handleChange}
                        className={`${inputStyle}, cursor-pointer`}
                     >
                        <option value={true}>Active</option>
                        <option
                           selected={product?.product?.status !== true}
                           value={false}
                        >
                           Inactive
                        </option>
                     </select>
                     <div className="space-y-2 mt-4 border-t pt-3">
                        <h4 className="uppercase">Sales channels and apps</h4>
                        <div>
                           <input
                              type="checkbox"
                              id="onlinestore"
                              name="onlinestore"
                              value="onlinestore"
                              onChange={(e) => setonlinestore(e.target.value)}
                              className="mr-1"
                           />
                           <label
                              htmlFor="onlinestore"
                              name="onlinestore"
                              className={`${labelStyle} cursor-pointer`}
                           >
                              Online Store
                           </label>
                        </div>
                        <div>
                           <input
                              type="checkbox"
                              id="pointofsale"
                              name="pointofsale"
                              value="pointofsale"
                              onChange={(e) => setpointofsale(e.target.value)}
                              className="mr-1"
                           />
                           <label
                              htmlFor="pointofsale"
                              name="pointofsale"
                              className={`${labelStyle} cursor-pointer`}
                           >
                              Point Of Sale
                           </label>
                        </div>
                     </div>
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="specification" className={labelStyle}>
                        Brand Specification
                     </label>
                     <div className="flex items-center justify-start gap-5">
                        <div className="inputTag">
                           <ul className="inputList">
                              {tags1?.map((tag, i) => (
                                 <li
                                    key={i}
                                    className="my-2 flex items-center justify-between bg-white p-1 text-sm"
                                 >
                                    {tag}
                                    <p
                                       className="cursor-pointer bg-red-500 px-2 py-1 text-xl font-bold text-white"
                                       onClick={() => {
                                          removeTag1(i)
                                       }}
                                    >
                                       <p>x</p>
                                    </p>
                                 </li>
                              ))}
                              <li>
                                 <input
                                    type="text"
                                    placeholder="Specification eg:Color"
                                    className={`${inputStyle}, lowercase`}
                                    onKeyDown={inputKeyDown1}
                                    ref={(c) => {
                                       tagInput1 = c
                                    }}
                                 />
                              </li>
                           </ul>
                        </div>
                        <div>
                           <div className="inputTag">
                              <ul className="inputList">
                                 {tags2?.map((tag, i) => (
                                    <li
                                       key={i}
                                       className="my-2 flex items-center justify-between bg-white p-1 text-sm"
                                    >
                                       {tag}
                                       <p
                                          className="cursor-pointer bg-red-500 px-2 py-1 text-xl font-bold text-white"
                                          onClick={() => {
                                             removeTag2(i)
                                          }}
                                       >
                                          <p>x</p>
                                       </p>
                                    </li>
                                 ))}
                                 <li>
                                    <input
                                       type="text"
                                       placeholder="Value eg:Yellow"
                                       className={`${inputStyle}, lowercase`}
                                       onKeyDown={inputKeyDown2}
                                       ref={(c) => {
                                          tagInput2 = c
                                       }}
                                    />
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="productImage" className={labelStyle}>
                        Product Image
                     </label>
                     <input
                        type="file"
                        id="file"
                        name="uploadImages"
                        multiple
                        onChange={onChange}
                     />

                     <button onClick={onSubmit} className="CssBtn1">
                        <span className="text">
                           Select only jpg, png, and avif file!
                        </span>
                        <span className="mt-1.5">Add Product Image</span>
                     </button>
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="productDescription" className={labelStyle}>
                        Product Description
                     </label>
                     <textarea
                        type="text"
                        name="description"
                        value={stationary.description}
                        onChange={handleChange}
                        placeholder={product?.product?.description}
                        className={`${textAreaStyle}, lowercase`}
                     ></textarea>
                  </div>
                  <div className="bg-white dark:bg-dark p-5 rounded-lg w-full shadow">
                     <h3 className=" mb-4">Order Type</h3>
                     <label
                        htmlFor="current"
                        className="cursor-pointer text-sm mb-2 block"
                     >
                        <input
                           onChange={(e) => setOrderType(e.target.value)}
                           value={"current"}
                           type="radio"
                           id="current"
                           name="orderType"
                           className="mr-2"
                        />
                        <span>Current Order</span>
                     </label>
                     <label htmlFor="pre" className="cursor-pointer text-sm">
                        <input
                           onChange={(e) => setOrderType(e.target.value)}
                           value={"pre"}
                           type="radio"
                           id="pre"
                           name="orderType"
                           className="mr-2"
                        />
                        <span>Pre Order</span>
                     </label>
                  </div>
                  <div className="bg-gray-100">
                     <p className=" p-2 bg-green-100">
                        Preview of updated school and classes for this product
                     </p>
                     <div className={`${boxStyle} w-full h-full`}>
                        <p className={labelStyle}>Schools</p>
                        <p className=" w-full p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                           {product?.product?.schools?.length === 0
                              ? "n/a"
                              : product?.product?.schools?.map((x) => (
                                   <span key={x?.school}>{x?.school}</span>
                                ))}
                        </p>
                        <p className={labelStyle}>Class</p>
                        <p className=" w-full p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                           {product?.product?.schools?.length === 0
                              ? "n/a"
                              : product?.product?.schools?.map((x, i) => (
                                   <span
                                      key={x?.school}
                                      className="flex flex-col gap-1"
                                   >
                                      {x.class?.map((p) => (
                                         <span
                                            key={x?.school + p}
                                            className="text-sm"
                                         >
                                            {p}
                                         </span>
                                      ))}
                                   </span>
                                ))}
                        </p>
                     </div>
                  </div>
                  <div>
                     <ul className="rounded-lg  text-black">
                        {schoolClass?.map((data, index) => {
                           return (
                              <li
                                 key={index}
                                 className="border border-gray-400 bg-gray-100 flex divide-x rounded-lg"
                              >
                                 <div className="grid grid-cols-2 w-full divide-x divide-gray-400">
                                    <div className=" py-2 px-3">
                                       {data?.school}
                                    </div>
                                    <div className=" py-2 px-3">
                                       {data?.class?.map((c, index) =>
                                          index + 1 !== data?.class?.length
                                             ? `${c}, `
                                             : c
                                       )}
                                    </div>
                                 </div>
                                 <span
                                    onClick={() =>
                                       removeFromSchoolAndClass(data?.school)
                                    }
                                    className="cursor-pointer w-[24px] flex justify-center items-center "
                                 >
                                    <ImCross />
                                 </span>
                              </li>
                           )
                        })}
                     </ul>
                  </div>
                  <div className={boxStyle}>
                     <div className="flex">
                        <div className="grid grid-cols-2">
                           <div className="w-full text-gray-600 ring-0">
                              <p className={labelStyle}>School Names</p>
                              <Select
                                 options={schoolOptions}
                                 value={selectedSchool}
                                 onChange={schoolsHandle}
                                 placeholder={"school name"}
                                 isSearchable
                                 noOptionsMessage={() => "no school found"}
                                 styles={{
                                    placeholder: (baseStyles, state) => ({
                                       ...baseStyles,
                                       border: "none",
                                       width: "300px",
                                    }),
                                 }}
                              />
                           </div>
                           <div className="w-full text-gray-600 ring-0">
                              <p className={labelStyle}>Class Type</p>
                              <Select
                                 options={classTypes}
                                 value={selectedClass}
                                 onChange={classTypeHandle}
                                 placeholder={"Class Type"}
                                 isSearchable
                                 noOptionsMessage={() => "no class found"}
                                 isMulti
                                 styles={{
                                    placeholder: (baseStyles, state) => ({
                                       ...baseStyles,
                                       border: "none",
                                       width: "300px",
                                    }),
                                 }}
                              />
                           </div>
                        </div>
                        <div
                           onClick={() => submitSchoolAndClass()}
                           className="cursor-pointer w-[25px] flex justify-center items-center pt-4"
                        >
                           ✔️
                        </div>
                     </div>
                  </div>
                  <div>
                     <button
                        type="submit"
                        className="border border-black py-3 px-10 mt-5 text-black font-semibold hover:bg-[#363730] ease-in hover:text-white"
                     >
                        Submit
                     </button>
                  </div>
               </div>
            </div>
         </form>
      </div>
   )
}

export default StationariesItemUpdate
