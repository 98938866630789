import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { ImCross } from "react-icons/im"
import { useFormik } from "formik"
import axios from "axios"
import { useParams } from "react-router-dom"
import {
   useGetAcademicQuery,
   useEditAcademicMutation,
} from "../../../../services/academic"
import Barcode from "react-barcode"
import Select from "react-select"
import toast from "react-hot-toast"
import { Avatar, SelectPicker } from "rsuite"
import UpdateBarcode from "../../../../components/Modal/UpdateBarcode"
import { useGetAuthorQuery } from "../../../../services/authors"
import { useGetSubWriterQuery } from "../../../../services/sub-writer"
import { useGetAcademicSchoolsQuery } from "../../../../services/academicSchool"
import { useGetAllPublicationsQuery } from "../../../../services/publications"

const AcademicItemUpdate = () => {
   // Define the initial state for the selected option
   const [openBarcodeModal, setOpenBarcodeModal] = useState(false)
   const { id } = useParams()
   const { data: product } = useGetAcademicQuery(id)
   const [editAcademic, { isSuccess }] = useEditAcademicMutation(id)

   // const [status, setStatus] = useState( false )
   const [orderType, setOrderType] = useState("")
   const [onlinestore, setonlinestore] = useState("")
   const [pointofsale, setpointofsale] = useState("")
   const [selectedClass, setSelectedClass] = useState([])
   const [selectedSchool, setSelectedSchool] = useState({})
   const [schools, setSchools] = useState("")
   const [classType, setClassType] = useState([])
   const [schoolClass, setSchoolClass] = useState([])
   const [schoolOptions, setSchoolOptions] = useState([])
   const [classTypes, setClassTypes] = useState([])
   const { data: authors } = useGetAuthorQuery()
   const { data: subwriters } = useGetSubWriterQuery()
   const { data: publications } = useGetAllPublicationsQuery()
   const [authorName, setAuthorName] = useState()
   const [subWriterName, setSubWriterName] = useState()
   const [publicationName, setPublicationName] = useState()
   const { data: academicSchools } = useGetAcademicSchoolsQuery()

   const authorData = authors?.map((item) => ({
      label: item.name,
      value: item.name,
   }))

   const subWritersData = subwriters?.map((item) => ({
      label: item.name,
      value: item.name,
   }))

   const publicationsData = publications?.map((item) => ({
      label: item.name,
      value: item.name,
   }))

   const classTypeHandle = (e) => {
      setSelectedClass(e)
      let typeData = e.map((data) => {
         return data?.value
      })
      setClassType(typeData)
   }
   const schoolsHandle = (e) => {
      setSelectedSchool(e)
      setSchools(e.value)
   }
   const submitSchoolAndClass = () => {
      const alreadyAdded = schoolClass?.find((data) => data?.school === schools)

      if (schools && classType?.length > 0 && !alreadyAdded?.school) {
         const data = {
            school: schools,
            class: classType,
         }
         setSchoolClass([...schoolClass, data])
      }

      if (alreadyAdded?.school) toast.error("already added")

      setSelectedClass(null)
      setSelectedSchool(null)
      setSchools(null)
      setClassType(null)
   }
   const removeFromSchoolAndClass = (data) => {
      setSchoolClass(
         schoolClass.filter((schooldata) => schooldata?.school !== data)
      )
   }

   //image upload
   const [files, setFiles] = useState([])
   const [pdf, setPdf] = useState("")

   const onChange = (e) => {
      setFiles(e.target.files)
   }
   const onChangePdf = (e) => {
      setPdf(e.target.files[0])
   }

   const [uploadData, setUploadData] = useState()
   const [uploadPdf, setUploadPdf] = useState()

   const onSubmit = async (event) => {
      event.preventDefault()
      const formData = new FormData()
      Object.values(files).forEach((file) => {
         formData.append("uploadImages", file)
      })

      try {
         if (files.length === 0)
            return alert("Please upload an image first the create a product!")
         await axios
            .post("/api/image-upload", formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            })
            .then((res) => {
               setUploadData(res.data)
               alert("Image uploaded")
            })
            .catch((err) => {
               alert(err?.response?.data?.message)
            })
      } catch (err) {
         if (err.response.status === 500) {
            console.log(err)
         } else {
            console.log(err.response.data.msg)
         }
      }
   }
   const onSubmitPdf = async (event) => {
      event.preventDefault()
      const formData = new FormData()
      formData.append("uploadPdfs", pdf)

      try {
         if (!pdf)
            return alert("Please upload a pdf first then update this product!")
         await axios
            .post("/api/pdfs-upload", formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            })
            .then((res) => {
               setUploadPdf(res.data)
               alert("pdf uploaded")
            })
            .catch((err) => {
               alert(err?.response?.data?.message)
            })
      } catch (err) {
         if (err.response.status === 500) {
            console.log(err)
         } else {
            console.log(err.response.data.msg)
         }
      }
   }

   const academic = useFormik({
      initialValues: {
         title: "",
         price: "",
         description: "",
         publisher: "",
         published: "",
         isbn: "",
         edition: "",
         countInStock: "",
         discount: "",
         gyankoshDiscount: "",
         ebookDiscount: "",
         ebookPrice: "",
         pageNumber: "",
         costPerItem: "",
      },
      onSubmit: async (values, { resetForm }) => {
         const authorInfo = authors?.find((item) => item?.name === authorName)
         const subWriterInfo = subwriters?.find(
            (item) => item?.name === subWriterName
         )
         try {
            const productObj = {
               schools: schoolClass,
               title: values.title,
               author: authorName,
               authorDescription: authorInfo?.description,
               authorImage: authorInfo?.image,
               subWriter: subWriterName,
               subWriterImage: subWriterInfo?.image,
               subWriterDescription: subWriterInfo?.description,
               price: Number(values.price),
               images: uploadData,
               pdfLink: uploadPdf,
               description: values.description,
               publisher: publicationName,
               published: values.published,
               isbn: values.isbn,
               edition: values.edition,
               countInStock: Number(values.countInStock),
               discount: values.discount,
               gyankoshDiscount: values.gyankoshDiscount,
               ebookDiscount: Number(values.ebookDiscount),
               ebookPrice: Number(values.ebookPrice),
               status: values.status,
               pointofsale: pointofsale,
               onlinestore: onlinestore,
               orderType: orderType,
               pageNumber: Number(values.pageNumber),
               costPerItem: Number(values.costPerItem),
               trending: values.trending,
               todaysDeal: values.todaysDeal,
               bookfair: values.bookfair,
               popularNow: values.popularNow,
               slug: `${product?.product?.category
                  ?.trim()
                  .replace(/\s+/g, "-")
                  .toLowerCase()}-${(values.title || product?.product?.title)
                  ?.trim()
                  .replace(/\s+/g, "-")
                  .toLowerCase()}-${(
                  authorName || product?.product?.author?.name
               )
                  ?.trim()
                  .replace(/\s+/g, "-")
                  .toLowerCase()}-${product?.product?.barcode
                  ?.trim()
                  .replace(/\s+/g, "-")}`,
            }

            await editAcademic({
               id: id,
               ...Object.fromEntries(
                  Object.entries(productObj).filter(([_, v]) => v)
               ),
            })
            alert("Product was update successfully")
            window.location.reload()
         } catch (err) {
            toast.error("update failed")
         }
      },
   })

   const inputStyle =
      "px-2 shadow py-2.5 rounded-md duration-300 outline-none placeholder:font-thin text-sm dark:bg-gray-50 dark:text-dark placeholder:text-darkText"
   const textAreaStyle =
      "rounded-lg dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin h-[120px] text-sm dark:text-dark"
   const boxStyle = "flex flex-col w-full border p-2 rounded"
   const labelStyle = "text-palette10 select-none"

   useEffect(() => {
      if (isSuccess) {
         toast.success("successfully updated")
         return window.location.reload()
      }
   }, [isSuccess])
   // CLOSE BARCODE SELECT MODAL
   const closeBarcodeModal = () => {
      setOpenBarcodeModal(false)
   }

   useEffect(() => {
      const classData = academicSchools?.allClass?.map((item) => {
         return { value: item, label: item }
      })
      const schoolData = academicSchools?.schools?.map((item) => {
         return { value: item?.name, label: item?.name }
      })

      setSchoolOptions(schoolData)
      setClassTypes(classData)
   }, [academicSchools])

   return (
      <div className="px-10 my-5 rounded-lg dark:text-white space-y-5">
         <h3 className="text-xl capitalize text-palette10 bg-white shadow text-center py-4 select-none">
            Academic Books
         </h3>
         <div className="flex justify-between items-start">
            <div className={`${boxStyle} w-[300px] `}>
               <p className={labelStyle}>Ichchapuron ID</p>
               <p className="p-4 rounded-lg bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                  {product?.product?.barcode || "N/A"}
               </p>
            </div>
            <div className="flex gap-2 items-start">
               <Barcode
                  value={product?.product?.barcode}
                  height={50}
                  width={1.0}
               />
               {!product?.product?.barcode?.includes("IP") && (
                  <button
                     onClick={(e) => setOpenBarcodeModal(true)}
                     type="button"
                  >
                     <svg
                        width="21px"
                        height="21px"
                        viewBox="0 0 24 24"
                        stroke-width="2.7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        color="#a89f9f"
                     >
                        <path
                           d="M14.3632 5.65156L15.8431 4.17157C16.6242 3.39052 17.8905 3.39052 18.6716 4.17157L20.0858 5.58579C20.8668 6.36683 20.8668 7.63316 20.0858 8.41421L18.6058 9.8942M14.3632 5.65156L4.74749 15.2672C4.41542 15.5993 4.21079 16.0376 4.16947 16.5054L3.92738 19.2459C3.87261 19.8659 4.39148 20.3848 5.0115 20.33L7.75191 20.0879C8.21972 20.0466 8.65806 19.8419 8.99013 19.5099L18.6058 9.8942M14.3632 5.65156L18.6058 9.8942"
                           stroke="#a89f9f"
                           stroke-width="2.7"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                        ></path>
                     </svg>
                  </button>
               )}{" "}
               <UpdateBarcode
                  openBarcodeModal={openBarcodeModal}
                  handleCloseBarcodeModal={closeBarcodeModal}
                  product={product?.product}
               />
            </div>
         </div>
         <form onSubmit={academic.handleSubmit} className="mt-5">
            <div className="flex justify-between gap-8 w-full">
               <div className="space-y-3 w-full">
                  <div className={boxStyle}>
                     <label htmlFor="productName" className={labelStyle}>
                        Product Name
                     </label>
                     <input
                        type="text"
                        name="title"
                        value={academic.values.title}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.title}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.title && academic.errors.title && (
                        <small className="text-palette6 my-0.5">
                           {academic.errors.title}
                        </small>
                     )}
                  </div>{" "}
                  <div className="bg-gray-100">
                     <p className=" p-2 bg-green-100">
                        Preview of updated school and classes for this product
                     </p>
                     <div className={`${boxStyle} w-full h-full`}>
                        <p className={labelStyle}>Schools</p>
                        <p className=" w-full p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                           {product?.product?.schools?.length === 0
                              ? "n/a"
                              : product?.product?.schools?.map((x) => (
                                   <span key={x?.school}>{x?.school}</span>
                                ))}
                        </p>
                        <p className={labelStyle}>Class</p>
                        <p className=" w-full p-2 rounded-md bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                           {product?.product?.schools?.length === 0
                              ? "n/a"
                              : product?.product?.schools?.map((x, i) => (
                                   <span
                                      key={x?.school}
                                      className="flex flex-col gap-1"
                                   >
                                      {x.class?.map((p) => (
                                         <span
                                            key={x?.school + p}
                                            className="text-sm"
                                         >
                                            {p}
                                         </span>
                                      ))}
                                   </span>
                                ))}
                        </p>
                     </div>
                  </div>
                  <div>
                     <ul className="rounded-lg  text-black">
                        {schoolClass?.map((data, index) => {
                           return (
                              <li
                                 key={index}
                                 className="border border-gray-400 bg-gray-100 flex divide-x rounded-lg"
                              >
                                 <div className="grid grid-cols-2 w-full divide-x divide-gray-400">
                                    <div className=" py-2 px-3">
                                       {data?.school}
                                    </div>
                                    <div className=" py-2 px-3">
                                       {data?.class?.map((c, index) =>
                                          index + 1 !== data?.class?.length
                                             ? `${c}, `
                                             : c
                                       )}
                                    </div>
                                 </div>
                                 <span
                                    onClick={() =>
                                       removeFromSchoolAndClass(data?.school)
                                    }
                                    className="cursor-pointer w-[24px] flex justify-center items-center "
                                 >
                                    <ImCross />
                                 </span>
                              </li>
                           )
                        })}
                     </ul>
                  </div>
                  <div className={boxStyle}>
                     <div className="flex z-50">
                        <div className="grid grid-cols-2">
                           <div className="w-full text-gray-600 ring-0">
                              <p className={labelStyle}>School Names</p>
                              <Select
                                 options={schoolOptions}
                                 value={selectedSchool}
                                 onChange={schoolsHandle}
                                 placeholder={"school name"}
                                 isSearchable
                                 noOptionsMessage={() => "no school found"}
                                 styles={{
                                    placeholder: (baseStyles, state) => ({
                                       ...baseStyles,
                                       border: "none",
                                       width: "300px",
                                    }),
                                 }}
                              />
                           </div>
                           <div className="w-full text-gray-600 ring-0">
                              <p className={labelStyle}>Class Type</p>
                              <Select
                                 options={classTypes}
                                 value={selectedClass}
                                 onChange={classTypeHandle}
                                 placeholder={"Class Type"}
                                 isSearchable
                                 noOptionsMessage={() => "no class found"}
                                 isMulti
                                 styles={{
                                    placeholder: (baseStyles, state) => ({
                                       ...baseStyles,
                                       border: "none",
                                       width: "300px",
                                    }),
                                 }}
                              />
                           </div>
                        </div>
                        <div
                           onClick={() => submitSchoolAndClass()}
                           className="cursor-pointer w-[25px] flex justify-center items-center pt-4"
                        >
                           ✔️
                        </div>
                     </div>
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="price" className={labelStyle}>
                        Price
                     </label>
                     <input
                        type="text"
                        name="price"
                        value={academic.values.price}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.price}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.price && academic.errors.price && (
                        <small className="text-palette6 my-0.5">
                           {academic.errors.price}
                        </small>
                     )}
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="publisher" className={labelStyle}>
                        Publisher
                     </label>
                     <SelectPicker
                        value={publicationName}
                        onChange={(item) => setPublicationName(item)}
                        placeholder={product?.product?.publisher}
                        className={`my-2 lowercase`}
                        data={publicationsData}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="isbnNumber" className={labelStyle}>
                        ISBN Number
                     </label>
                     <input
                        type="text"
                        name="isbn"
                        value={academic.values.isbn}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.isbn}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.isbn && academic.errors.isbn && (
                        <small className="text-palette6 my-0.5">
                           {academic.errors.isbn}
                        </small>
                     )}
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="published" className={labelStyle}>
                        Published
                     </label>
                     <input
                        type="text"
                        name="published"
                        value={academic.values.published}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.published}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.published &&
                        academic.errors.published && (
                           <small className="text-palette6 my-0.5">
                              {academic.errors.published}
                           </small>
                        )}
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="edition" className={labelStyle}>
                        Edition
                     </label>
                     <input
                        type="text"
                        name="edition"
                        value={academic.values.edition}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.edition}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.edition && academic.errors.edition && (
                        <small className="text-palette6 my-0.5">
                           {academic.errors.edition}
                        </small>
                     )}
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="countInStock" className={labelStyle}>
                        Count in Stock
                     </label>
                     <input
                        type="text"
                        name="countInStock"
                        value={academic.values.countInStock}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.countInStock}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.countInStock &&
                        academic.errors.countInStock && (
                           <small className="text-palette6 my-0.5">
                              {academic.errors.countInStock}
                           </small>
                        )}
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="discount" className={labelStyle}>
                        Discount
                     </label>
                     <input
                        type="text"
                        name="discount"
                        value={academic.values.discount}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.discount}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.discount && academic.errors.discount && (
                        <small className="text-palette6 my-0.5">
                           {academic.errors.discount}
                        </small>
                     )}
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="gyankoshDiscount" className={labelStyle}>
                        Gyankosh Discount
                     </label>
                     <input
                        type="text"
                        name="gyankoshDiscount"
                        value={academic.values.gyankoshDiscount}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.gyankoshDiscount}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.gyankoshDiscount &&
                        academic.errors.gyankoshDiscount && (
                           <small className="text-palette6 my-0.5">
                              {academic.gyankoshDiscount}
                           </small>
                        )}
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="costPerItem" className={labelStyle}>
                        Cost per item
                     </label>
                     <input
                        type="number"
                        name="costPerItem"
                        value={academic.values.costPerItem}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.costPerItem}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.costPerItem &&
                        academic.errors.costPerItem && (
                           <small className="text-palette6 my-0.5">
                              {academic.errors.costPerItem}
                           </small>
                        )}
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="pageNumber" className={labelStyle}>
                        Page Number
                     </label>
                     <input
                        type="number"
                        name="pageNumber"
                        placeholder={product?.product?.pageNumber}
                        value={academic.values.pageNumber}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="trending" className={labelStyle}>
                        Trending Now
                     </label>
                     <input
                        type="text"
                        name="trending"
                        placeholder={
                           product?.product?.trending === true
                              ? "true"
                              : "false"
                        }
                        value={academic.values.trending}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="todaysDeal" className={labelStyle}>
                        Todays Deal
                     </label>
                     <input
                        type="text"
                        name="todaysDeal"
                        placeholder={
                           product?.product?.todaysDeal === true
                              ? "true"
                              : "false"
                        }
                        value={academic.values.todaysDeal}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>{" "}
                  <div className={boxStyle}>
                     <label htmlFor="popularNow" className={labelStyle}>
                        Popular Now
                     </label>
                     <input
                        type="text"
                        name="popularNow"
                        placeholder={
                           product?.product?.popularNow === true
                              ? "true"
                              : "false"
                        }
                        value={academic.values.popularNow}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="bookfair" className={labelStyle}>
                        Book Fair
                     </label>
                     <input
                        type="text"
                        name="bookfair"
                        placeholder={
                           product?.product?.bookfair === true
                              ? "true"
                              : "false"
                        }
                        value={academic.values.bookfair}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className="bg-white dark:bg-dark p-5 rounded-lg w-full shadow">
                     <h3 className="capitalize">
                        Product Status:{" "}
                        <span className="text-xs text-gray-500 border p-1">
                           Current status:{" "}
                           {product?.product?.status === true
                              ? "Active"
                              : "Inactive"}
                        </span>
                     </h3>
                     <select
                        name="status"
                        onClick={academic.handleChange}
                        className={`${inputStyle}, cursor-pointer`}
                     >
                        <option value={true}>Active</option>
                        <option
                           selected={product?.product?.status !== true}
                           value={false}
                        >
                           Inactive
                        </option>
                     </select>
                     <div className="space-y-2 mt-4 border-t pt-3">
                        <h4 className="capitalize">
                           Sales channels and apps:{" "}
                           <span className="text-xs text-gray-500 border p-1">
                              Current status:{" "}
                              {product?.product?.onlinestore +
                                 ", " +
                                 product?.product?.pointofsale}
                           </span>
                        </h4>
                        <div>
                           <input
                              type="checkbox"
                              name="onlinestore"
                              id="onlinestore"
                              value="onlinestore"
                              onChange={(e) => setonlinestore(e.target.value)}
                              className="mr-1"
                           />
                           <label
                              htmlFor="onlinestore"
                              name="onlinestore"
                              className={`${labelStyle} cursor-pointer`}
                           >
                              Online Store
                           </label>
                        </div>
                        <div>
                           <input
                              type="checkbox"
                              name="pointofsale"
                              id="pointofsale"
                              value="pointofsale"
                              onChange={(e) => setpointofsale(e.target.value)}
                              className="mr-1"
                           />
                           <label
                              htmlFor="pointofsale"
                              name="pointofsale"
                              className={`${labelStyle} cursor-pointer`}
                           >
                              Point Of Sale
                           </label>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="space-y-3 w-full">
                  <div className={boxStyle}>
                     <label htmlFor="authorName" className={labelStyle}>
                        Author Name
                     </label>
                     <SelectPicker
                        value={authorName}
                        onChange={(item) => setAuthorName(item)}
                        placeholder={product?.product?.author?.name}
                        className={`${inputStyle}, lowercase`}
                        data={authorData}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="subWriter" className={labelStyle}>
                        subWriter Name
                     </label>
                     <SelectPicker
                        value={subWriterName}
                        onChange={(item) => setSubWriterName(item)}
                        placeholder={product?.product?.subWriter}
                        className={`${inputStyle}, lowercase `}
                        data={subWritersData}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="uploadPdfs" className={labelStyle}>
                        EBOOK
                     </label>
                     <input
                        type="file"
                        id="file"
                        name="uploadPdfs"
                        onChange={onChangePdf}
                     />
                     <button onClick={onSubmitPdf} className="CssBtn1">
                        <span className="text">Select only Pdf file!</span>
                        <span className="mt-1.5">Add PDF</span>
                     </button>
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="ebookPrice" className={labelStyle}>
                        Ebook Price
                     </label>
                     <input
                        type="number"
                        name="ebookPrice"
                        value={academic.values.ebookPrice}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.ebookPrice}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="ebookDiscount" className={labelStyle}>
                        Ebook Discount
                     </label>
                     <input
                        type="number"
                        name="ebookDiscount"
                        value={academic.values.ebookDiscount}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.ebookDiscount}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="productImage" className={labelStyle}>
                        Product Image
                     </label>
                     <Avatar
                        size="lg"
                        src={product?.product?.images?.[0]}
                        alt={product?.product?.title}
                        className="m-2"
                     />
                     <input
                        type="file"
                        id="file"
                        name="uploadImages"
                        multiple
                        onChange={onChange}
                     />

                     <button onClick={onSubmit} className="CssBtn1">
                        <span className="text">
                           Select only jpg, png, and avif file!
                        </span>
                        <span className="mt-1.5">Add Product Image</span>
                     </button>
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="productDescription" className={labelStyle}>
                        Product Description
                     </label>
                     <textarea
                        name="description"
                        value={academic.values.description}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.description}
                        className={`${textAreaStyle}, lowercase`}
                     ></textarea>
                     {academic.touched.description &&
                        academic.errors.description && (
                           <small className="text-palette6 my-0.5">
                              {academic.errors.description}
                           </small>
                        )}
                  </div>
                  <div className="bg-white dark:bg-dark p-5 rounded-lg w-full shadow">
                     <h3 className="capitalize mb-4">
                        Order Type:{" "}
                        <span className="text-xs text-gray-500 border p-1">
                           Current status:{" "}
                           {product?.product?.orderType === "pre"
                              ? "Pre Order"
                              : product?.product?.orderType === "current"
                              ? "Current Order"
                              : "n/a"}
                        </span>
                     </h3>
                     <label
                        htmlFor="current"
                        className="cursor-pointer text-sm mb-2 block"
                     >
                        <input
                           onChange={(e) => setOrderType(e.target.value)}
                           value={"current"}
                           type="radio"
                           id="current"
                           name="orderType"
                           className="mr-2"
                        />
                        <span>Current Order</span>
                     </label>
                     <label htmlFor="pre" className="cursor-pointer text-sm">
                        <input
                           onChange={(e) => setOrderType(e.target.value)}
                           value={"pre"}
                           type="radio"
                           id="pre"
                           name="orderType"
                           className="mr-2"
                        />
                        <span>Pre Order</span>
                     </label>
                  </div>
                  <div>
                     <button
                        type="submit"
                        className="border border-black py-3 px-10 mt-5 text-black font-semibold hover:bg-[#363730] ease-in hover:text-white"
                     >
                        Submit
                     </button>
                  </div>
               </div>
            </div>
         </form>
      </div>
   )
}

export default AcademicItemUpdate
