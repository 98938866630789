import React from "react"
import { Card, Typography, Avatar } from "@material-tailwind/react"
import { FaBookOpen } from "react-icons/fa"
import { BsFillCheckCircleFill } from "react-icons/bs"
import { returnProductValue } from "../utils/orderInformation"
import { getDiscountAmountFromPercentage } from "../utils/orderInformation"

const SingleOrderProduct = ({ order }) => {
   let paymentDiscountAmount = getDiscountAmountFromPercentage(order)

   return (
      <div>
         <Card>
            <table className="w-full min-w-max table-auto text-left">
               <thead>
                  <tr>
                     {["Product", "Quantity", "Price", "Custom Discount"].map(
                        (head) => (
                           <th
                              key={head}
                              className="border-b border-blue-gray-100 bg-blue-100  p-4"
                           >
                              <Typography
                                 variant="small"
                                 className="font-semibold leading-none opacity-70 text-black"
                              >
                                 {head}
                              </Typography>
                           </th>
                        )
                     )}
                  </tr>
               </thead>
               <tbody>
                  {order?.orderedProducts?.map((x, index) => {
                     const isLast = index === order?.orderedProducts.length - 1
                     const classes = isLast
                        ? "p-4"
                        : "p-4 border-b border-blue-gray-50"

                     return (
                        <tr key={x?._id}>
                           <td className={classes}>
                              <div className="flex gap-2.5 w-1/2">
                                 {x?.images?.length > 0 ? (
                                    <Avatar
                                       size="md"
                                       src={x?.images?.[0]}
                                       alt={x?.title}
                                       className="!rounded-none"
                                    />
                                 ) : (
                                    <FaBookOpen
                                       size={20}
                                       className="text-custom_lightBlue"
                                    />
                                 )}

                                 <div className="flex flex-col items-start justify-between flex-grow">
                                    <h1 className="font-semibold !text-sm">
                                       {x?.title?.length > 36
                                          ? x?.title.substr(0, 36)
                                          : x?.title}
                                       {x?.title?.length > 36 && "..."}
                                    </h1>
                                 </div>
                              </div>
                           </td>
                           <td className={classes}>
                              <Typography
                                 variant="small"
                                 color="blue-gray"
                                 className="font-normal"
                              >
                                 {x?.cartQuantity}
                              </Typography>
                           </td>
                           <td className={classes}>
                              <span className="line-through">
                                 {" "}
                                 {Math.round(x?.price) * x?.cartQuantity} TK.
                              </span>
                              <Typography
                                 variant="small"
                                 color="blue-gray"
                                 className="font-medium"
                              >
                                 {Math.round(x?.discountedPrice) *
                                    x?.cartQuantity}{" "}
                                 TK.
                              </Typography>
                           </td>
                           {/* <td className={classes}>
                              <Typography
                                 variant="small"
                                 color="blue-gray"
                                 className="font-normal"
                              >
                                 {(x?.discountedPrice +
                                    x?.custom_discount?.amount) *
                                    x?.cartQuantity}
                                 TK.
                              </Typography>
                           </td> */}
                           <td className={classes}>
                              <Typography
                                 variant="small"
                                 color="blue-gray"
                                 className="font-normal"
                              >
                                 {x?.custom_discount?.amount * x?.cartQuantity}
                                 {x?.custom_discount?.discountType ===
                                 "percentage"
                                    ? " %"
                                    : " TK."}
                              </Typography>
                              {x?.custom_discount?.amount === 0 ? null : (
                                 <span className="text-xs text-gray-500">
                                    Reason: {x?.custom_discount?.reason}
                                 </span>
                              )}
                           </td>
                        </tr>
                     )
                  })}
               </tbody>
            </table>
         </Card>

         {order?.orderStatus === "refund in progress" ||
         order?.orderStatus === "refunded" ? (
            <div className="bg-white dark:bg-dark dark:text-white dark:border-gray-600 w-full border rounded-lg p-5 my-4 shadow text-sm  space-y-2 text-black divide-y divide-gray-300">
               <h3 className=" dark:text-white mb-4 flex items-center text-base capitalize font-semibold">
                  <svg
                     viewBox="0 0 17 17"
                     xmlns="http://www.w3.org/2000/svg"
                     role="img"
                     className="Polaris-Icon__Svg_375hu w-5 mr-2 text-black"
                     fill="#007f5f"
                     focusable="false"
                     aria-hidden="true"
                  >
                     <path
                        d="M0 8.5a8.5 8.5 0 0 1 17 0"
                        fill="currentColor"
                     ></path>
                     <path d="M14.515 2.492A8.502 8.502 0 0 0 2.491 2.49a8.499 8.499 0 0 0-.002 12.02 8.504 8.504 0 0 0 12.023.003l.002-.002a8.503 8.503 0 0 0 0-12.016zM3.693 3.691a6.828 6.828 0 0 1 9.624 0 6.7 6.7 0 0 1 1.93 3.96H8.853L9.96 6.555a.857.857 0 0 0 0-1.207.857.857 0 0 0-1.207 0L6.448 7.65H1.769a6.688 6.688 0 0 1 1.921-3.96zm9.622 9.62a6.81 6.81 0 0 1-9.622 0 6.666 6.666 0 0 1-1.921-3.96h4.675l2.304 2.303a.86.86 0 0 0 1.207 0 .857.857 0 0 0 0-1.207L8.853 9.351h6.392a6.678 6.678 0 0 1-1.93 3.96z"></path>
                  </svg>
                  {order?.orderStatus}
               </h3>
               <div className="flex justify-between pb-6 border-b mb-4">
                  <h3>Paid by customer</h3>
                  <span>{order?.totalPrice} TK.</span>
               </div>
               <div className="flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                     <h3>Refunded </h3>
                     <span className="text-gray-500">
                        (
                        {order?.returned?.refundOptions?.refundReason ||
                           "Not provided"}
                        )
                     </span>{" "}
                  </div>
                  <span>
                     {order?.returned?.refundOptions?.refundAmount || 0} TK.
                  </span>
               </div>
               <div className="flex justify-between items-center ">
                  <h3>Net payment</h3>
                  <span>{order?.netPayment} TK.</span>
               </div>
               <div className="flex justify-between items-center ">
                  <h3>Balance</h3>
                  <span>
                     {order?.balance}
                     TK.
                  </span>
               </div>
            </div>
         ) : order?.orderStatus === "return in progress" ||
           order?.orderStatus === "returned" ? (
            <div className="bg-white dark:bg-dark dark:text-white dark:border-gray-600 w-full border rounded-lg p-5 my-4 shadow  space-y-2 text-black divide-y divide-gray-300">
               <h3 className=" dark:text-white mb-4 flex items-center text-base capitalize font-semibold">
                  <svg
                     viewBox="0 0 17 17"
                     xmlns="http://www.w3.org/2000/svg"
                     role="img"
                     className="Polaris-Icon__Svg_375hu w-5 mr-2 text-black"
                     fill="#007f5f"
                     focusable="false"
                     aria-hidden="true"
                  >
                     <path
                        d="M0 8.5a8.5 8.5 0 0 1 17 0"
                        fill="currentColor"
                     ></path>
                     <path d="M14.515 2.492A8.502 8.502 0 0 0 2.491 2.49a8.499 8.499 0 0 0-.002 12.02 8.504 8.504 0 0 0 12.023.003l.002-.002a8.503 8.503 0 0 0 0-12.016zM3.693 3.691a6.828 6.828 0 0 1 9.624 0 6.7 6.7 0 0 1 1.93 3.96H8.853L9.96 6.555a.857.857 0 0 0 0-1.207.857.857 0 0 0-1.207 0L6.448 7.65H1.769a6.688 6.688 0 0 1 1.921-3.96zm9.622 9.62a6.81 6.81 0 0 1-9.622 0 6.666 6.666 0 0 1-1.921-3.96h4.675l2.304 2.303a.86.86 0 0 0 1.207 0 .857.857 0 0 0 0-1.207L8.853 9.351h6.392a6.678 6.678 0 0 1-1.93 3.96z"></path>
                  </svg>
                  {order?.orderStatus}
               </h3>
               {order?.returned?.returnedProducts?.map((x) => (
                  <div className="text-xs flex justify-between py-8">
                     <div className="flex gap-4 mr-28">
                        <div className="relative">
                           <Avatar
                              size="md"
                              src={x?.images?.[0]}
                              alt={x?.title}
                              className="!rounded-none"
                           />
                           <div className="absolute -right-2 -top-2">
                              <span className="w-6 h-6 rounded-full bg-gray-200 dark:bg-gray-500 inline-block text-center pt-1">
                                 {x.returnQty}
                              </span>
                           </div>
                        </div>
                        <div>
                           <label
                              htmlFor="leavePage"
                              className="text-[#576CBC] cursor-pointer "
                           >
                              {x?.title}
                           </label>
                           <ul className="list-disc text-black dark:text-white ml-4">
                              {order?.returned?.reason ? (
                                 <li>
                                    Return reason:
                                    {order?.returned?.reason}
                                 </li>
                              ) : null}
                           </ul>
                        </div>
                     </div>
                     <span>
                        {x?.discountedPrice} x {x?.returnQty} TK.
                     </span>
                     <span>{x?.discountedPrice * x?.returnQty} TK.</span>
                  </div>
               ))}
               <div className="flex justify-between items-center">
                  <h3>Customer Payable </h3>
                  <span>
                     {order?.totalPrice}
                     TK.
                  </span>
               </div>
               <div className="flex justify-between items-center">
                  <h3>Return Product Value </h3>
                  <span>
                     {returnProductValue(order)}
                     TK.
                  </span>
               </div>
               <div className="flex justify-between items-center">
                  <h3>Shipping Cost</h3>
                  <span>
                     {order?.returned?.returnOptions?.shippingCost}
                     TK.
                  </span>
               </div>
               <div className="flex justify-between items-center">
                  <h3>Net payment</h3>
                  <span>- {order?.netPayment} TK.</span>
               </div>
               <div className="flex justify-between items-center ">
                  <h3>Balance</h3>
                  <span>-{order?.balance} TK.</span>
               </div>
            </div>
         ) : order?.orderStatus === "refund & return in progress" ||
           order?.orderStatus === "refunded & returned" ? (
            <div className="bg-white dark:bg-dark dark:text-white dark:border-gray-600 w-full border rounded-lg p-5 my-4 shadow text-sm  space-y-2 text-black divide-y divide-gray-300">
               <h3 className=" dark:text-white mb-4 flex items-center text-base capitalize font-semibold">
                  <svg
                     viewBox="0 0 17 17"
                     xmlns="http://www.w3.org/2000/svg"
                     role="img"
                     className="Polaris-Icon__Svg_375hu w-5 mr-2 text-black"
                     fill="#007f5f"
                     focusable="false"
                     aria-hidden="true"
                  >
                     <path
                        d="M0 8.5a8.5 8.5 0 0 1 17 0"
                        fill="currentColor"
                     ></path>
                     <path d="M14.515 2.492A8.502 8.502 0 0 0 2.491 2.49a8.499 8.499 0 0 0-.002 12.02 8.504 8.504 0 0 0 12.023.003l.002-.002a8.503 8.503 0 0 0 0-12.016zM3.693 3.691a6.828 6.828 0 0 1 9.624 0 6.7 6.7 0 0 1 1.93 3.96H8.853L9.96 6.555a.857.857 0 0 0 0-1.207.857.857 0 0 0-1.207 0L6.448 7.65H1.769a6.688 6.688 0 0 1 1.921-3.96zm9.622 9.62a6.81 6.81 0 0 1-9.622 0 6.666 6.666 0 0 1-1.921-3.96h4.675l2.304 2.303a.86.86 0 0 0 1.207 0 .857.857 0 0 0 0-1.207L8.853 9.351h6.392a6.678 6.678 0 0 1-1.93 3.96z"></path>
                  </svg>
                  {order?.orderStatus}
               </h3>
               {order?.returned?.returnedProducts?.map((x) => (
                  <div className="text-xs flex justify-between py-8">
                     <div className="flex gap-4 mr-28">
                        <div className="relative">
                           <Avatar
                              size="md"
                              src={x?.images?.[0]}
                              alt={x?.title}
                              className="!rounded-none"
                           />
                           <div className="absolute -right-2 -top-2">
                              <span className="w-6 h-6 rounded-full bg-gray-200 dark:bg-gray-500 inline-block text-center pt-1">
                                 {x.returnQty}
                              </span>
                           </div>
                        </div>
                        <div>
                           <label
                              htmlFor="leavePage"
                              className="text-[#576CBC] cursor-pointer "
                           >
                              {x?.title}
                           </label>
                           <ul className="list-disc text-black dark:text-white ml-4">
                              {order?.returned?.reason ? (
                                 <li>
                                    Return reason:
                                    {order?.returned?.reason}
                                 </li>
                              ) : null}
                           </ul>
                        </div>
                     </div>
                     <span>
                        {x?.discountedPrice} x {x?.returnQty} TK.
                     </span>
                     <span>{x?.discountedPrice * x?.returnQty} TK.</span>
                  </div>
               ))}
               <div className="flex justify-between items-center">
                  <h3> Paid by Customer </h3>
                  <span>
                     {order?.totalPrice}
                     TK.
                  </span>
               </div>
               <div className="flex justify-between items-center">
                  <h3>Return Product Value </h3>
                  <span>
                     {returnProductValue(order)}
                     TK.
                  </span>
               </div>
               <div className="flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                     <h3>Refunded </h3>
                     <span className="text-gray-500">
                        (
                        {order?.returned?.refundOptions?.refundReason ||
                           "Not provided"}
                        )
                     </span>{" "}
                  </div>
                  <span>
                     {order?.returned?.refundOptions?.refundAmount || 0} TK.
                  </span>
               </div>
               <div className="flex justify-between items-center">
                  <h3>Shipping Cost</h3>
                  <span>
                     {order?.returned?.returnOptions?.shippingCost}
                     TK.
                  </span>
               </div>
               <div className="flex justify-between items-center">
                  <h3>Net payment</h3>
                  <span>
                     {order?.netPayment}
                     TK.
                  </span>
               </div>
               <div className="flex justify-between items-center ">
                  <h3>Balance</h3>
                  <span>{order?.balance} TK.</span>
               </div>
            </div>
         ) : (
            <div className="bg-white dark:bg-dark dark:text-white dark:border-gray-600 w-full border rounded-lg p-5 my-4 shadow">
               <div className="text-black dark:text-white mb-4 flex items-center text-sm space-x-2">
                  <span className="border border-main rounded-full">
                     <BsFillCheckCircleFill
                        size={16}
                        className="bg-main rounded-full"
                     />
                  </span>
                  <h3>Payment ({order?.isPaid === true ? "Paid" : "Due"})</h3>
               </div>
               <div className="text-sm space-y-2 text-black divide-y divide-gray-300">
                  <div className="flex justify-between items-center">
                     <h3>Payment Method</h3>
                     <span>{order?.paymentMethod}</span>
                  </div>
                  <div className="flex justify-between items-center">
                     <h3>Subtotal</h3>
                     <span> {order?.subTotal} TK.</span>
                  </div>
                  {order?.paymentDiscount?.value !== 0 && (
                     <div className="flex justify-between items-center">
                        <h3>
                           Additional Discount
                           {order?.paymentDiscount?.reason && (
                              <span className="text-sm text-gray-500">
                                 ({order?.paymentDiscount?.reason})
                              </span>
                           )}
                        </h3>
                        <div className="flex justify-end gap-3">
                           <span>
                              {order?.paymentDiscount?.value || 0}{" "}
                              {order?.paymentDiscount?.discountType ===
                              "percentage"
                                 ? " %"
                                 : "TK."}
                           </span>
                           {order?.paymentDiscount?.value !== 0 && (
                              <span className="text-sm text-gray-500">
                                 (After Discount-{" "}
                                 {order?.subTotal - paymentDiscountAmount} TK.)
                              </span>
                           )}
                        </div>
                     </div>
                  )}
                  <div className="flex justify-between items-center">
                     <h3>Delivery fee</h3>
                     <span>
                        {order?.paymentShipping?.shipping_cost} TK.
                        {order?.paymentShipping?.shipping_type ? (
                           <span className="text-sm text-gray-500">
                              {" "}
                              ({order?.paymentShipping?.shipping_type})
                           </span>
                        ) : null}{" "}
                     </span>
                  </div>
                  <div className="flex justify-between items-center py-1">
                     <h3>Total</h3>
                     <span> {order?.totalPrice} TK.</span>
                  </div>
               </div>
               <div className="text-sm py-5 flex justify-between pt-6 border-t ">
                  <h3>Customer Payable</h3>
                  <span>
                     {order?.isPaid == true ? 0 : order?.totalPrice} TK.
                  </span>
               </div>
            </div>
         )}
      </div>
   )
}

export default SingleOrderProduct
