import { createSlice } from "@reduxjs/toolkit";
import { getSingleUser} from "./allUserFunc";

const getSingleUserSlice = createSlice({
  name: "getSingleUser",
  initialState: {
    user: {},
    status: null,
  },
  extraReducers: {
    [getSingleUser.pending]: (state) => {
      state.status = "loading";
    },
    [getSingleUser.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.status = "success";
    },
    [getSingleUser.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default getSingleUserSlice.reducer;
