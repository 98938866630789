import React from 'react';
import { FaBox, FaHeart, FaHome, FaSignOutAlt, FaThumbsUp, FaUser } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const Menubar = () => {
    return (
            <div className="drawer drawer-end">
                <input id="menubar" type="checkbox" className="drawer-toggle" />
                <div className="drawer-content">
                    {/* <!-- Page content here --> */}
                    <div>
                        <label htmlFor="menubar" className="drawer-button flex items-center gap-2 text-left m-10 cursor-pointer">
                                <div>
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkiIFjCOZ-mMeqxd2ryrneiHedE8G9S0AboA&usqp=CAU" alt="user" className="w-14 rounded-full border p-1"/>
                                </div>
                                <div className='dark:text-white'>
                                    <h3>Wahid Tarafder</h3>
                                    <h3 className='font-normal text-[12px]'>wahid.tarafder@yahoo.com</h3>
                                </div>
                        </label>
                    </div>
                </div> 
                <div className="drawer-side ">
                    <label htmlFor="menubar" className="drawer-overlay min-h-screen"></label>
                    <ul className='p-4 w-80 bg-base-100 dark:bg-dark dark:text-white capitalize text-gray-500 text-[15px] fixed right-0 top-0 min-h-screen'>
                        <li className='flex justify-between border-b pb-2 mb-4  w-full '>
                            <div className='flex items-center gap-2 pt-4'>
                                <div>
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkiIFjCOZ-mMeqxd2ryrneiHedE8G9S0AboA&usqp=CAU" alt="user" className="w-14 rounded-full border p-1"/>
                                </div>
                                <div>
                                    <h3>Wahid Tarafder</h3>
                                    <h3 className='text-primary font-normal text-[12px]'>wahid.tarafder@yahoo.com</h3>
                                </div>
                            </div>
                            <label htmlFor="menubar" className='text-black cursor-pointer'>✕</label>
                        </li>
                        <li>
                            <NavLink to='/users/user/id' className={`flex items-center gap-3 py-3  px-4 rounded-lg  hover:bg-gray-100 duration-200 hover:text-dark`}>
                                <FaUser className='text-xl text-orange-500'></FaUser>
                                <h3 className='mt-1'>My Account</h3>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/users/orders/id' className={`flex items-center gap-3 py-3 px-4 rounded-lg  hover:bg-gray-100 duration-200 hover:text-dark`}>
                                <FaBox className=' text-xl text-orange-500'></FaBox>
                                <h3 className='mt-1'>my orders</h3>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/users/reviews/id' className={`flex items-center gap-3 py-3 px-4 rounded-lg  hover:bg-gray-100 duration-200 hover:text-dark`}>
                                <FaHeart className='text-xl text-orange-500'></FaHeart>
                                <h3 className='mt-1'>my wishlist</h3>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/users/reviews/id' className={`flex items-center gap-3 py-3 px-4 rounded-lg  hover:bg-gray-100 duration-200 hover:text-dark`}>
                                <FaThumbsUp className='text-xl text-orange-500'></FaThumbsUp>
                                <h3 className='mt-1'>my reviews</h3>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/users/vouchers/id' className={`flex items-center gap-3 py-3 px-4 rounded-lg  hover:bg-gray-100 duration-200 hover:text-dark`}>
                                <FaHome className=' text-xl text-orange-500'></FaHome>
                                <h3 className='mt-1'>my Vouchers</h3>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/users/vouchers/id' className={`flex items-center gap-3 py-3 px-4 rounded-lg  hover:bg-gray-100 duration-200 hover:text-dark`}>
                                <FaHome className=' text-xl text-orange-500'></FaHome>
                                <h3 className='mt-1'>my Ichchapuron Balance</h3>
                            </NavLink>
                        </li>
                        <li className='w-full bg-[#212A3E] hover:bg-gray-700 duration-200 text-white py-3 absolute bottom-0 right-0'>
                            <button className='flex items-center gap-3 justify-center w-full'>
                                <FaSignOutAlt className=' text-xl text-orange-500'></FaSignOutAlt>
                                <h3 className='mt-1'>sign out</h3>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
    );
};

export default Menubar;