import React from "react"
import { customerOrdersData } from "../../utils/CustomerOrderInfo"
import { Tabs } from "rsuite"
import UserOrder from "../Users/SignleUser/UserOrders/UserOrder"
import UserOrderSummery from "../Users/SignleUser/UserOrders/UserOrderSummery"

const CustomerOrders = ({ orders }) => {
   const ordersByStatus = customerOrdersData(orders)

   return (
      <div>
         <UserOrderSummery orders={orders} />
         <Tabs defaultActiveKey="1">
            {Object.entries(ordersByStatus).map(([status, orders], index) => (
               <Tabs.Tab
                  eventKey={`${index + 1}`}
                  title={`${status}(${orders?.length})`}
               >
                  {orders.map((order) => (
                     <UserOrder order={order} />
                  ))}
               </Tabs.Tab>
            ))}
         </Tabs>
      </div>
   )
}

export default CustomerOrders
