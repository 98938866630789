import { Link, useParams } from "react-router-dom"
import LeavePage from "../../components/LeavePage"
import DeleteCustomer from "./Actions/DeleteCustomer"
import { useGetEmployeeQuery } from "../../services/employee"
import { employeeInfoFunc } from "../../utils/utils"
import { useGetCustomerQuery } from "../../services/customer"
import OrderCustomerInfo from "../../components/Customers/OrderCustomerInfo"
import CustomerOrders from "./CustomerOrders"
import { useGetcustomerOrdersQuery } from "../../services/order"
import CreateOrderButton from "../../components/CreateOrderButton"

const SingleCustomer = () => {
   const { phone } = useParams()
   const employeeFromLocal = JSON.parse(localStorage.getItem("ich-user"))
   const { data } = useGetCustomerQuery(phone)
   const { data: orders, isLoading } = useGetcustomerOrdersQuery(phone)
   const { data: user } = useGetEmployeeQuery(employeeFromLocal?.id)
   const permission = employeeInfoFunc(user, "customers")

   if (isLoading) {
      return <p className="p-10">loading...</p>
   }
   return (
      <div className="p-2 m-2 sm:m-5 dark:text-white">
         {/* heading */}
         <div className="py-3 w-f flex items-center justify-end order-1 md:order-2">
            <div className="dropdown dropdown-end">
               {permission?.permission !== "View Only" && (
                  <label tabIndex={0} className="m-1 flex cursor-pointer">
                     More actions
                     <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg_375hu w-5"
                        focusable="false"
                        aria-hidden="true"
                     >
                        <path d="M13.098 8h-6.196c-.751 0-1.172.754-.708 1.268l3.098 3.432c.36.399 1.055.399 1.416 0l3.098-3.433c.464-.513.043-1.267-.708-1.267Z"></path>
                     </svg>
                  </label>
               )}
               <ul
                  tabIndex={0}
                  className="dropdown-content p-0 sm:p-2 shadow bg-base-100 dark:bg-dark rounded-box w-52 sm:w-60"
               >
                  <label
                     htmlFor="deleteCustomer"
                     className="flex items-center py-3 px-3 rounded-md hover:bg-gray-100 w-full cursor-pointer hover:dark:text-dark"
                  >
                     <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg_375hu  w-4 mr-4"
                        focusable="false"
                        aria-hidden="true"
                     >
                        <path d="M6.707 5.293a1 1 0 0 0-1.414 1.414l3.293 3.293-3.293 3.293a1 1 0 1 0 1.414 1.414l3.293-3.293 3.293 3.293a1 1 0 0 0 1.414-1.414l-3.293-3.293 3.293-3.293a1 1 0 0 0-1.414-1.414l-3.293 3.293-3.293-3.293Z"></path>
                     </svg>
                     Delete Customer
                  </label>
                  <li className="py-3 px-3 rounded-md hover:bg-gray-100 cursor-pointer hover:dark:text-dark">
                     <Link to="/editCustomer" className="flex items-center">
                        <svg
                           viewBox="0 0 20 20"
                           className="Polaris-Icon__Svg_375hu w-4 mr-4"
                           focusable="false"
                           aria-hidden="true"
                        >
                           <path d="M7.5 2a1.5 1.5 0 0 0-1.5 1.5v9.5a1 1 0 0 0 1 1h9.5a1.5 1.5 0 0 0 1.5-1.5v-9a1.5 1.5 0 0 0-1.5-1.5h-9zm-4 4h.5v10h10v.5a1.5 1.5 0 0 1-1.5 1.5h-9a1.5 1.5 0 0 1-1.5-1.5v-9a1.5 1.5 0 0 1 1.5-1.5z"></path>
                        </svg>
                        <h3 className="text-sm capitalize">edit customer</h3>
                     </Link>
                  </li>
               </ul>
               <DeleteCustomer></DeleteCustomer>
            </div>
            <div className="ml-3">
               <button
                  type="button"
                  className="p-1 sm:px-2 sm:py-2 border rounded-md"
               >
                  <svg
                     viewBox="0 0 20 20"
                     className="Polaris-Icon__Svg_375hu w-4 sm:w-5"
                     focusable="false"
                     aria-hidden="true"
                  >
                     <path d="M12 16a.997.997 0 0 1-.707-.293l-5-5a.999.999 0 0 1 0-1.414l5-5a.999.999 0 1 1 1.414 1.414l-4.293 4.293 4.293 4.293a.999.999 0 0 1-.707 1.707z"></path>
                  </svg>
               </button>
               <button
                  type="button"
                  className="p-1 sm:px-2 sm:py-2 border rounded-md"
               >
                  <svg
                     viewBox="0 0 20 20"
                     className="Polaris-Icon__Svg_375hu w-4 sm:w-5"
                     focusable="false"
                     aria-hidden="true"
                  >
                     <path d="M8 16a.999.999 0 0 1-.707-1.707l4.293-4.293-4.293-4.293a.999.999 0 1 1 1.414-1.414l5 5a.999.999 0 0 1 0 1.414l-5 5a.997.997 0 0 1-.707.293z"></path>
                  </svg>
               </button>
            </div>
         </div>
         <LeavePage></LeavePage>
         <div className="grid grid-cols-1 lg:grid-cols-3 sm:gap-4">
            <div className="col-span-2 ">
               {orders?.length > 0 ? (
                  <CustomerOrders orders={orders} />
               ) : (
                  <div className="bg-white dark:bg-dark dark:border-gray-600 border rounded-lg p-5 my-4 shadow">
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="226"
                        height="226"
                        className="m-auto"
                        fill="none"
                     >
                        <path
                           d="M187 113.419c0 11.11-2.44 21.64-6.83 31.09-11.73 25.35-37.39 42.91-67.17 42.91-29.77 0-55.43-17.56-67.17-42.91-4.39-9.45-6.83-19.98-6.83-31.09 0-40.88 33.12-74 74-74 40.89 0 74 33.12 74 74z"
                           fill="#F0F1F2"
                        />
                        <g filter="url(#a)">
                           <path
                              d="M168.999 65v83.526l-36 18.474H94.795L56 149V41a2 2 0 0 1 2-2h85v24a2 2 0 0 0 2 2h23.999z"
                              fill="#fff"
                           />
                           <path
                              d="M169 65h-24a2 2 0 0 1-2-2V39l26 26z"
                              fill="#D2D5D9"
                           />
                        </g>
                        <path
                           d="M72 52h22"
                           stroke="#336FB2"
                           stroke-width="6"
                           stroke-linecap="round"
                        />
                        <path
                           d="M104.999 65H71v34h34l-.001-34zm0 45H71v34.001h34L104.999 110z"
                           fill="#E8E9EB"
                        />
                        <path
                           d="M120 77h31"
                           stroke="#D2D5D9"
                           stroke-width="4"
                           stroke-linecap="round"
                        />
                        <path
                           d="M120 86h16"
                           stroke="#E8E9EB"
                           stroke-width="4"
                           stroke-linecap="round"
                        />
                        <path
                           d="M119 121h24"
                           stroke="#D2D5D9"
                           stroke-width="4"
                           stroke-linecap="round"
                        />
                        <path
                           d="M119 130h32"
                           stroke="#E8E9EB"
                           stroke-width="4"
                           stroke-linecap="round"
                        />
                        <path
                           d="M93.112 114 96 130H80l3.053-16h10.059z"
                           fill="#EC856F"
                        />
                        <path d="m96 130-3 10H83l-3-10h16z" fill="#DE6A5A" />
                        <path
                           d="M100 77c0 1.58-.406 2.616-.962 4H75.963c-.556-1.384-.962-2.42-.962-4h25z"
                           fill="#399C97"
                        />
                        <path
                           d="M99 81c-1.804 5.271-6.271 9-11.5 9-5.228 0-9.696-3.73-11.5-9h23z"
                           fill="#7FBEC6"
                        />
                        <path
                           d="M75.418 174.422h37.5c1.1 0 2-.9 2-2v-1h-6.94c-.86 0-1.62-.55-1.9-1.37l-7.09-21.27a2 2 0 0 0-1.9-1.37h-30.17l6.5 25c0 1.11.9 2.01 2 2.01z"
                           fill="#B6BABF"
                        />
                        <path
                           d="M148.918 163.422h-68v13h68v-13z"
                           fill="#5AB2AC"
                        />
                        <path
                           d="M178.657 147.419c-2.68 5.18-5.96 10-9.74 14.38l-1.44 1.62c-13.53 14.75-32.97 24-54.56 24-21.59 0-41.03-9.25-54.56-24l-1.44-1.62c-3.78-4.38-7.06-9.2-9.74-14.38h42.3c.86 0 1.62.55 1.9 1.37l7.09 21.27a2 2 0 0 0 1.9 1.37h29.12c.86 0 1.62-.55 1.9-1.37l7.09-21.27a2 2 0 0 1 1.9-1.37h38.28z"
                           fill="#399C97"
                        />
                        <defs>
                           <filter
                              id="a"
                              x="46"
                              y="31"
                              width="133"
                              height="148"
                              filterUnits="userSpaceOnUse"
                              color-interpolation-filters="sRGB"
                           >
                              <feFlood
                                 flood-opacity="0"
                                 result="BackgroundImageFix"
                              />
                              <feColorMatrix
                                 in="SourceAlpha"
                                 values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              />
                              <feOffset dy="2" />
                              <feGaussianBlur stdDeviation="5" />
                              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                              <feBlend
                                 in2="BackgroundImageFix"
                                 result="effect1_dropShadow"
                              />
                              <feBlend
                                 in="SourceGraphic"
                                 in2="effect1_dropShadow"
                                 result="shape"
                              />
                           </filter>
                        </defs>
                     </svg>
                     <h3 className="text-[18px] font-semibold mb-5">
                        This customer hasn’t placed any orders.
                     </h3>
                     {permission?.permission !== "View Only" && (
                        <CreateOrderButton />
                     )}
                  </div>
               )}

               {/* timeline */}
               {/* <Timeline></Timeline> */}
            </div>
            <div className="col-span-1 ">
               <OrderCustomerInfo customer={data?.customer} />
            </div>
         </div>
      </div>
   )
}

export default SingleCustomer
