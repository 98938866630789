import React, { useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { inventoryData } from "./SubmenuData"

const InventoryDrawer = () => {
   const [inventoryItem, setInventoryItem] = useState("")
   const navigate = useNavigate()
   return (
      <div className="">
         <div className="flex items-center gap-2 text-primary dark:text-darkText text-sm pt-7">
            <svg
               width="18"
               height="18"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  d="M1.045 4.488h15.91"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinejoin="round"
               ></path>
               <path
                  d="M4.325 1.164h9.589c.423 0 .824.187 1.095.512l1.617 1.94c.213.257.33.58.33.913v10.408a1.9 1.9 0 0 1-1.9 1.9H3.183a1.9 1.9 0 0 1-1.9-1.9V4.529c0-.333.117-.656.33-.912L3.23 1.676c.271-.325.672-.512 1.095-.512zM6.98 7.812h4.275M9.12 1.4v2.85"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
               ></path>
            </svg>
            All Items
         </div>
         <ul className="pt-7 border-l">
            {inventoryData?.map((item) => {
               return (
                  <li
                     key={item?.id}
                     onClick={() => {
                        navigate(`${item?.path}`)
                        setInventoryItem(item?.name)
                     }}
                     className="pl-7 pr-3 py-2 hover:bg-[#968a8a1e] group-[]: rounded-lg group/item cursor-pointer"
                  >
                     <button
                        className={`flex items-center justify-between gap-2 text-sm w-full ${
                           inventoryItem === item?.name
                              ? "text-red-600"
                              : "text-primary dark:text-darkText"
                        }`}
                     >
                        <div className="flex items-center gap-2">
                           <svg
                              width="20"
                              height="16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="m7.47 2.53.22.22H18c.69 0 1.25.56 1.25 1.25v10c0 .69-.56 1.25-1.25 1.25H2c-.69 0-1.25-.56-1.25-1.25V2C.75 1.31 1.31.75 2 .75h3.69l1.78 1.78z"
                                 fill="#fff"
                                 stroke="currentColor"
                                 strokeWidth="1.5"
                              ></path>
                           </svg>
                           {item?.name}
                        </div>
                     </button>
                  </li>
               )
            })}
         </ul>
         <ul className="mt-[20%]">
            <li
               onClick={() => {
                  navigate(`/authors`)
                  setInventoryItem("Authors")
               }}
               className="pl-7 pr-3 py-2 hover:bg-[#968a8a1e] group-[]: rounded-lg group/item cursor-pointer"
            >
               <button
                  className={`flex items-center justify-between gap-2 text-sm w-full ${
                     inventoryItem === "Authors"
                        ? "text-red-600"
                        : "text-primary dark:text-darkText"
                  }`}
               >
                  <div className="flex items-center gap-2">
                     <svg
                        width="18"
                        height="18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M1.045 4.488h15.91"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinejoin="round"
                        ></path>
                        <path
                           d="M4.325 1.164h9.589c.423 0 .824.187 1.095.512l1.617 1.94c.213.257.33.58.33.913v10.408a1.9 1.9 0 0 1-1.9 1.9H3.183a1.9 1.9 0 0 1-1.9-1.9V4.529c0-.333.117-.656.33-.912L3.23 1.676c.271-.325.672-.512 1.095-.512zM6.98 7.812h4.275M9.12 1.4v2.85"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        ></path>
                     </svg>
                     Authors
                  </div>
               </button>
            </li>
            <li
               onClick={() => {
                  navigate(`/sub-writers`)
                  setInventoryItem("Sub Writer")
               }}
               className="pl-7 pr-3 py-2 hover:bg-[#968a8a1e] group-[]: rounded-lg group/item cursor-pointer"
            >
               <button
                  className={`flex items-center justify-between gap-2 text-sm w-full ${
                     inventoryItem === "Sub Writer"
                        ? "text-red-600"
                        : "text-primary dark:text-darkText"
                  }`}
               >
                  <div className="flex items-center gap-2">
                     <svg
                        width="18"
                        height="18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M1.045 4.488h15.91"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinejoin="round"
                        ></path>
                        <path
                           d="M4.325 1.164h9.589c.423 0 .824.187 1.095.512l1.617 1.94c.213.257.33.58.33.913v10.408a1.9 1.9 0 0 1-1.9 1.9H3.183a1.9 1.9 0 0 1-1.9-1.9V4.529c0-.333.117-.656.33-.912L3.23 1.676c.271-.325.672-.512 1.095-.512zM6.98 7.812h4.275M9.12 1.4v2.85"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        ></path>
                     </svg>
                     Sub Writer
                  </div>
               </button>
            </li>
            <li
               onClick={() => {
                  navigate(`/publicaitons`)
                  setInventoryItem("publicaitons")
               }}
               className="pl-7 pr-3 py-2 hover:bg-[#968a8a1e] group-[]: rounded-lg group/item cursor-pointer"
            >
               <button
                  className={`flex items-center justify-between gap-2 text-sm w-full ${
                     inventoryItem === "publicaitons"
                        ? "text-red-600"
                        : "text-primary dark:text-darkText"
                  }`}
               >
                  <div className="flex items-center gap-2">
                     <svg
                        width="18"
                        height="18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M1.045 4.488h15.91"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinejoin="round"
                        ></path>
                        <path
                           d="M4.325 1.164h9.589c.423 0 .824.187 1.095.512l1.617 1.94c.213.257.33.58.33.913v10.408a1.9 1.9 0 0 1-1.9 1.9H3.183a1.9 1.9 0 0 1-1.9-1.9V4.529c0-.333.117-.656.33-.912L3.23 1.676c.271-.325.672-.512 1.095-.512zM6.98 7.812h4.275M9.12 1.4v2.85"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        ></path>
                     </svg>
                     Publicaitons
                  </div>
               </button>
            </li>
            <li
               onClick={() => {
                  navigate(`/academic-school`)
                  setInventoryItem("Academic School")
               }}
               className="pl-7 pr-3 py-2 hover:bg-[#968a8a1e] group-[]: rounded-lg group/item cursor-pointer"
            >
               <button
                  className={`flex items-center justify-between gap-2 text-sm w-full ${
                     inventoryItem === "Academic School"
                        ? "text-red-600"
                        : "text-primary dark:text-darkText"
                  }`}
               >
                  <div className="flex items-center gap-2">
                     <svg
                        width="18"
                        height="18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M1.045 4.488h15.91"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinejoin="round"
                        ></path>
                        <path
                           d="M4.325 1.164h9.589c.423 0 .824.187 1.095.512l1.617 1.94c.213.257.33.58.33.913v10.408a1.9 1.9 0 0 1-1.9 1.9H3.183a1.9 1.9 0 0 1-1.9-1.9V4.529c0-.333.117-.656.33-.912L3.23 1.676c.271-.325.672-.512 1.095-.512zM6.98 7.812h4.275M9.12 1.4v2.85"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        ></path>
                     </svg>
                     Academic School
                  </div>
               </button>
            </li>
            <li
               onClick={() => {
                  navigate(`/brand`)
                  setInventoryItem("Brand")
               }}
               className="pl-7 pr-3 py-2 hover:bg-[#968a8a1e] group-[]: rounded-lg group/item cursor-pointer"
            >
               <button
                  className={`flex items-center justify-between gap-2 text-sm w-full ${
                     inventoryItem === "Brand"
                        ? "text-red-600"
                        : "text-primary dark:text-darkText"
                  }`}
               >
                  <div className="flex items-center gap-2">
                     <svg
                        width="18"
                        height="18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M1.045 4.488h15.91"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinejoin="round"
                        ></path>
                        <path
                           d="M4.325 1.164h9.589c.423 0 .824.187 1.095.512l1.617 1.94c.213.257.33.58.33.913v10.408a1.9 1.9 0 0 1-1.9 1.9H3.183a1.9 1.9 0 0 1-1.9-1.9V4.529c0-.333.117-.656.33-.912L3.23 1.676c.271-.325.672-.512 1.095-.512zM6.98 7.812h4.275M9.12 1.4v2.85"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        ></path>
                     </svg>
                     Brand
                  </div>
               </button>
            </li>
         </ul>
         <ul className="mt-[20%]">
            <li className="px-8 hover:bg-[#968a8a1e] py-2 rounded-xl">
               <NavLink className="flex items-center gap-2 text-primary dark:text-darkText text-sm">
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="20"
                     height="18"
                     viewBox="0 0 20 18"
                  >
                     <path
                        fillRule="evenodd"
                        fill="currentColor"
                        d="M16.127,3.873 C13.295,1.043 8.705,1.043 5.873,3.873 C4.351,5.396 3.647,7.426 3.763,9.421 L4.632,8.551 C4.925,8.258 5.4,8.258 5.692,8.551 C5.985,8.844 5.985,9.319 5.692,9.611 L3.612,11.693 C3.319,11.985 2.844,11.985 2.551,11.693 L0.47,9.61 C0.177,9.317 0.177,8.843 0.47,8.55 C0.763,8.257 1.237,8.257 1.53,8.55 L2.254,9.274 C2.182,6.944 3.034,4.591 4.813,2.812 C8.23,-0.605 13.77,-0.605 17.187,2.812 C20.604,6.229 20.604,11.769 17.187,15.186 C13.77,18.603 8.23,18.603 4.813,15.186 C4.52,14.893 4.52,14.418 4.813,14.126 C5.106,13.833 5.58,13.833 5.873,14.126 C8.705,16.956 13.295,16.956 16.127,14.126 C18.957,11.294 18.957,6.704 16.127,3.872 L16.127,3.873 Z M11.75,5 C11.75,4.586 11.414,4.25 11,4.25 C10.586,4.25 10.25,4.586 10.25,5 L10.25,10.25 C10.25,10.517 10.392,10.763 10.622,10.898 L13.622,12.648 C13.98,12.857 14.439,12.736 14.648,12.378 C14.857,12.02 14.736,11.561 14.378,11.352 L11.75,9.82 L11.75,5 Z"
                     ></path>
                  </svg>
                  History
               </NavLink>
            </li>
            <li className="px-8 hover:bg-[#968a8a1e] py-2 rounded-xl">
               <NavLink className="flex items-center gap-2 text-primary dark:text-darkText text-sm">
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="24"
                     height="24"
                     fill="none"
                     viewBox="0 0 24 24"
                  >
                     <path
                        fill="currentColor"
                        d="M10.518 3c-.972 0-1.773.831-1.773 1.841v.62H5.778C4.803 5.462 4 6.296 4 7.309v1.23c0 .164.062.32.174.436.11.115.261.18.419.18h.664l1 10.214v-.012c.099.93.865 1.644 1.766 1.644h7.954c.901 0 1.667-.714 1.766-1.644v.012l1-10.214h.664c.158 0 .308-.065.42-.18.11-.116.173-.272.173-.436v-1.23c0-1.013-.803-1.846-1.778-1.846H15.26v-.62c0-1.01-.8-1.842-1.773-1.842h-2.968zm0 1.23h2.968c.333 0 .588.265.588.611v.62H9.931v-.62c0-.346.254-.61.588-.61zm-4.74 2.462H18.222c.334 0 .593.27.593.616v.615H5.185v-.615c0-.347.26-.616.593-.616zm.673 2.462H17.55l-.984 10.062v.005c-.034.318-.28.548-.588.548H8.023c-.308 0-.554-.23-.588-.548v-.005L6.451 9.154z"
                     ></path>
                  </svg>
                  Trash
               </NavLink>
            </li>
         </ul>
      </div>
   )
}

export default InventoryDrawer
