import React, { useEffect, useContext } from "react"
import { useGetEmployeeQuery } from "../services/employee"
import { useLocation, useNavigate } from "react-router-dom"
import { DashboardContext } from "../context/DashboardProvider"

const PrivateRoute = ({ children }) => {
   const navigate = useNavigate()
   const location = useLocation()
   const { setEmployeeInfo } = useContext(DashboardContext)
   const employeeFromLocal = JSON.parse(localStorage.getItem("ich-user"))
   const { data, isLoading } = useGetEmployeeQuery(employeeFromLocal?.id)

   const isExit = data?.employee?.userPermission?.filter(
      (data) => data?.path === location?.pathname
   )

   useEffect(() => {
      setEmployeeInfo(isExit)
   }, [isExit?.length, location.pathname])

   if (isLoading) {
      return <p className="p-5 font-semibold text-orange-600">Laoding...</p>
   }
   if (isExit?.length > 0) {
      return children
   }
   return navigate("/home")
}

export default PrivateRoute
