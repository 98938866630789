import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import BarcodeGenerate from "../../../components/Modal/BarcodeGenerate"
import Barcode from "react-barcode"
import { TiDelete } from "react-icons/ti"
import { removeBarcode } from "../../../services/Features/barcode"
import toast from "react-hot-toast"
import Select from "react-select"
import { ImCross } from "react-icons/im"
import { useGetAcademicSchoolsQuery } from "../../../services/academicSchool"
import { useGetBrandsQuery } from "../../../services/brand"
import { SelectPicker } from "rsuite"
import { useGetSubCategoryQuery } from "../../../services/category"

const AddStationaries = () => {
   const { barcode } = useSelector((state) => state.barcode)
   const dispatch = useDispatch()
   const [openBarcodeModal, setOpenBarcodeModal] = useState(false)
   const { data: academicSchools } = useGetAcademicSchoolsQuery()
   const { data: brands } = useGetBrandsQuery()
   const [brandName, setBrandName] = useState()
   const { data: subCategories } = useGetSubCategoryQuery("stationary")
   const [subCategoryName, setSubCategoryName] = useState()

   const subCategoryData = subCategories?.map((item) => ({
      label: item.subCategory,
      value: item.subCategory,
   }))

   const inputStyle =
      "px-4 py-2 rounded-lg mt-1 border border-[#ddd] hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:bg-gray-50 dark:text-dark placeholder:text-darkText"
   const labelStyle = "text-sm ml-1"
   const boxStyle = "flex flex-col w-full"
   const textAreaStyle =
      "px-4 py-2 rounded-lg dark:bg-gray-50 mt-1 border border-[#ddd] hover:border-gray-400 duration-300 outline-none placeholder:font-thin h-[120px] text-sm dark:text-dark"

   const [status, setStatus] = useState(false)
   const [orderType, setOrderType] = useState("")
   const [onlinestore, setonlinestore] = useState("")
   const [pointofsale, setpointofsale] = useState("")
   const [selectedClass, setSelectedClass] = useState([])
   const [selectedSchool, setSelectedSchool] = useState({})
   const [classType, setClassType] = useState([])
   const [schoolClass, setSchoolClass] = useState([])
   const [schools, setSchools] = useState("")
   const [schoolOptions, setSchoolOptions] = useState([])
   const [classTypes, setClassTypes] = useState([])
   const [tags1, setTags1] = useState([])
   const [tags2, setTags2] = useState([])

   let tagInput1
   let tagInput2
   const removeTag1 = (i) => {
      const newTags = [...tags1]
      newTags.splice(i, 1)
      // Call the defined function setTags which will replace tags with the new value.
      setTags1(newTags)
   }
   const removeTag2 = (i) => {
      const newTags = [...tags2]
      newTags.splice(i, 1)
      // Call the defined function setTags which will replace tags with the new value.
      setTags2(newTags)
   }
   const inputKeyDown1 = (e) => {
      const val = e.target.value
      if (e.key === "Enter" && val) {
         e.preventDefault()
         if (tags1.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
            return
         }
         setTags1([...tags1, val])
         tagInput1.value = null
      } else if (e.key === "Backspace" && !val) {
         removeTag1(tags1.length - 1)
      }
   }
   const inputKeyDown2 = (e) => {
      const val = e.target.value
      if (e.key === "Enter" && val) {
         e.preventDefault()
         if (tags2.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
            return
         }
         setTags2([...tags2, val])
         tagInput2.value = null
      } else if (e.key === "Backspace" && !val) {
         removeTag2(tags2.length - 1)
      }
   }
   //............ specification end...........//
   const { userInfo } = useSelector((state) => state.login)

   const brandData = brands?.map((item) => ({
      label: item.name,
      value: item.name,
   }))

   //image upload
   const [files, setFiles] = useState([])

   const onChange = (e) => {
      setFiles(e.target.files)
   }
   const [uploadData, setUploadData] = useState()
   const onSubmit = async (event) => {
      event.preventDefault()
      const formData = new FormData()
      Object.values(files).forEach((file) => {
         formData.append("uploadImages", file)
      })

      try {
         if (files.length === 0)
            return alert("Please upload an image first the create a product!")
         await axios
            .post("/api/image-upload", formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            })
            .then((res) => {
               setUploadData(res.data)
               alert("Image uploaded")
            })
            .catch((err) => {
               alert(err?.response?.data?.message)
            })
      } catch (err) {
         if (err.response.status === 500) {
            console.log(err)
         } else {
            console.log(err.response.data.msg)
         }
      }
   }

   const stationary = useFormik({
      initialValues: {
         category: "stationary",
         type: "",
         title: "",
         brandDescription: "",
         brand: "",
         price: Number,
         description: "",
         trending: Boolean,
         todaysDeal: Boolean,
         countInStock: Number,
         discount: Number,
         gyankoshDiscount: Number,
      },
      validationSchema: Yup.object({
         category: Yup.string().required("This field required"),
         type: Yup.string().required("This field required"),
         title: Yup.string().required("This field required"),
         price: Yup.number()
            .required("This field required")
            .typeError("you must specify a number"),

         description: Yup.string().required("This field required"),
         countInStock: Yup.number()
            .required("This field required")
            .typeError("you must specify a number"),
      }),
      onSubmit: (values, { resetForm }) => {
         const brandInfo = brands?.find((item) => item?.name === brandName)
         try {
            const productObj = {
               category: stationary.values.category,
               subCategory: subCategoryName,
               type: stationary.values.type,
               title: stationary.values.title,
               images: uploadData,

               brandSpecification: {
                  name: tags1,
                  value: tags2,
               },
               brand: brandName,
               brandDescription: brandInfo?.description,
               brandImage: brandInfo?.image,
               schools: schoolClass,
               description: stationary.values.description,
               price: Number(stationary.values.price),
               countInStock: Number(stationary.values.countInStock),
               discount: Number(stationary.values.discount),
               gyankoshDiscount: Number(stationary.values.gyankoshDiscount),
               trending: stationary.values?.trending,
               todaysDeal: stationary.values?.todaysDeal,
               status: status,
               pointofsale: pointofsale,
               onlinestore: onlinestore,
               orderType: orderType,
               barcode: barcode,
               slug: `${stationary.values.category
                  .trim()
                  .replace(/\s+/g, "-")
                  .toLowerCase()}-${stationary.values.type
                  .trim()
                  .replace(/\s+/g, "-")
                  .toLowerCase()}-${stationary.values.title
                  .trim()
                  .replace(/\s+/g, "-")
                  .toLowerCase()}-${stationary.values.brand
                  .trim()
                  .replace(/\s+/g, "-")
                  .toLowerCase()}-${barcode.trim().replace(/\s+/g, "-")}`,
            }

            if (!barcode) {
               return alert("Please Create a barcode")
            } else if (!uploadData) {
               alert("Please upload an image first!")
            } else if (!subCategoryName) {
               alert("Please Create a sub category!")
            } else {
               axios
                  .post("/api/stationary", productObj, {
                     headers: {
                        "Content-Type": "application/json",
                     },
                  })
                  .then((response) => {
                     toast.success(response?.data?.message)
                     return window.location.reload()
                  })

               // setTimeout(() => window.location.reload(), 2000)
            }
         } catch (err) {
            if (err.response.status === 500) {
               console.log(err)
            } else {
               console.log(err.response.data.msg)
            }
         }
      },
   })

   // CLOSE BARCODE SELECT MODAL
   const closeBarcodeModal = () => {
      setOpenBarcodeModal(false)
   }

   const classTypeHandle = (e) => {
      setSelectedClass(e)
      let typeData = e.map((data) => {
         return data?.value
      })
      setClassType(typeData)
   }
   const schoolsHandle = (e) => {
      setSelectedSchool(e)
      setSchools(e.value)
   }
   const submitSchoolAndClass = () => {
      const alreadyAdded = schoolClass?.find((data) => data?.school === schools)

      if (schools && classType?.length > 0 && !alreadyAdded?.school) {
         const data = {
            school: schools,
            class: classType,
         }
         setSchoolClass([...schoolClass, data])
      }

      if (alreadyAdded?.school) toast.error("already added")

      setSelectedClass(null)
      setSelectedSchool(null)
      setSchools(null)
      setClassType(null)
   }
   const removeFromSchoolAndClass = (data) => {
      setSchoolClass(
         schoolClass.filter((schooldata) => schooldata?.school !== data)
      )
   }

   useEffect(() => {
      dispatch(removeBarcode())
   }, [])

   useEffect(() => {
      const classData = academicSchools?.allClass?.map((item) => {
         return { value: item, label: item }
      })
      const schoolData = academicSchools?.schools?.map((item) => {
         return { value: item?.name, label: item?.name }
      })

      setSchoolOptions(schoolData)
      setClassTypes(classData)
   }, [academicSchools])

   return (
      <>
         <h3 className="text-xl mt-1 capitalize text-palette10 bg-white shadow text-center py-4 select-none">
            Add New Item
         </h3>
         <div className="px-5 md:px-5 lg:px-10 my-5 rounded-lg dark:text-white">
            <div className="flex flex-col sm:flex-row md:flex-col lg:flex-row gap-4 items-start sm:justify-between">
               <h3 className="text-xl capitalize text-palette10 CustomBtn">
                  Stationary
               </h3>
               <div>
                  {!barcode ? (
                     <button
                        onClick={(e) => setOpenBarcodeModal(true)}
                        type="button"
                        className={`${labelStyle} text-center border rounded-lg px-4 py-1.5 border-gray-300`}
                     >
                        Add QR/Barcode
                     </button>
                  ) : (
                     <div className="flex gap-2 items-start">
                        <Barcode value={barcode} height={30} width={1.0} />
                        <button
                           onClick={(e) => dispatch(removeBarcode())}
                           type="button"
                        >
                           <TiDelete
                              size={30}
                              className=" text-red-600 hover:text-red-900 duration-200"
                           />
                        </button>
                     </div>
                  )}
                  <BarcodeGenerate
                     openBarcodeModal={openBarcodeModal}
                     handleCloseBarcodeModal={closeBarcodeModal}
                  />
               </div>
            </div>
            <form onSubmit={stationary?.handleSubmit} className="mt-5">
               <div className="sm:grid grid-cols-2 justify-between gap-3 sm:gap-4 lg:gap-8 w-full space-y-4 sm:space-y-0">
                  <div className="space-y-3 w-full">
                     <div className={boxStyle}>
                        <label htmlFor="productName" className={labelStyle}>
                           Product Name
                        </label>
                        <input
                           type="text"
                           name="title"
                           value={stationary.title}
                           onChange={stationary.handleChange}
                           placeholder="Title"
                           className={`${inputStyle}, lowercase`}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="category" className={labelStyle}>
                           Category
                        </label>
                        <input
                           type="text"
                           name="category"
                           readOnly
                           value={stationary.values.category}
                           placeholder="category"
                           className={`${inputStyle}, lowercase cursor-not-allowed`}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="subCategory" className={labelStyle}>
                           Sub Category
                        </label>
                        <SelectPicker
                           value={subCategoryName}
                           onChange={(item) => setSubCategoryName(item)}
                           placeholder="Sub Category"
                           className={`${inputStyle}, lowercase border-none  !px-0`}
                           data={subCategoryData}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="brand" className={labelStyle}>
                           Brand
                        </label>
                        <SelectPicker
                           value={brandName}
                           onChange={(item) => setBrandName(item)}
                           placeholder="Brand"
                           className={`${inputStyle}, lowercase border-none py-2 !px-0`}
                           data={brandData}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="type" className={labelStyle}>
                           Type
                        </label>
                        <input
                           type="text"
                           name="type"
                           value={stationary.type}
                           onChange={stationary.handleChange}
                           placeholder="type"
                           className={`${inputStyle}, lowercase`}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="price" className={labelStyle}>
                           Price
                        </label>
                        <input
                           type="text"
                           name="price"
                           value={stationary.price}
                           onChange={stationary.handleChange}
                           placeholder="price"
                           className={`${inputStyle}, lowercase`}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="countInStock" className={labelStyle}>
                           Count in Stock
                        </label>
                        <input
                           type="text"
                           name="countInStock"
                           value={stationary.countInStock}
                           onChange={stationary.handleChange}
                           placeholder="countInStock"
                           className={`${inputStyle}, lowercase`}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="discount" className={labelStyle}>
                           Discount
                        </label>
                        <input
                           type="text"
                           name="discount"
                           value={stationary.discount}
                           onChange={stationary.handleChange}
                           placeholder="discount (Must be Number)"
                           className={`${inputStyle}, lowercase`}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label
                           htmlFor="gyankoshDiscount"
                           className={labelStyle}
                        >
                           Gyankosh Discount
                        </label>
                        <input
                           type="text"
                           name="gyankoshDiscount"
                           value={stationary.gyankoshDiscount}
                           onChange={stationary.handleChange}
                           placeholder="gyankosh discount (Must be Number)"
                           className={`${inputStyle}, lowercase`}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="costPerItem" className={labelStyle}>
                           Cost per item
                        </label>
                        <input
                           type="text"
                           name="costPerItem"
                           value={stationary.costPerItem}
                           onChange={stationary.handleChange}
                           placeholder="costPerItem"
                           className={`${inputStyle}, lowercase`}
                        />
                     </div>
                     <div>
                        <ul className="rounded-lg  text-black">
                           {schoolClass?.map((data, index) => {
                              return (
                                 <li
                                    key={index}
                                    className="border border-gray-400 bg-gray-100 flex divide-x rounded-lg"
                                 >
                                    <div className="grid grid-cols-2 w-full divide-x divide-gray-400">
                                       <div className=" py-2 px-3">
                                          {data?.school}
                                       </div>
                                       <div className=" py-2 px-3">
                                          {data?.class?.map((c, index) =>
                                             index + 1 !== data?.class?.length
                                                ? `${c}, `
                                                : c
                                          )}
                                       </div>
                                    </div>
                                    <span
                                       onClick={() =>
                                          removeFromSchoolAndClass(data?.school)
                                       }
                                       className="cursor-pointer w-[24px] flex justify-center items-center "
                                    >
                                       <ImCross />
                                    </span>
                                 </li>
                              )
                           })}
                        </ul>
                     </div>
                     <div className={boxStyle}>
                        <div className="flex">
                           <div className="grid grid-cols-2">
                              <div className="w-full text-gray-600 ring-0">
                                 <p className={labelStyle}>School Names</p>
                                 <Select
                                    options={schoolOptions}
                                    value={selectedSchool}
                                    onChange={schoolsHandle}
                                    placeholder={"school name"}
                                    isSearchable
                                    noOptionsMessage={() => "no school found"}
                                    styles={{
                                       placeholder: (baseStyles, state) => ({
                                          ...baseStyles,
                                          border: "none",
                                          width: "300px",
                                       }),
                                    }}
                                 />
                              </div>
                              <div className="w-full text-gray-600 ring-0">
                                 <p className={labelStyle}>Class Type</p>
                                 <Select
                                    options={classTypes}
                                    value={selectedClass}
                                    onChange={classTypeHandle}
                                    placeholder={"Class Type"}
                                    isSearchable
                                    noOptionsMessage={() => "no class found"}
                                    isMulti
                                    styles={{
                                       placeholder: (baseStyles, state) => ({
                                          ...baseStyles,
                                          border: "none",
                                          width: "300px",
                                       }),
                                    }}
                                 />
                              </div>
                           </div>
                           <div
                              onClick={() => submitSchoolAndClass()}
                              className="cursor-pointer w-[25px] flex justify-center items-center pt-4"
                           >
                              ✔️
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* right side input box */}
                  <div className="space-y-3 w-full">
                     <div className="bg-white dark:bg-dark p-5 rounded-lg w-full shadow">
                        <h3>Product Status</h3>
                        <select
                           name="status"
                           onChange={(e) => {
                              if (e.target.value === "true") {
                                 setStatus(true)
                              } else {
                                 setStatus(false)
                              }
                           }}
                           className={`${inputStyle}, cursor-pointer`}
                        >
                           <option>Select..</option>
                           <option value={true}>Active</option>
                           <option value={false}>Inactive</option>
                        </select>
                        <div className="space-y-2 mt-4 border-t pt-3">
                           <h4 className="uppercase">
                              Sales channels and apps
                           </h4>
                           <div>
                              <input
                                 type="checkbox"
                                 name="onlinestore"
                                 id="onlinestore"
                                 value="onlinestore"
                                 onChange={(e) =>
                                    setonlinestore(e.target.value)
                                 }
                                 className="mr-1"
                              />
                              <label
                                 htmlFor="onlinestore"
                                 name="onlinestore"
                                 className={`${labelStyle} cursor-pointer`}
                              >
                                 Online Store
                              </label>
                           </div>
                           <div>
                              <input
                                 type="checkbox"
                                 name="pointofsale"
                                 id="pointofsale"
                                 value="pointofsale"
                                 onChange={(e) =>
                                    setpointofsale(e.target.value)
                                 }
                                 className="mr-1"
                              />
                              <label
                                 htmlFor="pointofsale"
                                 name="pointofsale"
                                 className={`${labelStyle} cursor-pointer`}
                              >
                                 Point Of Sale
                              </label>
                           </div>
                        </div>
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="specification" className={labelStyle}>
                           Brand Specification
                        </label>
                        <div className="xl:flex items-center justify-start gap-5">
                           <div className="inputTag">
                              <ul className="inputList">
                                 {tags1?.map((tag, i) => (
                                    <li
                                       key={i}
                                       className="my-2 flex items-center justify-between bg-white p-1 text-sm"
                                    >
                                       {tag}
                                       <p
                                          className="cursor-pointer bg-red-500 px-2 py-1 text-xl font-bold text-white"
                                          onClick={() => {
                                             removeTag1(i)
                                          }}
                                       >
                                          <p>x</p>
                                       </p>
                                    </li>
                                 ))}
                                 <li>
                                    <input
                                       type="text"
                                       placeholder="Specification eg:Color"
                                       className={`${inputStyle}, lowercase`}
                                       onKeyDown={inputKeyDown1}
                                       ref={(c) => {
                                          tagInput1 = c
                                       }}
                                    />
                                 </li>
                              </ul>
                           </div>
                           <div>
                              <div className="inputTag">
                                 <ul className="inputList">
                                    {tags2?.map((tag, i) => (
                                       <li
                                          key={i}
                                          className="my-2 flex items-center justify-between bg-white p-1 text-sm"
                                       >
                                          {tag}
                                          <p
                                             className="cursor-pointer bg-red-500 px-2 py-1 text-xl font-bold text-white"
                                             onClick={() => {
                                                removeTag2(i)
                                             }}
                                          >
                                             <p>x</p>
                                          </p>
                                       </li>
                                    ))}
                                    <li>
                                       <input
                                          type="text"
                                          placeholder="Value eg:Yellow"
                                          className={`${inputStyle}, lowercase`}
                                          onKeyDown={inputKeyDown2}
                                          ref={(c) => {
                                             tagInput2 = c
                                          }}
                                       />
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="productImage" className={labelStyle}>
                           Product Image
                        </label>
                        <input
                           type="file"
                           id="file"
                           name="uploadImages"
                           multiple
                           onChange={onChange}
                        />

                        <button onClick={onSubmit} className="CssBtn1">
                           <span className="text">
                              Select only jpg, png, and avif file!
                           </span>
                           <span className="mt-1.5">Add Product Image</span>
                        </button>
                     </div>
                     <div className={boxStyle}>
                        <label
                           htmlFor="productDescription"
                           className={labelStyle}
                        >
                           Product Description
                        </label>
                        <textarea
                           type="text"
                           name="description"
                           value={stationary.description}
                           onChange={stationary.handleChange}
                           placeholder="description"
                           className={`${textAreaStyle}, lowercase`}
                        ></textarea>
                     </div>
                     <div className="bg-white dark:bg-dark p-5 rounded-lg w-full shadow">
                        <h3 className=" mb-4">Order Type</h3>
                        <label
                           htmlFor="current"
                           className="cursor-pointer text-sm mb-2 block"
                        >
                           <input
                              onChange={(e) => setOrderType(e.target.value)}
                              value={"current"}
                              type="radio"
                              id="current"
                              name="orderType"
                              className="mr-2"
                           />
                           <span>Current Order</span>
                        </label>
                        <label htmlFor="pre" className="cursor-pointer text-sm">
                           <input
                              onChange={(e) => setOrderType(e.target.value)}
                              value={"pre"}
                              type="radio"
                              id="pre"
                              name="orderType"
                              className="mr-2"
                           />
                           <span>Pre Order</span>
                        </label>
                     </div>
                     <div>
                        <button
                           type="submit"
                           className="border border-black py-3 px-10 mt-5 text-black font-semibold hover:bg-[#363730] ease-in hover:text-white"
                        >
                           Submit
                        </button>
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </>
   )
}

export default AddStationaries
