import { createSlice } from "@reduxjs/toolkit"
import { enqueueSnackbar } from "notistack"

const initialState = {
   products: localStorage.getItem("orderProducts")
      ? JSON.parse(localStorage.getItem("orderProducts"))
      : [],
   totalQuantity: 0,
   totalAmount: 0,
}

export const createOrderProductSlice = createSlice({
   name: "cart",
   initialState,
   reducers: {
      addProduct(state, action) {
         const product = state.products.findIndex(
            (item) => item._id === action.payload._id
         )
         if (product >= 0) {
            state.products[product].cartQuantity += 1
         } else {
            const tempProduct = {
               ...action.payload,
               cartQuantity: 1,
               returnQty: 0,
               custom_discount: {
                  reason: "",
                  discountType: "",
                  amount: 0,
               },
            }
            state.products.push(tempProduct)
         }
         localStorage.setItem("orderProducts", JSON.stringify(state.products))
      },

      addProducts(state, action) {
         state.products = [...state.products, ...action.payload]
         localStorage.setItem("orderProducts", JSON.stringify(state.products))
      },

      addProductDiscount(state, action) {
         const product = state.products.findIndex(
            (item) => item._id === action.payload.product
         )
         if (product >= 0) {
            state.products[product].custom_discount =
               action.payload.productDiscount
            state.products[product].discountedPrice =
               action.payload.productDiscount.discountType === "amount"
                  ? state.products[product].discountedPrice -
                    action.payload.productDiscount.amount
                  : state.products[product].discountedPrice -
                    (action.payload.productDiscount.amount / 100) *
                       state.products[product].discountedPrice
         } else {
            enqueueSnackbar(`No product found`, { variant: "error" })
         }
         localStorage.setItem("orderProducts", JSON.stringify(state.products))
      },

      removeProductDiscount(state, action) {
         const product = state.products.findIndex(
            (item) => item._id === action.payload.product?._id
         )
         if (product >= 0) {
            state.products[product].discountedPrice =
               state.products[product].price -
               (state.products[product].discount / 100) *
                  state.products[product].price
            state.products[product].custom_discount =
               action.payload.productDiscount
         } else {
            enqueueSnackbar(`No product found`, { variant: "error" })
         }
         localStorage.setItem("orderProducts", JSON.stringify(state.products))
      },
      removeOrderProduct(state, action) {
         state?.products?.map((cartItem) => {
            if (cartItem?._id === action?.payload?._id) {
               const nextproducts = state?.products?.filter(
                  (item) => item?._id !== cartItem?._id
               )
               enqueueSnackbar(
                  `${cartItem?.title} was removed from Orderlist`,
                  { variant: "success" }
               )
               state.products = nextproducts
            }
            localStorage.setItem(
               "orderProducts",
               JSON.stringify(state.products)
            )
            return state
         })
      },
      decreaseProductQuantity(state, action) {
         const itemIndex = state.products.findIndex(
            (item) => item?._id === action.payload?._id
         )
         if (state.products[itemIndex].cartQuantity > 1) {
            state.products[itemIndex].cartQuantity -= 1
         } else if (state.products[itemIndex].cartQuantity === 1) {
            const nextproducts = state.products.filter(
               (item) => item._id !== action.payload._id
            )
            state.products = nextproducts
            enqueueSnackbar("Item was removed from Orderlist", {
               variant: "success",
            })
         }
         localStorage.setItem("orderProducts", JSON.stringify(state.products))
      },
      increaseProductQuantity(state, action) {
         const product = state.products.findIndex(
            (item) => item._id === action.payload._id
         )
         if (product >= 0) {
            state.products[product].cartQuantity += 1
         } else {
            const tempProduct = { ...action.payload, cartQuantity: 1 }
            state.products.push(tempProduct)
         }
         localStorage.setItem("orderProducts", JSON.stringify(state.products))
      },
      getTotals(state, action) {
         let { total, quantity } = state.products.reduce(
            (cartTotal, cartItem) => {
               const { discountedPrice, cartQuantity } = cartItem
               const itemTotal = discountedPrice * cartQuantity

               cartTotal.total += itemTotal
               cartTotal.quantity += cartQuantity

               return cartTotal
            },
            {
               total: 0,
               quantity: 0,
            }
         )
         total = parseFloat(total.toFixed(2))
         state.totalQuantity = quantity
         state.totalAmount = total
      },
      clearProduct(state, action) {
         state.products = []
         localStorage.removeItem("orderProducts")
      },
   },
})

export const {
   addProduct,
   addProducts,
   addProductDiscount,
   removeProductDiscount,
   removeOrderProduct,
   decreaseProductQuantity,
   increaseProductQuantity,
   getTotals,
   clearProduct,
} = createOrderProductSlice.actions

export const OrderProductReducer = createOrderProductSlice.reducer
