import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useFormik } from "formik"
import axios from "axios"
import { useParams } from "react-router-dom"
import { useGetBookQuery } from "../../../../services/books"
import Barcode from "react-barcode"
import { AiOutlineSearch } from "react-icons/ai"
import { RiDeleteBack2Line } from "react-icons/ri"
import UpdateBarcode from "../../../../components/Modal/UpdateBarcode"
import { useGetAuthorQuery } from "../../../../services/authors"
import { useGetSubWriterQuery } from "../../../../services/sub-writer"
import { SelectPicker } from "rsuite"
import { useGetAllPublicationsQuery } from "../../../../services/publications"

const GeneralBooksItemUpdate = () => {
   // Define the initial state for the selected option
   const [openBarcodeModal, setOpenBarcodeModal] = useState(false)
   const { id } = useParams()
   const { data: product } = useGetBookQuery(id)
   const [typeOpen, setTypeOpen] = useState(false)
   const [typeSearchValue, setTypeSearchValue] = useState("")
   const [orderType, setOrderType] = useState("")
   const { userInfo } = useSelector((state) => state.login)
   const [onlinestore, setonlinestore] = useState("")
   const [pointofsale, setpointofsale] = useState("")
   const { data: authors } = useGetAuthorQuery()
   const { data: subwriters } = useGetSubWriterQuery()
   const { data: publications } = useGetAllPublicationsQuery()
   const [authorName, setAuthorName] = useState()
   const [subWriterName, setSubWriterName] = useState()
   const [publicationName, setPublicationName] = useState()

   const authorData = authors?.map((item) => ({
      label: item.name,
      value: item.name,
   }))

   const subWritersData = subwriters?.map((item) => ({
      label: item.name,
      value: item.name,
   }))

   const publicationsData = publications?.map((item) => ({
      label: item.name,
      value: item.name,
   }))

   //image upload
   const [files, setFiles] = useState([])
   const [pdf, setPdf] = useState("")
   const onChange = (e) => {
      setFiles(e.target.files)
   }
   const onChangePdf = (e) => {
      setPdf(e.target.files[0])
   }

   const [uploadData, setUploadData] = useState()
   const [uploadPdf, setUploadPdf] = useState()

   const onSubmit = async (event) => {
      event.preventDefault()
      const formData = new FormData()
      Object.values(files).forEach((file) => {
         formData.append("uploadImages", file)
      })

      try {
         if (files.length === 0)
            return alert("Please upload an image first the create a product!")
         await axios
            .post("/api/image-upload", formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            })
            .then((res) => {
               setUploadData(res.data)
               alert("Image uploaded")
            })
            .catch((err) => {
               alert(err?.response?.data?.message)
            })
      } catch (err) {
         if (err.response.status === 500) {
            console.log(err)
         } else {
            console.log(err.response.data.msg)
         }
      }
   }
   const onSubmitPdf = async (event) => {
      event.preventDefault()
      const formData = new FormData()
      formData.append("uploadPdfs", pdf)

      try {
         if (!pdf)
            return alert("Please upload a pdf first then update this product!")
         await axios
            .post("/api/pdfs-upload", formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            })
            .then((res) => {
               setUploadPdf(res.data)
               alert("pdf uploaded")
            })
            .catch((err) => {
               alert(err?.response?.data?.message)
            })
      } catch (err) {
         if (err.response.status === 500) {
            console.log(err)
         } else {
            console.log(err.response.data.msg)
         }
      }
   }

   const academic = useFormik({
      initialValues: {
         category: "",
         subCategory: "",
         type: "",
         title: "",

         price: "",
         description: "",
         publisher: "",
         published: "",
         isbn: "",
         edition: "",
         language: "",
         countInStock: "",
         discount: "",
         gyankoshDiscount: "",
         ebookDiscount: "",
         ebookPrice: "",
         pageNumber: "",
         costPerItem: "",
         trending: Boolean,
         bookfair: Boolean,
         todaysDeal: Boolean,
         status: Boolean,
         popularNow: Boolean,
      },

      onSubmit: async (values, { resetForm }) => {
         const authorInfo = authors?.find((item) => item?.name === authorName)
         const subWriterInfo = subwriters?.find(
            (item) => item?.name === subWriterName
         )

         try {
            const productObj = {
               category: academic.values.category.replace(/\s+/g, " ").trim(),
               subCategory: academic.values.subCategory
                  .replace(/\s+/g, " ")
                  .trim(),
               // schools: schools,
               type: academic.values.type
                  .replace(/\s+/g, " ")
                  .trim()
                  .toLowerCase(),
               title: academic.values.title,
               author: authorName,
               authorDescription: authorInfo?.description,
               authorImage: authorInfo?.image,
               subWriter: subWriterName,
               subWriterImage: subWriterInfo?.image,
               subWriterDescription: subWriterInfo?.description,
               price: Number(academic.values.price),
               images: uploadData,
               pdfLink: uploadPdf,
               description: academic.values.description,
               publisher: publicationName,
               published: academic.values.published,
               isbn: academic.values.isbn,
               edition: academic.values.edition,
               language: academic.values.language,
               countInStock: Number(academic.values.countInStock),
               discount: academic.values.discount,
               gyankoshDiscount: academic.values.gyankoshDiscount,
               ebookDiscount: Number(academic.values.ebookDiscount),
               ebookPrice: Number(academic.values.ebookPrice),
               status: academic.values?.status,
               pointofsale: pointofsale,
               onlinestore: onlinestore,
               orderType: orderType,
               pageNumber: Number(academic.values.pageNumber),
               costPerItem: Number(academic.values.costPerItem),
               trending: academic.values.trending,
               todaysDeal: academic.values.todaysDeal,
               bookfair: academic.values.bookfair,
               popularNow: academic.values.popularNow,
               slug: `${(
                  academic.values.category.replace(/\s+/g, " ").trim() ||
                  product?.product?.category
               )
                  ?.trim()
                  .replace(/\s+/g, "-")
                  .toLowerCase()}-${(
                  academic.values.title || product?.product?.title
               )
                  ?.trim()
                  .replace(/\s+/g, "-")
                  .toLowerCase()}-${(
                  authorName || product?.product?.author?.name
               )
                  ?.trim()
                  .replace(/\s+/g, "-")
                  .toLowerCase()}-${product?.product?.barcode
                  ?.trim()
                  .replace(/\s+/g, "-")}`,
            }

            console.log("0", productObj)
            await axios
               .patch(
                  `/api/general/${id}`,
                  Object.fromEntries(
                     Object.entries(productObj).filter(([_, v]) => v)
                  ),
                  {
                     headers: {
                        "Content-Type": "application/json",
                        Authorization: `${userInfo.refreshToken}`,
                     },
                  }
               )
               .then((response) => {
                  alert(response?.data?.message)
                  window.location.reload()
               })
         } catch (err) {
            if (err.response.status === 500) {
               console.log(err)
            } else {
               console.log(err.response.data.msg)
            }
         }
      },
   })

   // CLOSE BARCODE SELECT MODAL
   const closeBarcodeModal = () => {
      setOpenBarcodeModal(false)
   }

   const inputStyle =
      "px-2 shadow py-2.5 rounded-md duration-300 outline-none placeholder:font-thin text-sm dark:bg-gray-50 dark:text-dark placeholder:text-darkText"
   const labelStyle = "text-palette10 select-none"
   const boxStyle = "flex flex-col w-full border p-2 rounded"
   const textAreaStyle =
      "px-4 py-2 rounded-lg dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin h-[120px] text-sm dark:text-dark"

   const types = [
      "Law",
      "Drama",
      "Horror",
      "Novel",
      "Poem",
      "Sports",
      "Medical",
      "Cooking",
      "Bangla Classic",
      "Bangla Translate",
      "Sarcasm & Satire",
      "Science Fiction",
      "Health & Disease",
      "Religious Books",
      "Tours & Travels",
      "Articles & Essay",
      "Animal",
      "Olympiad",
      "University",
      "Psychology",
      "Muktijuddho",
      "Bangladesh",
      "Philosophy",
      "Engineering",
      "Awarded Books",
      "History & Culture",
      "Para-Psychology",
      "Family & Parenting",
      "Fashion & Self-care",
      "Computer & Internet",
      "Agriculture & Firming",
      "Society & Environment",
      "Language & Dictionary",
      "Journalism & Mass Media",
      "Society, Culture & History",
      "Freelancing & Outsourcing",
      "Math, Science & Technology",
      "Mystery, Detective, Adventure",
      "Admission & Exam Preparation",
      "Self-Motivations & Meditations",
      "Biographies, Memories & Interviews",
      "Drawing, Painting, Design & Photography",
      "Business, Investment & Economy",
      "Professional, Journal & Reference",
      "Songs, Movies & Entertainment",
      "IELTS",
      "Story",
      "Comics & Picture Books",
      "Religious",
      "Politics",
      "New Arrivals",
   ]

   return (
      <div className="px-10 my-5 rounded-lg dark:text-white space-y-5">
         <h3 className="text-xl capitalize text-palette10 bg-white shadow text-center py-4 select-none">
            General Books
         </h3>
         <div className="flex justify-between items-start">
            <div className={`${boxStyle} w-[300px] `}>
               <p className={labelStyle}>Ichchapuron ID</p>
               <p className="p-4 rounded-lg bg-white dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin text-sm dark:text-dark">
                  {product?.product?.barcode || "N/A"}
               </p>
            </div>

            <div className="flex gap-2 items-start">
               <Barcode
                  value={product?.product?.barcode}
                  height={50}
                  width={1.0}
               />
               {!product?.product?.barcode?.includes("IP") && (
                  <button
                     onClick={(e) => setOpenBarcodeModal(true)}
                     type="button"
                  >
                     <svg
                        width="21px"
                        height="21px"
                        viewBox="0 0 24 24"
                        stroke-width="2.7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        color="#a89f9f"
                     >
                        <path
                           d="M14.3632 5.65156L15.8431 4.17157C16.6242 3.39052 17.8905 3.39052 18.6716 4.17157L20.0858 5.58579C20.8668 6.36683 20.8668 7.63316 20.0858 8.41421L18.6058 9.8942M14.3632 5.65156L4.74749 15.2672C4.41542 15.5993 4.21079 16.0376 4.16947 16.5054L3.92738 19.2459C3.87261 19.8659 4.39148 20.3848 5.0115 20.33L7.75191 20.0879C8.21972 20.0466 8.65806 19.8419 8.99013 19.5099L18.6058 9.8942M14.3632 5.65156L18.6058 9.8942"
                           stroke="#a89f9f"
                           stroke-width="2.7"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                        ></path>
                     </svg>
                  </button>
               )}{" "}
               <UpdateBarcode
                  openBarcodeModal={openBarcodeModal}
                  handleCloseBarcodeModal={closeBarcodeModal}
                  product={product?.product}
               />
            </div>
         </div>
         <form onSubmit={academic.handleSubmit} className="mt-5">
            <div className="flex justify-between gap-8 w-full">
               <div className="space-y-3 w-full">
                  <div className={boxStyle}>
                     <label htmlFor="productName" className={labelStyle}>
                        Product Name
                     </label>
                     <input
                        type="text"
                        name="title"
                        value={academic.values.title}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.title}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.title && academic.errors.title && (
                        <small className="text-palette6 my-0.5">
                           {academic.errors.title}
                        </small>
                     )}
                  </div>

                  <div className={boxStyle}>
                     <label htmlFor="category" className={labelStyle}>
                        Category
                     </label>
                     <input
                        type="text"
                        name="category"
                        value={academic.values.category}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.category}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.category && academic.errors.category && (
                        <small className="text-palette6 my-0.5">
                           {academic.errors.category}
                        </small>
                     )}
                  </div>
                  {/* <div className={ boxStyle }>
                            <MultipleValueTextInput
                                onItemAdded={ ( item, allItems ) =>
                                    setSchools( [
                                        ...schools,
                                        item
                                            .replace( /\s+/g, " " )
                                            .trim()
                                            .toLowerCase(),
                                    ] )
                                }
                                onItemDeleted={ ( item, allItems ) =>
                                    setSchools( allItems )
                                }
                                label="School Names"
                                name="item-input"
                                placeholder={ product?.product?.schools || "Type school name" }
                                className={ `${inputStyle}, lowercase` }
                            />
                        </div> */}
                  <div className={boxStyle}>
                     <label htmlFor="subject" className={labelStyle}>
                        Sub Category
                     </label>
                     <input
                        type="text"
                        name="subCategory"
                        value={academic.values.subCategory}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={
                           product?.product?.subCategory ||
                           "Type English or Bangla"
                        }
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.subCategory &&
                        academic.errors.subCategory && (
                           <small className="text-palette6 my-0.5">
                              {academic.errors.subCategory}
                           </small>
                        )}
                  </div>
                  {/* <div className={ boxStyle }>
                            <label htmlFor="type" className={ labelStyle }>Type</label>
                            <input
                                type="text"
                                name="type"
                                value={ academic.values.type }
                                onChange={ academic.handleChange }
                                onBlur={ academic.handleBlur }
                                placeholder={ product?.product?.type || "Type class name class one, o-level etc" }
                                className={ `${inputStyle}, lowercase` }
                            />
                            { academic.touched.type && academic.errors.type && (
                                <small className="text-palette6 my-0.5">
                                    { academic.errors.type }
                                </small>
                            ) }
                        </div> */}
                  <div onClick={() => setTypeOpen(true)} className={boxStyle}>
                     <label htmlFor="type" className={labelStyle}>
                        Type
                     </label>
                     <input
                        type="text"
                        name="type"
                        value={academic.values.type}
                        onBlur={academic.handleBlur}
                        placeholder={
                           product?.product?.type ||
                           "Type class name class one, o-level etc"
                        }
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.type && academic.errors.type && (
                        <small className="text-palette6 my-0.5">
                           {academic.errors.type}
                        </small>
                     )}
                  </div>
                  <div
                     className={`bg-white mt-2 pb-3 shadow-md rounded-lg overflow-y-auto max-h-96 w-full sm:w-80
                        ${typeOpen ? "block" : "hidden"}`}
                  >
                     <div className="bg-white px-4 sticky top-0 w-full">
                        <div className="text-right">
                           <button onClick={() => setTypeOpen(false)}>
                              <RiDeleteBack2Line size={23}></RiDeleteBack2Line>
                           </button>
                        </div>
                        <div className="flex items-center py-1 px-4  rounded-md bg-gray-200 relative">
                           <AiOutlineSearch className="text-[18px] text-gray-700"></AiOutlineSearch>
                           <input
                              value={typeSearchValue}
                              onChange={(e) =>
                                 setTypeSearchValue(
                                    e.target.value.toLowerCase()
                                 )
                              }
                              type="text"
                              placeholder="Type..."
                              className="placeholder:text-gray-7 outline-none w-full border-none focus:border-none bg-gray-200 focus:ring-0"
                           />
                        </div>
                     </div>
                     <ul className="px-4">
                        <h3 className="text-[15px] font-medium ">
                           Suggestions
                        </h3>
                        {types.map((type, index) => (
                           <li
                              key={index}
                              className={`${
                                 type.toLowerCase().startsWith(typeSearchValue)
                                    ? "block"
                                    : "hidden"
                              }`}
                           >
                              <input
                                 type="text"
                                 name="type"
                                 onClick={academic.handleChange}
                                 onMouseUp={() => setTypeOpen(false)}
                                 value={type}
                                 autocomplete="false"
                                 className="border-none outline-none py-2 hover:bg-gray-100 cursor-pointer w-full text-sm  rounded-md px-2"
                              />
                           </li>
                        ))}
                     </ul>
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="price" className={labelStyle}>
                        Price
                     </label>
                     <input
                        type="text"
                        name="price"
                        value={academic.values.price}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.price}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.price && academic.errors.price && (
                        <small className="text-palette6 my-0.5">
                           {academic.errors.price}
                        </small>
                     )}
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="publisher" className={labelStyle}>
                        Publisher
                     </label>
                     <SelectPicker
                        value={publicationName}
                        onChange={(item) => setPublicationName(item)}
                        placeholder={product?.product?.publisher}
                        className={`my-2 lowercase`}
                        data={publicationsData}
                     />
                  </div>

                  <div className={boxStyle}>
                     <label htmlFor="isbnNumber" className={labelStyle}>
                        ISBN Number
                     </label>
                     <input
                        type="text"
                        name="isbn"
                        value={academic.values.isbn}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.isbn}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.isbn && academic.errors.isbn && (
                        <small className="text-palette6 my-0.5">
                           {academic.errors.isbn}
                        </small>
                     )}
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="published" className={labelStyle}>
                        Published
                     </label>
                     <input
                        type="text"
                        name="published"
                        value={academic.values.published}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.published}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.published &&
                        academic.errors.published && (
                           <small className="text-palette6 my-0.5">
                              {academic.errors.published}
                           </small>
                        )}
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="edition" className={labelStyle}>
                        Edition
                     </label>
                     <input
                        type="text"
                        name="edition"
                        value={academic.values.edition}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.edition}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.edition && academic.errors.edition && (
                        <small className="text-palette6 my-0.5">
                           {academic.errors.edition}
                        </small>
                     )}
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="language" className={labelStyle}>
                        Language
                     </label>
                     <input
                        type="text"
                        name="language"
                        value={academic.values.language}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.language}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.language && academic.errors.language && (
                        <small className="text-palette6 my-0.5">
                           {academic.errors.language}
                        </small>
                     )}
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="countInStock" className={labelStyle}>
                        Count in Stock
                     </label>
                     <input
                        type="text"
                        name="countInStock"
                        value={academic.values.countInStock}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.countInStock}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.countInStock &&
                        academic.errors.countInStock && (
                           <small className="text-palette6 my-0.5">
                              {academic.errors.countInStock}
                           </small>
                        )}
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="discount" className={labelStyle}>
                        Discount
                     </label>
                     <input
                        type="text"
                        name="discount"
                        value={academic.values.discount}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.discount}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.discount && academic.errors.discount && (
                        <small className="text-palette6 my-0.5">
                           {academic.errors.discount}
                        </small>
                     )}
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="gyankoshDiscount" className={labelStyle}>
                        Gyankosh Discount
                     </label>
                     <input
                        type="text"
                        name="gyankoshDiscount"
                        value={academic.values.gyankoshDiscount}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.gyankoshDiscount}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.gyankoshDiscount &&
                        academic.errors.gyankoshDiscount && (
                           <small className="text-palette6 my-0.5">
                              {academic.gyankoshDiscount}
                           </small>
                        )}
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="costPerItem" className={labelStyle}>
                        Cost per item
                     </label>
                     <input
                        type="number"
                        name="costPerItem"
                        value={academic.values.costPerItem}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.costPerItem}
                        className={`${inputStyle}, lowercase`}
                     />
                     {academic.touched.costPerItem &&
                        academic.errors.costPerItem && (
                           <small className="text-palette6 my-0.5">
                              {academic.errors.costPerItem}
                           </small>
                        )}
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="pageNumber" className={labelStyle}>
                        Page Number
                     </label>
                     <input
                        type="number"
                        name="pageNumber"
                        placeholder={product?.product?.pageNumber}
                        value={academic.values.pageNumber}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="trending" className={labelStyle}>
                        Trending Now
                     </label>
                     <input
                        type="text"
                        name="trending"
                        placeholder={
                           product?.product?.trending === true
                              ? "true"
                              : "false"
                        }
                        value={academic.values.trending}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="todaysDeal" className={labelStyle}>
                        Todays Deal
                     </label>

                     <input
                        type="text"
                        name="todaysDeal"
                        placeholder={
                           product?.product?.todaysDeal === true
                              ? "true"
                              : "false"
                        }
                        value={academic.values.todaysDeal}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="popularNow" className={labelStyle}>
                        Popular Now
                     </label>
                     <input
                        type="text"
                        name="popularNow"
                        placeholder={
                           product?.product?.popularNow === true
                              ? "true"
                              : "false"
                        }
                        value={academic.values.popularNow}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="bookfair" className={labelStyle}>
                        Book Fair
                     </label>
                     <input
                        type="text"
                        name="bookfair"
                        placeholder={
                           product?.product?.bookfair === true
                              ? "true"
                              : "false"
                        }
                        value={academic.values.bookfair}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className="bg-white dark:bg-dark p-5 rounded-lg w-full shadow">
                     <h3>Product Status</h3>
                     <select
                        name="status"
                        onClick={academic.handleChange}
                        className={`${inputStyle}, cursor-pointer`}
                     >
                        <option value={true}>Active</option>
                        <option
                           selected={product?.product?.status !== true}
                           value={false}
                        >
                           Inactive
                        </option>
                     </select>
                     <div className="space-y-2 mt-4 border-t pt-3">
                        <h4 className="uppercase">Sales channels and apps</h4>
                        <div>
                           <input
                              type="checkbox"
                              id="onlinestore"
                              name="onlinestore"
                              value="onlinestore"
                              onChange={(e) => setonlinestore(e.target.value)}
                              className="mr-1"
                           />
                           <label
                              htmlFor="onlinestore"
                              name="onlinestore"
                              className={`${labelStyle} cursor-pointer`}
                           >
                              Online Store
                           </label>
                        </div>
                        <div>
                           <input
                              type="checkbox"
                              id="pointofsale"
                              name="pointofsale"
                              value="pointofsale"
                              onChange={(e) => setpointofsale(e.target.value)}
                              className="mr-1"
                           />
                           <label
                              htmlFor="pointofsale"
                              name="pointofsale"
                              className={`${labelStyle} cursor-pointer`}
                           >
                              Point Of Sale
                           </label>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="space-y-3 w-full">
                  <div className={boxStyle}>
                     <label htmlFor="authorName" className={labelStyle}>
                        Author Name
                     </label>
                     <SelectPicker
                        value={authorName}
                        onChange={(item) => setAuthorName(item)}
                        placeholder={product?.product?.author?.name}
                        className={`${inputStyle}, lowercase`}
                        data={authorData}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="subWriter" className={labelStyle}>
                        subWriter Name
                     </label>
                     <SelectPicker
                        value={subWriterName}
                        onChange={(item) => setSubWriterName(item)}
                        placeholder={product?.product?.subWriter}
                        className={`${inputStyle}, lowercase `}
                        data={subWritersData}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="uploadPdfs" className={labelStyle}>
                        EBOOK
                     </label>
                     <input
                        type="file"
                        id="file"
                        name="uploadPdfs"
                        onChange={onChangePdf}
                     />
                     <button onClick={onSubmitPdf} className="CssBtn1">
                        <span className="text">Select only Pdf file!</span>
                        <span className="mt-1.5">Add PDF</span>
                     </button>
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="ebookPrice" className={labelStyle}>
                        Ebook Price
                     </label>
                     <input
                        type="number"
                        name="ebookPrice"
                        value={academic.values.ebookPrice}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.ebookPrice}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="ebookDiscount" className={labelStyle}>
                        Ebook Discount
                     </label>
                     <input
                        type="number"
                        name="ebookDiscount"
                        value={academic.values.ebookDiscount}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.ebookDiscount}
                        className={`${inputStyle}, lowercase`}
                     />
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="productImage" className={labelStyle}>
                        Product Image
                     </label>
                     <input
                        type="file"
                        id="file"
                        name="uploadImages"
                        multiple
                        onChange={onChange}
                     />
                     {/* <span className="text-xs text-white w-full h-full bg-palette6 p-1 rounded px-0.5 mt-1">
                                only jpg, png, and avif format allowed!
                            </span>
                            <button
                                className="CssBtn2"
                                onClick={ onSubmit }
                            >
                                Add image
                            </button>{ " " } */}
                     <button onClick={onSubmit} className="CssBtn1">
                        <span className="text">
                           Select only jpg, png, and avif file!
                        </span>
                        <span className="mt-1.5">Add Product Image</span>
                     </button>
                  </div>
                  <div className={boxStyle}>
                     <label htmlFor="productDescription" className={labelStyle}>
                        Product Description
                     </label>
                     <textarea
                        name="description"
                        value={academic.values.description}
                        onChange={academic.handleChange}
                        onBlur={academic.handleBlur}
                        placeholder={product?.product?.description}
                        className={`${textAreaStyle}, lowercase`}
                     ></textarea>
                     {academic.touched.description &&
                        academic.errors.description && (
                           <small className="text-palette6 my-0.5">
                              {academic.errors.description}
                           </small>
                        )}
                  </div>
                  <div className="bg-white dark:bg-dark p-5 rounded-lg w-full shadow">
                     <h3 className=" mb-4">Order Type</h3>
                     <label
                        htmlFor="current"
                        className="cursor-pointer text-sm mb-2 block"
                     >
                        <input
                           onChange={(e) => setOrderType(e.target.value)}
                           value={"current"}
                           type="radio"
                           id="current"
                           name="orderType"
                           className="mr-2"
                        />
                        <span>Current Order</span>
                     </label>
                     <label htmlFor="pre" className="cursor-pointer text-sm">
                        <input
                           onChange={(e) => setOrderType(e.target.value)}
                           value={"pre"}
                           type="radio"
                           id="pre"
                           name="orderType"
                           className="mr-2"
                        />
                        <span>Pre Order</span>
                     </label>
                  </div>
                  {/* <button type='submit' className={ `${orderType === "current" || orderType === "pre" || 'disabled cursor-wait'} CustomBtn` }>Submit</button> */}
                  <div>
                     <button
                        type="submit"
                        className="border border-black py-3 px-10 mt-5 text-black font-semibold hover:bg-[#363730] ease-in hover:text-white"
                     >
                        Submit
                     </button>
                  </div>
               </div>
            </div>
         </form>
      </div>
   )
}

export default GeneralBooksItemUpdate
