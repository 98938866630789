import React, { useState } from "react"
import { Modal } from "rsuite"
import { useSelector } from "react-redux"
import axios from "axios"
import toast from "react-hot-toast"

const UpdateBarcode = ({
   openBarcodeModal,
   handleCloseBarcodeModal,
   product,
}) => {
   const [productBarcode, setProductBarcode] = useState("")
   const { userInfo } = useSelector((state) => state.login)

   const addBarcode = async () => {
      await axios
         .patch(
            `/api/${product?.category}/${product?._id}`,
            { barcode: productBarcode },
            {
               headers: {
                  "Content-Type": "application/json",
                  Authorization: `${userInfo.refreshToken}`,
               },
            }
         )
         .then((response) => {
            toast.success(response?.data?.message)
            return window.location.reload()
         })
   }
   return (
      <Modal
         size={"sm"}
         open={openBarcodeModal}
         aria-labelledby="contained-modal-title-vcenter"
         centered
      >
         <Modal.Body>
            <div className="relative pb-0 dark:bg-dark text-base space-y-4 min-h-72">
               <h3 className="text-lg font-semibold border-b pb-4">
                  Update Barcode
               </h3>
               <div className="flex items-center justify-center flex-col ">
                  <input
                     type="text"
                     name="title"
                     value={productBarcode}
                     onChange={(e) => setProductBarcode(e.target.value)}
                     placeholder="Scan your QR / Barcode"
                     className={`border-2 border-custom_skyBlue rounded-lg p-3 outline-none`}
                  />
               </div>
            </div>
         </Modal.Body>
         <Modal.Footer>
            <div className="text-right py-5 border-t">
               <button
                  onClick={() => {
                     handleCloseBarcodeModal()
                     setProductBarcode("")
                  }}
                  className="py-2 w-24 bg-gray-200 hover:bg-gray-300 duration-200 text-base font-medium border border-gray-300 rounded-lg cursor-pointer mr-2"
               >
                  Cancel
               </button>
               {productBarcode && (
                  <button
                     onClick={() => {
                        addBarcode(productBarcode)
                        handleCloseBarcodeModal()
                     }}
                     className="py-2 w-24 bg-green-800 hover:bg-green-900 duration-200 text-white text-base font-medium border border-gray-300 rounded-lg cursor-pointer mr-2"
                  >
                     Add
                  </button>
               )}
            </div>
         </Modal.Footer>
      </Modal>
   )
}

export default UpdateBarcode
