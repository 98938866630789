import { createSlice } from "@reduxjs/toolkit";
import { getCarts } from "./allCartFunc";

const getCartsSlice = createSlice({
  name: "getCarts",
  initialState: {
    carts: [],
    status: null,
  },
  extraReducers: {
    [getCarts.pending]: (state) => {
      state.status = "loading";
    },
    [getCarts.fulfilled]: (state, action) => {
      state.carts = action.payload;
      state.status = "success";
    },
    [getCarts.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default getCartsSlice.reducer;
