import { Spinner } from "@material-tailwind/react"

const CustomSpinner = () => {
   return (
      <div className="w-full h-screen flex items-center justify-center">
         <Spinner className="h-8 w-8" />
      </div>
   )
}

export default CustomSpinner