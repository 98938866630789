export const reportData=[
    {
        icon : <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2 2 7l10 5 10-5-10-5Z" fill="currentColor"></path>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.55 1.1a1 1 0 0 1 .9 0l10 5a1 1 0 0 1 0 1.8l-10 5a1 1 0 0 1-.9 0l-10-5a1 1 0 0 1 0-1.8l10-5ZM4.24 7 12 10.88 19.77 7 12 3.12 4.24 7ZM1.1 16.55a1 1 0 0 1 1.35-.44L12 20.88l9.55-4.77a1 1 0 1 1 .9 1.78l-10 5a1 1 0 0 1-.9 0l-10-5a1 1 0 0 1-.44-1.34Z" fill="currentColor"></path>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.1 11.55a1 1 0 0 1 1.35-.44L12 15.88l9.55-4.77a1 1 0 1 1 .9 1.78l-10 5a1 1 0 0 1-.9 0l-10-5a1 1 0 0 1-.44-1.34Z" fill="currentColor"></path>
        </svg>,
          description : "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quos ea reprehenderit dolorem vel totam dignissimos nostrum, placeat repellat vero harum dolores, nesciunt asperiores fugiat illum at cum? Quam, culpa pariatur!"
    },
    {
        icon : <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#a___xD8HZdAi)" fillRule="evenodd" clipRule="evenodd" fill="currentColor">
            <path d="M.3 5.3a1 1 0 0 1 1.4 0L8.5 12l4.3-4.3a1 1 0 0 1 1.4 0l9.5 9.5a1 1 0 0 1-1.4 1.4L13.5 10l-4.3 4.3a1 1 0 0 1-1.4 0L.3 6.7a1 1 0 0 1 0-1.4Z"></path>
            <path d="M23 11c.6 0 1 .4 1 1v6c0 .6-.4 1-1 1h-6a1 1 0 1 1 0-2h5v-5c0-.6.4-1 1-1Z"></path>
        </g>
        <defs>
            <clipPath id="a___xD8HZdAi">
                <path fill="#fff" d="M0 0h24v24H0z"></path>
            </clipPath>
        </defs>
        </svg>,
        description : "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quos ea reprehenderit dolorem vel totam dignissimos nostrum, placeat repellat vero harum dolores, nesciunt asperiores fugiat illum at cum? Quam, culpa pariatur!"
    },
    {
        icon : <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.3.3a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.4-1.4L19.58 5l-3.3-3.3a1 1 0 0 1 0-1.4Z" fill="currentColor"></path>
        <path fillRule="evenodd" clipRule="evenodd" d="M7 6a3 3 0 0 0-3 3v2a1 1 0 1 1-2 0V9a5 5 0 0 1 5-5h14a1 1 0 1 1 0 2H7ZM7.7 14.3a1 1 0 0 1 0 1.4L4.42 19l3.3 3.3a1 1 0 1 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 0 1 1.42 0Z" fill="currentColor"></path>
        <path fillRule="evenodd" clipRule="evenodd" d="M21 12a1 1 0 0 1 1 1v2a5 5 0 0 1-5 5H3a1 1 0 1 1 0-2h14a3 3 0 0 0 3-3v-2a1 1 0 0 1 1-1Z" fill="currentColor"></path>
        </svg> ,
        description : "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quos ea reprehenderit dolorem vel totam dignissimos nostrum, placeat repellat vero harum dolores, nesciunt asperiores fugiat illum at cum? Quam, culpa pariatur!"
    }

]