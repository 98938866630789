import React, { useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { evertalenventoryData } from "../Dashboard/MenuItem/Inventory/SubmenuData"

const EvertaleDrawer = () => {
   const [inventoryItem, setInventoryItem] = useState("")
   const navigate = useNavigate()
   return (
      <div className="">
         <div className="flex items-center gap-2 text-primary dark:text-darkText text-sm pt-7">
            <svg
               width="18"
               height="18"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  d="M1.045 4.488h15.91"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinejoin="round"
               ></path>
               <path
                  d="M4.325 1.164h9.589c.423 0 .824.187 1.095.512l1.617 1.94c.213.257.33.58.33.913v10.408a1.9 1.9 0 0 1-1.9 1.9H3.183a1.9 1.9 0 0 1-1.9-1.9V4.529c0-.333.117-.656.33-.912L3.23 1.676c.271-.325.672-.512 1.095-.512zM6.98 7.812h4.275M9.12 1.4v2.85"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
               ></path>
            </svg>
            All Items
         </div>
         <ul className="pt-7 border-l">
            {evertalenventoryData?.map((item) => {
               return (
                  <li
                     key={item?.id}
                     onClick={() => {
                        navigate(`${item?.path}`)
                        setInventoryItem(item?.name)
                     }}
                     className="pl-7 pr-3 py-2 hover:bg-[#968a8a1e] group-[]: rounded-lg group/item cursor-pointer"
                  >
                     <button
                        className={`flex items-center justify-between gap-2 text-sm w-full ${
                           inventoryItem === item?.name
                              ? "text-red-600"
                              : "text-primary dark:text-darkText"
                        }`}
                     >
                        <div className="flex items-center gap-2">
                           <svg
                              width="20"
                              height="16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="m7.47 2.53.22.22H18c.69 0 1.25.56 1.25 1.25v10c0 .69-.56 1.25-1.25 1.25H2c-.69 0-1.25-.56-1.25-1.25V2C.75 1.31 1.31.75 2 .75h3.69l1.78 1.78z"
                                 fill="#fff"
                                 stroke="currentColor"
                                 strokeWidth="1.5"
                              ></path>
                           </svg>
                           {item?.name}
                        </div>
                     </button>
                  </li>
               )
            })}
         </ul>
         <ul className="mt-[20%]">
            <li
               onClick={() => {
                  navigate(`/brand`)
                  setInventoryItem("Brand")
               }}
               className="pl-7 pr-3 py-2 hover:bg-[#968a8a1e] group-[]: rounded-lg group/item cursor-pointer"
            >
               <button
                  className={`flex items-center justify-between gap-2 text-sm w-full ${
                     inventoryItem === "Brand"
                        ? "text-red-600"
                        : "text-primary dark:text-darkText"
                  }`}
               >
                  <div className="flex items-center gap-2">
                     <svg
                        width="18"
                        height="18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M1.045 4.488h15.91"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinejoin="round"
                        ></path>
                        <path
                           d="M4.325 1.164h9.589c.423 0 .824.187 1.095.512l1.617 1.94c.213.257.33.58.33.913v10.408a1.9 1.9 0 0 1-1.9 1.9H3.183a1.9 1.9 0 0 1-1.9-1.9V4.529c0-.333.117-.656.33-.912L3.23 1.676c.271-.325.672-.512 1.095-.512zM6.98 7.812h4.275M9.12 1.4v2.85"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        ></path>
                     </svg>
                     Brand
                  </div>
               </button>
            </li>
         </ul>
      </div>
   )
}

export default EvertaleDrawer
