import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const bookfairApi = createApi({
   reducerPath: "bookfairApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["bookfairApi"],
   endpoints: (builder) => ({
      getbookfair: builder.query({
         query: (page) => `api/bookfair`,
         providesTags: ["bookfairApi"],
      }),
   }),
})

export const { useGetbookfairQuery } = bookfairApi
