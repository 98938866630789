import { createSlice } from "@reduxjs/toolkit"

const initialState = {
   barcode: "",
}

const barcodeSlice = createSlice({
   name: "barcode",
   initialState,
   reducers: {
      setBarcode: (state, action) => {
         state.barcode = action.payload
      },
      removeBarcode: (state) => {
         state.barcode = ""
      },
   },
})

export const { setBarcode, removeBarcode } = barcodeSlice.actions
export default barcodeSlice.reducer
