import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const userApi = createApi({
   reducerPath: "userApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["user"],
   endpoints: (builder) => ({
      getUsers: builder.query({
         query: () => "api/user",
         providesTags: ["user"],
      }),
      getUser: builder.query({
         query: (id) => `api/user/${id}`,
         providesTags: ["user"],
      }),
      updateUser: builder.mutation({
         query: ({ id, ...rest }) => ({
            url: `api/user/${id}`,
            method: "PATCH",
            body: rest,
         }),
         invalidatesTags: ["user"],
      }),
      deleteUser: builder.mutation({
         query: (id) => ({
            url: `api/user/${id}`,
            method: "DELETE",
         }),
         invalidatesTags: ["user"],
      }),
   }),
})

export const {
   useGetUsersQuery,
   useGetUserQuery,
   useUpdateUserMutation,
   useDeleteUserMutation,
} = userApi
