import React from "react"
import { useDispatch } from "react-redux"
import { addProducts, clearProduct } from "../services/order/orderProductSlice"
import { useNavigate } from "react-router-dom"

const CreateOrderButton = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch()
   const handleCreateOrder = () => {
      dispatch(clearProduct())

      navigate(`/createOrders`, {
         state: {
            route: "create",
         },
      })
   }
   return (
      <button
         type="button"
         onClick={handleCreateOrder}
         className="py-2 px-5 border border-custom_green bg-custom_green hover:bg-custom_lightGreen duration-150 text-white rounded-lg"
      >
         Create Order
      </button>
   )
}

export default CreateOrderButton
