import React, { useEffect, useState } from "react"
import { Modal } from "rsuite"
import {
   useEditCustomerInfoMutation,
   useGetBDGeoQuery,
} from "../../services/customer"
import { useDispatch, useSelector } from "react-redux"
import { editSelectedCustomer } from "../../services/Features/customerSlice"

const EditShippingAddress = ({ open, handleClose }) => {
   const { customer } = useSelector((state) => state.customer)
   const [editCustomerInfo] = useEditCustomerInfoMutation()
   const { data: bdGeoData } = useGetBDGeoQuery()
   const [thana, setThana] = useState([])

   const dispatch = useDispatch()

   const [SA, setSA] = useState({
      phone: "",
      address: "",
      area: "",
      district: "",
      thana: "",
   })
   useEffect(() => {
      const data = bdGeoData?.find((item) => item?.city === SA.district)

      setThana(data?.thana)
   }, [SA.district])
   useEffect(() => {
      setSA({
         ...customer?.shippingAddress,
      })
   }, [customer])

   const newShippingAddress = {
      phone: SA.phone || customer?.shippingAddress?.phone,
      address: SA.address || customer?.shippingAddress?.address,
      area: SA.area || customer?.shippingAddress?.area,
      district: SA.district || customer?.shippingAddress?.district,
      thana: SA.thana || customer?.shippingAddress?.thana,
   }

   const editCustomer = async (e) => {
      e.preventDefault()
      dispatch(editSelectedCustomer({ shippingAddress: newShippingAddress }))
      await editCustomerInfo({
         id: customer?._id,
         shippingAddress: newShippingAddress,
      })
      handleClose()
   }

   return (
      <Modal open={open}>
         <Modal.Body>
            <div className="relative pb-0 dark:bg-dark">
               <div
                  onClick={handleClose}
                  className="absolute right-2 top-2 text-[20px] mr-2 cursor-pointer"
               >
                  ✕
               </div>
               <h3 className="text-lg font-semibold border-b pb-4 mb-5">
                  Create a new customer
               </h3>
               <div className="text-[15px]">
                  <form action="" className="space-y-4 relative">
                     {/* shipping address */}

                     <div className="space-y-3">
                        <span className="mb-3 inline-block">
                           Shipping address
                        </span>

                        {/* district and divistion */}
                        <div className="flex items-center justify-between gap-2">
                           <div className="flex flex-col w-full">
                              <label htmlFor="district">District</label>
                              <select
                                 value={SA.district}
                                 name="district"
                                 onChange={(e) =>
                                    setSA({ ...SA, district: e.target.value })
                                 }
                                 className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 w-full mt-1 outline-none text-sm"
                              >
                                 <option value="">Select District</option>
                                 {bdGeoData?.map((item) => {
                                    return (
                                       <option
                                          selected={SA.district === item.city}
                                          key={item?.city}
                                          value={item?.city}
                                       >
                                          {item?.city}
                                       </option>
                                    )
                                 })}
                              </select>
                           </div>{" "}
                           <div className="flex flex-col w-full">
                              <label htmlFor="thana">Thana</label>
                              <select
                                 name="thana"
                                 onChange={(e) =>
                                    setSA({ ...SA, thana: e.target.value })
                                 }
                                 className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 w-full mt-1 outline-none text-sm"
                              >
                                 <option value="">Select Thana</option>
                                 {thana?.map((data) => {
                                    return (
                                       <option
                                          selected={SA.thana === data}
                                          value={data}
                                       >
                                          {data}
                                       </option>
                                    )
                                 })}
                              </select>
                           </div>
                        </div>
                        {/*city and phone */}
                        <div className="flex items-center justify-between gap-2">
                           {/* address */}
                           <div className="flex flex-col w-full">
                              <label htmlFor="discountType">Address</label>
                              <input
                                 name="address"
                                 value={SA.address}
                                 onChange={(e) =>
                                    setSA({ ...SA, address: e.target.value })
                                 }
                                 type="text"
                                 className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                                 placeholder="Address"
                              />
                           </div>
                           <div className="flex flex-col w-full">
                              <label htmlFor="area">Area</label>
                              <input
                                 name="area"
                                 value={SA.area}
                                 onChange={(e) =>
                                    setSA({ ...SA, area: e.target.value })
                                 }
                                 type="text"
                                 className="dark:text-dark  w-full px-3 py-2 border border-gray-300 rounded-lg outline-none"
                                 placeholder="Area"
                              />
                           </div>
                        </div>
                        <div className="flex flex-col w-full">
                           <label htmlFor="discountType">Phone</label>
                           <input
                              name="phone"
                              value={SA.phone}
                              onChange={(e) =>
                                 setSA({ ...SA, phone: e.target.value })
                              }
                              type="phone"
                              className="dark:text-dark  w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                              placeholder="Phone"
                           />
                        </div>
                     </div>
                     <div className="text-right py-5 border-t ">
                        <button
                           type="button"
                           onClick={handleClose}
                           className="py-2 px-4 border border-gray-300 hover:bg-gray-100 rounded-lg cursor-pointer mr-2"
                        >
                           Cancel
                        </button>
                        <button
                           onClick={editCustomer}
                           type="submit"
                           className="py-2 px-4 rounded-md border border-green-800 bg-green-800 hover:border-green-900 hover:bg-green-900  duration-150 text-white "
                        >
                           Update
                        </button>
                     </div>
                  </form>
               </div>
            </div>
         </Modal.Body>
      </Modal>
   )
}

export default EditShippingAddress
