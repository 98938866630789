import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { permissionHandle } from "../../../services/Features/employeeSlice"
import { useGetEmployeeQuery } from "../../../services/employee"
import { useParams } from "react-router-dom"
import { employeeFromLocal } from "../../../utils/utils"
import { directorEmail } from "../../../utils/const-info"

const EmployeePermission = ({ data }) => {
   const { id } = useParams()
   const { data: employeeInfo } = useGetEmployeeQuery(id)
   const exit = employeeInfo?.employee?.userPermission?.find(
      (item) => item?.title === data?.title
   )
   const [value, setValue] = useState(exit?.permission)
   const { permissions } = useSelector((state) => state.employeeAction)
   const [open, setOpen] = useState(false)
   const dispatch = useDispatch()

   const handlePermisstions = (e) => {
      const info = {
         title: data?.title,
         path: data?.path,
         permission: e.target.value,
      }
      setOpen(false)
      setValue(e.target.value)
      dispatch(permissionHandle(info))
   }

   useEffect(() => {
      setValue(exit?.permission)
   }, [exit?.permission])

   return (
      <li className="flex justify-between pb-2 text-gray-500">
         <div className="flex gap-3 items-center">
            <svg
               width="22"
               height="18"
               viewBox="0 0 30 25"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  d="M0 3C0 1.34315 1.34315 0 3 0H10L14 4H27C28.6569 4 30 5.34315 30 7V22C30 23.6569 28.6569 25 27 25H3C1.34315 25 0 23.6569 0 22V3Z"
                  fill="#A5A9BE"
               ></path>
            </svg>
            <h3 className="text-[15px] capitalize">{data?.title}</h3>
         </div>
         <div className={`relative`}>
            <button
               onClick={() => setOpen(!open)}
               type="button"
               className="border border-gray-200  px-8 py-3 font-semibold  rounded-md text-[13px] hover:bg-gray-100 top-0 right-0"
            >
               {value ? value : "ADD"}
            </button>
            <div
               className={`absolute top-12 left-0 z-50 bg-white ${
                  employeeFromLocal?.email !== directorEmail &&
                  employeeInfo?.employee?.email === directorEmail &&
                  "hidden cursor-not-allowed "
               }`}
            >
               <div
                  className={` rounded-md w-44 shadow-lg cursor-pointer ${
                     open ? "block" : "hidden"
                  }`}
               >
                  <input
                     onClick={(e) => handlePermisstions(e)}
                     className={`py-3 px-4 border hover:bg-gray-100 rounded-md outline-none cursor-pointer ${
                        exit?.permission === "View Only" && "bg-gray-200"
                     }`}
                     value="View Only"
                  />
                  <input
                     onClick={(e) => handlePermisstions(e)}
                     className={`py-3 px-4 hover:bg-gray-100 border rounded-md border-t-0 outline-none cursor-pointer ${
                        exit?.permission === "View and Edit" && "bg-gray-200"
                     }`}
                     value="View and Edit"
                  />
                  <input
                     onClick={(e) => handlePermisstions(e)}
                     className={`py-3 px-4 hover:bg-gray-100 border rounded-md border-t-0 outline-none cursor-pointer`}
                     value="Remove"
                  />
               </div>
            </div>
         </div>
      </li>
   )
}

export default EmployeePermission
