import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mode: JSON.parse(localStorage.getItem('darkMode')) || false
}
export const darkModeSlice = createSlice({
    name:'darkMode',
    initialState,
    reducers:{
        darkMode : (state) =>{
            state.mode = "true"
            localStorage.setItem('darkMode',JSON.stringify(state.mode))
        },
        lightMode : (state) =>{
            state.mode = "false"
            localStorage.setItem('darkMode',JSON.stringify(state.mode))
        },
    }
})

export const {darkMode,lightMode} = darkModeSlice.actions
export default darkModeSlice.reducer;