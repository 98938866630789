// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// Define a service using a base URL and expected endpoints
export const authApi = createApi({
   reducerPath: 'authApi',
   baseQuery: fetchBaseQuery({ baseUrl: '/' }),
   tagTypes: ['Auth'],
   endpoints: (builder) => ({
      getUsers: builder.query({
         query: () => `api/user/all-users`,
         providesTags: ['Auth'],
      }),
      getUser: builder.query({
         query: (id) => `api/user/user-info/${id}`,
         providesTags: ['Auth'],
      }),
      editUser: builder.mutation({
         query: ({ id, ...rest }) => ({
            url: `api/user/${id}`,
            method: 'PUT',
            body: rest,
         }),
         invalidatesTags: ['Auth'],
      }),
      deleteUser: builder.mutation({
         query: (id) => ({
            url: `api/user/${id}`,
            method: 'DELETE',
         }),
         invalidatesTags: ['Auth'],
      }),
   }),
})

export const {
   useGetUsersQuery,
   useGetUserQuery,
   useEditUserMutation,
   useDeleteUserMutation,
} = authApi
