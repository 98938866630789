import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    barcode:''
}
export const productSearchSlice = createSlice({
    name:'productSearch',
    initialState,
    reducers:{
        searchedProductBarcode : (state,action) =>{
            state.barcode =action.payload.barcode
        }
    }
})

export const {searchedProductBarcode} = productSearchSlice.actions
export default productSearchSlice.reducer;