import { useContext, useEffect, useRef, useState } from "react"
// import ScanningModal from "../../Scanning/ScanningModal"
import Customers from "../../components/Customers/Customers"
// import LeavePage from "../Drafts/LeavePage"
import { DashboardContext } from "../../context/DashboardProvider"
import { Avatar, Modal } from "rsuite"
import { useGetSearchKeywordQuery } from "../../services/Search"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import { Link } from "react-router-dom"
import {
   useGetOrderByIdQuery,
   useDeleteOrderMutation,
   useCreateOrderMutation,
   useOrderConfirmationMutation,
} from "../../services/order"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useUpdateOrderMutation } from "../../services/order"
import { useDispatch, useSelector } from "react-redux"
import {
   Chip,
   Button,
   Input,
   Dialog,
   DialogHeader,
   DialogBody,
   DialogFooter,
   Typography,
   Card,
} from "@material-tailwind/react"
import {
   addProduct,
   addProductDiscount,
   removeProductDiscount,
   removeOrderProduct,
   decreaseProductQuantity,
   increaseProductQuantity,
   getTotals,
   clearProduct,
} from "../../services/order/orderProductSlice"
import { enqueueSnackbar } from "notistack"
import logo from "../../assets/images/logo-ich.png"
import { setACustomer } from "../../services/Features/customerSlice"
import moment from "moment"
import { employeeInfoFunc, userAccount } from "../../utils/utils"
import { BsFillCheckCircleFill } from "react-icons/bs"
import { useGetShippingQuery } from "../../services/shipping"
import PaymentInformation from "../../components/Payment/PaymentInformation"
import { setPaymentMethod } from "../../services/Features/paymentSlice"
import { useGetEmployeeQuery } from "../../services/employee"
import axios from "axios"
import Barcode from "react-barcode"

const CreateUpdateOrder = () => {
   const { id } = useParams("")
   const { state } = useLocation()
   const [confirmModalOpen, setConfirmModalOpen] = useState(false)
   const [orderId, setOrderId] = useState("")
   const [invoiceId, setInvoiceId] = useState("")
   const dispatch = useDispatch()
   const navigate = useNavigate()
   //ORDER TYPE
   const [orderType, setOrderType] = useState("")

   // ORDER
   const { data: order } = useGetOrderByIdQuery(id)

   // shippin information from backend
   const { data: shippingInformation } = useGetShippingQuery()

   // GET CUSTOMERS
   const { customer } = useSelector((state) => state.customer)

   // payement method and payement information
   const { paymentMethod, paymentInformation } = useSelector(
      (state) => state.payment
   )

   // CREATE ORDER
   const [createOrder] = useCreateOrderMutation()
   const [orderConfirmation] = useOrderConfirmationMutation()
   const [updateOrder] = useUpdateOrderMutation()
   const [deleteOrder] = useDeleteOrderMutation()
   const employeeFromLocal = JSON.parse(localStorage.getItem("ich-user"))
   const { data: user } = useGetEmployeeQuery(employeeFromLocal?.id)
   const orderPermission = employeeInfoFunc(user, "orders")
   const darftPermission = employeeInfoFunc(user, "Drafts")
   const pdfRef = useRef(null)

   // CART ORDERED PRODUCTS
   const { products, totalAmount } = useSelector((state) => state.orderProduct)

   //PATMENT METHOD

   const [paymentDiscount, setPaymentDiscount] = useState({
      reason: "",
      discountTypes: "",
      amounts: 0,
   })

   //NOTES
   const [notes, setNotes] = useState("")
   //PRODUCT SEARCH
   const [search, setsearch] = useState("")
   //SCANNER
   const [scanSearch, setScanSearch] = useState("")

   //SEARCH PRODUCT DATA
   const { data } = useGetSearchKeywordQuery(search || scanSearch)
   const [openProductDiscount, setOpenProductDiscount] = useState(false)
   const [productID, setProductID] = useState("")
   const [productDiscount, setProductDiscount] = useState({
      reason: "",
      discountType: "amount",
      amount: 0,
   })

   const handleOpenProductDiscount = (id) => {
      setOpenProductDiscount(true)
      setProductID(id)
      setProductDiscount({
         reason: "",
         discountType: "amount",
         amount: 0,
      })
   }
   const handleCloseProductDiscount = () => setOpenProductDiscount(false)

   // SUBTOTAL
   const subtotal = products?.reduce((total, item) => {
      return item?.discountedPrice * item?.cartQuantity + total
   }, 0)

   const [openPaymentDiscount, setOpenPaymentDiscount] = useState(false)
   const handleOpenPaymentDiscount = () => setOpenPaymentDiscount(true)
   const handleClosePaymentDiscount = () => setOpenPaymentDiscount(false)

   //paymentShipping
   const [shipping, setShipping] = useState({
      shipping_carrier: "",
      shipping_type: "",
      shipping_cost: 0,
   })

   //paymentShipping
   const [openAddshipping, setOpenAddshipping] = useState(false)
   const handleOpenAddshipping = () => {
      if (customer?.shippingAddress) {
         setOpenAddshipping(true)
      } else {
         enqueueSnackbar("Please select a customer", { variant: "warning" })
      }
   }
   const handleCloseAddshipping = () => setOpenAddshipping(false)

   // PAYMENT DISCOUNT CALCULATION
   let paymentDiscountAmount =
      paymentDiscount?.discountTypes !== "amount"
         ? (totalAmount * Number(paymentDiscount.amounts)) / 100
         : Number(paymentDiscount.amounts)

   let total = Math.round(
      totalAmount - Number(paymentDiscountAmount) + shipping.shipping_cost
   )

   // ORDER CONFIRM FUNCTION
   const handleOrder = async ({ draftValue }) => {
      if (products?.length === 0 && !draftValue)
         return enqueueSnackbar("Product not selected!", {
            variant: "error",
         })

      if (!orderType && !draftValue)
         return enqueueSnackbar("Order type not selected!", {
            variant: "error",
         })

      if (paymentMethod === undefined)
         return enqueueSnackbar("Payment Method not selected!", {
            variant: "error",
         })
      const newOrder = {
         orderId: orderId,
         draftOrder: draftValue,
         orderTakenBy: order?.orderTakenBy || employeeFromLocal?.user,
         customer: customer,
         orderStatus: draftValue === true ? "In progress" : "approved",
         orderType: orderType,
         totalPrice: total,
         subTotal: subtotal,
         notes: notes,
         orderedProducts: products,
         paymentDiscount: {
            discountType: paymentDiscount.discountTypes,
            value: Number(paymentDiscount.amounts),
            reason: paymentDiscount.reason,
         },
         paymentShipping: {
            shipping_carrier: shipping.shipping_carrier,
            shipping_type: shipping.shipping_type,
            shipping_cost: Number(shipping.shipping_cost),
         },
         paymentMethod: paymentMethod || order?.paymentMethod,
         isPaid:
            paymentMethod && paymentMethod !== "cash on delivery"
               ? true
               : false,
         orderAccount: userAccount,
         paymentInformation,
      }

      if (draftValue === false) {
         newOrder["approvedAt"] = Date.now()
      }

      if (state?.route === "create" && draftValue) {
         await createOrder(newOrder)
         enqueueSnackbar("Draft order created Successfully", {
            variant: "success",
         })

         window.location.href = "/drafts"
      } else {
         orderHandleWithPdf(newOrder, draftValue)

         // UPDATE ORDER / CREATE ORDER
         // if (state?.route === "create") {
         //    createOrder({ ...newOrder })
         //    enqueueSnackbar("Order created Successfully", {
         //       variant: "success",
         //    })
         // } else if (state?.route === "draft") {
         //    orderConfirmation({
         //       id: id,
         //       ...newOrder,
         //    })
         //    enqueueSnackbar("Order created Successfully", {
         //       variant: "success",
         //    })
         // } else {
         //    updateOrder({ id: id, ...newOrder })
         //    enqueueSnackbar("Order updated Successfully", {
         //       variant: "success",
         //    })
         // }
         // dispatch(clearProduct())
         // dispatch(setACustomer({}))
         // window.location.href = "/orders"
      }
      setConfirmModalOpen(false)
   }

   // ORDER HANDLE WITH CREATING PDF
   const orderHandleWithPdf = async (newOrder, draftValue) => {
      try {
         const input = pdfRef.current
         const canvas = await html2canvas(input, { useCORS: true, scale: 2 })

         const imgData = canvas.toDataURL("image/jpeg", 1.0)
         const imgWidth = canvas.width
         const imgHeight = canvas.height

         const pdf = new jsPDF({
            unit: "mm",
            format: "a4",
         })

         const pdfWidth = pdf.internal.pageSize.getWidth()
         const pdfHeight = pdf.internal.pageSize.getHeight()

         const scale = pdfWidth / imgWidth

         pdf.addImage(
            imgData,
            "JPEG",
            0,
            0,
            imgWidth * scale,
            imgHeight * scale
         )

         // pdf.save(`${invoiceId}.pdf`)
         const file = pdf.output("blob")
         const formData = new FormData()
         formData.append("uploadPdfs", file, `${invoiceId}.pdf`)

         try {
            const response = await axios.post("/api/pdfs-upload", formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            })

            if (response?.data) {
               if (state?.route === "create") {
                  createOrder({
                     ...newOrder,
                     invoiceId,
                     invoice: response?.data,
                     sendInvoice: true,
                  })
                  enqueueSnackbar("Order created Successfully", {
                     variant: "success",
                  })
               } else if (state?.route === "draft") {
                  orderConfirmation({
                     id: id,
                     ...newOrder,
                     invoiceId,
                     invoice: response?.data,
                     sendInvoice: true,
                  })
                  enqueueSnackbar("Order created Successfully", {
                     variant: "success",
                  })
               } else {
                  updateOrder({
                     id: id,
                     ...newOrder,
                     invoiceId,
                     invoice: response?.data,
                     sendInvoice: true,
                  })
                  enqueueSnackbar("Order updated Successfully", {
                     variant: "success",
                  })
               }
               dispatch(clearProduct())
               dispatch(setACustomer({}))
               window.location.href = "/orders"
            }
         } catch (error) {
            if (error.response && error.response.status === 500) {
               console.error(error)
            } else {
               console.error(error.response?.data?.msg || "An error occurred")
            }
         }
      } catch (error) {
         console.error("Error generating or saving PDF:", error)
      }
      // const input = pdfRef.current
      // // Calculate the scale factor
      // html2canvas(input, { useCORS: true }).then(async (canvas) => {
      //    const img = new Image()
      //    img.src = canvas.toDataURL("image/jpeg", 1.0)

      //    const imgWidth = canvas.width
      //    const imgHeight = canvas.height

      //    // Set the PDF page size based on the content width
      //    const pdf = new jsPDF({
      //       unit: "pt",
      //       format: [imgWidth, imgHeight],
      //    })

      //    // Calculate the scaling factor to fit the content within the PDF page width
      //    const scale = pdf.internal.pageSize.getWidth() / imgWidth

      //    // Add the image to the PDF with adjusted scaling
      //    pdf.addImage(img, "PNG", 0, 0, imgWidth * scale, imgHeight * scale)

      //    pdf.save(`${orderId}.pdf`)

      //    // const file = pdf.output("blob")
      //    // const formData = new FormData()
      //    // formData.append("uploadPdfs", file, `${orderId}.pdf`)

      // try {
      //    const response = await axios.post("/api/pdfs-upload", formData, {
      //       headers: {
      //          "Content-Type": "multipart/form-data",
      //       },
      //    })

      //    if (response?.data) {
      //       if (state?.route === "create") {
      //          createOrder({
      //             ...newOrder,
      //             invoiceId,
      //             invoice: response?.data,
      //             sendInvoice: true,
      //          })
      //          enqueueSnackbar("Order created Successfully", {
      //             variant: "success",
      //          })
      //       } else if (state?.route === "draft") {
      //          orderConfirmation({
      //             id: id,
      //             ...newOrder,
      //             invoiceId,
      //             invoice: response?.data,
      //             sendInvoice: true,
      //          })
      //          enqueueSnackbar("Order created Successfully", {
      //             variant: "success",
      //          })
      //       } else {
      //          updateOrder({ id: id, ...newOrder })
      //          enqueueSnackbar("Order updated Successfully", {
      //             variant: "success",
      //          })
      //       }
      //       dispatch(clearProduct())
      //       dispatch(setACustomer({}))
      //       window.location.href = "/orders"
      //    }
      // } catch (error) {
      //    if (error.response && error.response.status === 500) {
      //       console.error(error)
      //    } else {
      //       console.error(error.response?.data?.msg || "An error occurred")
      //    }
      // }
      // })
   }

   // DELETE DRAFT ORDER
   const deleteOurOrder = (e) => {
      e.preventDefault()
      deleteOrder(id)
      enqueueSnackbar("Draft Order Delete Successfully", { variant: "success" })
      navigate("/drafts")
   }

   useEffect(() => {
      dispatch(getTotals())
   }, [products, dispatch])

   // SET ALL ORDER INFORMATION TO STATE
   useEffect(() => {
      setPaymentDiscount({
         reason: order?.paymentDiscount?.reason,
         discountTypes: order?.paymentDiscount?.discountType
            ? order?.paymentDiscount?.discountType
            : "amount",
         amounts: order?.paymentDiscount?.value || 0,
      })

      setShipping({
         shipping_carrier: order?.paymentShipping?.shipping_carrier,
         shipping_type: order?.paymentShipping?.shipping_type,
         shipping_cost: order?.paymentShipping?.shipping_cost || 0,
      })
      setOrderId(
         order?.orderId
            ? order?.orderId
            : "IOID" +
                 Math.round(Date.now() + Math.random())
                    .toString()
                    .slice(5)
      )
      setInvoiceId(
         order?.invoiceId
            ? order?.invoiceId
            : "IN" +
                 Math.round(Date.now() + Math.random())
                    .toString()
                    .slice(5)
      )
      setOrderType(order?.orderType)
      setNotes(order?.notes)
      dispatch(
         setPaymentMethod(order?.paymentMethod ? order?.paymentMethod : "")
      )
      dispatch(setACustomer(order?.customer ? order?.customer : {}))
   }, [order])

   const submitSearch = (e) => {
      e.preventDefault()
   }

   return (
      <div>
         {state?.route === "create" && (
            <div className="flex justify-between bg-black px-2 sm:px-6 py-2 text-white items-center sticky top-0 w-full z-50">
               <h3 className="text-sm">Unsaved draft order</h3>
               <div className="space-x-2">
                  <button
                     type="button"
                     onClick={() => navigate("/orders")}
                     className="px-1 sm:px-3 py-1 sm:py-2 rounded-md border hover:bg-[#ffffff42] duration-150 border-gray-300 text-xs sm:text-sm"
                  >
                     Discard
                  </button>
                  <Button
                     onClick={() => handleOrder({ draftValue: true })}
                     className="px-1 sm:px-3 py-1 sm:py-2 rounded-md border border-green-800 bg-green-800 hover:border-green-900 hover:bg-green-900  duration-150 text-white text-xs sm:text-sm "
                  >
                     Save
                  </Button>
               </div>
            </div>
         )}
         <div className="p-2 mb-5 m-2 md:m-5 dark:text-white">
            <div className="py-3 flex items-center mb-2">
               <Link
                  to="/orders"
                  className="text-[#5c5f62] p-2 mr-3 rounded-md border border-gray-300 cursor-pointer"
               >
                  <svg
                     viewBox="0 0 20 20"
                     className="Polaris-Icon__Svg_375hu w-5 h-5"
                     focusable="false"
                     aria-hidden="true"
                  >
                     <path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path>
                  </svg>
               </Link>
               <div>
                  <h3 className="font-semibold text-xl">
                     {state?.route === "create"
                        ? `Create Order`
                        : state?.route === "draft"
                        ? `#${order?.draftID}`
                        : `#${order?.orderId}`}
                  </h3>
                  {state?.route === "create" ? (
                     <p className="text-sm">
                        Date:{" "}
                        {moment
                           .utc(Date.now())
                           .utcOffset("+06:00")
                           .format("YYYY-MM-DD , h:mm a")}
                     </p>
                  ) : (
                     <p className="text-sm">
                        Date:{" "}
                        {moment
                           .utc(order?.createdAt)
                           .utcOffset("+06:00")
                           .format("YYYY-MM-DD , h:mm a")}
                     </p>
                  )}
               </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-2 xl:gap-4 ">
               <div className="col-span-2 overflow-hidden">
                  <div
                     className={`bg-white w-full border rounded-lg p-2 sm:p-5 shadow`}
                  >
                     <div className="flex flex-col sm:flex-row justify-start sm:items-center w-full gap-1">
                        <form
                           onSubmit={(e) => submitSearch(e)}
                           className="relative w-full"
                        >
                           <label
                              htmlFor="default-search"
                              className="text-sm font-medium text-gray-900 sr-only"
                           >
                              Search
                           </label>
                           <div className="relative">
                              <div className="absolute inset-y-0 left-0 flex items-center pl-2.5 pointer-events-none">
                                 <svg
                                    aria-hidden="true"
                                    className="w-5 h-5 text-gray-400"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                 >
                                    <path
                                       strokeLinecap="round"
                                       strokeLinejoin="round"
                                       strokeWidth="2"
                                       d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                    ></path>
                                 </svg>
                              </div>
                              <input
                                 name="search"
                                 value={search}
                                 onChange={(e) => setsearch(e.target.value)}
                                 className="pl-8 !text-base focus:ring-0 focus:outline-none focus:border-gray-300 leading-none text-left text-gray-500 px-4 py-2 w-full border  border-gray-300 outline-none rounded-lg"
                                 placeholder="Enter title or barcode to search"
                                 required
                              />
                              {/* <button
                                 type="submit"
                                 className="text-custom_blue cursor-pointer absolute right-0 bg-custom_yellow hover:bg-yellow-700 duration-150 rounded-sm font-medium top-0 bottom-0 flex items-center justify-center text-center text-sm px-5 rounded-r-lg"
                              >
                                 Search
                              </button> */}
                           </div>
                           {data?.length > 0 && (
                              <div className="absolute bottom-0 left-0 right-0 top-10 z-50 h-max overflow-y-scroll max-h-64 shadow-lg">
                                 {data?.map((x, i) => (
                                    <div
                                       key={i}
                                       onClick={() => {
                                          if (x?.countInStock === 0) {
                                             enqueueSnackbar(
                                                "Contact us for product request",
                                                { variant: "warning" }
                                             )
                                          } else {
                                             enqueueSnackbar(
                                                `${x?.title?.substr(
                                                   0,
                                                   20
                                                )} was added to the shopping cart.`,
                                                { variant: "success" }
                                             )
                                             {
                                                userAccount === "gyankosh"
                                                   ? dispatch(
                                                        addProduct({
                                                           ...x,
                                                           discount:
                                                              x?.gyankoshDiscount,
                                                           discountedPrice:
                                                              x?.gyankoshDiscountedPrice,
                                                        })
                                                     )
                                                   : dispatch(addProduct(x))
                                             }

                                             setsearch("")
                                          }
                                       }}
                                       className="flex h-14 w-full cursor-pointer p-2 items-center justify-between gap-1 overflow-hidden bg-white shadow hover:bg-gray-100 hover:text-custom-orange"
                                    >
                                       <div className="flex items-center gap-1">
                                          <div className="h-10 w-10">
                                             <Avatar
                                                circle
                                                src={x?.images[0]}
                                                alt={x?.title}
                                             />
                                          </div>
                                          <div className="flex flex-col items-start">
                                             <span className="block truncate text-sm font-medium">
                                                {x?.title?.length > 24
                                                   ? x?.title.substr(0, 24)
                                                   : x?.title}
                                                {x?.title?.length > 24 && "..."}
                                             </span>
                                             <span className="text-custom-blue text-xs">
                                                {x?.author?.name?.length > 24
                                                   ? x?.author?.name.substr(
                                                        0,
                                                        24
                                                     )
                                                   : x?.author?.name}
                                                {x?.author?.name?.length > 24 &&
                                                   "..."}
                                             </span>
                                          </div>
                                       </div>
                                       <div className="flex items-center gap-5 px-3">
                                          {x?.countInStock === 0 ? (
                                             <span className="text-sm text-custom-hot">
                                                Out of stock
                                             </span>
                                          ) : (
                                             <span className="text-sm text-custom-green">
                                                In stock ({x?.countInStock})
                                             </span>
                                          )}
                                          {x?.discount === 0 ? null : (
                                             <span className="text-sm text-custom-blue">
                                                (
                                                {Math.ceil(
                                                   userAccount === "gyankosh"
                                                      ? x?.gyankoshDiscount
                                                      : x?.discount
                                                )}
                                                % Off)
                                             </span>
                                          )}

                                          <div className="flex items-center">
                                             {userAccount === "gyankosh" ? (
                                                <>
                                                   {x?.gyankoshDiscount ===
                                                   0 ? (
                                                      <span className="RT inline-block text-sm">
                                                         {x?.price} Tk.
                                                      </span>
                                                   ) : (
                                                      <div className="flex items-center justify-center gap-1">
                                                         <span className="text-sm text-custom-green">
                                                            {" "}
                                                            {Math.round(
                                                               x?.gyankoshDiscountedPrice ||
                                                                  x?.price
                                                            )}{" "}
                                                            TK.
                                                         </span>
                                                         <div className="text-sm text-custom-gray line-through">
                                                            {x?.price} TK.
                                                         </div>
                                                      </div>
                                                   )}
                                                </>
                                             ) : (
                                                <>
                                                   {x?.discount === 0 ? (
                                                      <span className="RT inline-block text-sm">
                                                         {x?.price} Tk.
                                                      </span>
                                                   ) : (
                                                      <div className="flex items-center justify-center gap-1">
                                                         <span className="text-sm text-custom-green">
                                                            {" "}
                                                            {Math.round(
                                                               x?.discountedPrice
                                                            )}{" "}
                                                            TK.
                                                         </span>
                                                         <div className="text-sm text-custom-gray line-through">
                                                            {x?.price} TK.
                                                         </div>
                                                      </div>
                                                   )}
                                                </>
                                             )}
                                          </div>
                                       </div>
                                    </div>
                                 ))}
                              </div>
                           )}
                        </form>
                     </div>
                     {products?.length > 0 && (
                        <div className=" dark:bg-dark dark:border-gray-600 w-full border rounded-lg z-50">
                           <div className="flex flex-col gap-2">
                              <div className="w-full bg-white p-3 mt-3">
                                 <div className="flex items-center justify-between shadow w-full border-b-4 border-dotted bg-blue-100 border-white px-2 py-2.5">
                                    <h3 className="font-semibold text-gray-800 text-xs uppercase w-56">
                                       Product Details
                                    </h3>
                                    <div className="flex items-center justify-between w-1/2">
                                       <h3 className="font-semibold text-center text-xs uppercase">
                                          Quantity
                                       </h3>
                                       <h3 className="font-semibold text-center text-xs uppercase">
                                          Price
                                       </h3>
                                       <h3 className="font-semibold text-center text-xs uppercase">
                                          Add Discount
                                       </h3>
                                    </div>
                                 </div>
                                 <div className="flex flex-col gap-3 mt-1">
                                    {products?.map((x, index) => (
                                       <div
                                          key={x?.title}
                                          className="bg-white shadow py-2 px-1.5"
                                       >
                                          <div className="flex items-center justify-between w-full gap-2">
                                             <div className="flex gap-2.5 w-1/2">
                                                <Avatar
                                                   size="lg"
                                                   src={x?.images?.[0]}
                                                   alt={x?.title}
                                                   className="!rounded-none"
                                                />
                                                <div className="flex flex-col items-start justify-between flex-grow">
                                                   <h1 className="font-semibold !text-sm">
                                                      {x?.title?.length > 36
                                                         ? x?.title.substr(
                                                              0,
                                                              36
                                                           )
                                                         : x?.title}
                                                      {x?.title?.length > 36 &&
                                                         "..."}
                                                   </h1>
                                                   <button
                                                      onClick={() =>
                                                         dispatch(
                                                            removeOrderProduct(
                                                               x
                                                            )
                                                         )
                                                      }
                                                      className="font-semibold hover:text-red-500 text-gray-500 text-xs"
                                                   >
                                                      Remove
                                                   </button>
                                                </div>
                                             </div>
                                             <div className="flex items-center justify-between w-1/2">
                                                <div className="flex flex-col items-center">
                                                   <svg
                                                      onClick={() =>
                                                         dispatch(
                                                            decreaseProductQuantity(
                                                               x
                                                            )
                                                         )
                                                      }
                                                      className="cursor-pointer fill-current text-gray-600 w-3"
                                                      viewBox="0 0 448 512"
                                                   >
                                                      <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                                                   </svg>
                                                   <p className="my-1.5 border text-center w-8">
                                                      {x?.cartQuantity}
                                                   </p>
                                                   <svg
                                                      onClick={() =>
                                                         dispatch(
                                                            increaseProductQuantity(
                                                               x
                                                            )
                                                         )
                                                      }
                                                      className="cursor-pointer fill-current text-gray-600 w-3"
                                                      viewBox="0 0 448 512"
                                                   >
                                                      <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                                                   </svg>
                                                </div>
                                                <span className="text-center font-semibold text-sm">
                                                   {x?.discountedPrice *
                                                      x?.cartQuantity}
                                                   TK.
                                                </span>
                                                <div>
                                                   {Number(
                                                      x?.custom_discount?.amount
                                                   ) > 0 ? (
                                                      x?.custom_discount
                                                         ?.discountType ===
                                                      "amount" ? (
                                                         <div className="flex gap-2 items-center">
                                                            <Chip
                                                               value={`${Number(
                                                                  x
                                                                     ?.custom_discount
                                                                     ?.amount *
                                                                     x?.cartQuantity
                                                               )}Tk. off`}
                                                            />
                                                            <Button
                                                               className="!bg-custom_blue"
                                                               onClick={() =>
                                                                  dispatch(
                                                                     removeProductDiscount(
                                                                        {
                                                                           product:
                                                                              x,
                                                                           productDiscount:
                                                                              {
                                                                                 reason:
                                                                                    "",
                                                                                 discountType:
                                                                                    "amount",
                                                                                 amount: 0,
                                                                              },
                                                                        }
                                                                     )
                                                                  )
                                                               }
                                                            >
                                                               x
                                                            </Button>
                                                         </div>
                                                      ) : (
                                                         <div className="flex gap-2 items-center">
                                                            <Chip
                                                               value={`${Number(
                                                                  x
                                                                     ?.custom_discount
                                                                     ?.amount
                                                               )}% off`}
                                                            />
                                                            <Button
                                                               onClick={() =>
                                                                  dispatch(
                                                                     removeProductDiscount(
                                                                        {
                                                                           product:
                                                                              x,
                                                                           productDiscount:
                                                                              {
                                                                                 reason:
                                                                                    "",
                                                                                 discountType:
                                                                                    "amount",
                                                                                 amount: 0,
                                                                              },
                                                                        }
                                                                     )
                                                                  )
                                                               }
                                                            >
                                                               x
                                                            </Button>
                                                         </div>
                                                      )
                                                   ) : (
                                                      <Button
                                                         onClick={() =>
                                                            handleOpenProductDiscount(
                                                               x?._id
                                                            )
                                                         }
                                                      >
                                                         Click here
                                                      </Button>
                                                   )}
                                                </div>
                                                <Modal
                                                   open={openProductDiscount}
                                                >
                                                   <Modal.Body>
                                                      <div className="elative pb-0 dark:bg-dark">
                                                         <h3 className="text-lg font-semibold border-b pb-4 mb-5">
                                                            Add Discount
                                                         </h3>
                                                         <div>
                                                            <form
                                                               action=""
                                                               className="space-y-4"
                                                            >
                                                               <div className="flex items-end justify-between gap-2">
                                                                  <div className="flex flex-col w-full ">
                                                                     <label htmlFor="discountType">
                                                                        Discount
                                                                        Type
                                                                     </label>
                                                                     <select
                                                                        onChange={(
                                                                           e
                                                                        ) =>
                                                                           setProductDiscount(
                                                                              {
                                                                                 ...productDiscount,
                                                                                 discountType:
                                                                                    e
                                                                                       .target
                                                                                       .value,
                                                                              }
                                                                           )
                                                                        }
                                                                        className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 w-full mt-1 outline-none"
                                                                     >
                                                                        <option
                                                                           value="amount"
                                                                           defaultChecked
                                                                        >
                                                                           Amount
                                                                        </option>
                                                                     </select>
                                                                  </div>
                                                                  <Input
                                                                     size="md"
                                                                     label={`Discount Value Tk.`}
                                                                     type="number"
                                                                     name="amount"
                                                                     value={
                                                                        productDiscount.amount
                                                                     }
                                                                     onChange={(
                                                                        e
                                                                     ) =>
                                                                        setProductDiscount(
                                                                           {
                                                                              ...productDiscount,
                                                                              amount:
                                                                                 e
                                                                                    .target
                                                                                    .value,
                                                                           }
                                                                        )
                                                                     }
                                                                  />
                                                               </div>
                                                               <div className="flex flex-col w-full">
                                                                  {/* <label htmlFor="discountType">Reason</label> */}
                                                                  <Input
                                                                     size="md"
                                                                     label="Reason"
                                                                     type="text"
                                                                     value={
                                                                        productDiscount.reason
                                                                     }
                                                                     name="reason"
                                                                     onChange={(
                                                                        e
                                                                     ) =>
                                                                        setProductDiscount(
                                                                           {
                                                                              ...productDiscount,
                                                                              reason:
                                                                                 e
                                                                                    .target
                                                                                    .value,
                                                                           }
                                                                        )
                                                                     }
                                                                     // className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                                                                  />
                                                                  <span>
                                                                     Your
                                                                     customer
                                                                     can see
                                                                     this reason
                                                                  </span>
                                                               </div>
                                                            </form>
                                                         </div>
                                                      </div>
                                                   </Modal.Body>
                                                   <Modal.Footer>
                                                      <div className="text-right py-5 border-t">
                                                         <button
                                                            onClick={
                                                               handleCloseProductDiscount
                                                            }
                                                            className="py-2 px-4 border border-gray-300 rounded-lg cursor-pointer mr-2"
                                                         >
                                                            Cancel
                                                         </button>
                                                         <button
                                                            onClick={() => {
                                                               dispatch(
                                                                  addProductDiscount(
                                                                     {
                                                                        product:
                                                                           productID,
                                                                        productDiscount:
                                                                           {
                                                                              ...productDiscount,
                                                                              amount:
                                                                                 Number(
                                                                                    productDiscount?.amount
                                                                                 ),
                                                                           },
                                                                     }
                                                                  )
                                                               )
                                                               setOpenProductDiscount(
                                                                  false
                                                               )
                                                            }}
                                                            className="py-2 px-4 bg-[#008060] rounded-lg text-white"
                                                         >
                                                            Apply
                                                         </button>
                                                      </div>
                                                   </Modal.Footer>
                                                </Modal>
                                             </div>
                                          </div>
                                       </div>
                                    ))}
                                    {/* {order?.orderedProducts?.map((x, index) => (
                                    <div
                                       key={index}
                                       className="bg-white shadow py-2 px-1.5"
                                    >
                                       <div className="flex items-center justify-between w-full gap-2">
                                          <div className="flex gap-2.5 w-1/2">
                                             <Avatar
                                                size="lg"
                                                src={x?.images?.[0]}
                                                alt={x?.title}
                                                className="!rounded-none"
                                             />
                                             <div className="flex flex-col items-start justify-between flex-grow">
                                                <h1 className="font-semibold !text-sm">
                                                   {x?.title?.length > 36
                                                      ? x?.title.substr(0, 36)
                                                      : x?.title}
                                                   {x?.title?.length > 36 &&
                                                      "..."}
                                                </h1>
                                             </div>
                                          </div>

                                          <div className="flex items-center justify-between w-1/2">
                                             <span className="text-center font-semibold text-sm">
                                                {x?.cartQuantity}
                                             </span>
                                             <span className="text-center font-semibold text-sm">
                                                TK.{" "}
                                                {Math.round(
                                                   x?.discountedPrice
                                                ) * x?.cartQuantity}
                                             </span>
                                             <span>
                                                <Typography
                                                   variant="small"
                                                   color="blue-gray"
                                                   className="font-normal"
                                                >
                                                   TK.{" "}
                                                   {Math.round(
                                                      x?.custom_discount?.amount
                                                   )}
                                                </Typography>
                                                {x?.custom_discount?.amount ===
                                                0 ? null : (
                                                   <span className="text-xs text-gray-500">
                                                      Reason:{" "}
                                                      {
                                                         x?.custom_discount
                                                            ?.reason
                                                      }
                                                   </span>
                                                )}
                                             </span>
                                          </div>
                                       </div>
                                    </div>
                                 ))} */}
                                 </div>
                              </div>
                           </div>
                        </div>
                     )}
                  </div>
                  <div className=" bg-white flex flex-col gap-2 w-full border rounded-lg p-5 my-4 shadow">
                     <div className="text-black dark:text-white mb-4 flex items-center text-sm space-x-2">
                        <span className="border border-main rounded-full">
                           <BsFillCheckCircleFill
                              size={16}
                              className="bg-main rounded-full"
                           />
                        </span>
                        <h3>
                           Payment{" "}
                           {order
                              ? order?.isPaid === true
                                 ? "(Paid)"
                                 : "(Due)"
                              : ""}
                        </h3>
                     </div>

                     <div className="text-sm space-y-4">
                        <div className="flex justify-between items-center">
                           <h3>Subtotal abcabc</h3>
                           <span>{totalAmount} TK. </span>
                        </div>
                        <div className="flex justify-between items-center">
                           <div className="space-x-2">
                              <button
                                 type="button"
                                 onClick={handleOpenPaymentDiscount}
                                 className="text-[#576CBC] cursor-pointer"
                              >
                                 {" "}
                                 Add Discount
                              </button>
                              {paymentDiscount?.reason && (
                                 <span>({paymentDiscount?.reason})</span>
                              )}
                           </div>
                           <div className="flex gap-3">
                              <span>
                                 {paymentDiscount.amounts || 0}
                                 {paymentDiscount?.discountTypes ===
                                 "percentage"
                                    ? " %"
                                    : " TK.  "}
                              </span>
                              {paymentDiscount?.amounts !== 0 && (
                                 <span>
                                    (After Discount-{" "}
                                    {totalAmount - paymentDiscountAmount} TK.)
                                 </span>
                              )}
                           </div>
                           <Modal open={openPaymentDiscount}>
                              <Modal.Body>
                                 <div className="relative pb-0 dark:bg-dark text-base">
                                    <h3 className="text-lg font-semibold border-b pb-4 mb-5">
                                       Add Discount
                                    </h3>
                                    <div>
                                       <form action="" className="space-y-4">
                                          <div className="flex items-end justify-between gap-2">
                                             <div className="flex flex-col w-full ">
                                                <label htmlFor="discountTypes">
                                                   Discount Type
                                                </label>
                                                <select
                                                   value={
                                                      paymentDiscount?.discountTypes
                                                   }
                                                   onChange={(e) =>
                                                      setPaymentDiscount({
                                                         ...paymentDiscount,
                                                         discountTypes:
                                                            e.target.value,
                                                      })
                                                   }
                                                   className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 w-full mt-1 outline-none"
                                                >
                                                   <option
                                                      value="amount"
                                                      defaultChecked
                                                   >
                                                      Amount
                                                   </option>
                                                   <option value="percentage">
                                                      Percentage
                                                   </option>
                                                </select>
                                             </div>
                                             <div className="flex flex-col w-full">
                                                <div className="relative mt-1">
                                                   <Input
                                                      label={`Discount Value ${
                                                         paymentDiscount.discountTypes ===
                                                         "amount"
                                                            ? "Tk."
                                                            : "%"
                                                      }`}
                                                      size="md"
                                                      type="number"
                                                      name="amounts"
                                                      value={
                                                         paymentDiscount?.amounts
                                                      }
                                                      onChange={(e) =>
                                                         setPaymentDiscount({
                                                            ...paymentDiscount,
                                                            amounts:
                                                               e.target.value,
                                                         })
                                                      }
                                                   />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="flex flex-col w-full">
                                             {/* <label htmlFor="discountType">Reason</label> */}
                                             <Input
                                                label="Reason"
                                                type="text"
                                                value={paymentDiscount?.reason}
                                                name="reasons"
                                                onChange={(e) =>
                                                   setPaymentDiscount({
                                                      ...paymentDiscount,
                                                      reason: e.target.value,
                                                   })
                                                }
                                                // className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                                             />
                                             <span>
                                                Your customer can see this
                                                reason
                                             </span>
                                          </div>
                                       </form>
                                    </div>
                                 </div>
                              </Modal.Body>
                              <Modal.Footer>
                                 <div className="text-right py-5 border-t">
                                    <button
                                       onClick={handleClosePaymentDiscount}
                                       className="py-2 px-4 border border-gray-300 rounded-lg cursor-pointer mr-2"
                                    >
                                       Cancel
                                    </button>
                                    <button
                                       onClick={handleClosePaymentDiscount}
                                       className="py-2 px-4 bg-[#008060] rounded-lg text-white"
                                    >
                                       Apply
                                    </button>
                                 </div>
                              </Modal.Footer>
                           </Modal>
                        </div>
                        <div className="flex justify-between items-center">
                           <div
                              onClick={handleOpenAddshipping}
                              className="text-[#576CBC] cursor-pointer"
                           >
                              Add Shipping
                           </div>
                           <span>
                              {shipping.shipping_cost} TK.
                              <span className="text-sm text-gray-500">
                                 {shipping?.shipping_type &&
                                    `(${shipping?.shipping_type})`}
                              </span>
                           </span>
                           <Modal open={openAddshipping}>
                              <Modal.Body>
                                 <div className=" relative pb-0 dark:bg-dark">
                                    <div
                                       onClick={handleClosePaymentDiscount}
                                       className="absolute right-2 top-2 text-xl mr-2 cursor-pointer"
                                    >
                                       ✕
                                    </div>
                                    <h3 className="text-lg font-semibold border-b pb-4 mb-5">
                                       Add Shipping
                                    </h3>
                                    <form className="text-base">
                                       <div className="flex flex-col w-full text-base mb-5">
                                          <label
                                             htmlFor="notes"
                                             className=" font-semibold mb-1"
                                          >
                                             Shipping carrier
                                          </label>
                                          <select
                                             name="shipping_carrier"
                                             value={shipping.shipping_carrier}
                                             onChange={(e) =>
                                                setShipping({
                                                   ...shipping,
                                                   shipping_carrier:
                                                      e.target.value,
                                                })
                                             }
                                             className="dark:text-dark border cursor-pointer border-gray-300 rounded-lg px-3 py-2 w-full mt-1 outline-none text-sm"
                                          >
                                             <option>
                                                Select Shipping Carrier
                                             </option>
                                             <option value="Delivery Tiger">
                                                Delivery Tiger
                                             </option>
                                             <option value="Redx">Redx</option>
                                             {customer?.shippingAddress?.district?.includes(
                                                "Inside Dhaka"
                                             ) === false && (
                                                <option value="Sundarban Courier">
                                                   Sundarban Courier
                                                </option>
                                             )}
                                          </select>
                                       </div>
                                       {customer?.shippingAddress?.district?.includes(
                                          "Inside Dhaka"
                                       ) === true ? (
                                          <div className="space-y-2">
                                             {shippingInformation
                                                ?.filter(
                                                   (item) =>
                                                      item?.city ===
                                                      "Inside Dhaka"
                                                )
                                                ?.map((item) => {
                                                   return (
                                                      <div className="flex flex-col sm:flex-row sm:items-center justify-between">
                                                         <label
                                                            htmlFor={
                                                               item?.shipping_type
                                                            }
                                                            className="cursor-pointer"
                                                         >
                                                            <input
                                                               checked={
                                                                  shipping?.shipping_type ===
                                                                  item?.shipping_type
                                                               }
                                                               onChange={(e) =>
                                                                  setShipping({
                                                                     ...shipping,
                                                                     shipping_type:
                                                                        e.target
                                                                           .value,
                                                                     shipping_cost:
                                                                        item?.shipping_cost,
                                                                  })
                                                               }
                                                               type="radio"
                                                               id={
                                                                  item?.shipping_type
                                                               }
                                                               name={
                                                                  item?.shipping_type
                                                               }
                                                               value={
                                                                  item?.shipping_type
                                                               }
                                                               className="mr-2"
                                                            />
                                                            {
                                                               item?.shipping_type
                                                            }
                                                         </label>
                                                         <span className="text-right">
                                                            {
                                                               item?.shipping_cost
                                                            }{" "}
                                                            Tk.
                                                         </span>
                                                      </div>
                                                   )
                                                })}
                                          </div>
                                       ) : (
                                          <div className="space-y-2">
                                             {shippingInformation
                                                ?.filter(
                                                   (item) =>
                                                      item?.city ===
                                                      "Outside Dhaka"
                                                )
                                                ?.map((item) => {
                                                   return (
                                                      <div className="flex flex-col sm:flex-row sm:items-center justify-between">
                                                         <label
                                                            htmlFor={
                                                               item?.shipping_type
                                                            }
                                                            className="cursor-pointer"
                                                         >
                                                            <input
                                                               checked={
                                                                  shipping?.shipping_type ===
                                                                  item?.shipping_type
                                                               }
                                                               onChange={(e) =>
                                                                  setShipping({
                                                                     ...shipping,
                                                                     shipping_type:
                                                                        e.target
                                                                           .value,
                                                                     shipping_cost:
                                                                        item?.shipping_cost,
                                                                  })
                                                               }
                                                               type="radio"
                                                               id={
                                                                  item?.shipping_type
                                                               }
                                                               name={
                                                                  item?.shipping_type
                                                               }
                                                               value={
                                                                  item?.shipping_type
                                                               }
                                                               className="mr-2"
                                                            />
                                                            {
                                                               item?.shipping_type
                                                            }
                                                         </label>
                                                         <span className="text-right">
                                                            {
                                                               item?.shipping_cost
                                                            }{" "}
                                                            Tk.
                                                         </span>
                                                      </div>
                                                   )
                                                })}
                                          </div>
                                       )}
                                       <div className="flex items-center justify-between mt-2">
                                          <label
                                             htmlFor="no-shipping"
                                             className="cursor-pointer"
                                          >
                                             <input
                                                checked={
                                                   shipping?.shipping_type ===
                                                   "No shipping required"
                                                }
                                                onChange={(e) =>
                                                   setShipping({
                                                      ...shipping,
                                                      shipping_type:
                                                         e.target.value,
                                                      shipping_cost: 0,
                                                   })
                                                }
                                                type="radio"
                                                id="no-shipping"
                                                name="shipping_type"
                                                value="No shipping required"
                                                className="mr-2"
                                             />
                                             <span>No shipping required</span>
                                          </label>
                                          <span>0 Tk.</span>
                                       </div>
                                    </form>
                                 </div>
                              </Modal.Body>
                              <Modal.Footer>
                                 <div className="text-right py-5 border-t">
                                    <button
                                       onClick={handleCloseAddshipping}
                                       className="py-2 px-4 border border-gray-300 rounded-lg cursor-pointer mr-2"
                                    >
                                       Cancel
                                    </button>
                                    <button
                                       onClick={() => handleCloseAddshipping()}
                                       className="py-2 px-4 bg-[#008060] rounded-lg text-white"
                                    >
                                       Apply
                                    </button>
                                 </div>
                              </Modal.Footer>
                           </Modal>
                        </div>
                        <div className="flex justify-between items-center">
                           <h3>Total</h3>
                           <span>{total} Tk.</span>
                        </div>
                     </div>
                  </div>
                  <div className="bg-white flex flex-col gap-2 w-full border rounded-lg p-5 my-4 shadow">
                     <h2 className="font-semibold capitalize">Order Type</h2>
                     <label
                        htmlFor="current"
                        className="cursor-pointer text-sm flex items-center gap-2"
                     >
                        <input
                           checked={orderType == "current"}
                           onChange={(e) => setOrderType(e.target.value)}
                           value={"current"}
                           type="radio"
                           id="current"
                           name="orderType"
                        />
                        <span>Current Order</span>
                     </label>
                     <label
                        htmlFor="pre"
                        className="cursor-pointer text-sm flex items-center gap-2"
                     >
                        <input
                           checked={orderType == "pre"}
                           onChange={(e) => setOrderType(e.target.value)}
                           value={"pre"}
                           type="radio"
                           id="pre"
                           name="orderType"
                        />
                        <span>Pre-Order</span>
                     </label>
                  </div>
                  {confirmModalOpen && (
                     <Dialog
                        open={confirmModalOpen}
                        animate={{
                           mount: { scale: 1, y: 0 },
                           unmount: { scale: 0.9, y: -100 },
                        }}
                     >
                        <DialogHeader>
                           {state?.route === "update" ? "Update " : "Create "}
                           Order
                        </DialogHeader>
                        <DialogBody divider className="overflow-scroll h-auto">
                           <PaymentInformation pay={total} order={order} />
                        </DialogBody>
                        <DialogFooter>
                           <Button
                              variant="text"
                              color="red"
                              onClick={() => {
                                 setConfirmModalOpen(false)
                                 dispatch(setPaymentMethod(""))
                              }}
                              className="mr-1"
                           >
                              <span>Cancel</span>
                           </Button>
                           <Button
                              className="p-3 sm:px-5 bg-green-900 hover:bg-green-700 duration-150 text-white text-right rounded-md cursor-pointer"
                              onClick={() => {
                                 handleOrder({
                                    draftValue: false,
                                 })
                              }}
                           >
                              <span>Confirm</span>
                           </Button>
                        </DialogFooter>
                     </Dialog>
                  )}
                  {state?.route !== "update" && (
                     <label
                        htmlFor="restock"
                        className="cursor-pointer block  text-right text-sm mb-3"
                     >
                        <input
                           type="checkbox"
                           defaultChecked
                           id="restock"
                           name="refund"
                           className="mr-2"
                        />
                        <span>send invoice to customer</span>
                     </label>
                  )}{" "}
                  {((state?.route === `draft` &&
                     darftPermission?.permission !== "View Only") ||
                     (state?.route === "create" &&
                        orderPermission?.permission !== "View Only") ||
                     (state?.route === "update" &&
                        orderPermission?.permission !== "View Only")) && (
                     <div className="flex justify-end ">
                        <button
                           type="button"
                           onClick={() => setConfirmModalOpen(true)}
                           className="p-2 sm:px-5 bg-green-900 hover:bg-green-700 duration-150 text-white text-right rounded-md text-base cursor-pointer "
                        >
                           {state?.route === "update"
                              ? "Update"
                              : "Proceed to payment"}
                        </button>
                     </div>
                  )}
               </div>
               <div className="col-span-1">
                  <div className="bg-white w-full border  rounded-lg p-2 shadow mb-4">
                     <h2 className="font-semibold">Notes</h2>
                     <Input
                        size="md"
                        label={order?.notes ? order?.notes : "Notes"}
                        name="notes"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        className=" !border-gray-300"
                     />
                  </div>

                  <Customers
                     route={state?.route}
                     orderPermission={orderPermission}
                     darftPermission={darftPermission}
                  />
               </div>
            </div>
            <div className="border-t dark:border-gray-600 border-gray-300 pt-5 mt-5">
               {state?.route === `draft` &&
                  darftPermission?.permission !== "View Only" && (
                     <button
                        onClick={deleteOurOrder}
                        className="border border-red-500 text-red-600 px-3 py-2 rounded-md text-sm font-semibold"
                     >
                        Delete draft order
                     </button>
                  )}
            </div>
            {/* .......................new invoice */}
            <div style={{ opacity: 0 }} className="grid grid-cols-3 mt-5">
               <div
                  ref={pdfRef}
                  className=" col-span-2 space-y-2 overflow-hidden p-3 "
               >
                  <div className=" flex items-center justify-between w-full border-b border-blue-gray-100 pb-4">
                     <div>
                        <div className="w-28 h-12">
                           <img
                              src={logo}
                              alt="ichchapuron-logo"
                              className="w-full h-full"
                           />
                        </div>
                        <div className="text-sm pl-2">
                           <span>
                              {moment
                                 .utc(order?.createdAt)
                                 .utcOffset("+06:00")
                                 .format("DD MMMM YYYY, h:mm a")}
                           </span>
                           <div>
                              <span className="font-medium">
                                 Customer Name :{" "}
                              </span>
                              <span className="capitalize">
                                 {customer?.contactInformation?.name || "N/A"}
                              </span>
                           </div>
                           <div>
                              <span className="font-medium">
                                 Shipping Address :{" "}
                              </span>
                              <span className="capitalize">
                                 {" "}
                                 {customer?.shippingAddress?.address || "N/A"}
                              </span>
                           </div>
                           <div>
                              <span className="font-medium">Phone : </span>{" "}
                              {customer?.shippingAddress?.phone || "N/A"}
                           </div>
                        </div>
                     </div>
                     <div className="space-y-1 text-sm">
                        <div className="flex flex-col items-center justify-center gap-2">
                           <span>
                              Order Id :{" "}
                              {state?.route === "create"
                                 ? `${orderId}`
                                 : `${order?.orderId}`}
                           </span>
                           <Barcode
                              format="CODE128"
                              lineColor="#000000"
                              background="#FFFFFF"
                              height={20}
                              value={
                                 state?.route === "create"
                                    ? `${orderId}`
                                    : `${order?.orderId}`
                              }
                              width={0.9}
                              fontSize={10}
                              margin={0}
                              padding={0}
                              displayValue={false}
                              className="m-auto pl-2"
                           />
                        </div>
                        <div className="flex flex-col items-center justify-center gap-2">
                           <span>
                              Invoice Id :{" "}
                              {state?.route === "create" ||
                              state?.route === "draft"
                                 ? `${invoiceId}`
                                 : `${order?.invoiceId}`}
                           </span>
                           <Barcode
                              format="CODE128"
                              lineColor="#000000"
                              background="#FFFFFF"
                              height={20}
                              value={
                                 state?.route === "create" ||
                                 state?.route === "draft"
                                    ? `${invoiceId}`
                                    : `${order?.invoiceId}`
                              }
                              w
                              width={0.9}
                              fontSize={10}
                              margin={0}
                              padding={0}
                              displayValue={false}
                              className="m-auto pl-2"
                           />
                        </div>
                     </div>
                  </div>
                  {products?.length > 0 ? (
                     <table className="w-full min-w-max table-auto text-left border border-blue-gray-100 text-sm">
                        <thead>
                           <tr>
                              {[
                                 "Product",
                                 "Quantity",
                                 "Price",
                                 "Custom Discount",
                              ].map((head) => (
                                 <th
                                    key={head}
                                    className="border-b border-blue-gray-100 bg-blue-100 px-4 pb-4 "
                                 >
                                    <Typography
                                       variant="small"
                                       className="font-semibold leading-none opacity-70 text-black"
                                    >
                                       {head}
                                    </Typography>
                                 </th>
                              ))}
                           </tr>
                        </thead>
                        <tbody>
                           {products?.map((x, index) => {
                              const isLast =
                                 index === order?.orderedProducts.length - 1
                              const classes = isLast
                                 ? "px-4 pb-4"
                                 : "px-4 pb-4 border-b border-blue-gray-200 text-sm"

                              return (
                                 <tr key={x?._id}>
                                    <td className={classes}>
                                       <span className="font-medium ">
                                          {x?.title?.length > 30
                                             ? x?.title.substr(0, 30)
                                             : x?.title}
                                          {x?.title?.length > 30 && "..."}
                                       </span>
                                    </td>
                                    <td className={classes}>
                                       <span className="font-medium pl-2">
                                          {" "}
                                          {x?.cartQuantity}
                                       </span>
                                    </td>
                                    <td className={`${classes} `}>
                                       <div className={`flex item-start gap-2`}>
                                          <span className="font-medium ">
                                             {Math.round(x?.price) *
                                                x?.cartQuantity}
                                          </span>
                                          <span className="font-medium ">
                                             {" "}
                                             {Math.round(x?.discountedPrice) *
                                                x?.cartQuantity}{" "}
                                             TK.
                                          </span>
                                       </div>
                                    </td>
                                    <td className={classes}>
                                       <span className="font-medium ">
                                          {x?.custom_discount?.amount *
                                             x?.cartQuantity}
                                          {x?.custom_discount?.discountType ===
                                          "percentage"
                                             ? " %"
                                             : " TK."}
                                       </span>
                                       {x?.custom_discount?.amount ===
                                       0 ? null : (
                                          <span className="text-sm text-gray-500">
                                             Reason:{" "}
                                             {x?.custom_discount?.reason
                                                ?.length > 20
                                                ? x?.custom_discount?.reason.substr(
                                                     0,
                                                     20
                                                  )
                                                : x?.custom_discount?.reason}
                                             {x?.custom_discount?.reason
                                                ?.length > 20 && "..."}
                                          </span>
                                       )}
                                    </td>
                                 </tr>
                              )
                           })}
                        </tbody>
                     </table>
                  ) : (
                     "No product found"
                  )}

                  <div className="flex flex-col items-end text-sm ">
                     <div className="border border-blue-gray-100 w-6/12">
                        <div className="flex justify-between items-center border-b border-blue-gray-100 px-4 w-full pb-3 !pt-0">
                           <span>Subtotal</span>
                           <span>{totalAmount} TK.</span>
                        </div>
                        {paymentDiscount.amounts !== 0 && (
                           <div className="border-b border-blue-gray-100 px-4 w-full pb-3 !pt-0">
                              <div className="flex justify-between items-center ">
                                 <span>Additional Discount</span>
                                 <span>
                                    {paymentDiscount.amounts || 0}
                                    {paymentDiscount?.discountTypes ===
                                    "percentage"
                                       ? " %"
                                       : " TK.  "}
                                 </span>
                              </div>

                              <div className="text-xs text-gray-600 flex-col ">
                                 {paymentDiscount?.reason && (
                                    <span className="block">
                                       Reason : {paymentDiscount?.reason}
                                    </span>
                                 )}
                                 {paymentDiscount?.amounts !== 0 && (
                                    <span>
                                       (After Discount-{" "}
                                       {totalAmount - paymentDiscountAmount}{" "}
                                       TK.)
                                    </span>
                                 )}
                              </div>
                           </div>
                        )}
                        <div className=" border-b border-blue-gray-100 px-4 w-full pb-3 !pt-0">
                           <div className="flex  justify-between items-center">
                              <span>Delivery Fee</span>
                              <span> {shipping?.shipping_cost} TK.</span>
                           </div>
                           <span>
                              {" "}
                              {shipping?.shipping_type ? (
                                 <span className="text-xs text-gray-600">
                                    {shipping?.shipping_type}
                                 </span>
                              ) : null}
                           </span>
                        </div>
                        <div className="flex justify-between items-center border-b border-blue-gray-100 px-4 w-full pb-3 !pt-0">
                           <span>Total</span>
                           <span>{total} TK.</span>
                        </div>

                        <div className="flex justify-between items-center border-b border-blue-gray-100 px-4 w-full pb-3 !pt-0">
                           <span>Payment Method</span>
                           <span>{paymentMethod}</span>
                        </div>

                        <div className="flex justify-between items-center px-4 w-full pb-3 !pt-0">
                           <span>Customer Payable</span>
                           <span>
                              {paymentMethod &&
                              paymentMethod !== "cash on delivery"
                                 ? 0
                                 : total}
                              TK.
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default CreateUpdateOrder
