import React, { useEffect, useState } from "react"
import {
   useCreateCustomerMutation,
   useGetBDGeoQuery,
} from "../../services/customer"
import { Modal } from "rsuite"
import { enqueueSnackbar } from "notistack"
import { userAccount } from "../../utils/utils"
import { useGetCategoryQuery } from "../../services/category"
import Select from "react-select"

const CreateNewCustomer = ({ open, handleCloseCreateCustomer }) => {
   const [createCustomer, { isSuccess, isError, error }] =
      useCreateCustomerMutation()

   const { data: categories } = useGetCategoryQuery()
   const { data: bdGeoData } = useGetBDGeoQuery()
   const [thana, setThana] = useState([])

   const [CI, setCI] = useState({
      name: "",
      email: "",
      phone: "",
      dathOfBirth: "",
      customerTag: [],
   })

   const [SA, setSA] = useState({
      phone: "",
      address: "",
      area: "",
      district: "",
      thana: "",
   })

   // all tag data
   const tagOptions = categories?.map((item) => {
      return { value: `${item?.subCategory}`, label: `${item?.subCategory}` }
   })

   // multiple tag handle
   const handleCustomerTag = (item) => {
      setCI({ ...CI, customerTag: [...item] })
   }

   const data = {
      contactInformation: {
         name: CI.name,
         email: CI.email,
         phone: CI.phone,
         dathOfBirth: CI.dathOfBirth,
         customerTag: CI.customerTag,
      },
      shippingAddress: {
         phone: SA.phone,
         address: SA.address,
         area: SA.area,
         district: SA.district,
         thana: SA.thana,
      },
   }

   useEffect(() => {
      if (isSuccess) {
         enqueueSnackbar("Customer Created", { variant: "success" })
      }
      if (isError) {
         enqueueSnackbar(`${error?.data?.message}`, { variant: "error" })
      }
   }, [isSuccess, isError])

   useEffect(() => {
      const data = bdGeoData?.find((item) => item?.city === SA.district)

      setThana(data?.thana)
   }, [SA.district])

   const createACustomer = async (e) => {
      e.preventDefault()
      if (!data?.contactInformation?.phone || !data?.contactInformation?.name) {
         return enqueueSnackbar(`Customer Full Information not provided!`, {
            variant: "error",
         })
      }

      await createCustomer(data)
      handleCloseCreateCustomer()
      setCI({
         name: "",
         email: "",
         phone: "",
         dathOfBirth: "",
         customerTag: [],
      })
      setSA({
         phone: "",
         address: "",
         area: "",
         district: "",
         thana: "",
      })
   }

   return (
      <Modal open={open}>
         <Modal.Body>
            <div className="relative pb-0 dark:bg-dark">
               <div
                  onClick={handleCloseCreateCustomer}
                  className="absolute right-2 top-2 text-[20px] mr-2 cursor-pointer"
               >
                  ✕
               </div>
               <h3 className="text-lg font-semibold border-b pb-4 mb-5">
                  Create a new customer
               </h3>
               <div className="text-[15px]">
                  <form action="" className="space-y-4 relative">
                     {/* user name*/}
                     <div className="flex flex-col w-full ">
                        <label htmlFor="name">
                           User Name{" "}
                           <span className="text-red-600 text-base">*</span>
                        </label>
                        <input
                           name="name"
                           value={CI.name}
                           onChange={(e) =>
                              setCI({ ...CI, name: e.target.value })
                           }
                           type="text"
                           className="dark:text-dark w-full px-3 py-2 border border-gray-300 rounded-lg outline-none"
                           placeholder="User Name"
                        />
                     </div>
                     {/* email and phone */}
                     <div className="flex items-center justify-between gap-2">
                        <div className="flex flex-col w-full">
                           <label htmlFor="discountType">Email</label>
                           <input
                              name="email"
                              value={CI.email}
                              onChange={(e) =>
                                 setCI({ ...CI, email: e.target.value })
                              }
                              type="email"
                              className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                              placeholder="Enter your email"
                           />
                        </div>
                        {/* Phone */}
                        <div className="flex flex-col w-full">
                           <label htmlFor="discountType">
                              Phone{" "}
                              <span className="text-red-600 text-base">*</span>
                           </label>
                           <input
                              name="phone"
                              value={CI.phone}
                              onChange={(e) =>
                                 setCI({ ...CI, phone: e.target.value })
                              }
                              type="text"
                              className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                              placeholder="Enter your phone"
                           />
                        </div>
                     </div>

                     <div className="grid grid-cols-2 w-full gap-2">
                        {/* date of birth and customer tag */}
                        <div className="flex flex-col w-full">
                           <label htmlFor="dathOfBirth">Dath of birth</label>
                           <input
                              name="dathOfBirth"
                              value={CI.dathOfBirth}
                              onChange={(e) =>
                                 setCI({ ...CI, dathOfBirth: e.target.value })
                              }
                              type="date"
                              className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                              placeholder="Dath of birth"
                           />
                        </div>
                        {/* customer tag */}
                        <div className="flex flex-col w-full">
                           <label htmlFor="customerTag" className="mb-1 ">
                              Customer Tag
                           </label>
                           <div className="w-full text-gray-600">
                              <Select
                                 options={tagOptions}
                                 value={CI.customerTag}
                                 onChange={handleCustomerTag}
                                 placeholder="Select customer tag"
                                 isMulti
                                 isLoading={false}
                                 isClearable={false}
                                 isDisabled={false}
                                 isSearchable={true}
                                 noOptionsMessage={() => "no user found"}
                                 styles={{
                                    textTransform: "capitalize",
                                    placeholder: (baseStyles, state) => ({
                                       ...baseStyles,
                                       border: "none",
                                       width: "300px",
                                    }),
                                 }}
                              />
                           </div>
                        </div>
                     </div>
                     {/* shipping address */}
                     <div className="space-y-3">
                        <span className="mb-3 inline-block">
                           Shipping address
                        </span>

                        {/* district and divistion */}
                        <div className="flex items-center justify-between gap-2">
                           <div className="flex flex-col w-full">
                              <label htmlFor="district">District</label>
                              <select
                                 name="district"
                                 onChange={(e) => {
                                    setSA({ ...SA, district: e.target.value })
                                 }}
                                 className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 w-full mt-1 outline-none text-sm"
                              >
                                 <option value="">Select District</option>
                                 {bdGeoData?.map((item) => {
                                    return (
                                       <option
                                          key={item?.city}
                                          value={item?.city}
                                       >
                                          {item?.city}
                                       </option>
                                    )
                                 })}
                              </select>
                           </div>
                           {SA?.district && (
                              <div className="flex flex-col w-full">
                                 <label htmlFor="thana">Thana</label>
                                 <select
                                    name="thana"
                                    onChange={(e) =>
                                       setSA({ ...SA, thana: e.target.value })
                                    }
                                    className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 w-full mt-1 outline-none text-sm"
                                 >
                                    <option value="">Select District</option>
                                    {thana?.map((data) => {
                                       return (
                                          <option value={data}>{data}</option>
                                       )
                                    })}
                                 </select>
                              </div>
                           )}
                        </div>
                        {/* postcode and area */}
                        <div className="flex items-center justify-between gap-2">
                           <div className="flex flex-col w-full">
                              <label htmlFor="area">Area</label>
                              <input
                                 name="area"
                                 value={SA.area}
                                 onChange={(e) =>
                                    setSA({ ...SA, area: e.target.value })
                                 }
                                 type="text"
                                 className="dark:text-dark  w-full px-3 py-2 border border-gray-300 rounded-lg outline-none"
                                 placeholder="Area"
                              />
                           </div>
                           <div className="flex flex-col w-full">
                              <label htmlFor="address">Address</label>
                              <input
                                 name="address"
                                 value={SA.address}
                                 onChange={(e) =>
                                    setSA({ ...SA, address: e.target.value })
                                 }
                                 type="text"
                                 className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                                 placeholder="Address"
                              />
                           </div>
                        </div>
                        {/* address */}
                        <div className="flex flex-col w-full">
                           <label htmlFor="phone">Phone</label>
                           <input
                              name="phone"
                              value={SA.phone}
                              onChange={(e) =>
                                 setSA({ ...SA, phone: e.target.value })
                              }
                              type="phone"
                              className="dark:text-dark  w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                              placeholder="Phone"
                           />
                        </div>
                     </div>
                     <div className="text-right py-5 border-t ">
                        <button
                           type="button"
                           onClick={() => {
                              setCI({
                                 name: "",
                                 email: "",
                                 phone: "",
                                 dathOfBirth: "",
                                 customerTag: [],
                              })
                              setSA({
                                 phone: "",
                                 address: "",
                                 area: "",
                                 district: "",
                                 thana: "",
                              })
                              handleCloseCreateCustomer()
                           }}
                           className="py-2 px-4 border border-gray-300 hover:bg-gray-100 rounded-lg cursor-pointer mr-2"
                        >
                           Cancel
                        </button>
                        <button
                           onClick={(e) => createACustomer(e)}
                           type="submit"
                           className="py-2 px-4 rounded-md border border-green-800 bg-green-800 hover:border-green-900 hover:bg-green-900  duration-150 text-white"
                        >
                           Create Customer
                        </button>
                     </div>
                  </form>
               </div>
            </div>
         </Modal.Body>
      </Modal>
   )
}

export default CreateNewCustomer
