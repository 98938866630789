import { createSlice } from "@reduxjs/toolkit";
import { createUser } from "./allUserFunc";

const createUserSlice = createSlice({
  name: "createUser",
  initialState: {
    userInfo: {},
    status: null,
  },
  extraReducers: {
    [createUser.pending]: (state) => {
      state.status = "loading";
    },
    [createUser.fulfilled]: (state, action) => {
      state.userInfo = action.payload;
      state.status = "success";
    },
    [createUser.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default createUserSlice.reducer;
