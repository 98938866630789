import { useReactToPrint } from "react-to-print"
import React, { useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { useGetOrderByIdQuery } from "../../../services/order"
import InvoiceSlip from "../../../components/PrintPackging/InvoiceSlip"
import PrintPackagingSlip from "../../../components/PrintPackging/PrintPackagingSlip"

const OrderInvoice = () => {
   const { id } = useParams()

   const { data: order } = useGetOrderByIdQuery(id)

   const componentRef = useRef()

   const [qr, setqr] = useState(null)
   const [orderInfo, setOrderInfo] = useState("")

   const handlePrint = useReactToPrint({
      content: () => componentRef.current,
   })

   // print
   const combineFn = (data) => {
      if (data?.barcode === undefined) {
         alert("No barcode found with this product")
         setqr(null)
      } else {
         setOrderInfo(data)
         setTimeout(() => {
            handlePrint()
         }, 1000)
      }
   }

   return (
      <div className="w-2/3 mt-5 p-5  space-y-3">
         <button
            onClick={(e) => combineFn(order)}
            className="flex justify-end py-3 px-3 rounded-xl z-50 bg-custom_blue hover:bg-custom_darkBlue text-white "
         >
            <svg
               viewBox="0 0 20 20"
               className="Polaris-Icon__Svg_375hu w-4 mr-2 sm:mr-2 text-white"
               focusable="false"
               aria-hidden="true"
               fill="ffffff"
            >
               <path
                  fillRule="evenodd"
                  d="M14 11h2v-2h-2v2zm-7-4h6v-3h-6v3zm0 9h6v-2h-6v2zm8-9v-3.5a1.5 1.5 0 0 0-1.5-1.5h-7a1.5 1.5 0 0 0-1.5 1.5v3.5h-1.5a1.5 1.5 0 0 0-1.5 1.5v6a1.5 1.5 0 0 0 1.5 1.5h1.5v.5a1.5 1.5 0 0 0 1.5 1.5h7a1.5 1.5 0 0 0 1.5-1.5v-.5h1.5a1.5 1.5 0 0 0 1.5-1.5v-6a1.5 1.5 0 0 0-1.5-1.5h-1.5z"
               ></path>
            </svg>
            <h3 className="text-xs capitalize">print Order Invoice</h3>
         </button>
         <div className="border border-blue-gray-100 rounded-lg">
            <div ref={componentRef} className="overflow-hidden p-3 ">
               <InvoiceSlip order={order} />
            </div>
         </div>
      </div>
   )
}

export default OrderInvoice
