import { packagingCost } from "./const-info"

// ORDER PRODUCT BUYING PRICE
export const totalBuyingPrice = (order) => {
   let costPerItem = order?.orderedProducts?.reduce((total, item) => {
      return item?.costPerItem
         ? parseInt(item?.costPerItem) * parseInt(item?.cartQuantity) + total
         : 0 * parseInt(item?.cartQuantity) + total
   }, 0)
   return costPerItem
}

// GET TOTAL COST
export const getTotalCost = (order) => {
   const costPerItem = totalBuyingPrice(order)
   const shipping = order?.paymentShipping?.shipping_cost
   const total = Number(costPerItem) + parseInt(shipping) + packagingCost
   return total
}

// GET DISCOUNT AMOUNT FOR PERCENTAGE
export const getDiscountAmountFromPercentage = (order) => {
   return order?.paymentDiscount?.discountType !== "amount"
      ? (order?.subTotal * Number(order?.paymentDiscount?.value)) / 100
      : Number(order?.paymentDiscount?.value)
}

// GET NET PAYMENT OF REFUN & RETURN
export const netPaymentOfRefundReturn = (order, returned) => {
   return (
      order?.totalPrice -
      (Number(returned?.refundOptions?.refundAmount) +
         Number(returned?.returnOptions?.shippingCost))
   )
}

// GET REFUND & RETURN BALANCE
export const refundAndReturnTotalcost = (order, returned) => {
   const remainingOrder = []
   order?.orderedProducts?.forEach((item) => {
      let orderProduct = item
      if (returned?.returnedProducts?.length > 0) {
         for (const returnOrder of returned?.returnedProducts) {
            if (item?._id === returnOrder?._id) {
               orderProduct = {
                  ...returnOrder,
                  cartQuantity:
                     returnOrder?.cartQuantity - returnOrder?.returnQty,
               }
            }
         }
      }
      remainingOrder.push(orderProduct)
   })

   const remainingOrderBalance = remainingOrder.reduce((total, item) => {
      return parseInt(item?.costPerItem) * parseInt(item?.cartQuantity) + total
   }, 0)
   console.log("remainingOrderBalance", remainingOrderBalance)
   return (
      remainingOrderBalance +
      parseFloat(packagingCost) +
      order?.paymentShipping?.shipping_cost
   )
}

// GET NET PAYMENT AND BALANCE OF REFUND / RETURN PRODUCT
export const getNetPaymentAndBalance = (orderStatus, order, returned) => {
   let balance = 0
   let netPayment = 0
   const refundReturnNetPayment = netPaymentOfRefundReturn(order, returned)

   if (orderStatus === "return in progress") {
      netPayment = Number(returned?.returnOptions?.shippingCost) + packagingCost
      balance = netPayment
   } else if (orderStatus === "refund & return in progress") {
      netPayment = Number(refundReturnNetPayment)
      balance =
         Number(refundReturnNetPayment) -
         Number(refundAndReturnTotalcost(order, returned))
   } else {
      netPayment = order?.totalPrice - returned?.refundOptions?.refundAmount
      balance = netPayment - getTotalCost(order)
   }

   return { netPayment, balance }
}

// RETURN PRODUCTS VALUE
export const returnProductValue = (order) => {
   return order?.returned?.returnedProducts?.reduce((total, item) => {
      return item?.discountedPrice * item?.returnQty + total
   }, 0)
}

// ORDER PRODUCT BUYING PRICE
export const userTotalAmountSpent = (orders) => {
   let totalAmountSpent = orders
      ?.filter((item) => item?.orderStatus === "fullfilled")
      ?.reduce((total, item) => {
         return item?.totalPrice + total
      }, 0)
   return totalAmountSpent
}
