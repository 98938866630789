import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const categoryAPI = createApi({
   reducerPath: "categoryAPI",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["categoryAPI"],
   endpoints: (builder) => ({
      getCategory: builder.query({
         query: () => `api/category`,
         providesTags: ["categoryAPI"],
      }),
      getSubCategory: builder.query({
         query: (category) => `api/category/${category}`,
         providesTags: ["categoryAPI"],
      }),
      createCategory: builder.mutation({
         query: (data) => ({
            url: `api/category`,
            method: "POST",
            body: data,
         }),
         invalidatesTags: ["categoryAPI"],
      }),
      updateCategory: builder.mutation({
         query: ({ id, ...rest }) => ({
            url: `api/category/${id}`,
            method: "PATCH",
            body: rest,
         }),
         invalidatesTags: ["categoryAPI"],
      }),
      deleteCategory: builder.mutation({
         query: (id) => ({
            url: `api/category/${id}`,
            method: "DELETE",
         }),
         invalidatesTags: ["categoryAPI"],
      }),
   }),
})

export const {
   useGetCategoryQuery,
   useGetSubCategoryQuery,
   useCreateCategoryMutation,
   useUpdateCategoryMutation,
   useDeleteCategoryMutation,
} = categoryAPI
