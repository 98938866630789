import React from "react"
import { useParams } from "react-router-dom"
import { userTotalAmountSpent } from "../../../../utils/orderInformation"
import { useGetEmployeeQuery } from "../../../../services/employee"
import { employeeInfoFunc } from "../../../../utils/utils"
import UserSidebar from "../UserSidebar"
import { useGetMyPurchaseOrdersQuery } from "../../../../services/order"
import { useGetUserQuery } from "../../../../services/user"
import UserOrder from "../UserOrders/UserOrder"

const UserPurchase = () => {
   const { id } = useParams()
   const { data: userInfo } = useGetUserQuery(id)
   const { data: orders, isLoading } = useGetMyPurchaseOrdersQuery(userInfo)
   const employeeFromLocal = JSON.parse(localStorage.getItem("ich-user"))
   const { data: user } = useGetEmployeeQuery(employeeFromLocal?.id)
   const permission = employeeInfoFunc(user, "users")

   const userAmountSpent = userTotalAmountSpent(orders)

   if (isLoading) {
      return <p>Loading...</p>
   }
   return (
      <div className="grid grid-cols-8 justify-between ">
         <div className="col-span-7  p-10">
            {orders?.length > 0 ? (
               <>
                  <div className="grid grid-cols-3 items-center gap-4 bg-white dark:bg-dark dark:text-white border rounded-lg px-5 py-7 mb-4 shadow">
                     <div className="border-r">
                        <p className="text-sm">Total Amount</p>
                        <h3 className="text-xl mt-2 text-black dark:text-white">
                           ৳{" "}
                           <span className="font-semibold">
                              {userAmountSpent} TK.
                           </span>
                        </h3>
                     </div>
                     <div className="border-r">
                        <p className="text-sm">Total Purchase</p>
                        <h3 className="text-xl font-semibold mt-2 text-black dark:text-white">
                           {orders?.length}
                        </h3>
                     </div>
                     <div>
                        <p className="text-sm">Average order value</p>
                        <h3 className="sm:text-xl mt-2 text-black dark:text-white">
                           ৳{" "}
                           <span className="font-semibold">
                              {userAmountSpent / orders?.length}
                           </span>
                        </h3>
                     </div>
                  </div>
                  <div className="flex flex-col divide-y space-y-4 p-10 rounded-lg shadow bg-white dark:bg-dark m-auto">
                     {orders?.map((order, index) => (
                        <UserOrder key={index} order={order} />
                     ))}
                  </div>
               </>
            ) : (
               <h3 className="text-center text-red-500 text-base font-semibold">
                  No orders found
               </h3>
            )}
         </div>
         <div className="col-span-1">
            <UserSidebar user={userInfo}></UserSidebar>
         </div>
      </div>
   )
}

export default UserPurchase
