import React, { useEffect, useState } from "react"
import { TiDelete } from "react-icons/ti"
import { useDispatch, useSelector } from "react-redux"
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { AiOutlineSearch } from "react-icons/ai"
import { RiDeleteBack2Line } from "react-icons/ri"
import BarcodeGenerate from "../../../components/Modal/BarcodeGenerate"
import Barcode from "react-barcode"
import { removeBarcode } from "../../../services/Features/barcode"
import toast from "react-hot-toast"
import { useGetSubWriterQuery } from "../../../services/sub-writer"
import { useGetAuthorQuery } from "../../../services/authors"
import { SelectPicker } from "rsuite"
import { useGetAllPublicationsQuery } from "../../../services/publications"

const AddGeneralBooks = () => {
   const { barcode } = useSelector((state) => state.barcode)
   const dispatch = useDispatch()
   const [openBarcodeModal, setOpenBarcodeModal] = useState(false)
   const [typeOpen, setTypeOpen] = useState(false)
   const [typeSearchValue, setTypeSearchValue] = useState("")
   const [status, setStatus] = useState(true)
   const [orderType, setOrderType] = useState("")
   const [onlinestore, setonlinestore] = useState("")
   const [pointofsale, setpointofsale] = useState("")
   const { userInfo } = useSelector((state) => state.login)
   const [files, setFiles] = useState([])
   const [pdf, setPdf] = useState("")
   const { data: authors } = useGetAuthorQuery()
   const { data: subwriters } = useGetSubWriterQuery()
   const { data: publications } = useGetAllPublicationsQuery()

   const [authorName, setAuthorName] = useState()
   const [subWriterName, setSubWriterName] = useState()
   const [publicationName, setPublicationName] = useState()

   const authorData = authors?.map((item) => ({
      label: item.name,
      value: item.name,
   }))
   const subWritersData = subwriters?.map((item) => ({
      label: item.name,
      value: item.name,
   }))
   const publicationsData = publications?.map((item) => ({
      label: item.name,
      value: item.name,
   }))

   const onChange = (e) => {
      setFiles(e.target.files)
   }
   const onChangePdf = (e) => {
      setPdf(e.target.files[0])
   }

   const [uploadData, setUploadData] = useState()
   const [uploadPdf, setUploadPdf] = useState()

   const onSubmit = async (event) => {
      event.preventDefault()
      const formData = new FormData()
      Object.values(files).forEach((file) => {
         formData.append("uploadImages", file)
      })

      try {
         if (files.length === 0)
            return alert("Please upload an image first the create a product!")
         await axios
            .post("/api/image-upload", formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            })
            .then((res) => {
               setUploadData(res.data)
               alert("Image uploaded")
            })
            .catch((err) => {
               alert(err?.response?.data?.message)
            })
      } catch (err) {
         if (err.response.status === 500) {
            console.log(err)
         } else {
            console.log(err.response.data.msg)
         }
      }
   }
   const onSubmitPdf = async (event) => {
      event.preventDefault()
      const formData = new FormData()
      formData.append("uploadPdfs", pdf)

      try {
         if (!pdf)
            return alert("Please upload a pdf first then update this product!")
         await axios
            .post("/api/pdfs-upload", formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            })
            .then((res) => {
               setUploadPdf(res.data)
               alert("pdf uploaded")
            })
            .catch((err) => {
               alert(err?.response?.data?.message)
            })
      } catch (err) {
         if (err.response.status === 500) {
            console.log(err)
         } else {
            console.log(err.response.data.msg)
         }
      }
   }

   const types = [
      "Law",
      "Drama",
      "Horror",
      "Novel",
      "Poem",
      "Sports",
      "Medical",
      "Cooking",
      "Bangla Classic",
      "Bangla Translate",
      "Sarcasm & Satire",
      "Science Fiction",
      "Health & Disease",
      "Religious Books",
      "Tours & Travels",
      "Articles & Essay",
      "Animal",
      "Olympiad",
      "University",
      "Psychology",
      "Muktijuddho",
      "Bangladesh",
      "Philosophy",
      "Engineering",
      "Awarded Books",
      "History & Culture",
      "Para-Psychology",
      "Family & Parenting",
      "Fashion & Self-care",
      "Computer & Internet",
      "Agriculture & Firming",
      "Society & Environment",
      "Language & Dictionary",
      "Journalism & Mass Media",
      "Society, Culture & History",
      "Freelancing & Outsourcing",
      "Math, Science & Technology",
      "Mystery, Detective, Adventure",
      "Admission & Exam Preparation",
      "Self-Motivations & Meditations",
      "Biographies, Memories & Interviews",
      "Drawing, Painting, Design & Photography",
      "Business, Investment & Economy",
      "Professional, Journal & Reference",
      "Songs, Movies & Entertainment",
      "IELTS",
      "Story",
      "Comics & Picture Books",
      "Religious",
      "Politics",
      "New Arrivals",
   ]
   const general = useFormik({
      initialValues: {
         category: "general",
         subCategory: "",
         type: "",
         title: "",
         subWriterDescription: "",
         price: "",
         description: "",
         publisher: "",
         published: "",
         isbn: "",
         edition: "",
         language: "",
         countInStock: "",
         discount: "",
         gyankoshDiscount: "",
         ebookDiscount: "",
         ebookPrice: "",
         pageNumber: "",
         costPerItem: "",
      },
      validationSchema: Yup.object({
         category: Yup.string().required("This field required"),
         subCategory: Yup.string().required("This field required"),
         type: Yup.string().required("This field required"),
         title: Yup.string().required("This field required"),
         authorDescription: Yup.string().required("This field required"),
         price: Yup.number()
            .required("This field required")
            .typeError("you must specify a number"),
         description: Yup.string().required("This field required"),
         publisher: Yup.string().required("This field required"),
         published: Yup.string().required("This field required"),
         isbn: Yup.string().required("This field required"),
         edition: Yup.string().required("This field required"),
         language: Yup.string().required("This field required"),
         countInStock: Yup.number()
            .required("This field required")
            .typeError("you must specify a number"),
      }),
      onSubmit: (values, { resetForm }) => {
         try {
            const productObj = {
               category: general.values.category,
               subCategory: general.values.subCategory
                  .replace(/\s+/g, " ")
                  .trim(),
               type: general.values.type
                  .replace(/\s+/g, " ")
                  .trim()
                  .toLowerCase(),
               title: general.values.title,

               price: Number(general.values.price),
               images: uploadData,
               pdfLink: uploadPdf,
               description: general.values.description,
               publisher: general.values.publisher,
               published: general.values.published,
               isbn: general.values.isbn,
               edition: general.values.edition,
               language: general.values.language,
               countInStock: Number(general.values.countInStock),
               discount: Number(general.values.discount),
               gyankoshDiscount: Number(general.values.gyankoshDiscount),
               ebookDiscount: Number(general.values.ebookDiscount),
               ebookPrice: Number(general.values.ebookPrice),
               status: status,
               pointofsale: pointofsale,
               onlinestore: onlinestore,
               orderType: orderType,
               pageNumber: Number(general.values.pageNumber),
               barcode: barcode,
               costPerItem: Number(general.values.costPerItem),
               slug: `${general.values.category
                  .replace(/\s+/g, "-")
                  .toLowerCase()}-${general.values.title
                  .replace(/\s+/g, "-")
                  .toLowerCase()}-${authorName
                  .replace(/\s+/g, "-")
                  .toLowerCase()}-${barcode}`,
            }

            if (!uploadData) {
               alert("Please upload an image first!")
            } else {
               axios
                  .post("/api/general", productObj, {
                     headers: {
                        "Content-Type": "application/json",
                        Authorization: `${userInfo.refreshToken}`,
                     },
                  })
                  .then((response) => {
                     toast.success(response?.data?.message)
                     return window.location.reload()
                  })
            }
         } catch (err) {
            if (err.response.status === 500) {
               console.log(err)
            } else {
               console.log(err.response.data.msg)
            }
         }
      },
   })
   const handleGeneralSubmit = (e) => {
      e.preventDefault()
      const authorInfo = authors?.find((item) => item?.name === authorName)
      const subWriterInfo = subwriters?.find(
         (item) => item?.name === subWriterName
      )

      try {
         const productObj = {
            category: general.values.category,
            subCategory: general.values.subCategory.replace(/\s+/g, " ").trim(),
            type: general.values.type.replace(/\s+/g, " ").trim().toLowerCase(),
            title: general.values.title,
            author: {
               name: authorName,
               description: authorInfo?.description,
            },
            authorImage: authorInfo?.image,
            subWriter: subWriterName,
            subWriterImage: subWriterInfo?.image,
            subWriterDescription: subWriterInfo?.description,
            price: Number(general.values.price),
            images: uploadData,
            pdfLink: uploadPdf,
            description: general.values.description,
            publisher: publicationName,
            published: general.values.published,
            isbn: general.values.isbn,
            edition: general.values.edition,
            language: general.values.language,
            countInStock: Number(general.values.countInStock),
            discount: Number(general.values.discount),
            gyankoshDiscount: Number(general.values.gyankoshDiscount),
            ebookDiscount: Number(general.values.ebookDiscount),
            ebookPrice: Number(general.values.ebookPrice),
            status: status,
            pointofsale: pointofsale,
            onlinestore: onlinestore,
            orderType: orderType,
            pageNumber: Number(general.values.pageNumber),
            barcode: barcode,
            costPerItem: Number(general.values.costPerItem),
            slug: `${general.values.category
               .trim()
               .replace(/\s+/g, "-")
               .toLowerCase()}-${general.values.title
               .trim()
               .replace(/\s+/g, "-")
               .toLowerCase()}-${authorName
               .trim()
               .replace(/\s+/g, "-")
               .toLowerCase()}-${barcode.trim().replace(/\s+/g, "-")}`,
         }

         if (!barcode) {
            return alert("Please Create a barcode")
         } else if (!uploadData) {
            alert("Please upload an image first!")
         } else {
            axios
               .post("/api/general", productObj, {
                  headers: {
                     "Content-Type": "application/json",
                     Authorization: `${userInfo.refreshToken}`,
                  },
               })
               .then((response) => {
                  toast.success(response?.data?.message)
                  return window.location.reload()
               })
         }
      } catch (err) {
         if (err.response.status === 500) {
            console.log(err)
         } else {
            console.log(err.response.data.msg)
         }
      }
   }
   // CLOSE BARCODE SELECT MODAL
   const closeBarcodeModal = () => {
      setOpenBarcodeModal(false)
   }
   useEffect(() => {
      dispatch(removeBarcode())
   }, [])
   const inputStyle =
      "px-2 shadow py-2.5 rounded-md duration-300 outline-none placeholder:font-thin text-sm dark:bg-gray-50 dark:text-dark placeholder:text-darkText"
   const labelStyle = "text-palette10 select-none"
   const boxStyle = "flex flex-col w-full border p-2 rounded"
   const textAreaStyle =
      "px-4 py-2 rounded-lg dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin h-[120px] text-sm dark:text-dark"

   return (
      <>
         <h3 className="text-xl mt-1 capitalize text-palette10 bg-white shadow text-center py-4 select-none">
            Add New Item
         </h3>
         <div className="px-5 md:px-5 lg:px-10 my-5 rounded-lg dark:text-white">
            <div className="flex flex-col sm:flex-row md:flex-col lg:flex-row gap-4 items-start sm:justify-between">
               <h3 className="text-xl capitalize text-palette10 CustomBtn">
                  General Books
               </h3>
               <div>
                  {!barcode ? (
                     <button
                        onClick={(e) => setOpenBarcodeModal(true)}
                        htmlFor="productName"
                        type="button"
                        className={`${labelStyle} text-center border rounded-lg px-4 py-1.5 border-gray-300`}
                     >
                        Add QR/Barcode
                     </button>
                  ) : (
                     <div className="flex gap-2 items-start">
                        <Barcode value={barcode} height={30} width={1.0} />
                        <button
                           onClick={(e) => dispatch(removeBarcode())}
                           type="button"
                        >
                           <TiDelete
                              size={30}
                              className=" text-red-600 hover:text-red-900 duration-200"
                           />
                        </button>
                     </div>
                  )}
                  <BarcodeGenerate
                     openBarcodeModal={openBarcodeModal}
                     handleCloseBarcodeModal={closeBarcodeModal}
                  />
               </div>
            </div>
            <form className="mt-5">
               <div className="sm:grid grid-cols-2 justify-between gap-3 sm:gap-4 lg:gap-8 w-full space-y-4 sm:space-y-0">
                  <div className="space-y-3 w-full">
                     <div className={boxStyle}>
                        <label htmlFor="productName" className={labelStyle}>
                           Product Name
                        </label>
                        <input
                           type="text"
                           name="title"
                           value={general.values.title}
                           onChange={general.handleChange}
                           onBlur={general.handleBlur}
                           placeholder="Title"
                           className={`${inputStyle}, lowercase`}
                        />
                        {general.touched.title && general.errors.title && (
                           <small className="text-palette6 my-0.5">
                              {general.errors.title}
                           </small>
                        )}
                     </div>

                     <div className={boxStyle}>
                        <label htmlFor="category" className={labelStyle}>
                           Category
                        </label>
                        <input
                           type="text"
                           name="category"
                           readOnly
                           value={general.values.category}
                           onBlur={general.handleBlur}
                           placeholder="general"
                           className={`${inputStyle}, lowercase cursor-not-allowed`}
                        />
                        {general.touched.category &&
                           general.errors.category && (
                              <small className="text-palette6 my-0.5">
                                 {general.errors.category}
                              </small>
                           )}
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="subCategory" className={labelStyle}>
                           Sub-category
                        </label>
                        <input
                           type="text"
                           name="subCategory"
                           value={general.values.subCategory}
                           onChange={general.handleChange}
                           onBlur={general.handleBlur}
                           placeholder="Type English or Bangla"
                           className={`${inputStyle}, lowercase`}
                        />
                        {general.touched.subCategory &&
                           general.errors.subCategory && (
                              <small className="text-palette6 my-0.5">
                                 {general.errors.subCategory}
                              </small>
                           )}
                     </div>
                     <div
                        onClick={() => setTypeOpen(true)}
                        className={boxStyle}
                     >
                        <label htmlFor="type" className={labelStyle}>
                           Type
                        </label>
                        <input
                           type="text"
                           name="type"
                           value={general.values.type}
                           onBlur={general.handleBlur}
                           autocomplete="off"
                           placeholder="Product Type.."
                           className={`${inputStyle}, lowercase`}
                        />
                        {general.touched.type && general.errors.type && (
                           <small className="text-palette6 my-0.5">
                              {general.errors.type}
                           </small>
                        )}
                     </div>
                     <div
                        className={`bg-white mt-2 pb-3 shadow-md rounded-lg overflow-y-auto max-h-96 w-full sm:w-80
                        ${typeOpen ? "block" : "hidden"}`}
                     >
                        <div className="bg-white px-4 sticky top-0 w-full">
                           <div className="text-right">
                              <button onClick={() => setTypeOpen(false)}>
                                 <RiDeleteBack2Line
                                    size={23}
                                 ></RiDeleteBack2Line>
                              </button>
                           </div>
                           <div className="flex items-center py-1 px-4  rounded-md bg-gray-200 relative">
                              <AiOutlineSearch className="text-[18px] text-gray-700"></AiOutlineSearch>
                              <input
                                 value={typeSearchValue}
                                 onChange={(e) =>
                                    setTypeSearchValue(
                                       e.target.value.toLowerCase()
                                    )
                                 }
                                 type="text"
                                 placeholder="Type..."
                                 className="placeholder:text-gray-7 outline-none w-full border-none focus:border-none bg-gray-200 focus:ring-0"
                              />
                           </div>
                        </div>
                        <ul className="px-4">
                           <h3 className="text-[15px] font-medium ">
                              Suggestions
                           </h3>
                           {types.map((type, index) => (
                              <li
                                 key={index}
                                 className={`${
                                    type
                                       .toLowerCase()
                                       .startsWith(typeSearchValue)
                                       ? "block"
                                       : "hidden"
                                 }`}
                              >
                                 <input
                                    type="text"
                                    name="type"
                                    onClick={general.handleChange}
                                    onMouseUp={() => setTypeOpen(false)}
                                    value={type}
                                    autocomplete="false"
                                    className="border-none outline-none py-2 hover:bg-gray-100 cursor-pointer w-full text-sm  rounded-md px-2"
                                 />
                              </li>
                           ))}
                        </ul>
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="price" className={labelStyle}>
                           Price
                        </label>
                        <input
                           type="text"
                           name="price"
                           value={general.values.price}
                           onChange={general.handleChange}
                           onBlur={general.handleBlur}
                           placeholder="Price"
                           className={`${inputStyle}, lowercase`}
                        />
                        {general.touched.price && general.errors.price && (
                           <small className="text-palette6 my-0.5">
                              {general.errors.price}
                           </small>
                        )}
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="publisher" className={labelStyle}>
                           Publisher
                        </label>
                        <SelectPicker
                           value={publicationName}
                           onChange={(item) => setPublicationName(item)}
                           placeholder="Publisher"
                           className={`my-2 lowercase`}
                           data={publicationsData}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="isbnNumber" className={labelStyle}>
                           ISBN Number
                        </label>
                        <input
                           type="text"
                           name="isbn"
                           value={general.values.isbn}
                           onChange={general.handleChange}
                           onBlur={general.handleBlur}
                           placeholder="ISBN Number"
                           className={`${inputStyle}, lowercase`}
                        />
                        {general.touched.isbn && general.errors.isbn && (
                           <small className="text-palette6 my-0.5">
                              {general.errors.isbn}
                           </small>
                        )}
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="published" className={labelStyle}>
                           Published
                        </label>
                        <input
                           type="text"
                           name="published"
                           value={general.values.published}
                           onChange={general.handleChange}
                           onBlur={general.handleBlur}
                           placeholder="Published"
                           className={`${inputStyle}, lowercase`}
                        />
                        {general.touched.published &&
                           general.errors.published && (
                              <small className="text-palette6 my-0.5">
                                 {general.errors.published}
                              </small>
                           )}
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="edition" className={labelStyle}>
                           Edition
                        </label>
                        <input
                           type="text"
                           name="edition"
                           value={general.values.edition}
                           onChange={general.handleChange}
                           onBlur={general.handleBlur}
                           placeholder="Edition"
                           className={`${inputStyle}, lowercase`}
                        />
                        {general.touched.edition && general.errors.edition && (
                           <small className="text-palette6 my-0.5">
                              {general.errors.edition}
                           </small>
                        )}
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="language" className={labelStyle}>
                           Language
                        </label>
                        <input
                           type="text"
                           name="language"
                           value={general.values.language}
                           onChange={general.handleChange}
                           onBlur={general.handleBlur}
                           placeholder="Language"
                           className={`${inputStyle}, lowercase`}
                        />
                        {general.touched.language &&
                           general.errors.language && (
                              <small className="text-palette6 my-0.5">
                                 {general.errors.language}
                              </small>
                           )}
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="countInStock" className={labelStyle}>
                           Count in Stock
                        </label>
                        <input
                           type="text"
                           name="countInStock"
                           value={general.values.countInStock}
                           onChange={general.handleChange}
                           onBlur={general.handleBlur}
                           placeholder="Count in Stock (Must be Number)"
                           className={`${inputStyle}, lowercase`}
                        />
                        {general.touched.countInStock &&
                           general.errors.countInStock && (
                              <small className="text-palette6 my-0.5">
                                 {general.errors.countInStock}
                              </small>
                           )}
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="discount" className={labelStyle}>
                           Discount
                        </label>
                        <input
                           type="text"
                           name="discount"
                           value={general.values.discount}
                           onChange={general.handleChange}
                           onBlur={general.handleBlur}
                           placeholder="Leave it if no discount (Must be Number)"
                           className={`${inputStyle}, lowercase`}
                        />
                        {general.touched.discount &&
                           general.errors.discount && (
                              <small className="text-palette6 my-0.5">
                                 {general.errors.discount}
                              </small>
                           )}
                     </div>
                     <div className={boxStyle}>
                        <label
                           htmlFor="gyankoshDiscount"
                           className={labelStyle}
                        >
                           Gyankosh Discount
                        </label>
                        <input
                           type="text"
                           name="gyankoshDiscount"
                           value={general.values.gyankoshDiscount}
                           onChange={general.handleChange}
                           onBlur={general.handleBlur}
                           placeholder="Leave it if no gyankosh discount (Must be Number)"
                           className={`${inputStyle}, lowercase`}
                        />
                        {general.touched.gyankoshDiscount &&
                           general.errors.gyankoshDiscount && (
                              <small className="text-palette6 my-0.5">
                                 {general.errors.gyankoshDiscount}
                              </small>
                           )}
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="costPerItem" className={labelStyle}>
                           Cost per item
                        </label>
                        <input
                           type="number"
                           name="costPerItem"
                           value={general.values.costPerItem}
                           onChange={general.handleChange}
                           onBlur={general.handleBlur}
                           placeholder="Price in BDT"
                           className={`${inputStyle}, lowercase`}
                        />
                        {general.touched.costPerItem &&
                           general.errors.costPerItem && (
                              <small className="text-palette6 my-0.5">
                                 {general.errors.costPerItem}
                              </small>
                           )}
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="pageNumber" className={labelStyle}>
                           Page Number
                        </label>

                        <input
                           type="number"
                           name="pageNumber"
                           placeholder="Page Number"
                           value={general.values.pageNumber}
                           onChange={general.handleChange}
                           onBlur={general.handleBlur}
                           className={`${inputStyle}, lowercase`}
                        />
                     </div>
                     <div className="bg-white dark:bg-dark p-5 rounded-lg w-full shadow">
                        <h3>Product Status</h3>
                        <select
                           name="status"
                           onChange={(e) => {
                              if (e.target.value === "true") {
                                 setStatus(true)
                              } else {
                                 setStatus(false)
                              }
                           }}
                           className={`${inputStyle}, cursor-pointer`}
                        >
                           <option>Select..</option>
                           <option value={true}>Active</option>
                           <option value={false}>Inactive</option>
                        </select>
                        <div className="space-y-2 mt-4 border-t pt-3">
                           <h4 className="uppercase">
                              Sales channels and apps
                           </h4>
                           <div>
                              <input
                                 type="checkbox"
                                 name="onlinestore"
                                 id="onlinestore"
                                 value="onlinestore"
                                 onChange={(e) =>
                                    setonlinestore(e.target.value)
                                 }
                                 className="mr-1"
                              />
                              <label
                                 htmlFor="onlinestore"
                                 name="onlinestore"
                                 className={`${labelStyle} cursor-pointer`}
                              >
                                 Online Store
                              </label>
                           </div>
                           <div>
                              <input
                                 type="checkbox"
                                 name="pointofsale"
                                 id="pointofsale"
                                 value="pointofsale"
                                 onChange={(e) =>
                                    setpointofsale(e.target.value)
                                 }
                                 className="mr-1"
                              />
                              <label
                                 htmlFor="pointofsale"
                                 name="pointofsale"
                                 className={`${labelStyle} cursor-pointer`}
                              >
                                 Point Of Sale
                              </label>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* right side input box */}
                  <div className="space-y-3 w-full">
                     <div className={boxStyle}>
                        <label htmlFor="authorName" className={labelStyle}>
                           Author Name
                        </label>
                        <SelectPicker
                           value={authorName}
                           onChange={(item) => setAuthorName(item)}
                           placeholder="Author Name"
                           className={`${inputStyle}, lowercase`}
                           data={authorData}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="subWriter" className={labelStyle}>
                           subWriter Name
                        </label>
                        <SelectPicker
                           value={subWriterName}
                           onChange={(item) => setSubWriterName(item)}
                           placeholder="Sub Writer Name"
                           className={`${inputStyle}, lowercase `}
                           data={subWritersData}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="uploadPdfs" className={labelStyle}>
                           EBOOK
                        </label>
                        <input
                           type="file"
                           id="file"
                           name="uploadPdfs"
                           onChange={onChangePdf}
                        />
                        <button onClick={onSubmitPdf} className="CssBtn1">
                           <span className="text">Select only Pdf file!</span>
                           <span className="mt-1.5">Add PDF</span>
                        </button>
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="ebookPrice" className={labelStyle}>
                           Ebook Price
                        </label>
                        <input
                           type="number"
                           name="ebookPrice"
                           value={general.values.ebookPrice}
                           onChange={general.handleChange}
                           onBlur={general.handleBlur}
                           placeholder="Price in BDT"
                           className={`${inputStyle}, lowercase`}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="ebookDiscount" className={labelStyle}>
                           Ebook Discount
                        </label>
                        <input
                           type="number"
                           name="ebookDiscount"
                           value={general.values.ebookDiscount}
                           onChange={general.handleChange}
                           onBlur={general.handleBlur}
                           placeholder="Discount in percentage"
                           className={`${inputStyle}, lowercase`}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="productImage" className={labelStyle}>
                           Product Image
                        </label>
                        <input
                           type="file"
                           id="file"
                           name="uploadImages"
                           multiple
                           onChange={onChange}
                        />
                        {/* <span className="text-xs text-white w-full h-full bg-palette6 p-1 rounded px-0.5 mt-1">
                                only jpg, png, and avif format allowed!
                            </span>
                            <button
                                className="CssBtn2"
                                onClick={ onSubmit }
                            >
                                Add image
                            </button>{ " " } */}
                        <button onClick={onSubmit} className="CssBtn1">
                           <span className="text">
                              Select only jpg, png, and avif file!
                           </span>
                           <span className="mt-1.5">Add Product Image</span>
                        </button>
                     </div>
                     <div className={boxStyle}>
                        <label
                           htmlFor="productDescription"
                           className={labelStyle}
                        >
                           Product Description
                        </label>
                        <textarea
                           name="description"
                           value={general.values.description}
                           onChange={general.handleChange}
                           onBlur={general.handleBlur}
                           placeholder="Product Description"
                           className={`${textAreaStyle}, lowercase`}
                        ></textarea>
                        {general.touched.description &&
                           general.errors.description && (
                              <small className="text-palette6 my-0.5">
                                 {general.errors.description}
                              </small>
                           )}
                     </div>
                     <div className="bg-white dark:bg-dark p-5 rounded-lg w-full shadow">
                        <h3 className=" mb-4">Order Type</h3>
                        <label
                           htmlFor="current"
                           className="cursor-pointer text-sm mb-2 block"
                        >
                           <input
                              onChange={(e) => setOrderType(e.target.value)}
                              value={"current"}
                              type="radio"
                              id="current"
                              name="orderType"
                              className="mr-2"
                           />
                           <span>Current Order</span>
                        </label>
                        <label htmlFor="pre" className="cursor-pointer text-sm">
                           <input
                              onChange={(e) => setOrderType(e.target.value)}
                              value={"pre"}
                              type="radio"
                              id="pre"
                              name="orderType"
                              className="mr-2"
                           />
                           <span>Pre Order</span>
                        </label>
                     </div>
                     {/* <button type='submit' className={ `${orderType === "current" || orderType === "pre" || 'disabled cursor-wait'} CustomBtn` }>Submit</button> */}
                     <div>
                        <button
                           onClick={handleGeneralSubmit}
                           type="submit"
                           className="border border-black py-3 px-10 mt-5 text-black font-semibold hover:bg-[#363730] ease-in hover:text-white"
                        >
                           Submit
                        </button>
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </>
   )
}

export default AddGeneralBooks
