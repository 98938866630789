import React from "react"
import { useContext } from "react"
import { DashboardContext } from "../../context/DashboardProvider"
import { Menu } from "@headlessui/react"
import { Link } from "react-router-dom"

const ReportItemDetails = () => {
   return (
      <div>
         <div className="flex items-center justify-between">
            <div className="flex gap-4 items-center">
               <div className="flex items-center text-[#9191A1]">
                  <span>
                     <svg
                        width="18"
                        height="18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M1.045 4.488h15.91"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinejoin="round"
                        ></path>
                        <path
                           d="M4.325 1.164h9.589c.423 0 .824.187 1.095.512l1.617 1.94c.213.257.33.58.33.913v10.408a1.9 1.9 0 0 1-1.9 1.9H3.183a1.9 1.9 0 0 1-1.9-1.9V4.529c0-.333.117-.656.33-.912L3.23 1.676c.271-.325.672-.512 1.095-.512zM6.98 7.812h4.275M9.12 1.4v2.85"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeMiterlimit="10"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        ></path>
                     </svg>
                  </span>
                  <h3 className="text-[18px] px-4">All Items</h3>
                  <span>
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        className="Chevron__Svg-sc-zctemm-0 gOuVYO"
                     >
                        <path
                           fill="currentColor"
                           fillRule="evenodd"
                           clipRule="evenodd"
                           d="M6.07303 11.9509C6.25207 12.118 6.48481 12.2 6.71446 12.2C6.94411 12.2 7.17686 12.118 7.35589 11.9509L10.9271 8.6176C11.291 8.27787 11.291 7.72227 10.9271 7.38254L7.35589 4.04928C6.99978 3.71689 6.42914 3.71689 6.07303 4.04928C5.70905 4.389 5.70905 4.94461 6.07303 5.28434L8.9826 8.00007L6.07303 10.7158C5.70905 11.0555 5.70905 11.6111 6.07303 11.9509Z"
                        ></path>
                     </svg>
                  </span>
               </div>
               <h3 className="text-lg">abc</h3>
            </div>
            <Menu>
               <Menu.Button className="flex items-center bg-[#dd2a3b] opacity-[0.8] px-4 py-2 rounded-md font-semibold text-white text-xs ">
                  <button
                     type="button"
                     className=" bg-[#ffffff3b] p-1 mr-2 rounded-sm"
                  >
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                     >
                        <path
                           stroke="currentColor"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           strokeMiterlimit="10"
                           strokeWidth="1.4"
                           d="M8 8l4-4m0 0l4 4m-4-4v9"
                        ></path>
                        <path
                           stroke="currentColor"
                           strokeLinecap="round"
                           strokeWidth="1.4"
                           d="M5 13v4a3 3 0 003 3h8a3 3 0 003-3v-4"
                        ></path>
                     </svg>
                  </button>
                  Export Report
               </Menu.Button>
               <Menu.Items className="absolute top-20 right-8">
                  <Menu.Item className="flex items-center justify-start bg-[#dd2a3b] opacity-[0.8] w-[200px] px-5 py-3 rounded-md font-semibold text-white text-xs ">
                     {({ active }) => (
                        <Link
                           className={`${active && "bg-[#576CBC]"} text-left`}
                           href="/account-settings"
                        >
                           <button type="button" className=" mr-2">
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 fill="none"
                                 viewBox="0 0 24 24"
                                 strokeWidth="1.5"
                                 stroke="currentColor"
                                 className="w-6 h-6"
                              >
                                 <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                 />
                              </svg>
                           </button>
                           ADD ITEM
                        </Link>
                     )}
                  </Menu.Item>
               </Menu.Items>
            </Menu>
         </div>
      </div>
   )
}

export default ReportItemDetails
