import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
   setPaymentInformation,
   setPaymentMethod,
} from "../../services/Features/paymentSlice"

const PaymentInformation = ({ pay, order }) => {
   const dispatch = useDispatch()

   const { paymentMethod, paymentInformation } = useSelector(
      (state) => state.payment
   )

   const paymentInformationHandle = (key, value) => {
      dispatch(
         setPaymentInformation({
            ...paymentInformation,
            [key]: value,
            change: key == "amountReceived" ? value - pay : 0,
         })
      )
   }

   const paymentMethodHandle = (value) => {
      dispatch(setPaymentMethod(value))
      dispatch(
         setPaymentInformation({ trNumber: "", amountReceived: 0, change: 0 })
      )
   }

   useEffect(() => {
      dispatch(setPaymentMethod(""))
   }, [])

   useEffect(() => {
      dispatch(setPaymentMethod(order?.paymentMethod))
      dispatch(setPaymentInformation(order?.paymentInformation))
   }, [order])

   return (
      <div className="flex flex-col gap-10">
         <div className="grid grid-cols-3 justify-between items-center gap-8">
            <button
               onClick={() => paymentMethodHandle("cash")}
               type="button"
               className={`w-full border shadow-lg rounded-xl text-center py-5 duration-100 px-10  hover:bg-blue-50 ${
                  paymentMethod === "cash" &&
                  "bg-custom_blue text-white hover:bg-custom_blue "
               }`}
            >
               Cash
            </button>
            <div>
               <button
                  onClick={() => paymentMethodHandle("card")}
                  type="button"
                  className={`w-full border shadow-lg rounded-xl text-center py-5 duration-100 px-10 hover:bg-blue-50 ${
                     paymentMethod === "card" &&
                     "bg-custom_blue text-white hover:bg-custom_blue"
                  }`}
               >
                  {" "}
                  Card
               </button>
            </div>
            <div>
               <button
                  onClick={() => paymentMethodHandle("bkash")}
                  type="button"
                  className={`w-full border shadow-lg rounded-xl text-center py-5 duration-100 px-10 hover:bg-blue-50 ${
                     paymentMethod === "bkash" &&
                     "bg-custom_blue text-white hover:bg-custom_blue"
                  }`}
               >
                  bKash
               </button>
            </div>
         </div>
         <div className="grid grid-cols-3 gap-8">
            <div>
               <button
                  onClick={() => paymentMethodHandle("nagad")}
                  type="button"
                  className={`w-full border shadow-lg rounded-xl text-center py-5 duration-100 px-10 hover:bg-blue-50 ${
                     paymentMethod === "nagad" &&
                     "bg-custom_blue text-white hover:bg-custom_blue"
                  }`}
               >
                  Nagad
               </button>
            </div>
            <div>
               <button
                  onClick={() => paymentMethodHandle("cash on delivery")}
                  type="button"
                  className={`w-full border shadow-lg rounded-xl text-center py-5 duration-100 px-10 hover:bg-blue-50 ${
                     paymentMethod === "cash on delivery" &&
                     "bg-custom_blue text-white hover:bg-custom_blue"
                  }`}
               >
                  Cash on delivery
               </button>
            </div>
         </div>
         {paymentMethod && paymentMethod !== "cash on delivery" && (
            <form className="bg-white rounded-lg shadow-lg border px-3 py-6 flex justify-between">
               {paymentMethod == "cash" ? (
                  <div className="flex flex-col w-full">
                     <label htmlFor="amountNumber">Amount Received</label>
                     <input
                        name="amountReceived"
                        type="number"
                        value={paymentInformation?.amountReceived}
                        onChange={(e) =>
                           paymentInformationHandle(
                              "amountReceived",
                              Number(e.target.value)
                           )
                        }
                        className="dark:text-dark w-2/4 px-3 py-2 border border-gray-300 rounded-lg outline-none"
                        placeholder="Amount Received"
                     />
                  </div>
               ) : (
                  <div className="flex flex-col w-full ">
                     <label htmlFor="reference">
                        Transaction Reference Number
                     </label>
                     <input
                        name="reference"
                        type="text"
                        value={paymentInformation?.trNumber}
                        onChange={(e) =>
                           paymentInformationHandle("trNumber", e.target.value)
                        }
                        className="dark:text-dark w-2/4 px-3 py-2 border border-gray-300 rounded-lg outline-none"
                        placeholder="Reference Number"
                     />
                  </div>
               )}
               <div className="flex flex-col justify-between">
                  <p className="w-36 font-semibold text-sm text-red-500">
                     {" "}
                     Pay : {pay} TK.
                  </p>
                  {paymentMethod === "cash" && (
                     <p className="w-36 font-semibold ">
                        Change : {paymentInformation?.amountReceived - pay} TK.
                     </p>
                  )}
               </div>
            </form>
         )}
      </div>
   )
}

export default PaymentInformation
