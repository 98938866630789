import React, { useEffect, useState } from "react"
import { ImCross } from "react-icons/im"
import { useFormik } from "formik"
import * as Yup from "yup"
// import { useBarcode } from "@createnextapp/react-barcode"
import Select from "react-select"
import axios from "axios"
import { useCreateAcademicMutation } from "../../../services/academic"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"

// import uuid v4
// import { v4 as uuid } from "uuid"
import BarcodeGenerate from "../../../components/Modal/BarcodeGenerate"
import Barcode from "react-barcode"
import { TiDelete } from "react-icons/ti"
import { removeBarcode } from "../../../services/Features/barcode"
import { SelectPicker } from "rsuite"
import { useGetAuthorQuery } from "../../../services/authors"
import { useGetSubWriterQuery } from "../../../services/sub-writer"
import { useGetAcademicSchoolsQuery } from "../../../services/academicSchool"
import { useGetAllPublicationsQuery } from "../../../services/publications"

const AddAcademicBooks = () => {
   const { barcode } = useSelector((state) => state.barcode)
   const dispatch = useDispatch()
   const [openBarcodeModal, setOpenBarcodeModal] = useState(false)
   const [createAcademic] = useCreateAcademicMutation()
   const { data: academicSchools } = useGetAcademicSchoolsQuery()

   const { data: authors } = useGetAuthorQuery()
   const { data: subwriters } = useGetSubWriterQuery()
   const { data: publications } = useGetAllPublicationsQuery()

   const [authorName, setAuthorName] = useState()
   const [subWriterName, setSubWriterName] = useState()
   const [publicationName, setPublicationName] = useState()

   const authorData = authors?.map((item) => ({
      label: item.name,
      value: item.name,
   }))

   const subWritersData = subwriters?.map((item) => ({
      label: item.name,
      value: item.name,
   }))

   const publicationsData = publications?.map((item) => ({
      label: item.name,
      value: item.name,
   }))

   const [selectedClass, setSelectedClass] = useState([])
   const [selectedSchool, setSelectedSchool] = useState({})
   const [classType, setClassType] = useState([])
   const [schoolClass, setSchoolClass] = useState([])
   const [status, setStatus] = useState(true)
   const [orderType, setOrderType] = useState("")
   const [onlinestore, setonlinestore] = useState("")
   const [pointofsale, setpointofsale] = useState("")
   const { userInfo } = useSelector((state) => state.login)

   const [files, setFiles] = useState([])
   const [pdf, setPdf] = useState("")
   const [schools, setSchools] = useState("")
   const [schoolOptions, setSchoolOptions] = useState([])
   const [classTypes, setClassTypes] = useState([])

   const onChange = (e) => {
      setFiles(e.target.files)
   }
   const onChangePdf = (e) => {
      setPdf(e.target.files[0])
   }

   const [uploadData, setUploadData] = useState()
   const [uploadPdf, setUploadPdf] = useState()

   const onSubmit = async (event) => {
      event.preventDefault()
      const formData = new FormData()
      Object.values(files).forEach((file) => {
         formData.append("uploadImages", file)
      })

      try {
         if (files.length === 0)
            return alert("Please upload an image first the create a product!")
         await axios
            .post("/api/image-upload", formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            })
            .then((res) => {
               setUploadData(res.data)
               alert("Image uploaded")
            })
            .catch((err) => {
               alert(err?.response?.data?.message)
            })
      } catch (err) {
         if (err.response.status === 500) {
            console.log(err)
         } else {
            console.log(err.response.data.msg)
         }
      }
   }
   const onSubmitPdf = async (event) => {
      event.preventDefault()
      const formData = new FormData()
      formData.append("uploadPdfs", pdf)

      try {
         if (!pdf)
            return alert("Please upload a pdf first then update this product!")
         await axios
            .post("/api/pdfs-upload", formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            })
            .then((res) => {
               setUploadPdf(res.data)
               alert("pdf uploaded")
            })
            .catch((err) => {
               alert(err?.response?.data?.message)
            })
      } catch (err) {
         if (err.response.status === 500) {
            console.log(err)
         } else {
            console.log(err.response.data.msg)
         }
      }
   }

   const classTypeHandle = (e) => {
      setSelectedClass(e)
      let typeData = e.map((data) => {
         return data?.value
      })
      setClassType(typeData)
   }
   const schoolsHandle = (e) => {
      setSelectedSchool(e)
      setSchools(e.value)
   }
   const submitSchoolAndClass = () => {
      const alreadyAdded = schoolClass?.find((data) => data?.school === schools)

      if (schools && classType?.length > 0 && !alreadyAdded?.school) {
         const data = {
            school: schools,
            class: classType,
         }
         setSchoolClass([...schoolClass, data])
      }

      if (alreadyAdded?.school) toast.error("already added")

      setSelectedClass(null)
      setSelectedSchool(null)
      setSchools(null)
      setClassType(null)
   }
   const removeFromSchoolAndClass = (data) => {
      setSchoolClass(
         schoolClass.filter((schooldata) => schooldata?.school !== data)
      )
   }
   const academic = useFormik({
      initialValues: {
         category: "academic",
         type: "",
         title: "",
         price: "",
         description: "",
         published: "",
         isbn: "",
         edition: "",
         countInStock: "",
         discount: "",
         gyankoshDiscount: "",
         ebookDiscount: "",
         ebookPrice: "",
         pageNumber: "",
         costPerItem: "",
      },
      validationSchema: Yup.object({
         title: Yup.string().required("This field required"),
         price: Yup.number()
            .required("This field required")
            .typeError("you must specify a number"),
      }),
      onSubmit: (values, { resetForm }) => {
         const authorInfo = authors?.find((item) => item?.name === authorName)
         const subWriterInfo = subwriters?.find(
            (item) => item?.name === subWriterName
         )
         try {
            const productObj = {
               category: values.category,
               schools: schoolClass,
               title: values.title,
               author: {
                  name: authorName,
                  description: authorInfo?.description,
               },
               authorImage: authorInfo?.image,
               subWriter: subWriterName,
               subWriterImage: subWriterInfo?.image,
               subWriterDescription: subWriterInfo?.description,
               price: Number(values.price),
               images: uploadData,
               pdfLink: uploadPdf,
               description: values.description,
               publisher: publicationName,
               published: values.published,
               isbn: values.isbn,
               edition: values.edition,
               countInStock: Number(values.countInStock),
               discount: Number(values.discount),
               gyankoshDiscount: Number(values.gyankoshDiscount),
               ebookDiscount: Number(values.ebookDiscount),
               ebookPrice: Number(values.ebookPrice),
               status: status,
               pointofsale: pointofsale,
               onlinestore: onlinestore,
               orderType: orderType,
               pageNumber: Number(values.pageNumber),
               barcode,
               costPerItem: Number(values.costPerItem),
               slug: `${values.category
                  .replace(/\s+/g, "-")
                  .toLowerCase()}-${values.title
                  .replace(/\s+/g, "-")
                  .toLowerCase()}-${authorName
                  .replace(/\s+/g, "-")
                  .toLowerCase()}-${barcode
                  .replace(/\s+/g, "-")
                  .toLowerCase()}`,
            }

            if (!barcode) {
               return alert("Please Create a barcode")
            } else {
               axios
                  .post("/api/academic", productObj, {
                     headers: {
                        "Content-Type": "application/json",
                        Authorization: `${userInfo.refreshToken}`,
                     },
                  })
                  .then((response) => {
                     toast.success(response?.data?.message)
                     return window.location.reload()
                  })
            }
         } catch (err) {
            toast.error("create failed")
         }
      },
   })

   // CLOSE BARCODE SELECT MODAL
   const closeBarcodeModal = () => {
      setOpenBarcodeModal(false)
   }

   useEffect(() => {
      dispatch(removeBarcode())
   }, [])

   useEffect(() => {
      const classData = academicSchools?.allClass?.map((item) => {
         return { value: item, label: item }
      })
      const schoolData = academicSchools?.schools?.map((item) => {
         return { value: item?.name, label: item?.name }
      })

      setSchoolOptions(schoolData)
      setClassTypes(classData)
   }, [academicSchools])

   const inputStyle =
      "px-2 shadow py-2.5 rounded-md duration-300 outline-none placeholder:font-thin text-sm dark:bg-gray-50 dark:text-dark placeholder:text-darkText"
   const labelStyle = "text-palette10 select-none"
   const boxStyle = "flex flex-col w-full border p-2 rounded"
   const textAreaStyle =
      "rounded-lg dark:bg-gray-50 mt-1 hover:border-gray-400 duration-300 outline-none placeholder:font-thin h-[120px] text-sm dark:text-dark"

   return (
      <>
         <h3 className="text-xl mt-1 capitalize text-palette10 bg-white shadow text-center py-4 select-none">
            Add New Item
         </h3>
         <div className="px-5 md:px-5 lg:px-10 my-5 rounded-lg dark:text-white">
            <div className="flex flex-col sm:flex-row md:flex-col lg:flex-row gap-4 items-start sm:justify-between">
               <h3 className="text-xl capitalize text-palette10 CustomBtn">
                  Academic Books
               </h3>
               <div>
                  {!barcode ? (
                     <button
                        onClick={(e) => setOpenBarcodeModal(true)}
                        type="button"
                        className={`${labelStyle} text-center border rounded-lg px-4 py-1.5 border-gray-300`}
                     >
                        Add QR/Barcode
                     </button>
                  ) : (
                     <div className="flex gap-2 items-start">
                        <Barcode value={barcode} height={30} width={1.0} />
                        <button
                           onClick={(e) => dispatch(removeBarcode())}
                           type="button"
                        >
                           <TiDelete
                              size={30}
                              className=" text-red-600 hover:text-red-900 duration-200"
                           />
                        </button>
                     </div>
                  )}
                  <BarcodeGenerate
                     openBarcodeModal={openBarcodeModal}
                     handleCloseBarcodeModal={closeBarcodeModal}
                  />
               </div>
               {/* <img ref={inputRef} alt="" className="w-56 lg:w-auto" /> */}
            </div>
            <form className="mt-5">
               <div className="sm:grid grid-cols-2 justify-between gap-3 sm:gap-4 lg:gap-8 w-full space-y-4 sm:space-y-0">
                  <div className="space-y-3 w-full">
                     <div className={boxStyle}>
                        <label htmlFor="productName" className={labelStyle}>
                           Product Name
                        </label>
                        <input
                           type="text"
                           name="title"
                           value={academic.values.title}
                           onChange={academic.handleChange}
                           onBlur={academic.handleBlur}
                           placeholder="Title"
                           className={`${inputStyle}, lowercase`}
                        />
                        {academic.touched.title && academic.errors.title && (
                           <small className="text-palette6 my-0.5">
                              {academic.errors.title}
                           </small>
                        )}
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="category" className={labelStyle}>
                           Category
                        </label>
                        <input
                           type="text"
                           name="category"
                           readOnly
                           value={academic.values.category}
                           onBlur={academic.handleBlur}
                           placeholder="Academic"
                           className={`${inputStyle}, lowercase cursor-not-allowed`}
                        />
                        {academic.touched.category &&
                           academic.errors.category && (
                              <small className="text-palette6 my-0.5">
                                 {academic.errors.category}
                              </small>
                           )}
                     </div>
                     <div>
                        <ul className="rounded-lg  text-black">
                           {schoolClass?.map((data, index) => {
                              return (
                                 <li
                                    key={index}
                                    className="border border-gray-400 bg-gray-100 flex divide-x rounded-lg"
                                 >
                                    <div className="grid grid-cols-2 w-full divide-x divide-gray-400">
                                       <div className=" py-2 px-3">
                                          {data?.school}
                                       </div>
                                       <div className=" py-2 px-3">
                                          {data?.class?.map((c, index) =>
                                             index + 1 !== data?.class?.length
                                                ? `${c}, `
                                                : c
                                          )}
                                       </div>
                                    </div>
                                    <span
                                       onClick={() =>
                                          removeFromSchoolAndClass(data?.school)
                                       }
                                       className="cursor-pointer w-[24px] flex justify-center items-center "
                                    >
                                       <ImCross />
                                    </span>
                                 </li>
                              )
                           })}
                        </ul>
                     </div>
                     <div className={boxStyle}>
                        <div className="flex z-50">
                           <div className="grid grid-cols-2">
                              <div className="w-full text-gray-600 ring-0">
                                 <p className={labelStyle}>School Names</p>
                                 <Select
                                    options={schoolOptions}
                                    value={selectedSchool}
                                    onChange={schoolsHandle}
                                    placeholder={"School Name"}
                                    isSearchable
                                    noOptionsMessage={() => "no school found"}
                                    styles={{
                                       placeholder: (baseStyles, state) => ({
                                          ...baseStyles,
                                          border: "none",
                                          width: "300px",
                                       }),
                                    }}
                                 />
                              </div>
                              <div className="w-full text-gray-600 ring-0">
                                 <p className={labelStyle}>Class Type</p>
                                 <Select
                                    options={classTypes}
                                    value={selectedClass}
                                    onChange={classTypeHandle}
                                    placeholder={"Class Type"}
                                    isSearchable
                                    noOptionsMessage={() => "no class found"}
                                    isMulti
                                    styles={{
                                       placeholder: (baseStyles, state) => ({
                                          ...baseStyles,
                                          border: "none",
                                          width: "300px",
                                       }),
                                    }}
                                 />
                              </div>
                           </div>
                           <div
                              onClick={() => submitSchoolAndClass()}
                              className="cursor-pointer w-[25px] flex justify-center items-center pt-4"
                           >
                              ✔️
                           </div>
                        </div>
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="price" className={labelStyle}>
                           Price
                        </label>
                        <input
                           type="text"
                           name="price"
                           value={academic.values.price}
                           onChange={academic.handleChange}
                           onBlur={academic.handleBlur}
                           placeholder="Price"
                           className={`${inputStyle}, lowercase`}
                        />
                        {academic.touched.price && academic.errors.price && (
                           <small className="text-palette6 my-0.5">
                              {academic.errors.price}
                           </small>
                        )}
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="publisher" className={labelStyle}>
                           Publisher
                        </label>
                        <SelectPicker
                           value={publicationName}
                           onChange={(item) => setPublicationName(item)}
                           placeholder="Publisher"
                           className={`my-2 lowercase z-10`}
                           data={publicationsData}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="isbnNumber" className={labelStyle}>
                           ISBN Number
                        </label>
                        <input
                           type="text"
                           name="isbn"
                           value={academic.values.isbn}
                           onChange={academic.handleChange}
                           onBlur={academic.handleBlur}
                           placeholder="ISBN Number"
                           className={`${inputStyle}, lowercase`}
                        />
                        {academic.touched.isbn && academic.errors.isbn && (
                           <small className="text-palette6 my-0.5">
                              {academic.errors.isbn}
                           </small>
                        )}
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="published" className={labelStyle}>
                           Published
                        </label>
                        <input
                           type="text"
                           name="published"
                           value={academic.values.published}
                           onChange={academic.handleChange}
                           onBlur={academic.handleBlur}
                           placeholder="Published"
                           className={`${inputStyle}, lowercase`}
                        />
                        {academic.touched.published &&
                           academic.errors.published && (
                              <small className="text-palette6 my-0.5">
                                 {academic.errors.published}
                              </small>
                           )}
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="edition" className={labelStyle}>
                           Edition
                        </label>
                        <input
                           type="text"
                           name="edition"
                           value={academic.values.edition}
                           onChange={academic.handleChange}
                           onBlur={academic.handleBlur}
                           placeholder="Edition"
                           className={`${inputStyle}, lowercase`}
                        />
                        {academic.touched.edition &&
                           academic.errors.edition && (
                              <small className="text-palette6 my-0.5">
                                 {academic.errors.edition}
                              </small>
                           )}
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="countInStock" className={labelStyle}>
                           Count in Stock
                        </label>
                        <input
                           type="text"
                           name="countInStock"
                           value={academic.values.countInStock}
                           onChange={academic.handleChange}
                           onBlur={academic.handleBlur}
                           placeholder="Count in Stock (Must be Number)"
                           className={`${inputStyle}, lowercase`}
                        />
                        {academic.touched.countInStock &&
                           academic.errors.countInStock && (
                              <small className="text-palette6 my-0.5">
                                 {academic.errors.countInStock}
                              </small>
                           )}
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="discount" className={labelStyle}>
                           Discount
                        </label>
                        <input
                           type="text"
                           name="discount"
                           value={academic.values.discount}
                           onChange={academic.handleChange}
                           onBlur={academic.handleBlur}
                           placeholder="Leave it if no discount (Must be Number)"
                           className={`${inputStyle}, lowercase`}
                        />
                        {academic.touched.discount &&
                           academic.errors.discount && (
                              <small className="text-palette6 my-0.5">
                                 {academic.errors.discount}
                              </small>
                           )}
                     </div>
                     <div className={boxStyle}>
                        <label
                           htmlFor="gyankoshDiscount"
                           className={labelStyle}
                        >
                           Gyankosh Discount
                        </label>
                        <input
                           type="text"
                           name="gyankoshDiscount"
                           value={academic.values.gyankoshDiscount}
                           onChange={academic.handleChange}
                           onBlur={academic.handleBlur}
                           placeholder="Leave it if no gyankosh discount (Must be Number)"
                           className={`${inputStyle}, lowercase`}
                        />
                        {academic.touched.gyankoshDiscount &&
                           academic.errors.gyankoshDiscount && (
                              <small className="text-palette6 my-0.5">
                                 {academic.errors.gyankoshDiscount}
                              </small>
                           )}
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="costPerItem" className={labelStyle}>
                           Cost per item
                        </label>
                        <input
                           type="number"
                           name="costPerItem"
                           value={academic.values.costPerItem}
                           onChange={academic.handleChange}
                           onBlur={academic.handleBlur}
                           placeholder="Price in BDT"
                           className={`${inputStyle}, lowercase`}
                        />
                        {academic.touched.costPerItem &&
                           academic.errors.costPerItem && (
                              <small className="text-palette6 my-0.5">
                                 {academic.errors.costPerItem}
                              </small>
                           )}
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="pageNumber" className={labelStyle}>
                           Page Number
                        </label>

                        <input
                           type="number"
                           name="pageNumber"
                           placeholder="Page Number"
                           value={academic.values.pageNumber}
                           onChange={academic.handleChange}
                           onBlur={academic.handleBlur}
                           className={`${inputStyle}, lowercase`}
                        />
                     </div>
                     <div className="bg-white dark:bg-dark p-5 rounded-lg w-full shadow">
                        <h3>Product Status</h3>
                        <select
                           name="status"
                           onChange={(e) => {
                              if (e.target.value === "true") {
                                 setStatus(true)
                              } else {
                                 setStatus(false)
                              }
                           }}
                           className={`${inputStyle}, cursor-pointer`}
                        >
                           <option>Select..</option>
                           <option value={true}>Active</option>
                           <option value={false}>Inactive</option>
                        </select>
                        <div className="space-y-2 mt-4 border-t pt-3">
                           <h4 className="uppercase">
                              Sales channels and apps
                           </h4>
                           <div>
                              <input
                                 type="checkbox"
                                 name="onlinestore"
                                 id="onlinestore"
                                 value="onlinestore"
                                 onChange={(e) =>
                                    setonlinestore(e.target.value)
                                 }
                                 className="mr-1"
                              />
                              <label
                                 htmlFor="onlinestore"
                                 name="onlinestore"
                                 className={`${labelStyle} cursor-pointer`}
                              >
                                 Online Store
                              </label>
                           </div>
                           <div>
                              <input
                                 type="checkbox"
                                 name="pointofsale"
                                 id="pointofsale"
                                 value="pointofsale"
                                 onChange={(e) =>
                                    setpointofsale(e.target.value)
                                 }
                                 className="mr-1"
                              />
                              <label
                                 htmlFor="pointofsale"
                                 name="pointofsale"
                                 className={`${labelStyle} cursor-pointer`}
                              >
                                 Point Of Sale
                              </label>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* right side input box */}
                  <div className="space-y-3 w-full">
                     <div className={boxStyle}>
                        <label htmlFor="authorName" className={labelStyle}>
                           Author Name
                        </label>
                        <SelectPicker
                           value={authorName}
                           onChange={(item) => setAuthorName(item)}
                           placeholder="Author Name"
                           className={`${inputStyle}, lowercase`}
                           data={authorData}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="subWriter" className={labelStyle}>
                           subWriter Name
                        </label>
                        <SelectPicker
                           value={subWriterName}
                           onChange={(item) => setSubWriterName(item)}
                           placeholder="Sub Writer Name"
                           className={`${inputStyle}, lowercase `}
                           data={subWritersData}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="uploadPdfs" className={labelStyle}>
                           EBOOK
                        </label>
                        <input
                           type="file"
                           id="file"
                           name="uploadPdfs"
                           onChange={onChangePdf}
                        />
                        <button onClick={onSubmitPdf} className="CssBtn1">
                           <span className="text">Select only Pdf file!</span>
                           <span className="mt-1.5">Add PDF</span>
                        </button>
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="ebookPrice" className={labelStyle}>
                           Ebook Price
                        </label>
                        <input
                           type="number"
                           name="ebookPrice"
                           value={academic.values.ebookPrice}
                           onChange={academic.handleChange}
                           onBlur={academic.handleBlur}
                           placeholder="Price in BDT"
                           className={`${inputStyle}, lowercase`}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="ebookDiscount" className={labelStyle}>
                           Ebook Discount
                        </label>
                        <input
                           type="number"
                           name="ebookDiscount"
                           value={academic.values.ebookDiscount}
                           onChange={academic.handleChange}
                           onBlur={academic.handleBlur}
                           placeholder="Discount in percentage"
                           className={`${inputStyle}, lowercase`}
                        />
                     </div>
                     <div className={boxStyle}>
                        <label htmlFor="productImage" className={labelStyle}>
                           Product Image
                        </label>
                        <input
                           type="file"
                           id="file"
                           name="uploadImages"
                           multiple
                           onChange={onChange}
                        />
                        {/* <span className="text-xs text-white w-full h-full bg-palette6 p-1 rounded px-0.5 mt-1">
                                only jpg, png, and avif format allowed!
                            </span>
                            <button
                                className="CssBtn2"
                                onClick={ onSubmit }
                            >
                                Add image
                            </button>{ " " } */}
                        <button onClick={onSubmit} className="CssBtn1">
                           <span className="text">
                              Select only jpg, png, and avif file!
                           </span>
                           <span className="mt-1.5">Add Product Image</span>
                        </button>
                     </div>
                     <div className={boxStyle}>
                        <label
                           htmlFor="productDescription"
                           className={labelStyle}
                        >
                           Product Description
                        </label>
                        <textarea
                           name="description"
                           value={academic.values.description}
                           onChange={academic.handleChange}
                           onBlur={academic.handleBlur}
                           placeholder="Product Description"
                           className={`${textAreaStyle}, lowercase`}
                        ></textarea>
                        {academic.touched.description &&
                           academic.errors.description && (
                              <small className="text-palette6 my-0.5">
                                 {academic.errors.description}
                              </small>
                           )}
                     </div>
                     <div className="bg-white dark:bg-dark p-5 rounded-lg w-full shadow">
                        <h3 className=" mb-4">Order Type</h3>
                        <label
                           htmlFor="current"
                           className="cursor-pointer text-sm mb-2 block"
                        >
                           <input
                              onChange={(e) => setOrderType(e.target.value)}
                              value={"current"}
                              type="radio"
                              id="current"
                              name="orderType"
                              className="mr-2"
                           />
                           <span>Current Order</span>
                        </label>
                        <label htmlFor="pre" className="cursor-pointer text-sm">
                           <input
                              onChange={(e) => setOrderType(e.target.value)}
                              value={"pre"}
                              type="radio"
                              id="pre"
                              name="orderType"
                              className="mr-2"
                           />
                           <span>Pre Order</span>
                        </label>
                     </div>
                  </div>
               </div>
               <button
                  type="submit"
                  onClick={academic.handleSubmit}
                  className="border border-black py-3 px-10 mt-5 text-black font-semibold hover:bg-[#363730] ease-in hover:text-white"
               >
                  Submit
               </button>
            </form>
         </div>
      </>
   )
}

export default AddAcademicBooks
