import React from "react"
import { useLocation, useNavigate } from "react-router-dom"

const AccountSelect = () => {
   const navigate = useNavigate()
   const { state } = useLocation()

   const switchSystem = (value) => {
      localStorage.setItem("ich-account", JSON.stringify(value))
      window.location.href = "/home"
   }

   return (
      <div className="w-full min-h-screen flex flex-col items-center justify-center ">
         <div className="text-black">
            <h3 className="text-4xl font-semibold ">
               Please choose an account
            </h3>
            <h3 className="text-[18px] py-5">Log in to your account.</h3>
         </div>

         <div className="flex items-center justify-center ">
            {state === "ichchapuron & gyankosh" ? (
               <div className="flex items-center gap-20">
                  <button
                     onClick={() => switchSystem("ichchapuron")}
                     type="button"
                     className="w-52 py-3 bg-custom_blue hover:bg-custom_skyBlue  duration-300 text-white font-semibold text-lg rounded-lg"
                  >
                     ইচ্ছাপূরণ
                  </button>
                  <button
                     onClick={() => switchSystem("gyankosh")}
                     type="button"
                     className="w-52 py-3 bg-custom_blue hover:bg-custom_skyBlue duration-300 text-white font-semibold text-lg rounded-lg"
                  >
                     জ্ঞানকোষ
                  </button>
               </div>
            ) : state === "gyankosh" ? (
               <button
                  onClick={() => switchSystem("gyankosh")}
                  type="button"
                  className="w-52 py-3 bg-custom_blue hover:bg-custom_skyBlue duration-300 text-white font-semibold text-lg rounded-lg"
               >
                  জ্ঞানকোষ
               </button>
            ) : state === "ichchapuron" ? (
               <button
                  onClick={() => switchSystem("ichchapuron")}
                  type="button"
                  className="w-52 py-3 bg-custom_blue hover:bg-custom_skyBlue  duration-300 text-white font-semibold text-lg rounded-lg"
               >
                  ইচ্ছাপূরণ
               </button>
            ) : (
               <p>You are not Authorized</p>
            )}
         </div>
      </div>
   )
}

export default AccountSelect
