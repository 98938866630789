export const CustomerOrderInfo = ({ orders, phone }) => {
   const total = orders?.filter(
      (item) =>
         item?.customer && item?.customer?.contactInformation?.phone === phone
   )
   let orderCounts = {}

   total?.forEach((item) => {
      const orderStatus = item?.orderStatus

      if (orderStatus !== undefined) {
         orderCounts[orderStatus] = (orderCounts[orderStatus] || 0) + 1
      }
   })

   return {
      total: total?.length,
      orderCounts,
   }
}

export const customerOrdersData = (orders) => {
   let ordersByStatus = {}

   orders?.forEach((item) => {
      const orderStatus = item?.orderStatus

      if (orderStatus !== undefined) {
         if (!ordersByStatus.hasOwnProperty(orderStatus)) {
            ordersByStatus[orderStatus] = []
         }
         ordersByStatus[orderStatus].push(item)
      }
   })

   return ordersByStatus
}
