import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useLoginEmployeeMutation } from "../../services/employee"
import logo from "../../assets/images/logo-ich.png"

const Login = () => {
   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm()
   const [loginEmployee, { error, isSuccess, isError, isLoading, data }] =
      useLoginEmployeeMutation()
   const navigate = useNavigate()
   const location = useLocation()
   const from = location?.state?.from?.pathname || "/"
   const [eyeOpen, setEyeOpen] = useState(false)

   useEffect(() => {
      if (isSuccess) {
         const user = {
            refreshToken: data.refreshToken,
            email: data.data?.email,
            user: data?.data?.name,
            id: data?.data?.id,
         }
         if (data?.data?.role === "admin") {
            user.role = data?.data?.role
         }

         localStorage.setItem("ich-user", JSON.stringify(user))
         toast.success(`${data.message}`, { duration: 1000 })
         navigate("/user/account", { state: data?.data?.account })
      }

      if (isError) {
         toast.error(`${error?.data?.message}`, { duration: 1000 })
         navigate("/")
      }
   }, [isSuccess, isError, error])

   const userLoginHandle = async (data) => {
      const { email, password } = data

      const user = {
         email,
         password,
      }
      loginEmployee(user)
   }

   return (
      <div className="grid grid-cols-1 md:grid-cols-2 min-h-screen px-10">
         <div className="py-16 w-full">
            <div className="text-center pb-16 pt-2 w-[150px] h-[40px] mb-10">
               <img src={logo} alt="" width={"100%"} />
            </div>
            <div className="text-black">
               <h3 className="text-4xl font-semibold ">Welcome back!</h3>
               <h3 className="text-[18px] py-5">Log in to your account.</h3>
            </div>
            <form
               action=""
               onSubmit={handleSubmit(userLoginHandle)}
               className="space-y-4 text-[15px]"
            >
               <div>
                  <input
                     type="text"
                     {...register("email", {
                        required: "Email is required",
                        pattern: {
                           value: /\S+@\ichchapuron.com.bd/,
                           message: "Email is not valid!",
                        },
                     })}
                     className="w-full border-b border-gray-300 outline-none mt-3 border rounded-md py-3 px-3"
                     placeholder="Email"
                  />
                  {errors?.email && (
                     <p className="text-red-600">{errors.email?.message}</p>
                  )}
               </div>
               <div>
                  <div className="relative">
                     <input
                        type={eyeOpen ? "text" : "password"}
                        {...register("password", {
                           required: "Password is required!",
                           minLength: {
                              value: 6,
                              message: "password should be must 6 characters",
                           },
                        })}
                        className="w-full border-b border-gray-300 outline-none border rounded-md py-3 px-3"
                        placeholder="Password"
                     />
                     <div
                        onClick={() => setEyeOpen(!eyeOpen)}
                        className="absolute right-4 top-4 cursor-pointer"
                     >
                        {eyeOpen ? (
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 16 16"
                           >
                              <path
                                 fill="#1D222B"
                                 d="M2.805 1.862l-.943.943L3.388 4.33C1.61 5.76.774 7.61.72 7.737L.608 8l.112.263c.089.207 2.232 5.07 7.28 5.07 1.377 0 2.531-.367 3.492-.898l1.703 1.703.943-.943L2.805 1.862zM8 2.667c-.808 0-1.533.134-2.195.344l1.097 1.097C7.25 4.042 7.612 4 8 4c3.597 0 5.445 3.059 5.927 3.997-.196.376-.626 1.104-1.298 1.838l.95.95c1.12-1.212 1.657-2.421 1.7-2.522L15.393 8l-.112-.263c-.089-.207-2.232-5.07-7.28-5.07zM4.34 5.284L5.708 6.65c-.236.395-.375.855-.375 1.35 0 1.47 1.196 2.666 2.667 2.666.494 0 .954-.139 1.349-.375l1.15 1.15c-.716.339-1.547.558-2.5.558-3.596 0-5.444-3.059-5.926-3.997.277-.53 1.02-1.761 2.268-2.72zm3.8.064l2.511 2.51c-.072-1.355-1.155-2.438-2.51-2.51zm-1.425 2.31l1.625 1.626c-.11.03-.223.05-.341.05-.736 0-1.333-.599-1.333-1.334 0-.119.02-.232.049-.341z"
                              ></path>
                           </svg>
                        ) : (
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="12"
                              viewBox="0 0 16 12"
                           >
                              <path
                                 fill="#1D222B"
                                 d="M8,0.667 C2.667,0.667 0.667,6 0.667,6 C0.667,6 2.667,11.333 8,11.333 C13.334,11.333 15.334,6 15.334,6 C15.334,6 13.334,0.667 8,0.667 Z M8,2 C11.518,2 13.297,4.845 13.873,5.997 C13.296,7.142 11.503,10 8,10 C4.483,10 2.704,7.155 2.128,6.003 C2.705,4.858 4.497,2 8,2 Z M8,3.333 C6.528,3.333 5.334,4.527 5.334,6 C5.334,7.473 6.528,8.667 8,8.667 C9.473,8.667 10.667,7.473 10.667,6 C10.667,4.527 9.473,3.333 8,3.333 Z M8,4.667 C8.737,4.667 9.334,5.263 9.334,6 C9.334,6.737 8.737,7.333 8,7.333 C7.264,7.333 6.667,6.737 6.667,6 C6.667,5.263 7.264,4.667 8,4.667 Z"
                              ></path>
                           </svg>
                        )}
                     </div>
                     {errors?.password && (
                        <p className="text-red-600">
                           {errors?.password?.message}
                        </p>
                     )}
                  </div>
               </div>
               <div className="text-right pt-5">
                  <button
                     type="submit"
                     className="py-3 px-4 bg-custom_blue hover:bg-custom_lightBlue duration-200 rounded-md text-white w-full"
                  >
                     Continue
                  </button>
               </div>
            </form>
            <div className="text-center text-[15px] pt-10">
               <span>New here? </span>
               <Link
                  to="/user/auth/register"
                  className="text-[#576CBC] text-right mt-1"
               >
                  Create an account
               </Link>
            </div>
         </div>
         <div className="bg-stone-100 w-full"></div>
      </div>
   )
}

export default Login
