import React from "react"
import { FaBox, FaHome, FaThumbsUp } from "react-icons/fa"
import { NavLink } from "react-router-dom"

const UserSidebar = ({ user }) => {
   return (
      <>
         <div className="sm:hidden dark:text-white m-3 sm:m-8 ">
            <ul className="flex flex-wrap items-center gap-2 capitalize">
               <li>
                  <div
                     className={`flex flex-col items-center py-1 sm:py-6 px-3 sm:px-6 cursor-pointer rounded-lg shadow-lg bg-white dark:bg-gray-800 hover:bg-yellow-100 hover:dark:bg-slate-700`}
                  >
                     <div className="relative">
                        <h3 className="mt-1 text-sm">Points</h3>
                        <span className="absolute bottom-3 left-10 text-white border rounded-full py-[5px] px-[7px] font-semibold bg-yellow-500 text-[12px]">
                           20
                        </span>
                     </div>
                  </div>
               </li>
               <li>
                  <NavLink
                     to={`/users/${user?._id}`}
                     className={`flex gap-1 items-center py-1 sm:py-6 px-3 sm:px-6 rounded-lg shadow-lg bg-white dark:bg-gray-800 hover:bg-yellow-100 hover:dark:bg-slate-700 `}
                  >
                     <FaHome className="text-sm text-secondary"></FaHome>
                     <h3 className="mt-1 text-sm">User</h3>
                  </NavLink>
               </li>
               <li>
                  <NavLink
                     to={`/users/reviews/${user?._id}`}
                     className={`flex gap-1 items-center py-1 sm:py-6 px-3 sm:px-6 rounded-lg shadow-lg bg-white dark:bg-gray-800 hover:bg-yellow-100 hover:dark:bg-slate-700`}
                  >
                     <FaThumbsUp className="text-sm text-secondary"></FaThumbsUp>
                     <h3 className="mt-1 text-sm">review</h3>
                  </NavLink>
               </li>
               <li>
                  <NavLink
                     to={`/user/order/${user?._id}`}
                     className={`flex gap-1 items-center py-1 sm:py-6 px-3 sm:px-6 rounded-lg shadow-lg bg-white dark:bg-gray-800 hover:bg-yellow-100 hover:dark:bg-slate-700`}
                  >
                     <FaBox className="text-sm text-secondary"></FaBox>
                     <h3 className="mt-1 text-sm">Order</h3>
                  </NavLink>
               </li>
               <li>
                  <NavLink
                     to={`/user/purchase/${user?._id}`}
                     className={`flex gap-1 items-center py-1 sm:py-6 px-3 sm:px-6 rounded-lg shadow-lg bg-white dark:bg-gray-800 hover:bg-yellow-100 hover:dark:bg-slate-700`}
                  >
                     <FaBox className="text-sm text-secondary"></FaBox>
                     <h3 className="mt-1 text-sm">purchase</h3>
                  </NavLink>
               </li>
               <li>
                  <NavLink
                     to="/users/vouchers/id"
                     className={`flex gap-1 items-center py-1 sm:py-6 px-3 sm:px-6 rounded-lg shadow-lg bg-white dark:bg-gray-800 hover:bg-yellow-100 hover:dark:bg-slate-700`}
                  >
                     <FaHome className="text-sm text-secondary"></FaHome>
                     <h3 className="mt-1 text-sm">Vouchers</h3>
                  </NavLink>
               </li>
            </ul>
         </div>
         <div className="hidden sm:block relative dark:text-white">
            <div className="absolute right-20 top-0 w-2 h-[100vh] bg-gray-300 z-10"></div>
            <div className="z-50 absolute right-0 top-8">
               <ul className="items-center px-5 space-y-5 capitalize">
                  <li>
                     <div
                        className={`flex flex-col items-center py-6 px-6 cursor-pointer rounded-lg shadow-lg bg-white dark:bg-gray-800 hover:bg-yellow-100 hover:dark:bg-slate-700`}
                     >
                        <div className="relative">
                           <h3 className="mt-1">Points</h3>
                           <span className="absolute bottom-3 left-10 text-white border rounded-full py-[5px] px-[7px] font-semibold bg-yellow-500 text-[12px]">
                              20
                           </span>
                        </div>
                     </div>
                  </li>
                  <li>
                     <NavLink
                        to={`/users/${user?._id}`}
                        className={`flex flex-col items-center py-4 px-6 rounded-lg shadow-lg bg-white dark:bg-gray-800 hover:bg-yellow-100 hover:dark:bg-slate-700 `}
                     >
                        <FaHome className="text-xl text-secondary"></FaHome>
                        <h3 className="mt-1">User</h3>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink
                        to={`/users/reviews/${user?._id}`}
                        className={`flex flex-col items-center py-4 px-6 rounded-lg shadow-lg bg-white dark:bg-gray-800 hover:bg-yellow-100 hover:dark:bg-slate-700`}
                     >
                        <FaThumbsUp className="text-xl text-secondary"></FaThumbsUp>
                        <h3 className="mt-1">review</h3>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink
                        to={`/user/order/${user?._id}`}
                        className={`flex flex-col items-center py-4 px-6 rounded-lg shadow-lg bg-white dark:bg-gray-800 hover:bg-yellow-100 hover:dark:bg-slate-700`}
                     >
                        <FaBox className=" text-xl text-secondary"></FaBox>
                        <h3 className="mt-1">Order</h3>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink
                        to={`/user/purchase/${user?._id}`}
                        className={`flex flex-col items-center py-4 px-6 rounded-lg shadow-lg bg-white dark:bg-gray-800 hover:bg-yellow-100 hover:dark:bg-slate-700`}
                     >
                        <FaBox className=" text-xl text-secondary"></FaBox>
                        <h3 className="mt-1">purchase</h3>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink
                        to="/users/vouchers/id"
                        className={`flex flex-col items-center py-4 px-6 rounded-lg shadow-lg bg-white dark:bg-gray-800 hover:bg-yellow-100 hover:dark:bg-slate-700`}
                     >
                        <FaHome className=" text-xl text-secondary"></FaHome>
                        <h3 className="mt-1">Vouchers</h3>
                     </NavLink>
                  </li>
               </ul>
            </div>
         </div>
      </>
   )
}

export default UserSidebar
