import React, { useState } from "react"
import { enqueueSnackbar } from "notistack"

import {
   Button,
   Dialog,
   DialogHeader,
   DialogBody,
   DialogFooter,
} from "@material-tailwind/react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import AgGridTable from "../../components/AgGridTable"

import axios from "axios"
import {
   useCreateSubWriterMutation,
   useDeleteSubWriterMutation,
   useGetSubWriterQuery,
   useUpdateSubWriterMutation,
} from "../../services/sub-writer"
import { Avatar } from "rsuite"
import { useGetEmployeeQuery } from "../../services/employee"
import { employeeInfoFunc } from "../../utils/utils"

const SubWriters = () => {
   const [name, setName] = useState("")
   const [description, setDescription] = useState("")
   const [authorImage, setauthorImage] = useState("")
   const [uploadauthorImage, setUploadauthorImage] = useState()
   const [seletedData, setSeletedData] = useState()

   const { data: subwriters } = useGetSubWriterQuery()
   const [createSubWriter, { isLoading: isCreating }] =
      useCreateSubWriterMutation()
   const [updateSubWriter, { isLoading: isUpdating }] =
      useUpdateSubWriterMutation()
   const [deleteSubWriter] = useDeleteSubWriterMutation()
   const employeeFromLocal = JSON.parse(localStorage.getItem("ich-user"))
   const { data } = useGetEmployeeQuery(employeeFromLocal?.id)
   const permission = employeeInfoFunc(data, "inventory")

   const [open, setOpen] = React.useState(false)

   const handleOpen = (data) => {
      setOpen(!open)
      setSeletedData(data)
   }

   const handleFormSubmit = async (e) => {
      e.preventDefault()

      if (!name) {
         let errorMessage = !name ? "Please select Sub Writer name!" : ""

         enqueueSnackbar(errorMessage, { variant: "error" })
      } else {
         try {
            await createSubWriter({
               name: name,
               image: uploadauthorImage,
               description: description,
            })
            enqueueSnackbar("Sub-Writer created", { variant: "default" })
            setName("")
            setDescription("")
         } catch (error) {
            enqueueSnackbar(`Error creating Sub Writer ${error}`, {
               variant: "error",
            })
            setName("")
            setDescription("")
         } finally {
         }
      }
   }
   const updateFormSubmit = async (e) => {
      e.preventDefault()
      try {
         await updateSubWriter({
            id: seletedData?._id,
            name: name === "" ? seletedData?.name : name,
            image:
               uploadauthorImage === ""
                  ? seletedData?.image
                  : uploadauthorImage,
            description:
               description === "" ? seletedData?.description : description,
         })
         enqueueSnackbar("Sub-Writer updated", { variant: "default" })
         setOpen(!open)
         setName("")
         setDescription("")
      } catch (error) {
         enqueueSnackbar("Error updating Sub-Writer", { variant: "error" })
         setOpen(!open)
         setName("")
         setDescription("")
      }
   }
   const onauthorImageChangePdf = (e) => {
      setauthorImage(e.target.files[0])
   }

   const onSubmitauthorImage = async (event) => {
      event.preventDefault()
      const formData = new FormData()
      formData.append("uploadImages", authorImage)

      try {
         if (!authorImage)
            return alert(
               "Please upload an image first then create this product!"
            )

         await axios
            .post("/api/image-upload", formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            })
            .then((res) => {
               setUploadauthorImage(res.data[0])
               alert("Sub-Writer Image uploaded")
            })
            .catch((err) => {
               alert(err?.response?.data?.message)
            })
      } catch (err) {
         if (err.response.status === 500) {
            console.log(err)
         } else {
            console.log(err.response.data.msg)
         }
      }
   }

   const columnDef = [
      {
         headerName: "Image",
         field: "image",
         cellRenderer: (x) => (
            <Avatar src={x?.data?.image} alt={x?.data?.name} />
         ),
      },
      {
         headerName: "Sub Writer Name",
         cellClass: "tableCell",
         field: "name",
      },
      {
         headerName: "Sub Writer Description",
         cellClass: "tableCell",
         field: "description",
      },
      {
         headerName: "Actions",
         field: "_id",
         cellRenderer: (x) => (
            <div className="text-sm z-50 px-4">
               {permission && permission?.permission !== "View Only" ? (
                  <ul className="flex">
                     <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50">
                        <button
                           onClick={() => handleOpen(x?.data)}
                           className="flex items-center gap-3"
                        >
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 cursor-pointer text-green-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                           >
                              <path
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                                 d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              ></path>
                           </svg>
                        </button>
                     </li>
                     <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50">
                        <button
                           onClick={() => {
                              let message = window.confirm(
                                 "Do you want to remove it?"
                              )
                              if (message === true) {
                                 deleteSubWriter(x?.data?._id)
                              }
                           }}
                           className="flex items-center gap-2 text-[#4F5366] text-sm"
                        >
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 cursor-pointer text-red-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                           >
                              <path
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                                 d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              ></path>
                           </svg>
                        </button>
                     </li>
                  </ul>
               ) : (
                  <p className="py-3">N/A</p>
               )}
            </div>
         ),
      },
   ]

   return (
      <div className="p-4 bg-slate-800 md:bg-white  dark:bg-dark text-black ">
         <form onSubmit={handleFormSubmit}>
            <h2 className="text-xl font-semibold mb-4">
               Add Sub-Writer Information
            </h2>
            <div className="w-72 my-4">
               <input
                  type="text"
                  className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Sub Writer name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
               />
            </div>{" "}
            <div className="w-72 my-4">
               <input
                  type="text"
                  className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Sub Writer Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
               />
            </div>
            <div className="w-72 my-4">
               <div className="w-full block appearance-none focus:bg-white focus:border-gray-500 bg-white border border-gray-300 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none">
                  <label htmlFor="authorImage" className=" ">
                     Sub Writer Image
                  </label>
                  <input
                     type="file"
                     id="file"
                     name="writersImageUpload"
                     onChange={onauthorImageChangePdf}
                  />
               </div>
               <button onClick={onSubmitauthorImage} className="CssBtn1">
                  <span className="text">
                     Select only jpg, png, and avif file!
                  </span>
                  <span className="mt-1.5">Add Sub Writer Image</span>
               </button>
            </div>
            {permission && permission?.permission !== "View Only" && (
               <button
                  type="submit"
                  className={`px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 ${
                     isCreating ? "opacity-50 cursor-not-allowed" : "" // Disable the button when isCreating is true
                  }`}
               >
                  {isCreating ? "Loading..." : "Add"}
               </button>
            )}
         </form>
         <div className="w-full my-10">
            <h2 className="text-xl font-semibold mb-4">
               All Sub-Writer Information
            </h2>{" "}
            {subwriters?.length == 0 ? (
               <p>No Sub-Writer data found</p>
            ) : (
               <AgGridTable
                  data={subwriters}
                  columnDef={columnDef}
                  height="62vh"
               />
            )}
            <Dialog open={open} handler={handleOpen}>
               <DialogHeader>Update Sub-Writer</DialogHeader>
               <DialogBody divider>
                  <form onSubmit={updateFormSubmit}>
                     <div className="w-72 my-4">
                        <input
                           type="text"
                           className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                           placeholder={seletedData?.name || "Sub Writer name"}
                           value={name}
                           onChange={(e) => setName(e.target.value)}
                        />
                     </div>{" "}
                     <div className="w-72 my-4">
                        <input
                           type="text"
                           className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                           placeholder={
                              seletedData?.description ||
                              "Sub Writer Description"
                           }
                           value={description}
                           onChange={(e) => setDescription(e.target.value)}
                        />
                     </div>
                     <div className="w-72 my-4">
                        <div className="w-full block appearance-none focus:bg-white focus:border-gray-500 bg-white border border-gray-300 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none">
                           <label htmlFor="authorImage" className=" ">
                              Sub Writer Image
                           </label>
                           <input
                              type="file"
                              id="file"
                              name="writersImageUpload"
                              onChange={onauthorImageChangePdf}
                           />
                        </div>
                        <button
                           onClick={onSubmitauthorImage}
                           className="CssBtn1"
                        >
                           <span className="text">
                              Select only jpg, png, and avif file!
                           </span>
                           <span className="mt-1.5">Add Sub Writer Image</span>
                        </button>
                     </div>
                     <button
                        type="submit"
                        className={`px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 ${
                           isUpdating ? "opacity-50 cursor-not-allowed" : "" // Disable the button when isCreating is true
                        }`}
                     >
                        {isUpdating ? "Loading..." : "Update"}
                     </button>
                  </form>
               </DialogBody>
               <DialogFooter>
                  <Button
                     variant="text"
                     color="red"
                     onClick={() => {
                        setOpen(false)
                        setName("")
                        setDescription("")
                     }}
                     className="mr-1"
                  >
                     <span>Cancel</span>
                  </Button>
               </DialogFooter>
            </Dialog>
         </div>
      </div>
   )
}

export default SubWriters
