import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import { Link, useNavigate } from "react-router-dom"
import { useCreateEmployeeMutation } from "../../services/employee"
import logo from "../../assets/images/logo-ich.png"

const Register = () => {
   const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
   } = useForm()
   const [createEmployee, { isError, isSuccess, isLoading, error, data }] =
      useCreateEmployeeMutation()
   const navigate = useNavigate()

   const small_id = new Date().valueOf().toString().slice(8, 13)

   useEffect(() => {
      if (isLoading) {
         console.log("loading")
      }
      if (isError) {
         console.log("error", error)
         toast.error(`${error?.data?.message}`, { duration: 1000 })
      }
      if (isSuccess) {
         toast.success("Registration successfull", { duration: 1000 })
         navigate("/user/auth/login")
      }
   }, [isError, isLoading, isSuccess, navigate, reset])

   const createUserHandle = async (data) => {
      const { name, email, address, contactNumber, password } = data

      const user = {
         name,
         email,
         address,
         contactNumber,
         password,
         barcode: "IEID" + small_id,
      }
      createEmployee(user)
   }

   return (
      <div className="w-full min-h-screen bg-[rgb(246 248 249)] p-6">
         <div>
            <img src={logo} alt="" className="w-[200px]" />
         </div>
         <div className="w-full flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-2xl mx-3 sm:mx-0 px-6 sm:px-8 py-10 text-[#00000094] w-full sm:w-8/12 lg:w-6/12">
               <h3 className="text-xl font-normal">Personal Information</h3>
               <form
                  onSubmit={handleSubmit(createUserHandle)}
                  action=""
                  className="space-y-6"
               >
                  <div className="pb-6 space-y-2 rounded-bl-md rounded-br-md">
                     <div className="grid grid-cols-2 gap-3">
                        <div className="relative z-0 mt-6 w-full group">
                           <input
                              name="name"
                              id="name"
                              type="text"
                              {...register("name", {
                                 required: "Name is required!",
                              })}
                              className="block py-2.5 w-full text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none  peer rounded-md px-3"
                              placeholder=" "
                           />
                           <label
                              htmlFor="name"
                              className="peer-focus:font-medium absolute text-sm RT duration-300 transform -translate-y-6 scale-75 top-1 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-custom-light-purple peer-focus:dark:text-custom-light-purple peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 px-2"
                           >
                              Name
                           </label>
                           {errors?.name && (
                              <p className="text-red-600">
                                 {errors.name?.message}
                              </p>
                           )}
                        </div>
                        <div className="relative z-0 mt-6 w-full group">
                           <input
                              name="email"
                              id="email"
                              type="email"
                              {...register("email", {
                                 required: "Email is required",
                                 pattern: {
                                    value: /\S+@\ichchapuron.com.bd/,
                                    message: "Email is not valid!",
                                 },
                              })}
                              className="block py-2.5 w-full text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none  peer rounded-md px-3"
                              placeholder=" "
                           />
                           <label
                              htmlFor="email"
                              className="peer-focus:font-medium absolute text-sm RT duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-custom-light-purple peer-focus:dark:text-custom-light-purple peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 px-2"
                           >
                              Email
                           </label>
                           {errors?.email && (
                              <p className="text-red-600">
                                 {errors.email?.message}
                              </p>
                           )}
                        </div>
                     </div>
                     <div className="grid grid-cols-2 gap-3">
                        <div className="relative z-0 mt-6 w-full group">
                           <input
                              name="address"
                              id="address"
                              type="text"
                              {...register("address", {
                                 required: "Address is required!",
                              })}
                              className="block py-2.5 w-full text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none  peer rounded-md px-3"
                              placeholder=" "
                           />
                           <label
                              htmlFor="address"
                              className="peer-focus:font-medium absolute text-sm RT duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-custom-light-purple peer-focus:dark:text-custom-light-purple peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 px-2"
                           >
                              Address
                           </label>
                           {errors?.address && (
                              <p className="text-red-600">
                                 {errors?.address?.message}
                              </p>
                           )}
                        </div>
                        <div className="relative z-0 mt-6 w-full group">
                           <input
                              name="contactNumber"
                              id="contactNumber"
                              type="text"
                              {...register("contactNumber", {
                                 required: "Contact Number is required!",
                              })}
                              className="block py-2.5 w-full text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none  peer rounded-md px-3"
                              placeholder=" "
                           />
                           <label
                              htmlFor="contactNumber"
                              className="peer-focus:font-medium absolute text-sm RT duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-custom-light-purple peer-focus:dark:text-custom-light-purple peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 px-2"
                           >
                              Contact Number
                           </label>
                           {errors?.contactNumber && (
                              <p className="text-red-600">
                                 {errors?.contactNumber?.message}
                              </p>
                           )}
                        </div>
                     </div>
                     <div className="grid grid-cols-2 gap-3">
                        <div className="relative z-0 mt-6 w-full group">
                           <input
                              name="password"
                              id="password"
                              type="password"
                              {...register("password", {
                                 required: "Password is required!",
                                 minLength: {
                                    value: 6,
                                    message:
                                       "password should be must 6 characters",
                                 },
                              })}
                              className="block py-2.5 w-full text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none  peer rounded-md px-3"
                              placeholder=" "
                           />
                           <label
                              htmlFor="password"
                              className="peer-focus:font-medium absolute text-sm RT duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-custom-light-purple peer-focus:dark:text-custom-light-purple peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 px-2"
                           >
                              Password
                           </label>
                           {errors?.password && (
                              <p className="text-red-600">
                                 {errors?.password?.message}
                              </p>
                           )}
                        </div>
                     </div>
                     {/* <div className="space-y-1 text-sm">
                <label htmlFor="name" className="block font-medium text-[15px]">Confirm Password</label>
                <input type="confirmPassword" { ...register( "confirmPassword", {
                      required: "confirmPassword is required!",
                      minLength: { value: 6, message: 'password should be must 6 characters' }
                    } ) }
                      className="outline-none flex-grow w-full px-4 mb-2 transition duration-200 bg-white border border-gray-200 rounded-md shadow-sm py-3" />
                  { errors?.confirmPassword && <p className='text-red-600'>{ errors.confirmPassword?.message }</p> }
              </div> */}
                  </div>
                  <div className="flex gap-2">
                     <button
                        type="submit"
                        className="capitalize bg-blue-500 hover:bg-blue-600 duration-200 py-2 rounded-md px-5 text-white"
                     >
                        create account
                     </button>
                     <Link
                        to="/user/auth/login"
                        className="capitalize bg-[#ffffffab] border border-gray-400 !no-underline py-2 rounded-md px-5"
                     >
                        Existing User Login
                     </Link>
                  </div>
               </form>
            </div>
         </div>
      </div>
   )
}

export default Register
