import React from "react"
import { Link } from "react-router-dom"
import { Modal } from "rsuite"

const LeavePage = ({ open, handleClose }) => {
   return (
      <Modal open={open} onClose={handleClose}>
         <Modal.Body>
            <div className="relative p-0 dark:bg-dark dark:text-white">
               <div
                  onClick={handleClose}
                  className="absolute right-2 top-2 text-[20px] cursor-pointer"
               >
                  ✕
               </div>
               <h3 className="text-lg font-semibold px-5 py-8">
                  Leave page with unsaved changes?
               </h3>
               <p className="py-4 border-t border-b border-gray-300 text-[15px] p-5">
                  Leaving this page will delete all unsaved changes.
               </p>
               <div className="text-sm py-6 text-right px-5">
                  <button
                     onClick={handleClose}
                     className="px-5 py-2 rounded-md border border-gray-400 mr-2 cursor-pointer"
                  >
                     Stay
                  </button>
                  <Link
                     to="/"
                     className="bg-[#d82c0d] px-5 py-2 rounded-md text-white"
                  >
                     Leave page
                  </Link>
               </div>
            </div>
         </Modal.Body>
      </Modal>
   )
}

export default LeavePage
