import React from "react"
import ReactApexChart from "react-apexcharts"

const DashboardPages = () => {
   const series = [
      {
         name: "Sales",
         data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
      },
   ]

   const options = {
      chart: {
         height: 350,
         type: "line",
         zoom: {
            enabled: false,
         },
      },
      xaxis: {
         categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
         ],
      },
   }

   return (
      <div className="flex justify-center items-center">
         <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto">
            <div class="flex justify-between mb-4">
               <ReactApexChart
                  options={options}
                  series={series}
                  type="line"
                  height={350}
               />
            </div>
            <p class="text-gray-600 text-sm">Last updated: 9/18/2023</p>
         </div>
      </div>
   )
}

export default DashboardPages
