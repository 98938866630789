import axios from "axios"
import React, { useState } from "react"
import { useSelector } from "react-redux"

const UpdateUser = ({ data }) => {
   const [files, setFiles] = useState([])

   const onChange = (e) => {
      setFiles(e.target.files)
   }
   const [uploadData, setUploadData] = useState()
   const { userInfo } = useSelector((state) => state.login)
   const [updatePassword, setUpdatePassword] = useState()

   const onSubmit = async (event) => {
      event.preventDefault()
      const formData = new FormData()
      Object.values(files).forEach((file) => {
         formData.append("uploadImages", file)
      })

      try {
         if (files.length === 0)
            return alert("Please upload an image first the create a product!")
         await axios
            .post("/api/image-upload", formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            })
            .then((res) => {
               setUploadData(res.data)
               alert("Image uploaded")
            })
            .catch((err) => {
               alert(err?.response?.data?.message)
            })
      } catch (err) {
         if (err.response.status === 500) {
            console.log(err)
         } else {
            console.log(err.response.data.msg)
         }
      }
   }

   const [user, setUser] = useState({
      name: data?.name,
      email: data?.email,
      password: data?.password,
   })

   const handleChange = (e) => {
      const { name, value } = e.target
      setUser({
         ...user,
         [name]: value,
      })
   }

   const handleUserUpdate = async (e) => {
      e.preventDefault()

      try {
         const userObj = {
            email: user.email,
            name: user.name,
            image: data?.image,
         }
         if (uploadData?.length > 0) {
            userObj.image = uploadData[0]
         }
         if (updatePassword) userObj.password = updatePassword

         await axios
            .patch(
               `/api/user/${data?._id}`,
               Object.fromEntries(
                  Object.entries(userObj).filter(([_, v]) => v)
               ),
               {
                  headers: {
                     "Content-Type": "application/json",
                     Authorization: `${userInfo.refreshToken}`,
                  },
               }
            )
            .then((response) => {
               alert(response?.data?.message)
               window.location.reload()
            })
      } catch (err) {}
   }

   return (
      <div>
         <input type="checkbox" id="updateUserInfo" className="modal-toggle" />
         <div className="modal">
            <div className="modal-box relative pb-0 dark:bg-dark">
               <label
                  htmlFor="updateUserInfo"
                  className="absolute right-2 top-2 text-[20px] mr-2 cursor-pointer"
               >
                  ✕
               </label>
               <h3 className="text-lg font-semibold border-b pb-4 mb-5">
                  Edit User information
               </h3>
               <div className="text-[15px]">
                  <form onSubmit={handleUserUpdate} className="space-y-4">
                     <div className="flex flex-col">
                        <label htmlFor="image">User Image</label>
                        <input
                           type="file"
                           id="file"
                           name="uploadImages"
                           multiple
                           onChange={onChange}
                        />

                        <button onClick={onSubmit} className="CssBtn1">
                           <span className="text">
                              Select only jpg, png, and avif file!
                           </span>
                           <span className="mt-1.5">Add User Image</span>
                        </button>
                     </div>
                     <div className="flex flex-col w-full">
                        <label htmlFor="name">Name</label>
                        <input
                           type="text"
                           className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                           name="name"
                           value={user?.name}
                           onChange={handleChange}
                           placeholder={data?.name}
                        />
                     </div>
                     <div className="flex flex-col w-full">
                        <label htmlFor="email">Email</label>
                        <input
                           type="email"
                           className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                           name="email"
                           value={user?.email}
                           onChange={handleChange}
                           placeholder={data?.email}
                        />
                     </div>
                     {/* <div className="flex flex-col w-full">
                        <label htmlFor="email">Password</label>
                        <input
                           type="text"
                           className="dark:text-dark w-full px-3 mt-1 py-2 border border-gray-300 rounded-lg outline-none"
                           name="password"
                           value={updatePassword}
                           onChange={(e) => setUpdatePassword(e.target.value)}
                           placeholder="******"
                        />
                     </div> */}
                     <div className="flex items-center w-full ">
                        <input
                           type="checkbox"
                           id="update"
                           className="mr-2 cursor-pointer"
                        />
                        <label
                           htmlFor="update"
                           id="update"
                           className="cursor-pointer"
                        >
                           Update user profile
                        </label>
                     </div>
                     <div className="text-right py-5 border-t">
                        <label
                           htmlFor="updateUserInfo"
                           className="py-2 px-4 border border-gray-300 rounded-lg cursor-pointer mr-2"
                        >
                           Cancel
                        </label>
                        <button
                           type="submit"
                           className="py-2 px-4 bg-[#008060] rounded-lg text-white"
                        >
                           Update
                        </button>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   )
}

export default UpdateUser
