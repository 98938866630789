import React from "react"
import { FaPencilAlt } from "react-icons/fa"
import UpdateUser from "./Action/UpdateUser"
import UserSidebar from "./SignleUser/UserSidebar"
import { useGetUserQuery } from "../../services/user"
import { useParams } from "react-router-dom"
import { useGetEmployeeQuery } from "../../services/employee"
import { employeeInfoFunc } from "../../utils/utils"

const User = () => {
   const { id } = useParams()
   const { data, isLoading } = useGetUserQuery(id)
   const employeeFromLocal = JSON.parse(localStorage.getItem("ich-user"))
   const { data: user } = useGetEmployeeQuery(employeeFromLocal?.id)
   const permission = employeeInfoFunc(user, "users")

   return (
      <div className="grid grid-cols-1 sm:grid-cols-3">
         <div className="order-2 sm:order-1 col-span-2 bg-white dark:bg-dark dark:text-white min-h-[90vh] m-3 sm:m-8 p-4 sm:p-10 rounded-lg shadow">
            <div className="flex justify-between gap-20">
               <img
                  src={data?.image}
                  alt="user"
                  className="w-20 h-20 rounded-full border-2 border-[#576CBC]"
               />
               {permission?.permission !== "View Only" && (
                  <label
                     htmlFor="updateUserInfo"
                     className="flex top-0 gap-3 text-sm cursor-pointer"
                  >
                     <FaPencilAlt></FaPencilAlt>
                     Change
                  </label>
               )}
            </div>
            <div className="mt-5">
               <h3 className="text-black dark:text-darkText font-semibold mt-3">
                  Name
               </h3>
               <span className="text-sm">{data?.name}</span>
               {/* <h3 className='text-lg font-semibold'>Name : Wahid Tarafder</h3> */}
               <h3 className="text-black dark:text-darkText font-semibold mt-3">
                  Email
               </h3>
               <span className="text-sm">{data?.email}</span>
               <h3 className="text-black dark:text-darkText font-semibold mt-3">
                  Mobile Number
               </h3>
               <span className="text-sm">{data?.phone}</span>

               <h3 className="text-black dark:text-darkText font-semibold mt-3">
                  Password
               </h3>
               <span className="text-sm">*****</span>
            </div>
            <UpdateUser data={data}></UpdateUser>
         </div>
         <div className="order-1 sm:order-2">
            <UserSidebar user={data}></UserSidebar>
         </div>
      </div>
   )
}

export default User
