import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const searchItemsApi = createApi({
   reducerPath: "searchItems",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["searchItems"],
   endpoints: (builder) => ({
      getsearchItems: builder.query({
         query: (term) => `api/search/${term}`,
         providesTags: ["searchItems"],
      }),
      getsearchItem: builder.query({
         query: (term) => `api/OrderSearch/${term}`,
         providesTags: ["searchItems"],
      }),
      getProductSearch:builder.query({
         query:()=>  `api/allProductSearch`,
         providesTags :  ["searchItems"]
      })
   }),
})

export const {
   useGetsearchItemsQuery,
   useGetsearchItemQuery
} = searchItemsApi
