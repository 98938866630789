import React from "react"
import { useState } from "react"
import { useContext } from "react"
import { FaVoicemail } from "react-icons/fa"
import { AiOutlineDeliveredProcedure } from "react-icons/ai"
import { ImUserTie } from "react-icons/im"
import {
   MdOutlineCategory,
   MdReviews,
   MdDrafts,
   MdOutlineInventory,
} from "react-icons/md"
import { TfiEmail } from "react-icons/tfi"
import { GiDeliveryDrone } from "react-icons/gi"
import { BsCart2 } from "react-icons/bs"
import { CiSearch } from "react-icons/ci"
import { RiDashboardLine } from "react-icons/ri"
import { NavLink, useNavigate } from "react-router-dom"
import { DashboardContext } from "../../context/DashboardProvider"
import { toast } from "react-hot-toast"

const Menu = () => {
   const navigate = useNavigate()
   const userEmail = "wahidTarafdarrrr@yehoo.com"
   const { setShowDrawer, setSidebarContent, responsiveSidebar } =
      useContext(DashboardContext)
   const employeeFromLocal = JSON.parse(localStorage.getItem("ich-user"))
   const [orderMenuOpen, setOrderMenuOpen] = useState(false)
   const [bannerOpen, setBannerOpen] = useState(false)

   const itemClickHandle = (name, submenu) => {
      setSidebarContent(name)
      !submenu ? setShowDrawer(false) : setShowDrawer(true)
   }

   const logoutHandle = () => {
      localStorage.removeItem("ich-user")
      localStorage.removeItem("ich-account")
      toast.success("Logout successfull")
      window.location.href = "/user/auth/login"
   }

   return (
      <div className="px-8 text-[#ffffffa6]">
         <ul className={`hidden md:block`}>
            <li>
               <NavLink
                  to="/dashboard"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("dashboard")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200"
                  >
                     <RiDashboardLine className="text-xl w-12" />
                     <span className="capitalize text-sm font-medium">
                        dashboard
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/inventory"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("inventory", true)}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200"
                  >
                     <MdOutlineInventory className="text-xl w-12" />
                     <span className="capitalize text-sm font-medium ">
                        inventory
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/category"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("category")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200"
                  >
                     <MdOutlineCategory className="text-xl w-12" />
                     <span className="capitalize text-sm font-medium ">
                        category
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/users"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("users")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200"
                  >
                     <span className="">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           width="48"
                           height="24"
                           fill="none"
                           viewBox="0 0 24 24"
                           strokeWidth="1.5"
                           stroke="currentColor"
                        >
                           <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                           />
                        </svg>
                     </span>
                     <span className="capitalize text-sm font-medium">
                        users
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/search"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("search", true)}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200"
                  >
                     <CiSearch className="text-xl w-12" />
                     <span className="capitalize text-sm font-medium">
                        search
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/reports"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("reports")}
                     className="py-0 rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200"
                  >
                     <span className="">
                        <svg
                           width="48"
                           height="24"
                           className="my-3"
                           viewBox="0 0 24 24"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              d="M8 13V17"
                              stroke="currentColor"
                              strokeOpacity=".8"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                           ></path>
                           <path
                              d="M12 7V17"
                              stroke="currentColor"
                              strokeOpacity=".8"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                           ></path>
                           <path
                              d="M16 10V17"
                              stroke="currentColor"
                              strokeOpacity=".8"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                           ></path>
                           <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5 3H19C20.105 3 21 3.895 21 5V19C21 20.105 20.105 21 19 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                           ></path>
                        </svg>
                     </span>
                     <span className="capitalize text-sm font-medium">
                        reports
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <div
                  onClick={() => setOrderMenuOpen(!orderMenuOpen)}
                  className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200 cursor-pointer"
               >
                  <BsCart2 className="text-xl w-12" />
                  <span className="capitalize text-sm font-medium">order</span>
               </div>
               <div className={`ml-8 ${orderMenuOpen ? "block" : "hidden"}`}>
                  <NavLink
                     to="/orders"
                     className={({ isActive, isPending }) =>
                        isActive
                           ? "text-custom_lightBlue rounded-md hover:bg-[#bbbaba1e] flex items-center gap-2 duration-200 text-sm px-2 py-3 capitalize"
                           : "rounded-md hover:bg-[#bbbaba1e] flex items-center gap-2 duration-200 text-sm px-2 py-3 capitalize"
                     }
                  >
                     <BsCart2 className="text-lg" />
                     <span className="capitalize text-sm font-medium">
                        orders
                     </span>
                  </NavLink>
                  <NavLink
                     to="drafts"
                     className={({ isActive, isPending }) =>
                        isActive
                           ? "text-custom_lightBlue rounded-md hover:bg-[#bbbaba1e] flex items-center gap-2 duration-200 text-sm px-2 py-3 capitalize"
                           : "rounded-md hover:bg-[#bbbaba1e] flex items-center gap-2 duration-200 text-sm px-2 py-3 capitalize"
                     }
                  >
                     <MdDrafts className="text-lg" />
                     <span>Drafts</span>
                  </NavLink>
                  <NavLink
                     to="shipping"
                     className={({ isActive, isPending }) =>
                        isActive
                           ? "text-custom_lightBlue rounded-md hover:bg-[#bbbaba1e] flex items-center gap-2 duration-200 text-sm px-2 py-3 capitalize"
                           : "rounded-md hover:bg-[#bbbaba1e] flex items-center gap-2 duration-200 text-sm px-2 py-3 capitalize"
                     }
                  >
                     <BsCart2 className="text-lg" />
                     <span>shipping</span>
                  </NavLink>
               </div>
            </li>
            <li>
               <NavLink
                  to="/dispatch"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("dispatch")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200"
                  >
                     {" "}
                     <FaVoicemail className="text-xl w-12" />
                     <span className="capitalize text-sm font-medium">
                        dispatch
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/delivery"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("delivery")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <FaVoicemail className="text-xl w-12" />
                     <span className="capitalize text-sm font-medium">
                        delivery
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/delivered"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("delivered")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <AiOutlineDeliveredProcedure className="text-xl w-12" />
                     <span className="capitalize text-sm font-medium">
                        delivered
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/fullfilled"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("fullfilled")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <GiDeliveryDrone className="text-xl w-12" />
                     <span className="capitalize text-sm font-medium">
                        FullFilled
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/canceled"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("canceled")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <GiDeliveryDrone className="text-xl w-12" />
                     <span className="capitalize text-sm font-medium">
                        Canceled
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/refund-&-return"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("refund-&-return")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <AiOutlineDeliveredProcedure className="text-xl w-12" />
                     <span className="capitalize text-sm font-medium">
                        refunded <br></br> & returned
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/employee"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("employee")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <span>
                        {" "}
                        <ImUserTie className="text-xl w-12" />
                     </span>
                     <span className="capitalize text-sm font-medium">
                        employee
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/customers"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("customers")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        height="24"
                        fill="transparent"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                     >
                        <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           strokeWidth="2"
                           d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                        ></path>
                     </svg>
                     <span className="capitalize text-sm font-medium">
                        customers
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <div onClick={() => setBannerOpen(!bannerOpen)}>
                  <button
                     onClick={() => itemClickHandle("banner")}
                     className={`py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200  w-full`}
                  >
                     <BsCart2 className="text-xl w-12" />
                     <span className="capitalize text-sm font-medium ">
                        Banner
                     </span>
                  </button>
               </div>
               <div className={`ml-14 ${bannerOpen ? "block" : "hidden"}`}>
                  <NavLink
                     to="/top-banner"
                     className={({ isActive, isPending }) =>
                        isActive && "text-custom_lightBlue"
                     }
                  >
                     <span className="capitalize text-sm font-medium  py-3 rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200">
                        Top Banner
                     </span>
                  </NavLink>
               </div>
            </li>
            <li>
               <NavLink
                  to="/reviews"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("verify reviews")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <span className="">
                        <MdReviews className="text-xl w-12" />
                     </span>
                     <span className="capitalize text-sm font-medium ">
                        verify reviews
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/email-marketing"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("email marketing")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <TfiEmail className="text-xl w-12" />
                     <span className="capitalize text-sm font-medium">
                        email marketing
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/tips"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("tips")}
                     className="py-0 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <span className="">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           width="48"
                           height="48"
                           fill="none"
                           viewBox="0 0 48 48"
                        >
                           <path
                              stroke="#ffffffa6"
                              strokeLinejoin="round"
                              strokeMiterlimit="10"
                              strokeOpacity=".8"
                              strokeWidth="1.5"
                              d="M24 15.52c-4.682 0-8.479 3.797-8.479 8.48 0 1.497.392 2.9 1.073 4.122l-1.055 3.776c-.096.343.22.66.565.564l3.778-1.054c1.22.68 2.623 1.07 4.119 1.07 4.682 0 8.479-3.796 8.479-8.478 0-4.683-3.797-8.48-8.48-8.48z"
                           ></path>
                           <path
                              stroke="#ffffffa6"
                              strokeLinecap="round"
                              strokeMiterlimit="10"
                              strokeOpacity=".8"
                              strokeWidth="1.5"
                              d="M21.938 21.709c0-1.14.923-2.063 2.062-2.063 1.139 0 2.063.924 2.063 2.063 0 1.94-2.063 1.9-2.063 3.895"
                           ></path>
                           <path
                              stroke="#ffffffa6"
                              fillOpacity=".8"
                              d="M24 29.042c.507 0 .917-.41.917-.917 0-.506-.41-.916-.916-.916-.507 0-.917.41-.917.916s.41.917.917.917z"
                           ></path>
                        </svg>
                     </span>
                     <span className="capitalize text-sm font-medium">
                        tips
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/notifications"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("notifications")}
                     className="py-0 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <span className="">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           width="48"
                           height="48"
                           fill="none"
                           viewBox="0 0 48 48"
                        >
                           <path
                              stroke="#ffffffa6"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeMiterlimit="10"
                              strokeOpacity=".8"
                              strokeWidth="1.5"
                              d="M23.683 15.008c-3.52.17-6.229 3.298-6.229 6.933v3.81l-1.36 2.823c-.303.664.166 1.426.877 1.426h14.058c.71 0 1.18-.762.878-1.426l-1.361-2.824v-4c0-3.836-3.104-6.925-6.863-6.742zM26.182 30.75c0 1.242-.977 2.25-2.182 2.25s-2.182-1.008-2.182-2.25"
                           ></path>
                        </svg>
                     </span>
                     <span className="capitalize text-sm font-medium">
                        notifications
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <div className="dropdown dropdown-top dropdown-center w-full !z-50">
                  <label tabIndex={0}>
                     <div className="py-3 w-full rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200 cursor-pointer">
                        <span className="">
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="48"
                              height="20"
                              ml-4
                              viewBox="0 0 18 20"
                           >
                              <path
                                 fill="none"
                                 stroke="#ffffffa6"
                                 strokeLinejoin="round"
                                 strokeOpacity=".8"
                                 strokeWidth="1.5"
                                 d="M11.075,15.92 L10.929,17.25 C10.309,17.395 9.665,17.48 9.001,17.48 C8.335,17.48 7.691,17.395 7.071,17.25 L6.926,15.92 C6.854,15.267 6.476,14.688 5.906,14.358 C5.337,14.03 4.645,13.992 4.045,14.257 L2.822,14.795 C1.941,13.856 1.268,12.722 0.886,11.458 L1.97,10.663 C2.5,10.275 2.813,9.657 2.813,9 C2.813,8.343 2.5,7.726 1.97,7.336 L0.886,6.542 C1.268,5.278 1.942,4.144 2.822,3.205 L4.045,3.743 C4.646,4.008 5.337,3.97 5.906,3.641 C6.475,3.313 6.854,2.733 6.925,2.08 L7.071,0.75 C7.692,0.605 8.335,0.521 9,0.521 C9.665,0.521 10.309,0.605 10.929,0.75 L11.075,2.08 C11.146,2.733 11.525,3.313 12.094,3.642 C12.663,3.97 13.354,4.008 13.956,3.744 L15.178,3.205 C16.059,4.144 16.732,5.278 17.114,6.542 L16.031,7.337 C15.501,7.726 15.188,8.343 15.188,9 C15.188,9.657 15.501,10.274 16.031,10.664 L17.114,11.458 C16.732,12.722 16.058,13.856 15.178,14.795 L13.956,14.257 C13.354,13.992 12.663,14.03 12.094,14.358 C11.525,14.687 11.147,15.267 11.075,15.92 Z M9,11.98 C10.646,11.98 11.98,10.645 11.98,9 C11.98,7.355 10.646,6.021 9,6.021 C7.355,6.021 6.021,7.355 6.021,9.001 C6.021,10.645 7.355,11.979 9.001,11.979 L9,11.98 Z"
                                 transform="translate(0 1)"
                              ></path>
                           </svg>
                        </span>
                        <span className="capitalize text-sm font-medium dark:text-white">
                           settings
                        </span>
                     </div>
                  </label>
                  <ul
                     tabIndex={0}
                     className="dropdown-content p-4 shadow bg-base-100 dark:bg-dark  dark:text-white text-black rounded-box w-48"
                  >
                     {/* <li className="flex items-center justify-center gap-4 pb-3 border-b">
                        <div>
                           <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkiIFjCOZ-mMeqxd2ryrneiHedE8G9S0AboA&usqp=CAU"
                              alt="profile"
                              className="w-10 rounded-full"
                           />
                        </div>
                        <h3 className="font-normal">
                           {employeeFromLocal?.email}
                        </h3>
                     </li> */}
                     <li className="py-2 hover:bg-[#bbbaba1e] rounded-xl">
                        <NavLink className="bg-transparent flex items-center gap-2 text-base">
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="20"
                              viewBox="0 0 18 20"
                           >
                              <g
                                 fill="none"
                                 fillRule="evenodd"
                                 stroke="#9a9aab"
                                 strokeWidth="1.5"
                                 transform="translate(0 1)"
                              >
                                 <path d="M9,11.98 C10.646,11.98 11.98,10.645 11.98,9 C11.98,7.355 10.644,6.02 9,6.02 C7.356,6.02 6.021,7.356 6.021,9 C6.021,10.644 7.355,11.98 9.001,11.98 L9,11.98 Z"></path>
                                 <path
                                    strokeLinejoin="round"
                                    d="M11.075,15.92 C11.147,15.267 11.525,14.687 12.094,14.358 C12.663,14.03 13.354,13.992 13.956,14.257 L15.178,14.795 C16.058,13.856 16.732,12.722 17.114,11.458 L16.03,10.663 C15.5,10.275 15.188,9.657 15.188,9 C15.188,8.343 15.501,7.725 16.031,7.337 L17.114,6.542 C16.732,5.278 16.059,4.144 15.178,3.205 L13.956,3.743 C13.354,4.008 12.663,3.97 12.094,3.642 C11.525,3.313 11.147,2.733 11.075,2.08 L10.929,0.75 C10.309,0.605 9.665,0.52 9,0.52 C8.335,0.52 7.691,0.605 7.071,0.75 L6.925,2.08 C6.854,2.733 6.475,3.313 5.906,3.641 C5.338,3.97 4.646,4.007 4.045,3.743 L2.822,3.205 C1.942,4.144 1.268,5.278 0.886,6.542 L1.97,7.336 C2.5,7.726 2.813,8.343 2.813,9 C2.813,9.657 2.5,10.275 1.97,10.663 L0.886,11.458 C1.268,12.722 1.941,13.856 2.822,14.795 L4.045,14.257 C4.645,13.992 5.338,14.03 5.906,14.358 C6.476,14.687 6.854,15.267 6.926,15.92 L7.071,17.25 C7.691,17.395 8.335,17.48 9.001,17.48 C9.665,17.48 10.309,17.395 10.929,17.25 L11.075,15.92 Z"
                                 ></path>
                              </g>
                           </svg>
                           Settings
                        </NavLink>
                     </li>
                     <li className="py-2 hover:bg-[#bbbaba1e] rounded-xl">
                        <NavLink className="bg-transparent flex items-center gap-2 text-base">
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="17"
                              viewBox="0 0 18 17"
                           >
                              <path
                                 fill="none"
                                 stroke="#9a9aab"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                                 strokeWidth="1.5"
                                 d="M11.86,3.513 C12.83,3.513 13.617,2.727 13.617,1.757 C13.617,0.787 12.83,0 11.86,0 C10.89,0 10.104,0.787 10.104,1.757 C10.104,2.727 10.89,3.513 11.86,3.513 Z M16.25,1.756 L14.055,1.756 L16.25,1.756 Z M9.662,1.756 L0,1.756 L9.662,1.756 Z M10.98,14.494 C11.95,14.494 12.736,13.707 12.736,12.737 C12.736,11.767 11.95,10.981 10.979,10.981 C10.009,10.981 9.223,11.767 9.223,12.737 C9.223,13.707 10.009,14.494 10.979,14.494 L10.98,14.494 Z M0,12.738 L8.784,12.738 L0,12.738 Z M13.176,12.738 L16.25,12.738 L13.176,12.738 Z M4.392,9.004 C5.362,9.004 6.148,8.217 6.148,7.247 C6.148,6.277 5.362,5.49 4.392,5.49 C3.422,5.49 2.635,6.277 2.635,7.247 C2.635,8.217 3.421,9.004 4.392,9.004 Z M0,7.248 L2.196,7.248 L0,7.248 Z M6.588,7.248 L16.25,7.248 L6.588,7.248 Z"
                                 transform="translate(1 1)"
                              ></path>
                           </svg>
                           Preferences
                        </NavLink>
                     </li>
                     <li className="py-2 hover:bg-[#bbbaba1e] rounded-xl">
                        <div
                           onClick={logoutHandle}
                           className="bg-transparent flex items-center gap-2 text-base cursor-pointer"
                        >
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="15"
                              viewBox="0 0 13 15"
                           >
                              <path
                                 fill="#9a9aab"
                                 fillRule="evenodd"
                                 d="M6.5,0 C6.101,0 5.778,0.323 5.778,0.722 L5.778,6.5 C5.778,6.899 6.101,7.222 6.5,7.222 C6.899,7.222 7.222,6.899 7.222,6.5 L7.222,0.722 C7.222,0.323 6.899,0 6.5,0 Z M0,7.944 C0,5.114 1.809,2.707 4.333,1.814 L4.333,3.375 C2.625,4.187 1.444,5.928 1.444,7.945 C1.444,10.735 3.708,13 6.5,13 C9.292,13 11.556,10.736 11.556,7.944 C11.556,5.928 10.375,4.187 8.666,3.375 L8.666,1.815 C11.191,2.707 13,5.115 13,7.945 C13,11.535 10.09,14.445 6.5,14.445 C2.91,14.445 0,11.535 0,7.945 L0,7.944 Z"
                              ></path>
                           </svg>
                           Sign Out
                        </div>
                     </li>
                  </ul>
               </div>
            </li>
         </ul>
         <ul className={`${responsiveSidebar && "block md:hidden "} `}>
            <li>
               <NavLink to="/dashboard">
                  <div
                     onClick={() => itemClickHandle("dashboard")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200"
                  >
                     <RiDashboardLine className="text-xl w-12" />
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink to="/inventory">
                  <div
                     onClick={() => itemClickHandle("inventory", true)}
                     className="py-0 rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200"
                  >
                     <MdOutlineInventory className="text-xl w-12" />
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/category"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("category")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200"
                  >
                     <MdOutlineCategory className="text-xl w-12" />
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink to="/users">
                  <div
                     onClick={() => itemClickHandle("users")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200"
                  >
                     {" "}
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                     >
                        <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                     </svg>
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink to="/search">
                  <div
                     onClick={() => itemClickHandle("search", true)}
                     className="py-0 rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200"
                  >
                     <CiSearch className="text-xl w-12" />
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink to="/reports">
                  <div
                     onClick={() => itemClickHandle("reports")}
                     className="py-0 rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200"
                  >
                     <svg
                        width="48"
                        height="24"
                        className="my-3"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M8 13V17"
                           stroke="currentColor"
                           strokeOpacity=".8"
                           strokeWidth="1.5"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        ></path>
                        <path
                           d="M12 7V17"
                           stroke="currentColor"
                           strokeOpacity=".8"
                           strokeWidth="1.5"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        ></path>
                        <path
                           d="M16 10V17"
                           stroke="currentColor"
                           strokeOpacity=".8"
                           strokeWidth="1.5"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        ></path>
                        <path
                           fillRule="evenodd"
                           clipRule="evenodd"
                           d="M5 3H19C20.105 3 21 3.895 21 5V19C21 20.105 20.105 21 19 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3Z"
                           stroke="currentColor"
                           strokeWidth="1.5"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        ></path>
                     </svg>
                  </div>
               </NavLink>
            </li>
            <li>
               <div
                  onClick={() => setOrderMenuOpen(!orderMenuOpen)}
                  className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200 cursor-pointer"
               >
                  <BsCart2 className="text-xl w-12" />
               </div>
               <div className={`ml-8 ${orderMenuOpen ? "block" : "hidden"}`}>
                  <NavLink
                     to="/orders"
                     className={({ isActive, isPending }) =>
                        isActive
                           ? "text-custom_lightBlue rounded-md hover:bg-[#bbbaba1e] flex items-center gap-2 duration-200 text-sm px-2 py-3 capitalize"
                           : "rounded-md hover:bg-[#bbbaba1e] flex items-center gap-2 duration-200 text-sm px-2 py-3 capitalize"
                     }
                  >
                     <BsCart2 className="text-lg" />
                  </NavLink>
                  <NavLink
                     to="drafts"
                     className={({ isActive, isPending }) =>
                        isActive
                           ? "text-custom_lightBlue rounded-md hover:bg-[#bbbaba1e] flex items-center gap-2 duration-200 text-sm px-2 py-3 capitalize"
                           : "rounded-md hover:bg-[#bbbaba1e] flex items-center gap-2 duration-200 text-sm px-2 py-3 capitalize"
                     }
                  >
                     <MdDrafts className="text-lg" />
                  </NavLink>
                  <NavLink
                     to="shipping"
                     className={({ isActive, isPending }) =>
                        isActive
                           ? "text-custom_lightBlue rounded-md hover:bg-[#bbbaba1e] flex items-center gap-2 duration-200 text-sm px-2 py-3 capitalize"
                           : "rounded-md hover:bg-[#bbbaba1e] flex items-center gap-2 duration-200 text-sm px-2 py-3 capitalize"
                     }
                  >
                     <BsCart2 className="text-lg" />
                  </NavLink>
               </div>
            </li>

            <li>
               <NavLink to="/dispatch">
                  <div
                     onClick={() => itemClickHandle("dispatch")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200"
                  >
                     <FaVoicemail className="text-xl w-12" />
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink to="/delivery">
                  <div
                     onClick={() => itemClickHandle("delivery")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <FaVoicemail className="text-xl w-12" />
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/delivered"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("delivered")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <AiOutlineDeliveredProcedure className="text-xl w-12" />
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/fullfilled"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("fullfilled")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <GiDeliveryDrone className="text-xl w-12" />
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/canceled"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("canceled")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <svg
                        width="24px"
                        height="24px"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        color="#ffffff"
                     >
                        <g clip-path="url(#clip0)">
                           <path
                              d="M18.6213 12.1213L20.7426 10M22.864 7.87868L20.7426 10M20.7426 10L18.6213 7.87868M20.7426 10L22.864 12.1213"
                              stroke="#ffffff"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                           ></path>
                           <path
                              d="M1 20V19C1 15.134 4.13401 12 8 12V12C11.866 12 15 15.134 15 19V20"
                              stroke="#ffffff"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                           ></path>
                           <path
                              d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z"
                              stroke="#ffffff"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                           ></path>
                        </g>
                        <defs>
                           <clipPath id="clip0">
                              <rect
                                 width="24"
                                 height="24"
                                 stroke-width="1.5"
                                 fill="white"
                              ></rect>
                           </clipPath>
                        </defs>
                     </svg>
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink
                  to="/refund-&-return"
                  className={({ isActive, isPending }) =>
                     isActive && "text-custom_lightBlue"
                  }
               >
                  <div
                     onClick={() => itemClickHandle("refund-&-return")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <AiOutlineDeliveredProcedure className="text-xl w-12" />
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink to="/employee">
                  <div
                     onClick={() => itemClickHandle("employee")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <ImUserTie className="text-xl w-12" />
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink to="/customers">
                  <div
                     onClick={() => itemClickHandle("customers")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        height="24"
                        fill="transparent"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                     >
                        <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           strokeWidth="2"
                           d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                        ></path>
                     </svg>
                  </div>
               </NavLink>
            </li>
            <li>
               <div onClick={() => setBannerOpen(!bannerOpen)}>
                  <button
                     onClick={() => itemClickHandle("banner")}
                     className={`py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200  w-full`}
                  >
                     <BsCart2 className="text-xl  w-12" />
                  </button>
               </div>
               <div className={`ml-3 ${bannerOpen ? "block" : "hidden"}`}>
                  <NavLink to="/topBanner">
                     <span className="capitalize text-sm font-medium   py-2 sm:py-3 rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200">
                        Top Banner
                     </span>
                  </NavLink>
                  <NavLink to="offerBanner">
                     <span className="capitalize text-sm font-medium  py-2 sm:py-3 rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200">
                        Offer Banner
                     </span>
                  </NavLink>
               </div>
            </li>
            <li>
               <NavLink to="/reviews">
                  <div
                     onClick={() => itemClickHandle("verify reviews")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <MdReviews className="text-xl w-12" />
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink to="/email-marketing">
                  <div
                     onClick={() => itemClickHandle("email marketing")}
                     className="py-3 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <TfiEmail className="text-xl w-12" />
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink to="/tips">
                  <div
                     onClick={() => itemClickHandle("tips")}
                     className="py-0 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <span className="">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           width="48"
                           height="48"
                           fill="none"
                           viewBox="0 0 48 48"
                        >
                           <path
                              stroke="#fff"
                              strokeLinejoin="round"
                              strokeMiterlimit="10"
                              strokeOpacity=".8"
                              strokeWidth="1.5"
                              d="M24 15.52c-4.682 0-8.479 3.797-8.479 8.48 0 1.497.392 2.9 1.073 4.122l-1.055 3.776c-.096.343.22.66.565.564l3.778-1.054c1.22.68 2.623 1.07 4.119 1.07 4.682 0 8.479-3.796 8.479-8.478 0-4.683-3.797-8.48-8.48-8.48z"
                           ></path>
                           <path
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeMiterlimit="10"
                              strokeOpacity=".8"
                              strokeWidth="1.5"
                              d="M21.938 21.709c0-1.14.923-2.063 2.062-2.063 1.139 0 2.063.924 2.063 2.063 0 1.94-2.063 1.9-2.063 3.895"
                           ></path>
                           <path
                              stroke="#fff"
                              fillOpacity=".8"
                              d="M24 29.042c.507 0 .917-.41.917-.917 0-.506-.41-.916-.916-.916-.507 0-.917.41-.917.916s.41.917.917.917z"
                           ></path>
                        </svg>
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <NavLink to="/notifications">
                  <div
                     onClick={() => itemClickHandle("notifications")}
                     className="py-0 rounded-md hover:bg-[#bbbaba1e] flex items-center duration-200"
                  >
                     <span className="">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           width="48"
                           height="48"
                           fill="none"
                           viewBox="0 0 48 48"
                        >
                           <path
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeMiterlimit="10"
                              strokeOpacity=".8"
                              strokeWidth="1.5"
                              d="M23.683 15.008c-3.52.17-6.229 3.298-6.229 6.933v3.81l-1.36 2.823c-.303.664.166 1.426.877 1.426h14.058c.71 0 1.18-.762.878-1.426l-1.361-2.824v-4c0-3.836-3.104-6.925-6.863-6.742zM26.182 30.75c0 1.242-.977 2.25-2.182 2.25s-2.182-1.008-2.182-2.25"
                           ></path>
                        </svg>
                     </span>
                  </div>
               </NavLink>
            </li>
            <li>
               <div className="dropdown dropdown-right dropdown-end w-full z-50">
                  <label tabIndex={0}>
                     <div className="py-3 w-full rounded-md hover:bg-[#bbbaba1e]  flex items-center duration-200 cursor-pointer">
                        <span className="">
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="48"
                              height="20"
                              ml-4
                              viewBox="0 0 18 20"
                           >
                              <path
                                 fill="none"
                                 stroke="#fff"
                                 strokeLinejoin="round"
                                 strokeOpacity=".8"
                                 strokeWidth="1.5"
                                 d="M11.075,15.92 L10.929,17.25 C10.309,17.395 9.665,17.48 9.001,17.48 C8.335,17.48 7.691,17.395 7.071,17.25 L6.926,15.92 C6.854,15.267 6.476,14.688 5.906,14.358 C5.337,14.03 4.645,13.992 4.045,14.257 L2.822,14.795 C1.941,13.856 1.268,12.722 0.886,11.458 L1.97,10.663 C2.5,10.275 2.813,9.657 2.813,9 C2.813,8.343 2.5,7.726 1.97,7.336 L0.886,6.542 C1.268,5.278 1.942,4.144 2.822,3.205 L4.045,3.743 C4.646,4.008 5.337,3.97 5.906,3.641 C6.475,3.313 6.854,2.733 6.925,2.08 L7.071,0.75 C7.692,0.605 8.335,0.521 9,0.521 C9.665,0.521 10.309,0.605 10.929,0.75 L11.075,2.08 C11.146,2.733 11.525,3.313 12.094,3.642 C12.663,3.97 13.354,4.008 13.956,3.744 L15.178,3.205 C16.059,4.144 16.732,5.278 17.114,6.542 L16.031,7.337 C15.501,7.726 15.188,8.343 15.188,9 C15.188,9.657 15.501,10.274 16.031,10.664 L17.114,11.458 C16.732,12.722 16.058,13.856 15.178,14.795 L13.956,14.257 C13.354,13.992 12.663,14.03 12.094,14.358 C11.525,14.687 11.147,15.267 11.075,15.92 Z M9,11.98 C10.646,11.98 11.98,10.645 11.98,9 C11.98,7.355 10.646,6.021 9,6.021 C7.355,6.021 6.021,7.355 6.021,9.001 C6.021,10.645 7.355,11.979 9.001,11.979 L9,11.98 Z"
                                 transform="translate(0 1)"
                              ></path>
                           </svg>
                        </span>
                     </div>
                  </label>
                  <ul
                     tabIndex={0}
                     className="dropdown-content p-4 shadow bg-base-100 dark:bg-dark  dark:text-white text-black rounded-box w-48"
                  >
                     <li className="flex flex-col sm:flex-row items-center justify-center gap-4 pb-3 border-b">
                        <div>
                           <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkiIFjCOZ-mMeqxd2ryrneiHedE8G9S0AboA&usqp=CAU"
                              alt="profile"
                              className="w-10 rounded-full"
                           />
                        </div>
                        <h3 className="font-normal text-sm sm:text-base">
                           {userEmail.length > 28
                              ? userEmail.slice(0, 28) + "...."
                              : userEmail}
                        </h3>
                     </li>
                     <li className="py-2 hover:bg-[#bbbaba1e] rounded-xl">
                        <NavLink className="bg-transparent flex items-center gap-2 text-sm sm:text-base">
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="20"
                              viewBox="0 0 18 20"
                           >
                              <g
                                 fill="none"
                                 fillRule="evenodd"
                                 stroke="#9a9aab"
                                 strokeWidth="1.5"
                                 transform="translate(0 1)"
                              >
                                 <path d="M9,11.98 C10.646,11.98 11.98,10.645 11.98,9 C11.98,7.355 10.644,6.02 9,6.02 C7.356,6.02 6.021,7.356 6.021,9 C6.021,10.644 7.355,11.98 9.001,11.98 L9,11.98 Z"></path>
                                 <path
                                    strokeLinejoin="round"
                                    d="M11.075,15.92 C11.147,15.267 11.525,14.687 12.094,14.358 C12.663,14.03 13.354,13.992 13.956,14.257 L15.178,14.795 C16.058,13.856 16.732,12.722 17.114,11.458 L16.03,10.663 C15.5,10.275 15.188,9.657 15.188,9 C15.188,8.343 15.501,7.725 16.031,7.337 L17.114,6.542 C16.732,5.278 16.059,4.144 15.178,3.205 L13.956,3.743 C13.354,4.008 12.663,3.97 12.094,3.642 C11.525,3.313 11.147,2.733 11.075,2.08 L10.929,0.75 C10.309,0.605 9.665,0.52 9,0.52 C8.335,0.52 7.691,0.605 7.071,0.75 L6.925,2.08 C6.854,2.733 6.475,3.313 5.906,3.641 C5.338,3.97 4.646,4.007 4.045,3.743 L2.822,3.205 C1.942,4.144 1.268,5.278 0.886,6.542 L1.97,7.336 C2.5,7.726 2.813,8.343 2.813,9 C2.813,9.657 2.5,10.275 1.97,10.663 L0.886,11.458 C1.268,12.722 1.941,13.856 2.822,14.795 L4.045,14.257 C4.645,13.992 5.338,14.03 5.906,14.358 C6.476,14.687 6.854,15.267 6.926,15.92 L7.071,17.25 C7.691,17.395 8.335,17.48 9.001,17.48 C9.665,17.48 10.309,17.395 10.929,17.25 L11.075,15.92 Z"
                                 ></path>
                              </g>
                           </svg>
                           Settings
                        </NavLink>
                     </li>
                     <li className="py-2 hover:bg-[#bbbaba1e] rounded-xl">
                        <NavLink className="bg-transparent flex items-center gap-2 text-sm sm:text-base">
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="17"
                              viewBox="0 0 18 17"
                           >
                              <path
                                 fill="none"
                                 stroke="#9a9aab"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                                 strokeWidth="1.5"
                                 d="M11.86,3.513 C12.83,3.513 13.617,2.727 13.617,1.757 C13.617,0.787 12.83,0 11.86,0 C10.89,0 10.104,0.787 10.104,1.757 C10.104,2.727 10.89,3.513 11.86,3.513 Z M16.25,1.756 L14.055,1.756 L16.25,1.756 Z M9.662,1.756 L0,1.756 L9.662,1.756 Z M10.98,14.494 C11.95,14.494 12.736,13.707 12.736,12.737 C12.736,11.767 11.95,10.981 10.979,10.981 C10.009,10.981 9.223,11.767 9.223,12.737 C9.223,13.707 10.009,14.494 10.979,14.494 L10.98,14.494 Z M0,12.738 L8.784,12.738 L0,12.738 Z M13.176,12.738 L16.25,12.738 L13.176,12.738 Z M4.392,9.004 C5.362,9.004 6.148,8.217 6.148,7.247 C6.148,6.277 5.362,5.49 4.392,5.49 C3.422,5.49 2.635,6.277 2.635,7.247 C2.635,8.217 3.421,9.004 4.392,9.004 Z M0,7.248 L2.196,7.248 L0,7.248 Z M6.588,7.248 L16.25,7.248 L6.588,7.248 Z"
                                 transform="translate(1 1)"
                              ></path>
                           </svg>
                           Preferences
                        </NavLink>
                     </li>
                     <li className="py-2 hover:bg-[#bbbaba1e] rounded-xl">
                        <button className="bg-transparent flex items-center gap-2 text-sm sm:text-base cursor-pointer">
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="15"
                              viewBox="0 0 13 15"
                           >
                              <path
                                 fill="#9a9aab"
                                 fillRule="evenodd"
                                 d="M6.5,0 C6.101,0 5.778,0.323 5.778,0.722 L5.778,6.5 C5.778,6.899 6.101,7.222 6.5,7.222 C6.899,7.222 7.222,6.899 7.222,6.5 L7.222,0.722 C7.222,0.323 6.899,0 6.5,0 Z M0,7.944 C0,5.114 1.809,2.707 4.333,1.814 L4.333,3.375 C2.625,4.187 1.444,5.928 1.444,7.945 C1.444,10.735 3.708,13 6.5,13 C9.292,13 11.556,10.736 11.556,7.944 C11.556,5.928 10.375,4.187 8.666,3.375 L8.666,1.815 C11.191,2.707 13,5.115 13,7.945 C13,11.535 10.09,14.445 6.5,14.445 C2.91,14.445 0,11.535 0,7.945 L0,7.944 Z"
                              ></path>
                           </svg>
                           Sign Out
                        </button>
                     </li>
                  </ul>
               </div>
            </li>
         </ul>
      </div>
   )
}

export default Menu
