import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
   useGetOrderByIdQuery,
   useUpdateOrderMutation,
} from "../../services/order"
import moment from "moment"
import { enqueueSnackbar } from "notistack"
import OrderCustomerInfo from "../../components/Customers/OrderCustomerInfo"
import CustomSpinner from "../../components/CustomSpinner"
import SingleOrderProduct from "../../components/SingleOrderProduct"
import { Link } from "react-router-dom"
import { useGetEmployeeQuery } from "../../services/employee"
import { employeeInfoFunc } from "../../utils/utils"

const RefundReturnItem = () => {
   const { id } = useParams()
   const [updateOrder] = useUpdateOrderMutation()
   const { data: order, isLoading } = useGetOrderByIdQuery(id)
   const [orderStatus, setOrderStatus] = useState("")

   const employeeFromLocal = JSON.parse(localStorage.getItem("ich-user"))
   const { data: user } = useGetEmployeeQuery(employeeFromLocal?.id)
   const permission = employeeInfoFunc(user, "refund & return")

   const updateStatus = () => {
      const status =
         orderStatus === "refund in progress"
            ? {
                 orderStatus: "refunded",
                 refundedAt: Date.now(),
              }
            : orderStatus === "return in progress"
            ? {
                 orderStatus: "returned",
                 returnedAt: Date.now(),
              }
            : orderStatus === "refund & return in progress"
            ? {
                 orderStatus: "refunded & returned",
                 refundReturnInProgressAt: Date.now(),
              }
            : {
                 orderStatus: orderStatus,
              }

      updateOrder({ id: order?._id, ...status })
      enqueueSnackbar(`${status?.orderStatus} successfull`, {
         variant: "info",
      })
      return (window.location.href = "/refund-&-return")
   }

   useEffect(() => {
      setOrderStatus(order?.orderStatus)
   }, [order])

   if (isLoading) {
      return <CustomSpinner />
   }

   return (
      <div className="p-2 mb-5 border-b m-5">
         <div className="flex items-start py-3">
            <Link
               to="/refund-&-return"
               className="text-[#5c5f62] p-2 mr-3 rounded-md border border-gray-300 cursor-pointer"
            >
               <svg
                  viewBox="0 0 20 20"
                  className="Polaris-Icon__Svg_375hu w-5 h-5"
                  focusable="false"
                  aria-hidden="true"
               >
                  <path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path>
               </svg>
            </Link>
            <div className="dark:text-white space-y-2">
               <h3 className="font-semibold text-xl">#{order?.orderId}</h3>
               <p className="text-sm">
                  Date:{" "}
                  {moment
                     .utc(order?.createdAt)
                     .utcOffset("+06:00")
                     .format("DD-MM-YYYY , HH:mm a")}
               </p>
            </div>
         </div>
         <div className="grid grid-cols-3 gap-4 ">
            <div className="col-span-2 ">
               <SingleOrderProduct order={order} />
            </div>
            <div className="col-span-1 ">
               <div className=" text-sm px-5 mb-4 border-b bg-white dark:bg-dark dark:text-white dark:border-gray-600 w-full border rounded-lg p-5 shadow">
                  <h3 className="font-semibold m-0">Notes</h3>
                  <p className="m-0">{order?.notes}</p>
               </div>
               <OrderCustomerInfo customer={order?.customer} />
            </div>
         </div>
         {permission?.permission !== "View Only" && (
            <div className="text-sm sm:text-sm py-5 text-right ">
               <button
                  type="button"
                  onClick={() => {
                     updateStatus()
                  }}
                  className="py-2 px-2 sm:px-5 bg-custom_green hover:bg-custom_lightGreen text-white rounded-lg capitalize"
               >
                  Confirm
               </button>
            </div>
         )}
      </div>
   )
}

export default RefundReturnItem
