import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const authorApi = createApi({
   reducerPath: "authorApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["author"],
   endpoints: (builder) => ({
      getAuthor: builder.query({
         query: () => "api/author",
         providesTags: ["author"],
      }),
      createAuthor: builder.mutation({
         query: (data) => ({
            url: `api/author`,
            method: "POST",
            body: data,
         }),
         invalidatesTags: ["author"],
      }),
      updateAuthor: builder.mutation({
         query: ({ id, ...rest }) => ({
            url: `api/author/${id}`,
            method: "PATCH",
            body: rest,
         }),
         invalidatesTags: ["author"],
      }),
      deleteAuthor: builder.mutation({
         query: (id) => ({
            url: `api/author/${id}`,
            method: "DELETE",
         }),
         invalidatesTags: ["author"],
      }),
   }),
})

export const {
   useGetAuthorQuery,
   useCreateAuthorMutation,
   useUpdateAuthorMutation,
   useDeleteAuthorMutation,
} = authorApi
