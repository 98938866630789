import { createSlice } from "@reduxjs/toolkit";
import { addToCart } from "./allCartFunc";

const addToCartSlice = createSlice({
  name: "addToCart",
  initialState: {
    carts: [],
    status: null,
  },
  extraReducers: {
    [addToCart.pending]: (state) => {
      state.status = "loading";
    },
    [addToCart.fulfilled]: (state, action) => {
      state.carts = action.payload;
      state.status = "success";
    },
    [addToCart.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default addToCartSlice.reducer;
