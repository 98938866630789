import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const allbooksApi = createApi({
   reducerPath: "allBooks",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["allBooks"],
   endpoints: (builder) => ({
      getBooks: builder.query({
         query: () => "api/allproducts/allbooks",
         providesTags: ["allBooks"],
      }),
   }),
})

export const { useGetBooksQuery } = allbooksApi
