import React, { useContext, useState } from "react"
import { DashboardContext } from "../../context/DashboardProvider"
import { MdOutlineKeyboardArrowDown } from "react-icons/md"
import { enqueueSnackbar } from "notistack"

import {
   Button,
   Dialog,
   DialogHeader,
   DialogBody,
   DialogFooter,
} from "@material-tailwind/react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import AgGridTable from "../../components/AgGridTable"
import {
   useCreateShippingMutation,
   useDeleteShippingMutation,
   useGetShippingQuery,
   useUpdateShippingMutation,
} from "../../services/shipping"

const Shipping = () => {
   const { responsiveSidebar } = useContext(DashboardContext)
   const [city, setCity] = useState("")
   const [shippingType, setShippingType] = useState("")
   const [shippingCost, setShippingCost] = useState(null)
   const [seletedData, setSeletedData] = useState()
   const { data: shippings } = useGetShippingQuery()
   const [createShipping, { isLoading: isCreating }] =
      useCreateShippingMutation()
   const [updateShipping, { isLoading: isUpdating }] =
      useUpdateShippingMutation()
   const [deleteShipping] = useDeleteShippingMutation()
   const [open, setOpen] = React.useState(false)

   const { employeeInfo } = useContext(DashboardContext)
   const permission = employeeInfo?.find((data) => data?.permission)

   const handleOpen = (data) => {
      setOpen(!open)
      setSeletedData(data)
   }

   const handleFormSubmit = async (e) => {
      e.preventDefault()

      if (!shippingType || !shippingCost || !city) {
         let errorMessage = !city ? "Please select city! " : ""
         errorMessage += !shippingType
            ? "Please select shipping type!"
            : (errorMessage += !shippingCost
                 ? "Please select shipping cost!"
                 : "")

         enqueueSnackbar(errorMessage, { variant: "error" })
      } else {
         try {
            await createShipping({
               city,
               shipping_type: shippingType,
               shipping_cost: shippingCost,
            })
            enqueueSnackbar("Shipping created", { variant: "default" })
            setCity("")
            setShippingCost("")
            setShippingType("")
         } catch (error) {
            enqueueSnackbar(`Error creating shipping ${error}`, {
               variant: "error",
            })
            setCity("")
            setShippingCost("")
            setShippingType("")
         } finally {
         }
      }
   }
   const updateFormSubmit = async (e) => {
      e.preventDefault()
      try {
         await updateShipping({
            id: seletedData?._id,
            city: city === "" ? seletedData?.city : city,
            shipping_type:
               shippingType === "" ? seletedData?.shipping_type : shippingType,
            shipping_cost: shippingCost || seletedData?.shipping_cost,
         })
         enqueueSnackbar("Shipping updated", { variant: "default" })
         setOpen(!open)
         setCity("")
         setShippingCost("")
         setShippingType("")
      } catch (error) {
         enqueueSnackbar("Error updating shipping", { variant: "error" })
         setOpen(!open)
         setCity("")
         setShippingCost("")
         setShippingType("")
      }
   }

   const columnDef = [
      { headerName: "City", cellClass: "tableCell", field: "city" },
      {
         headerName: "Shipping Type",
         cellClass: "tableCell",
         field: "shipping_type",
      },
      {
         headerName: "Shipping Cost",
         cellClass: "tableCell",
         field: "shipping_cost",
      },
      {
         headerName: "Actions",
         field: "_id",
         cellRenderer: (x) => (
            <div className="text-sm z-50 px-4">
               {permission && permission?.permission !== "View Only" ? (
                  <ul className="flex">
                     <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50">
                        <button
                           onClick={() => handleOpen(x?.data)}
                           className="flex items-center gap-3"
                        >
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 cursor-pointer text-green-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                           >
                              <path
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                                 d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              ></path>
                           </svg>
                        </button>
                     </li>
                     <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50">
                        <button
                           onClick={() => {
                              let message = window.confirm(
                                 "Do you want to remove it?"
                              )
                              if (message === true) {
                                 deleteShipping(x?.data?._id)
                              }
                           }}
                           className="flex items-center gap-2 text-[#4F5366] text-sm"
                        >
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 cursor-pointer text-red-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                           >
                              <path
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                                 d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              ></path>
                           </svg>
                        </button>
                     </li>
                  </ul>
               ) : (
                  <p className="py-3 px-4">N/A</p>
               )}
            </div>
         ),
      },
   ]

   return (
      <div
         className={`sm:p-4 pt-0 bg-slate-800 md:bg-white  dark:bg-dark text-black min-h-screen ${
            !responsiveSidebar ? "hidden" : "block"
         }`}
      >
         <form onSubmit={handleFormSubmit}>
            <h2 className="text-xl font-semibold mb-4">
               Add Shipping Information
            </h2>{" "}
            <div className="w-72 relative">
               <select
                  className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
               >
                  <option value="" className="bg-gray-400">
                     Select City
                  </option>
                  <option value="Inside Dhaka">Inside Dhaka</option>
                  <option value="Outside Dhaka">Outside Dhaka</option>
               </select>
               <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <MdOutlineKeyboardArrowDown size={20} />
               </div>
            </div>
            <div className="w-72 my-4">
               <input
                  type="text"
                  className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Type Shipping Type"
                  value={shippingType}
                  onChange={(e) => setShippingType(e.target.value)}
               />
            </div>
            <div className="w-72 my-4">
               <input
                  type="number"
                  className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Type Shipping Cost"
                  value={shippingCost}
                  onChange={(e) => setShippingCost(e.target.value)}
               />
            </div>
            {permission?.permission !== "View Only" && (
               <button
                  type="submit"
                  className={`px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 ${
                     isCreating ? "opacity-50 cursor-not-allowed" : "" // Disable the button when isCreating is true
                  }`}
               >
                  {isCreating ? "Loading..." : "Add"}
               </button>
            )}
         </form>
         <div className="w-full my-10">
            <h2 className="text-xl font-semibold mb-4">
               All Shipping Information
            </h2>{" "}
            {shippings?.length == 0 ? (
               <p>No shipping data found</p>
            ) : (
               <AgGridTable
                  data={shippings}
                  columnDef={columnDef}
                  height="62vh"
               />
            )}
            <Dialog open={open} handler={handleOpen}>
               <DialogHeader>Update Shipping.</DialogHeader>
               <DialogBody divider>
                  <form onSubmit={updateFormSubmit}>
                     <div className="w-72 my-4">
                        <select
                           placeholder={seletedData?.city || "Type City"}
                           className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                           onChange={(e) => setCity(e.target.value)}
                        >
                           <option
                              selected={seletedData?.city === "Inside Dhaka"}
                              value="Inside Dhaka"
                           >
                              Inside Dhaka
                           </option>
                           <option
                              selected={seletedData?.city === "Outside Dhaka"}
                              value="Outside Dhaka"
                           >
                              Outside Dhaka
                           </option>
                        </select>
                     </div>
                     <div className="w-72 my-4">
                        <input
                           type="text"
                           className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                           placeholder={
                              seletedData?.shipping_type || "Type Shipping type"
                           }
                           value={shippingType}
                           onChange={(e) => setShippingType(e.target.value)}
                        />
                     </div>
                     <div className="w-72 my-4">
                        <input
                           type="number"
                           className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                           placeholder={
                              seletedData?.shipping_cost || "Type Shipping cost"
                           }
                           value={shippingCost}
                           onChange={(e) => setShippingCost(e.target.value)}
                        />
                     </div>
                     <button
                        type="submit"
                        className={`px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 ${
                           isUpdating ? "opacity-50 cursor-not-allowed" : "" // Disable the button when isCreating is true
                        }`}
                     >
                        {isUpdating ? "Loading..." : "Update"}
                     </button>
                  </form>
               </DialogBody>
               <DialogFooter>
                  <Button
                     variant="text"
                     color="red"
                     onClick={() => {
                        setOpen(false)
                        setCity("")
                        setShippingCost("")
                        setShippingType("")
                     }}
                     className="mr-1"
                  >
                     <span>Cancel</span>
                  </Button>
               </DialogFooter>
            </Dialog>
         </div>
      </div>
   )
}

export default Shipping
