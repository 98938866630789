import React from "react"
import { useGetOrderQuery } from "../../services/order"
import { useSelector } from "react-redux"
import { CustomerOrderInfo } from "../../utils/CustomerOrderInfo"
import { Avatar, Popover, Whisper } from "rsuite"
import { userAccount } from "../../utils/utils"

const CustomerTotalOrder = ({ customer }) => {
   const { data: orders, isLoading } = useGetOrderQuery(userAccount)
   const orderStatusCounts = CustomerOrderInfo({
      orders,
      phone: customer?.contactInformation?.phone,
   })

   if (isLoading) {
      return <p>loading...</p>
   }

   console.log("customer===============================", orderStatusCounts)
   return (
      <Whisper
         followCursor
         speaker={
            <Popover>
               {Object.entries(orderStatusCounts?.orderCounts).map(
                  ([status, count]) => (
                     <p
                        key={status} // Adding a unique key for each element
                        className="text-[#576CBC] mb-1 cursor-pointer block"
                     >
                        {status} orders : {count}
                     </p>
                  )
               )}
            </Popover>
         }
      >
         <label className="text-[#576CBC] mb-1 cursor-pointer block">
            {orderStatusCounts?.total} Order
         </label>
      </Whisper>
   )
}

export default CustomerTotalOrder
