import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const shippingApi = createApi({
   reducerPath: "shippingApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["shippingAPI"],
   endpoints: (builder) => ({
      getShipping: builder.query({
         query: () => `api/shipping`,
         providesTags: ["shippingAPI"],
      }),
      createShipping: builder.mutation({
         query: (data) => ({
            url: `api/shipping`,
            method: "POST",
            body: data,
         }),
         invalidatesTags: ["shippingAPI"],
      }),
      updateShipping: builder.mutation({
         query: ({ id, ...rest }) => ({
            url: `api/shipping/${id}`,
            method: "PATCH",
            body: rest,
         }),
         invalidatesTags: ["shippingAPI"],
      }),
      deleteShipping: builder.mutation({
         query: (id) => ({
            url: `api/shipping/${id}`,
            method: "DELETE",
         }),
         invalidatesTags: ["shippingAPI"],
      }),
   }),
})

export const {
   useGetShippingQuery,
   useCreateShippingMutation,
   useUpdateShippingMutation,
   useDeleteShippingMutation,
} = shippingApi
