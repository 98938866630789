import React from "react"
import { reportData } from "./reportData"
import ReportItem from "./ReportItem"

const Reports = () => {
   return (
      <div>
         <div className=" border-b border-gray-200 md:mx-10 mx-4 py-5  ">
            <h3 className="text-2xl sm:text-[32px] font-medium">Reports</h3>
         </div>
         <div className="w-11/12 md:w-9/12 m-auto my-8">
            {reportData?.map((report, index) => (
               <ReportItem report={report} key={index}></ReportItem>
            ))}
         </div>
      </div>
   )
}

export default Reports
