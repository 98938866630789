import React, { useState, useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useContext } from "react"
import { DashboardContext } from "../../context/DashboardProvider"
import ScanningModal from "../Scanning/ScanningModal"
import { useDispatch, useSelector } from "react-redux"
import useScanDetection from "use-scan-detection"
import { useReactToPrint } from "react-to-print"
import Barcode from "react-barcode"
import axios from "axios"
import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import { useGetOneByBarcodeQuery } from "../../services/Search"

import { searchedProductBarcode } from "../../services/Features/productSearchSlice"
import { inventoryData } from "../../components/Dashboard/MenuItem/Inventory/SubmenuData"

const Inventory = () => {
   const { barcode } = useSelector((state) => state.productSearch)
   const { sidebarOpen } = useContext(DashboardContext)
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const [search, setSearch] = useState("")
   const { data: results, isSuccess } = useGetOneByBarcodeQuery(barcode)
   const [data, setData] = useState([])
   const [isData, setIsData] = useState(false)
   const [tableData, setTableData] = useState(null)
   const [qr, setqr] = useState(null)
   const componentRef = useRef()
   const handlePrint = useReactToPrint({
      content: () => componentRef.current,
   })
   const combineFn = (e, d) => {
      e.preventDefault()
      if (d === undefined) {
         alert("No barcode found with this product")
         setqr(null)
      } else {
         setqr(d)
         setTimeout(() => {
            handlePrint()
         }, 1000)
      }
   }

   useEffect(() => {
      const allData = results?.filter((data) => data?.barcode === search)
      setData(allData)
   }, [results, isSuccess])

   useEffect(() => {
      if (barcode) {
         setData(results)
         setIsData(true)
      }
   }, [barcode])

   const [barcodeScan, setBarcodeScan] = useState("")
   useScanDetection({
      onComplete: setBarcodeScan,
      minLength: 3,
   })

   const handleProductDelete = async (id) => {
      const confirmation = window.confirm("Do you want to delete this product?")
      if (confirmation) {
         if (data[0]?.category === "general") {
            await axios.delete(`/api/general/${id}`)
         } else if (
            data[0]?.category === "office-supplies" ||
            data[0]?.category === "stationary"
         ) {
            await axios.delete(`/api/office-supplies/${id}`)
         } else {
            await axios.delete(`/api/${data[0]?.category}/${id}`)
         }
         window.location.reload()
      } else {
         console.log("Ignore to delete this product")
      }
   }

   const defaultColDef = {
      shortable: true,
      flex: true,
      filter: true,
      floatingFilter: true,
   }

   useEffect(() => {
      setTableData(data)
   }, [data])

   const columnDef = [
      {
         headerName: "Barcode",
         field: "barcode",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/${data[0]?.category}-view/${data[0]?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params?.data?.barcode ? params?.data?.barcode : "N/A"}
               </Link>
            )
         },
      },
      {
         headerName: "Title",
         field: "title",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/${data[0]?.category}-view/${data[0]?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params?.data?.title ? params?.data?.title : "N/A"}
               </Link>
            )
         },
      },
      {
         headerName: "Writer",
         field: "author.name",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/${data[0]?.category}-view/${data[0]?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params?.data?.author?.name
                     ? params?.data?.author?.name
                     : "N/A"}
               </Link>
            )
         },
      },
      {
         headerName: "Category",
         field: "category",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/${data[0]?.category}-view/${data[0]?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params?.data?.category ? params?.data?.category : "N/A"}
               </Link>
            )
         },
      },
      {
         headerName: "Type",
         field: "type",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/${data[0]?.category}-view/${data[0]?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params?.data?.type ? params?.data?.type : "N/A"}
               </Link>
            )
         },
      },
      {
         headerName: "Discount%",
         field: "discount",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/${data[0]?.category}-view/${data[0]?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params?.data?.discount ? params?.data?.discount : "N/A"}
               </Link>
            )
         },
      },
      {
         headerName: "Stock",
         field: "countInStock",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/${data[0]?.category}-view/${data[0]?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params?.data?.countInStock
                     ? params?.data?.countInStock
                     : "N/A"}
               </Link>
            )
         },
      },
      {
         headerName: "Actions",
         field: "_id",
         cellRenderer: (x) => (
            <div className="text-[15px] z-50">
               <ul className="flex">
                  <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50">
                     <Link
                        to={`/edit-${data[0]?.category}/${data[0]?._id}`}
                        className="flex items-center gap-2 text-[#4F5366] text-sm"
                     >
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           className="h-5 w-5 cursor-pointer text-green-600"
                           fill="none"
                           viewBox="0 0 24 24"
                           stroke="currentColor"
                           strokeWidth="2"
                        >
                           <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                           ></path>
                        </svg>
                     </Link>
                  </li>
                  <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50">
                     <button
                        onClick={() => handleProductDelete(data[0]?._id)}
                        className="flex items-center gap-2 text-[#4F5366] text-sm"
                     >
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           className="h-5 w-5 cursor-pointer text-red-600"
                           fill="none"
                           viewBox="0 0 24 24"
                           stroke="currentColor"
                           strokeWidth="2"
                        >
                           <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                           ></path>
                        </svg>
                     </button>
                  </li>
                  <li
                     onClick={(e) => combineFn(e, x?.data?.barcode)}
                     className="px-2 cursor-pointer hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50"
                  >
                     <Link className="flex items-center gap-2 text-[#4F5366] text-sm">
                        <svg
                           viewBox="0 0 20 20"
                           className="Polaris-Icon__Svg_375hu w-4 text-blue-500"
                           focusable="false"
                           aria-hidden="true"
                        >
                           <path
                              fillRule="evenodd"
                              d="M14 11h2v-2h-2v2zm-7-4h6v-3h-6v3zm0 9h6v-2h-6v2zm8-9v-3.5a1.5 1.5 0 0 0-1.5-1.5h-7a1.5 1.5 0 0 0-1.5 1.5v3.5h-1.5a1.5 1.5 0 0 0-1.5 1.5v6a1.5 1.5 0 0 0 1.5 1.5h1.5v.5a1.5 1.5 0 0 0 1.5 1.5h7a1.5 1.5 0 0 0 1.5-1.5v-.5h1.5a1.5 1.5 0 0 0 1.5-1.5v-6a1.5 1.5 0 0 0-1.5-1.5h-1.5z"
                           ></path>
                        </svg>
                     </Link>
                  </li>
                  <li className="hidden">
                     <Barcode
                        ref={componentRef}
                        value={qr}
                        options={{
                           width: 1,
                           height: 1,
                           format: "CODE39",
                           displayValue: true,
                           font: "monospace",
                           textAlign: "center",
                           textPosition: "bottom",
                           textMargin: 2,
                           fontSize: 20,
                           background: "#ffffff",
                           lineColor: "#000000",
                        }}
                     />
                  </li>
               </ul>
            </div>
         ),
      },
   ]

   return (
      <div className="relative">
         <div className="border-b border-gray-200 md:mx-10 mx-4 py-5  flex justify-between">
            <h3 className="text-lg sm:text-2xl md:text-[32px] font-medium dark:text-white text-dark">
               All Items
            </h3>
         </div>

         {/* search item and items info */}
         <div className="flex justify-between items-center my-7 ml-2 sm:mx-4 md:mx-10 ">
            <div className="py-1 px-2 pl-4 flex justify-center items-center border text-darkText bg-white rounded-lg">
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 mr-3 [text-[#4F5366]"
               >
                  <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
               </svg>
               <input
                  value={barcode}
                  onChange={(e) => {
                     setSearch(e.target.value)
                     dispatch(
                        searchedProductBarcode({ barcode: e.target.value })
                     )
                  }}
                  type="text"
                  className=" bg-white outline-none "
                  placeholder="Search All Items"
               />
               <div
                  className="tooltip tooltip-bottom py-0"
                  data-tip="Scanning Mode"
               >
                  <label htmlFor="scanningModal" className=" cursor-pointer">
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                     >
                        <path
                           stroke="currentColor"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           strokeMiterlimit="10"
                           strokeWidth="1.373"
                           d="M9.193 19.118V4.876M16.535 19.118V4.876M19.12 19.118V4.876M4.88 19.118V4.876"
                        ></path>
                        <path
                           stroke="currentColor"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           strokeMiterlimit="10"
                           strokeWidth=".915"
                           d="M6.818 19.337V4.663"
                        ></path>
                        <path
                           fill="currentColor"
                           d="M14.156 19.769h-2.59c-.238 0-.431-.194-.431-.432V4.663c0-.238.193-.432.431-.432h2.59c.238 0 .431.194.431.432v14.674c0 .238-.193.432-.431.432z"
                        ></path>
                     </svg>
                  </label>
                  <ScanningModal></ScanningModal>
               </div>
            </div>
         </div>
         {isData && barcode?.length > 0 ? (
            <div className="flex items-start gap-5 flex-col mx-10">
               {data?.length == 0 ? (
                  <div className="text-red-600 flex items-center justify-center w-full h-[60vh]">
                     No Products Available!
                  </div>
               ) : (
                  <div
                     className="ag-theme-alpine w-[100%] "
                     style={{ height: "25vh", width: "63vw" }}
                  >
                     <AgGridReact
                        rowData={tableData}
                        columnDefs={columnDef}
                        defaultColDef={defaultColDef}
                        domLayout="autoWidth"
                     ></AgGridReact>
                  </div>
               )}
            </div>
         ) : (
            <>
               <div className="grid sm:grid-cols-2 lg:grid-cols-6 gap-2 justify-start mx-4 md:mx-10 text-[15px] sm:text-sm md:text-[17px] lg:text-[18px] text-[#9191A1]">
                  <h3>Folder: 8</h3>
                  <h3>Items: 0</h3>
                  <h3 className="lg:col-span-2">Total Quantity: 0 units</h3>
                  <h3 className="lg:col-span-2">Total Value: A$0.00</h3>
               </div>
               {/*All Items start*/}
               <div
                  className={`lg:w-3/4 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-center gap-5 mt-8 sm:mx-4 md:mx-10 ${
                     sidebarOpen && "lg:grid-cols-3"
                  }`}
               >
                  {inventoryData?.map((item) => (
                     <div
                        onClick={() => navigate(item?.path)}
                        key={item.id}
                        className="card bg-base-100 shadow-xl w-full group cursor-pointer"
                     >
                        <figure className="bg-lightGray h-[120px] text-center">
                           <svg
                              width="64"
                              height="64"
                              viewBox="0 0 64 64"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M48.9474 16.2499H28.7368L23.6842 10.9999H11.0526C8.27368 10.9999 6 13.3624 6 16.2499V47.7499C6 50.6374 8.27368 52.9999 11.0526 52.9999H50.2105C52.3579 52.9999 54 51.2936 54 49.0624V21.4999C54 18.6124 51.7263 16.2499 48.9474 16.2499Z"
                                 fill="#BCC0D3"
                              ></path>
                              <path
                                 d="M52.996 23.9999H19.1775C16.7888 23.9999 14.6516 25.8453 14.2745 28.3499L10 52.9999H50.2128C52.5 52.9999 54.3789 51.1544 54.7561 48.6499L57.8991 30.1953C58.5277 27.0317 56.139 23.9999 52.996 23.9999Z"
                                 fill="#E6E6F0"
                              ></path>
                           </svg>
                        </figure>
                        <div className="flex items-center justify-between pt-3 w-full px-4 pb-8 bg-white dark:bg-gray-700">
                           <div>
                              <h2 className="card-title text-base dark:text-darkText">
                                 {item.name}
                              </h2>
                           </div>
                        </div>
                     </div>
                  ))}
               </div>
            </>
         )}
      </div>
   )
}

export default Inventory
