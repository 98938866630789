import React, { useContext, useState } from "react"
import { enqueueSnackbar } from "notistack"

import {
   Button,
   Dialog,
   DialogHeader,
   DialogBody,
   DialogFooter,
} from "@material-tailwind/react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import AgGridTable from "../../components/AgGridTable"

import axios from "axios"
import { DashboardContext } from "../../context/DashboardProvider"
import {
   useCreateTopBannerMutation,
   useDeleteTopBannerMutation,
   useGetTopBannerQuery,
   useUpdateTopBannerMutation,
} from "../../services/topBanner"

const TopBanner = () => {
   const [bannerImage, setBannerImage] = useState("")
   const [uploadbannerImage, setUploadBannerImage] = useState()
   const [seletedData, setSeletedData] = useState()
   const { data: topBanners } = useGetTopBannerQuery()

   const [createTopBanner, { isLoading: isCreating }] =
      useCreateTopBannerMutation()
   const [updateTopBanner, { isLoading: isUpdating }] =
      useUpdateTopBannerMutation()
   const [deleteTopBanner] = useDeleteTopBannerMutation()

   const { employeeInfo } = useContext(DashboardContext)
   const permission = employeeInfo?.find((data) => data?.permission)

   const [open, setOpen] = React.useState(false)

   const handleOpen = (data) => {
      setOpen(!open)
      setSeletedData(data)
   }

   const handleFormSubmit = async (e) => {
      e.preventDefault()

      if (!uploadbannerImage) {
         return enqueueSnackbar("Please upload a banner!", { variant: "error" })
      } else {
         try {
            await createTopBanner({
               image: uploadbannerImage,
            })
            enqueueSnackbar("banner created", { variant: "default" })
         } catch (error) {
            enqueueSnackbar(`Error creating banner ${error}`, {
               variant: "error",
            })
         } finally {
         }
      }
   }
   const updateFormSubmit = async (e) => {
      if (!uploadbannerImage) {
         return enqueueSnackbar("Please upload a banner!", { variant: "error" })
      }
      e.preventDefault()
      try {
         await updateTopBanner({
            id: seletedData?._id,
            image:
               uploadbannerImage === ""
                  ? seletedData?.image
                  : uploadbannerImage,
         })
         enqueueSnackbar("banner updated", { variant: "default" })
         setOpen(!open)
      } catch (error) {
         enqueueSnackbar("Error updating banner", { variant: "error" })
         setOpen(!open)
      }
   }
   const onBannerImageChange = (e) => {
      setBannerImage(e.target.files[0])
   }

   const onSubmitbannerImage = async (event) => {
      event.preventDefault()
      const formData = new FormData()
      formData.append("uploadImages", bannerImage)

      try {
         if (!bannerImage)
            return alert(
               "Please upload an image first then create this product!"
            )

         await axios
            .post("/api/image-upload", formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            })
            .then((res) => {
               setUploadBannerImage(res.data[0])
               alert("Banner Image uploaded")
            })
            .catch((err) => {
               alert(err?.response?.data?.message)
            })
      } catch (err) {
         if (err.response.status === 500) {
            console.log(err)
         } else {
            console.log(err.response.data.msg)
         }
      }
   }

   const columnDef = [
      {
         headerName: "Image",
         field: "image",
         cellRenderer: (x) => <img src={x?.data?.image} alt="banner image" />,
      },

      {
         headerName: "Actions",
         field: "_id",
         cellRenderer: (x) => (
            <div className="text-sm z-50 px-4">
               {permission && permission?.permission !== "View Only" ? (
                  <ul className="flex">
                     <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50">
                        <button
                           onClick={() => handleOpen(x?.data)}
                           className="flex items-center gap-3"
                        >
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 cursor-pointer text-green-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                           >
                              <path
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                                 d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              ></path>
                           </svg>
                        </button>
                     </li>
                     <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50">
                        <button
                           onClick={() => {
                              let message = window.confirm(
                                 "Do you want to remove it?"
                              )
                              if (message === true) {
                                 deleteTopBanner(x?.data?._id)
                              }
                           }}
                           className="flex items-center gap-2 text-[#4F5366] text-sm"
                        >
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 cursor-pointer text-red-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                           >
                              <path
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                                 d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              ></path>
                           </svg>
                        </button>
                     </li>
                  </ul>
               ) : (
                  <p className="py-3">N/A</p>
               )}
            </div>
         ),
      },
   ]

   return (
      <div className="p-4  bg-slate-800 md:bg-white  dark:bg-dark text-black ">
         <form onSubmit={handleFormSubmit}>
            <h2 className="text-xl font-semibold mb-4 text-custom_darkBlue">
               Add Top Banner
            </h2>
            <div className="w-72 my-4">
               <div className="w-full block appearance-none focus:bg-white focus:border-gray-500 bg-white border border-gray-300 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none">
                  <label htmlFor="bannerImage" className=" ">
                     Top Banner Image
                  </label>
                  <input
                     type="file"
                     id="file"
                     name="bannerImageUpload"
                     onChange={onBannerImageChange}
                  />
               </div>
               <button onClick={onSubmitbannerImage} className="CssBtn1">
                  <span className="text">
                     Select only jpg, png, and avif file!
                  </span>
                  <span className="mt-1.5">Add Top Banner</span>
               </button>
            </div>
            {permission && permission?.permission !== "View Only" && (
               <button
                  type="submit"
                  className={`px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 ${
                     isCreating ? "opacity-50 cursor-not-allowed" : "" // Disable the button when isCreating is true
                  }`}
               >
                  {isCreating ? "Loading..." : "Add"}
               </button>
            )}
         </form>
         <div className="w-full my-10">
            <h2 className="text-xl font-semibold mb-4">Top Banner</h2>{" "}
            {topBanners?.length == 0 ? (
               <p>No Banner data found</p>
            ) : (
               <AgGridTable
                  data={topBanners}
                  columnDef={columnDef}
                  height="62vh"
               />
            )}
            <Dialog open={open} handler={handleOpen}>
               <DialogHeader>Update Banner</DialogHeader>
               <DialogBody divider>
                  <form onSubmit={updateFormSubmit}>
                     <div className="w-72 my-4">
                        <div className="w-full block appearance-none focus:bg-white focus:border-gray-500 bg-white border border-gray-300 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none">
                           <label htmlFor="bannerImage" className=" ">
                              Top Banner Image
                           </label>
                           <input
                              type="file"
                              id="file"
                              name="bannerImageUpload"
                              onChange={onBannerImageChange}
                           />
                        </div>
                        <button
                           onClick={onSubmitbannerImage}
                           className="CssBtn1"
                        >
                           <span className="text">
                              Select only jpg, png, and avif file!
                           </span>
                           <span className="mt-1.5">Add Top Banner</span>
                        </button>
                     </div>
                     <button
                        type="submit"
                        className={`px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 ${
                           isUpdating ? "opacity-50 cursor-not-allowed" : "" // Disable the button when isCreating is true
                        }`}
                     >
                        {isUpdating ? "Loading..." : "Update"}
                     </button>
                  </form>
               </DialogBody>
               <DialogFooter>
                  <Button
                     variant="text"
                     color="red"
                     onClick={() => {
                        setOpen(false)
                     }}
                     className="mr-1"
                  >
                     <span>Cancel</span>
                  </Button>
               </DialogFooter>
            </Dialog>
         </div>
      </div>
   )
}

export default TopBanner
