import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { DashboardContext } from "../../context/DashboardProvider"
import { useGetCustomersQuery } from "../../services/customer"
import CustomerTotalOrder from "../../components/Customers/CustomerTotalOrder"
import AgGridTable from "../../components/AgGridTable"

const AllCustomers = () => {
   const { employeeInfo } = useContext(DashboardContext)
   const permission = employeeInfo?.find((data) => data?.permission)
   const { data } = useGetCustomersQuery()

   const columnDef = [
      {
         headerName: "CUSTOMER NAME",
         field: "contactInformation.name",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/customer/${params.data?.contactInformation?.phone}`}
                  className="block px-4 !no-underline capitalize"
               >
                  {params.data?.contactInformation?.name}
               </Link>
            )
         },
      },
      {
         headerName: "EMAIL",
         field: "contactInformation.email",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/customer/${params.data?.contactInformation?.phone}`}
                  className="block px-4 !no-underline "
               >
                  {params.data?.contactInformation?.email}
               </Link>
            )
         },
      },
      {
         headerName: "EMAIL SUBSCRIPTION",
         field: "contactInformation.name",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/customer/${params.data?.contactInformation?.phone}`}
                  className="block px-4 !no-underline "
               >
                  <button className="bg-[#aee9d1] px-5 rounded-full">
                     subscribe
                  </button>
               </Link>
            )
         },
      },
      {
         headerName: "LOCATION",
         field: "shippingAddress.address",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/customer/${params.data?.contactInformation?.phone}`}
                  className="px-4 block !no-underline"
               >
                  {params?.data?.shippingAddress?.address || "Website"}
               </Link>
            )
         },
      },
      {
         headerName: "ORDERS",
         field: "",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/customer/${params.data?.contactInformation?.phone}`}
                  className="px-4 block !no-underline"
               >
                  <CustomerTotalOrder customer={params?.data} />
               </Link>
            )
         },
      },
      {
         headerName: "SPENT",
         field: "",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/customer/${params.data?.contactInformation?.phone}`}
                  className="px-4 block !no-underline"
               >
                  {params?.data?.shippingAddress?.address || "Website"}
               </Link>
            )
         },
      },
      // {
      //    headerName: "ACTION",
      //    field: "_id",
      //    cellRenderer: (params) => (
      //       <div className="text-[15px] z-50">
      //          <ul tabIndex={0} className="flex  ">
      //             {permission?.permission !== "View Only" && (
      //                <>
      //                   <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50">
      //                      <button className="flex items-center gap-2 text-[#4F5366] text-sm">
      //                         <svg
      //                            xmlns="http://www.w3.org/2000/svg"
      //                            className="h-5 w-5 cursor-pointer text-green-600"
      //                            fill="none"
      //                            viewBox="0 0 24 24"
      //                            stroke="currentColor"
      //                            strokeWidth="2"
      //                         >
      //                            <path
      //                               strokeLinecap="round"
      //                               strokeLinejoin="round"
      //                               d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
      //                            ></path>
      //                         </svg>
      //                      </button>
      //                   </li>
      //                </>
      //             )}
      //          </ul>
      //       </div>
      //    ),
      // },
   ]

   return (
      <div className="p-2 mb-5 m-2 sm:m-5 dark:text-white space-y-2">
         <div className="flex flex-col sm:flex-row gap-3 justify-between items-center py-5 dark:text-white">
            <h3 className="font-semibold text-xl">All customers</h3>
            <div className="space-x-2">
               <button className="py-2 px-5 border border-gray-300  shadow-md bg-white hover:bg-gray-900 hover:text-white duration-150 rounded-lg">
                  Export
               </button>
               <button className="py-2  px-5 border border-gray-300  shadow-md bg-white hover:bg-gray-900 hover:text-white duration-150 rounded-lg">
                  Import
               </button>
               {permission?.permission !== "View Only" && (
                  <Link
                     to="/addCustomer"
                     className="hover:!text-white hover:!no-underline py-2 px-5 border border-custom_green bg-custom_green hover:bg-custom_lightGreen duration-150 text-white rounded-lg"
                  >
                     Add Customer
                  </Link>
               )}
            </div>
         </div>
         <div className="w-full border rounded-lg p-5 bg-white dark:bg-dark dark:text-white ">
            <div className="flex items-center gap-5 pb-3 ">
               <h3 className="text-sm sm:text-4 md:text-[18px] text-[#9191A1]">
                  Total Orders: {data?.Customers?.length}
               </h3>
            </div>

            <div className="flex gap-10 mb-3"></div>
            {data?.Customers?.length === 0 ? (
               <div className="text-red-600 flex items-center justify-center  w-full h-[60vh]">
                  No Products Available!
               </div>
            ) : (
               <AgGridTable
                  data={data?.Customers}
                  columnDef={columnDef}
                  height="70vh"
               />
            )}
         </div>
         {/* <div className="overflow-x-auto w-full border rounded-lg bg-white dark:bg-dark pt-3">
            <div className="flex items-center w-full gap-2 mb-3 px-2 sm:px-5 ">
               <div className="py-1 px-2 pl-4 w-full flex items-center justify-between border border-gray-300 text-[#4F5366] bg-white rounded-lg">
                  <div className="flex items-center">
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5 mr-3 [text-[#4F5366]"
                     >
                        <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                     </svg>
                     <input
                        type="text"
                        className="w-full bg-white outline-none placeholder:font-thin"
                        placeholder="Search orders"
                     />
                  </div>
               </div>
               <button className="border rounded-l rounded-md px-3 py-1 flex justify-center items-center border-gray-300 text-gray-600 dark:text-white">
                  <svg
                     viewBox="0 0 20 20"
                     className="Polaris-Icon__Svg_375hu w-[15px] mr-1"
                     focusable="false"
                     aria-hidden="true"
                  >
                     <path d="M5.293 2.293a.997.997 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.414l-1.293-1.293v7.586a1 1 0 1 1-2 0v-7.586l-1.293 1.293a1 1 0 0 1-1.414-1.414l3-3zm7.707 4.707a1 1 0 0 1 2 0v7.585l1.293-1.292a.999.999 0 1 1 1.414 1.414l-3 3a.997.997 0 0 1-1.414 0l-3-3a.997.997 0 0 1 0-1.414.999.999 0 0 1 1.414 0l1.293 1.292v-7.585z"></path>
                  </svg>
                  Short
               </button>
            </div>
            <div className="overflow-x-auto pt-3">
               <table className="relative table table-compact w-full dark:text-dark">
                  <thead className="sticky top-0 w-full left-0">
                     <tr>
                        <th>
                           <label>
                              <input
                                 type="checkbox"
                                 className="checkbox w-5 h-5"
                              />
                           </label>
                        </th>
                        <th className="text-xs">Customer name</th>
                        <th className="text-xs">Email subscription</th>
                        <th className="text-xs">Location</th>
                        <th className="text-xs">Orders</th>
                        <th className="text-xs">Spent</th>
                        <th className="text-xs py-4">view</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <th>
                           <label>
                              <input
                                 type="checkbox"
                                 className="checkbox w-5 h-5"
                              />
                           </label>
                        </th>
                        <td className="text-xs">mitaly akter</td>
                        <td className="text-sm">
                           <span className="bg-[#aee9d1] py-1 px-3 rounded-full">
                              subscribed
                           </span>
                        </td>
                        <td className="text-xs">dhaka,bangladesh</td>
                        <td className="text-xs">0 orders</td>
                        <td className="text-xs">৳0.00</td>
                        <td className="text-xs">
                           <Link to="/customer/id" className="text">
                              <FaEye></FaEye>
                           </Link>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div> */}
      </div>
   )
}

export default AllCustomers
