import React from 'react';

const Timeline = () => {
    return (
        <div className='mt-7'>
            <div className='flex justify-between border-b border-gray-300 dark:border-gray-500 dark:text-white pb-3 my-3'>
                <h3>Timeline</h3>
                <div className='flex items-center'>
                    <input type="checkbox" id='update' className='mr-2 cursor-pointer' />
                    <label htmlFor="update" id='update' className='cursor-pointer'>Show comments</label>
                </div>
            </div>
            <div>
                <form action="" className='dark:text-white'>
                    <div className='flex flex-col w-full'>
                        <input type="text" className='w-full px-3 py-3 outline-none bg-white dark:text-dark border-b rounded-lg' placeholder='Leave a comment...' />
                    </div>
                    <div className='flex justify-between items-center py-2 bg-white dark:text-white px-3 rounded-lg'>
                        <div className='flex space-x-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" role="img" className="Polaris-Icon__Svg_375hu w-5" fill='#7e7676cc' focusable="false" aria-hidden="true">
                                <path d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0m0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8M7 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2m6-2a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-.696 4.281c-.03.03-.786.72-2.304.72-1.497 0-2.252-.67-2.303-.718a1 1 0 0 0-1.404 1.424C6.425 12.84 7.653 14 10 14c2.346 0 3.575-1.16 3.707-1.293a.993.993 0 0 0 .005-1.397 1.006 1.006 0 0 0-1.408-.029"></path></svg>
                            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu w-5" fill='#7e7676cc' focusable="false" aria-hidden="true">
                                <path d="M10 13c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-13c-5.514 0-10 4.486-10 10s4.486 10 10 10a1 1 0 0 0 0-2c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8v1c0 .827-.673 1.5-1.5 1.5s-1.5-.673-1.5-1.5v-1c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5c1.531 0 2.887-.707 3.805-1.795a3.477 3.477 0 0 0 2.695 1.295c1.93 0 3.5-1.57 3.5-3.5v-1c0-5.514-4.486-10-10-10z"></path></svg>
                            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu w-5" fill='#7e7676cc' focusable="false" aria-hidden="true">
                                <path fillRule="evenodd" d="M7.992 2.124a1 1 0 1 0-1.984-.248l-.39 3.124h-3.618a1 1 0 0 0 0 2h3.367l-.75 6h-2.617a1 1 0 1 0 0 2h2.367l-.36 2.876a1 1 0 1 0 1.985.248l.39-3.124h5.985l-.36 2.876a1 1 0 0 0 1.985.248l.39-3.124h3.618a1 1 0 1 0 0-2h-3.367l.75-6h2.617a1 1 0 1 0 0-2h-2.367l.36-2.876a1 1 0 1 0-1.985-.248l-.39 3.124h-5.986l.36-2.876zm4.625 10.876.75-6h-5.984l-.75 6h5.984z">
                                </path>
                            </svg>
                            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg_375hu w-5" fill='#7e7676cc' focusable="false" aria-hidden="true">
                                <path d="M5.243 20a5.228 5.228 0 0 1-3.707-1.533 5.213 5.213 0 0 1-1.536-3.708c0-1.402.546-2.719 1.536-3.708l9.515-9.519a5.25 5.25 0 0 1 8.553 1.7 5.21 5.21 0 0 1 .396 2.008 5.208 5.208 0 0 1-1.535 3.708l-4.258 4.26a3.124 3.124 0 0 1-5.092-1.012 3.098 3.098 0 0 1-.236-1.196c0-.835.324-1.619.914-2.208l4.5-4.501a1 1 0 1 1 1.414 1.414l-4.5 4.501a1.112 1.112 0 0 0-.328.794 1.114 1.114 0 0 0 1.121 1.12c.297 0 .582-.118.793-.327l4.258-4.26a3.223 3.223 0 0 0 .949-2.293c0-.866-.337-1.681-.949-2.293a3.248 3.248 0 0 0-4.586 0l-9.515 9.518a3.224 3.224 0 0 0-.95 2.295c0 .866.338 1.68.95 2.293a3.248 3.248 0 0 0 4.586 0l1.757-1.758a1 1 0 1 1 1.414 1.414l-1.757 1.758a5.236 5.236 0 0 1-3.707 1.533z"></path>
                            </svg>
                        </div>
                        <button type='submit' className='bg-gray-200 dark:bg-gray-600 dark:text-white px-4 py-2 rounded-md text-sm font-semibold text-slate-600'>Post</button>
                    </div>
                    <div className='text-right mt-2 text-sm'>
                        <span>Your customer can see this reason</span>
                    </div>
                </form>
                <div className="">
                    <div className="container max-w-5xl px-1 sm:px-4 py-12 mx-auto">
                        <div className="dark:text-white">
                            <div className="relative col-span-12 px-1 sm:px-4 space-y-6 sm:col-span-9">
                                <div className="text-[15px] col-span-12 space-y-12 relative sm:px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-gray-300">
                                    <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-3 sm:before:h-3 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-[#00a0ac]">
                                        <div className='flex justify-between gap-2'>
                                            <details>
                                                <summary className="py-2 outline-none cursor-pointer">
                                                    You updated the customer for this order.
                                                </summary>
                                                <div className="px-4 pb-4 text-sm">
                                                    <span className='text-black dark:text-darkText'>Added</span>
                                                    <p>mitaly akter</p>
                                                </div>
                                            </details>
                                            <span>1:43 PM</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-3 sm:before:h-3 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-[#00a0ac]">
                                        <div className='flex justify-between gap-2'>
                                            <details>
                                                <summary className="py-2 outline-none cursor-pointer">
                                                    You manually marked this order as paid.
                                                </summary>
                                                <div className="px-4 pb-4 text-sm space-y-3">
                                                    <div>
                                                        <span className='text-black dark:text-darkText'>Order</span>
                                                        <p>#1023</p>
                                                    </div>
                                                    <div>
                                                        <span className='text-black dark:text-darkText'>Message</span>
                                                        <p>Marked the manual payment as received</p>
                                                    </div>
                                                    <div>
                                                        <span className='text-black dark:text-darkText'>Amount</span>
                                                        <p>৳20.00</p>
                                                    </div>
                                                    <div>
                                                        <span className='text-black dark:text-darkText'>Status</span>
                                                        <p>success</p>
                                                    </div>
                                                    <div>
                                                        <span className='text-black dark:text-darkText'>Type</span>
                                                        <p>sale</p>
                                                    </div>
                                                    <div>
                                                        <span className='text-black dark:text-darkText'>Created</span>
                                                        <p>Dec 31, 2022, 6:53 pm</p>
                                                    </div>
                                                </div>
                                            </details>
                                            <span>1:43 PM</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-3 sm:before:h-3 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-gray-400">
                                        <div className='flex justify-between gap-2'>
                                            <details>
                                                <summary className="py-2 outline-none cursor-pointer">
                                                    1 line item oversold on this order.
                                                </summary>
                                                <div className="px-0 sm:px-4 pb-4 text-[15px]">
                                                    <p>humayon Ahmed - large</p>
                                                </div>
                                            </details>
                                            <span>1:43 PM</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-3 sm:before:h-3 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-[#00a0ac]">
                                        <p>You created this order for mitaly akter from draft order <span className='text-[#576CBC]'>#D26.</span></p>
                                        <p>Taxes are calculated by</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Timeline;