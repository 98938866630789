// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

// Define a service using a base URL and expected endpoints
export const newsletterApi = createApi({
   reducerPath: "newsletterApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["Newsletter"],
   endpoints: (builder) => ({
      getNewsletters: builder.query({
         query: () => `api/newsletter`,
         providesTags: ["Newsletter"],
      }),
      createNewsletter: builder.mutation({
         query: (value) => ({
            url: `api/newsletter`,
            method: "POST",

            body: { email: value },
         }),
         invalidatesTags: ["Newsletter"],
      }),
      deleteNewsletter: builder.mutation({
         query: (id) => ({
            url: `api/newsletter/${id}`,
            method: "DELETE",
         }),
         invalidatesTags: ["Newsletter"],
      }),
   }),
})

export const {
   useGetNewslettersQuery,
   useCreateNewsletterMutation,
   useDeleteNewsletterMutation,
} = newsletterApi
