import React, { useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { DashboardContext } from "../../context/DashboardProvider"
import { useGetDispatchOrdersQuery } from "../../services/order"
import moment from "moment"
import { Exportfile } from "../../utils/Exportfile"
import AgGridTable from "../../components/AgGridTable"
import { userAccount } from "../../utils/utils"
import PrintPackagingSlip from "../../components/PrintPackging/PrintPackagingSlip"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "react-toastify/dist/ReactToastify.css"

const AllDispatch = () => {
   const [dateRange, setDateRange] = useState([])
   const [startDate, endDate] = dateRange
   const { data: result } = useGetDispatchOrdersQuery(userAccount)
   const [orderAt, setOrderAt] = useState("updatedAt")
   const [shippingMethod, setShippingMethod] = useState(null)

   const dispatchOrder = result
      ?.filter((order) => {
         const orderDate =
            order?.[`${orderAt}`] && moment(order?.[`${orderAt}`])
         const startDateInfo = startDate && moment(startDate)
         const endDateInfo = endDate && moment(endDate)

         if (!startDate && !endDate) {
            return true
         } else if (
            orderDate &&
            startDateInfo &&
            endDateInfo &&
            orderDate.isSameOrAfter(startDateInfo.startOf("day")) &&
            orderDate.isSameOrBefore(endDateInfo.endOf("day"))
         ) {
            return true
         }
         return false
      })
      .filter((order) =>
         shippingMethod
            ? order?.paymentShipping?.shipping_carrier === shippingMethod
            : true
      )

   const navigate = useNavigate()
   const [csData, setCsData] = useState([])
   const { employeeInfo } = useContext(DashboardContext)
   const permission = employeeInfo?.find((data) => data?.permission)

   // DOWNLOAD
   const handleDispatchExcelSheet = async (shippingMethod) => {
      setShippingMethod(shippingMethod)
      await getExcelSheet(shippingMethod)
   }

   // EXCEL SHEET DATA
   const getExcelSheet = async (shippingMethod) => {
      let dispatchOrderForDownload = []

      dispatchOrder?.forEach((order) => {
         const productNames = order?.orderedProducts?.map(
            (item) => `#${item?.title}`
         )
         const productBarcode = order?.orderedProducts?.map(
            (item) => `${item?.barcode}`
         )
         const customerName = order?.customer?.contactInformation?.name

         const address = order?.customer?.shippingAddress?.address
         const mobile = order?.customer?.shippingAddress?.phone

         let redata
         if (shippingMethod === "Sundarban Courier") {
            redata = {
               Name: customerName,
               "Product Name": `${[...productNames]}`,
               Barcode: `${[...productBarcode]}`,
               Address: address,
               Mobile: mobile,
            }
         } else {
            redata = {
               PackageInfo: `${[...productBarcode]}`,
               PackageDescription: order?.orderedProducts?.length,
               note: order?.notes || "N/A",
               CollectionAmount: order?.totalPrice,
               ActualAmount:
                  order?.totalPrice - order?.paymentShipping?.shipping_cost,
               CustomerName: customerName,
               CustomerMobile: mobile,
               CustomerAddress: address,
               District: order?.customer?.shippingAddress?.district,
               DeliveryMethod: "Regular Delivery ২-৩",
               Weight: 0.5,
            }
         }
         dispatchOrderForDownload.push(redata)
      })
      setCsData(dispatchOrderForDownload)
   }

   const columnDef = [
      {
         headerName: "ORDER ID",
         field: "orderId",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/dispatch/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params?.data?.orderId}
               </Link>
            )
         },
      },
      {
         headerName: "ORDER DATE",
         field: "date",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/dispatch/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {moment
                     .utc(params.data.createdAt)
                     .utcOffset("+06:00")
                     .format("DD-MM-YYYY")}
               </Link>
            )
         },
      },
      {
         headerName: "DISPATCH DATE",
         field: "date",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/dispatch/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {moment
                     .utc(params.data.updatedAt)
                     .utcOffset("+06:00")
                     .format("DD-MM-YYYY")}
               </Link>
            )
         },
      },
      {
         headerName: "ORDER STATUS",
         field: "orderStatus",
         cellRenderer: function (params) {
            return (
               <div
                  onClick={() => navigate(`/dispatch/${params?.data?._id}`)}
                  className="cursor-pointer px-4"
               >
                  <span
                     className={`px-4 py-1 !no-underline text-center rounded-full shadow-md capitalize text-sm font-medium
                       ${
                          params.data?.orderStatus === "dispatched"
                             ? "bg-[#D2F99C] text-[#5e8032]"
                             : "bg-[#DFB3F7] text-[#623c77]"
                       }`}
                  >
                     {params.data?.orderStatus || "-"}
                  </span>
               </div>
            )
         },
      },
      {
         headerName: "CUSTOMER",
         field: "customer.contactInformation.name",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/dispatch/${params?.data?._id}`}
                  className="px-4 block !no-underline capitalize"
               >
                  {params.data?.customer?.contactInformation?.name}
               </Link>
            )
         },
      },
      {
         headerName: "CUSTOMER PHONE",
         field: "customer.shippingAddress.phone",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/dispatch/${params?.data?._id}`}
                  className="px-4 block !no-underline capitalize"
               >
                  {params.data?.customer?.shippingAddress.phone}
               </Link>
            )
         },
      },
      {
         headerName: "ORDER TAKEN BY",
         field: "orderTakenBy",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/dispatch/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.orderTakenBy || "Website"}
               </Link>
            )
         },
      },
      {
         headerName: "TOTAL",
         field: "totalPrice",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/dispatch/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.totalPrice || "-"}
               </Link>
            )
         },
      },
      {
         headerName: "PAYMENT STATUS",
         field: "isPaid",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/dispatch/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.isPaid === true ? "Paid" : "Not paid"}
               </Link>
            )
         },
      },
      {
         headerName: "ORDER TYPE",
         field: "orderType",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/dispatch/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.orderType || "-"}
               </Link>
            )
         },
      },

      {
         headerName: "PAYMENT METHOD",
         field: "paymentMethod",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/dispatch/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.paymentMethod}
               </Link>
            )
         },
      },
      {
         headerName: "SHIPPING BY",
         field: "paymentShipping.shipping_carrier",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/dispatch/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.paymentShipping?.shipping_carrier || "-"}
               </Link>
            )
         },
      },
      {
         headerName: "SHIPPING TYPE",
         field: "paymentShipping.shipping_type",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/dispatch/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.paymentShipping?.shipping_type} (
                  {params.data?.paymentShipping?.shipping_cost})
               </Link>
            )
         },
      },
      {
         headerName: "ACTION",
         field: "_id",
         cellRenderer: (params) => (
            <div className="text-[15px] z-50 px-4">
               {permission?.permission !== "View Only" ? (
                  <ul>
                     <li>
                        <PrintPackagingSlip data={params.data} icon={true} />
                     </li>
                  </ul>
               ) : (
                  "N/A"
               )}
            </div>
         ),
      },
   ]

   return (
      <section className="p-2 mb-5 m-2 sm:m-5 ">
         <div className="flex flex-col sm:flex-row gap-3 justify-between items-center pb-5 dark:text-white">
            <h3 className="font-semibold text-xl">All Dispatch</h3>
            <div className="space-x-2 flex gap-2">
               <select
                  onChange={(e) => {
                     setOrderAt(e.target.value)
                     setDateRange([])
                  }}
                  className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 outline-none cursor-pointer hover:shadow-md duration-200"
               >
                  <option value="createdAt">Dispatch/On hold</option>
                  <option value="dispatchedAt">Dispatched</option>
                  <option value="dispatchOnHoldAt">Dispatch On Hold</option>
               </select>
               <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                     setDateRange(update)
                  }}
                  isClearable={true}
                  className="date-picker  shadow-md hover:shadow-lg"
                  placeholderText="Select Date"
                  dateFormat={"dd/MM/yyyy"}
               />
               {permission && permission?.permission !== "View Only" && (
                  <>
                     <select
                        value={shippingMethod}
                        onChange={(e) =>
                           handleDispatchExcelSheet(e.target.value)
                        }
                        className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 outline-none cursor-pointer"
                     >
                        <option value="">All Shipping Carrier</option>
                        <option value="Delivery Tiger">Delivery Tiger</option>
                        <option value="Redx">Redx</option>
                        <option value="Sundarban Courier">
                           Sundarban Courier
                        </option>
                     </select>

                     <div>
                        <Exportfile
                           csvData={csData}
                           fileName={shippingMethod}
                        />
                     </div>
                  </>
               )}{" "}
            </div>
         </div>
         <div className="w-full border rounded-lg p-5 bg-white dark:bg-dark dark:text-white ">
            <div className="flex items-center gap-5 pb-3 ">
               <h3 className="text-sm sm:text-base md:text-lg text-[#9191A1]">
                  Total Orders: {dispatchOrder?.length}
               </h3>
            </div>

            <div className="flex gap-10 mb-3"></div>
            {dispatchOrder?.length === 0 ? (
               <div className="text-red-600 flex items-center justify-center  w-full h-[60vh]">
                  No Products Available!
               </div>
            ) : (
               <AgGridTable
                  data={dispatchOrder}
                  columnDef={columnDef}
                  height="70vh"
               />
            )}
         </div>
      </section>
   )
}

export default AllDispatch
