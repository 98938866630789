import React from "react"
import { useContext } from "react"
import { DashboardContext } from "../../context/DashboardProvider"
import InventoryDrawer from "./MenuItem/Inventory/InventoryDrawer"
import Search from "./MenuItem/Search/Search"
import { userAccount } from "../../utils/utils"
import EvertaleDrawer from "../evertaleDashboard/EvertaleDrawer"

const Submenu = () => {
   const { sidebarOpen, setSidebarOpen, sidebarContent, employeeInfo } =
      useContext(DashboardContext)

   return (
      <div
         className={`min-h-screen bg-white dark:bg-dark overflow-auto ${
            employeeInfo?.length == 0 && "hidden"
         }`}
      >
         <div className={`relative`}>
            {/* <div className={ `py-7 mx-4 flex justify-center items-center border-b text-primary dark:text-[white] ${sidebarContent === "search" && "hidden"}` }>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className='w-5 h-5 mr-3 text-primary dark:text-white'>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                    <input type="text" className='w-full bg-transparent outline-none' placeholder='Search Folder' />
                </div> */}
            <button
               onClick={() => setSidebarOpen(!sidebarOpen)}
               className="absolute -right-5 top-[68px] mr-5  bg-[#ffffff] dark:bg-gray-600 dark:text-white rounded-full border border-gray-400 z-50"
            >
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 16 16"
                  className="Chevron__Svg-sc-zctemm-0 ciFKgs w-5 h-5"
               >
                  <path
                     fill="currentColor"
                     fillRule="evenodd"
                     clipRule="evenodd"
                     d="M6.07303 11.9509C6.25207 12.118 6.48481 12.2 6.71446 12.2C6.94411 12.2 7.17686 12.118 7.35589 11.9509L10.9271 8.6176C11.291 8.27787 11.291 7.72227 10.9271 7.38254L7.35589 4.04928C6.99978 3.71689 6.42914 3.71689 6.07303 4.04928C5.70905 4.389 5.70905 4.94461 6.07303 5.28434L8.9826 8.00007L6.07303 10.7158C5.70905 11.0555 5.70905 11.6111 6.07303 11.9509Z"
                  ></path>
               </svg>
            </button>
         </div>
         <div className={`px-5 ${!sidebarOpen && "hidden duration-500"}`}>
            {sidebarContent === "inventory" ? (
               <>
                  {userAccount === "evertale" ? (
                     <EvertaleDrawer />
                  ) : (
                     <InventoryDrawer />
                  )}
               </>
            ) : (
               sidebarContent === "search" && <Search></Search>
            )}
         </div>
      </div>
   )
}

export default Submenu
