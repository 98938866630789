import React from "react"
import Timeline from "../../components/Timeline/Timeline"
import { Link, useParams } from "react-router-dom"
import { useGetEmployeeQuery } from "../../services/employee"
import moment from "moment"

const SingleEmployee = () => {
   const { id } = useParams()
   const { data } = useGetEmployeeQuery(id)

   return (
      <div className="p-2 m-2 sm:m-5 dark:text-white w-12/12 sm:w-10/12 md:w-8/12">
         {/* heading */}
         <div className="flex items-center order-2 sm:order-1">
            <Link
               to="/employee"
               className="text-[#5c5f62] p-2 mr-2 sm:mr-3 rounded-md border border-gray-300"
            >
               <svg
                  viewBox="0 0 20 20"
                  className="Polaris-Icon__Svg_375hu w-5 h-5"
                  focusable="false"
                  fill="gray"
                  aria-hidden="true"
               >
                  <path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path>
               </svg>
            </Link>
            <div>
               <p className="text-sm">
                  {" "}
                  {moment
                     .utc(data?.employee?.createdAt)
                     .utcOffset("+06:00")
                     .format("DD MMMM YYYY, h:mm a")}
               </p>
            </div>
         </div>

         <div className="bg-white dark:bg-dark dark:border-gray-600 w-full border rounded-lg p-5 py-10 shadow mt-5">
            <div className="w-[150px] h-[150px] mb-5">
               <img
                  src={
                     data?.employee?.image ||
                     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkiIFjCOZ-mMeqxd2ryrneiHedE8G9S0AboA&usqp=CAU"
                  }
                  alt=""
                  className="w-full h-full object-contain"
               />
            </div>
            <div className="text-[15px] space-y-3">
               <h3>Employee ID : {data?.employee?.barcode || "N/A"}</h3>
               <h3>Name : {data?.employee?.name || "N/A"}</h3>
               <h3>Email : {data?.employee?.email || "N/A"}</h3>
               <h3>Address : {data?.employee?.address || "N/A"}</h3>
               <h3>
                  Contact Number : {data?.employee?.contactNumber || "N/A"}
               </h3>
               <h3>NID : {data?.employee?.nid || "N/A"}</h3>
               <h3>Dath Of Birth : {data?.employee?.dob || "N/A"}</h3>
               <h3>Blood Group : {data?.employee?.blood || "N/A"}</h3>
               <h3>Joining Date : {data?.employee?.joiningDate || "N/A"}</h3>
               <h3>
                  Employment Status :{" "}
                  {data?.employee?.employmentStatus || "N/A"}
               </h3>
               <h3>
                  Resigning Date : {data?.employee?.resigningDate || "N/A"}
               </h3>
               <h3>Designation : {data?.employee?.designation || "N/A"}</h3>
               <h3>
                  Emergency Contact Name :{" "}
                  {data?.employee?.emergencyContactName || "N/A"}
               </h3>
               <h3>
                  Emergency Contact Number :{" "}
                  {data?.employee?.emergencyContactNumber || "N/A"}
               </h3>
               <h3>
                  Relationship With The Person :{" "}
                  {data?.employee?.emergencyRelationshipInfo || "N/A"}
               </h3>
               <h3>Bank Name : {data?.employee?.bankName || "N/A"}</h3>
               <h3>
                  Bank A/C Number : {data?.employee?.bankAccountNumber || "N/A"}
               </h3>
               <h3>
                  Relationship With The Person :{" "}
                  {data?.employee?.bankRelationship || "N/A"}
               </h3>
            </div>
         </div>
         <div>
            <Timeline></Timeline>
         </div>
      </div>
   )
}

export default SingleEmployee
