import React, { useEffect } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"
import { useGetOrderByIdQuery } from "../../../services/order"
import { addProduct } from "../../../services/order/returnProduct"
import {
   addProducts,
   clearProduct,
} from "../../../services/order/orderProductSlice"
import moment from "moment"
import { Button } from "@material-tailwind/react"
import { useUpdateOrderMutation } from "../../../services/order"
import { useDispatch } from "react-redux"
import { enqueueSnackbar } from "notistack"
import OrderCustomerInfo from "../../../components/Customers/OrderCustomerInfo"
import CustomSpinner from "../../../components/CustomSpinner"
import SingleOrderProduct from "../../../components/SingleOrderProduct"
import { useGetEmployeeQuery } from "../../../services/employee"
import { employeeInfoFunc } from "../../../utils/utils"
import PrintPackagingSlip from "../../../components/PrintPackging/PrintPackagingSlip"

const SingleOrder = () => {
   const navigate = useNavigate()
   const { id } = useParams()
   const dispatch = useDispatch()

   const { data: order, isLoading } = useGetOrderByIdQuery(id)
   const [updateOrder] = useUpdateOrderMutation()

   const employeeFromLocal = JSON.parse(localStorage.getItem("ich-user"))
   const { data: user } = useGetEmployeeQuery(employeeFromLocal?.id)
   const permission = employeeInfoFunc(user, "orders")

   const updateStatus = (value) => {
      updateOrder({ id: order?._id, ...value })
   }

   useEffect(() => {
      dispatch(addProduct(order?.orderedProducts || []))
   }, [dispatch, order?.orderedProducts])

   // EDIT ORDER

   const handleEditOrder = (product) => {
      dispatch(clearProduct())
      dispatch(addProducts(product?.orderedProducts))

      navigate(`/update/${product?._id}`, {
         state: {
            route: "update",
         },
      })
   }

   if (isLoading) {
      return <CustomSpinner />
   }
   return (
      <div className="p-2 mb-5 border-b m-5">
         <div className="py-3 flex items-center justify-between w-full">
            <div className="flex items-start">
               <Link
                  to="/orders"
                  className="text-[#5c5f62] p-2 mr-3 rounded-md border border-gray-300 cursor-pointer"
               >
                  <svg
                     viewBox="0 0 20 20"
                     className="Polaris-Icon__Svg_375hu w-5 h-5"
                     focusable="false"
                     aria-hidden="true"
                  >
                     <path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path>
                  </svg>
               </Link>
               <div className="dark:text-white space-y-2">
                  <h3 className="font-semibold text-xl">#{order?.orderId}</h3>
                  <p className="text-sm">
                     Date:{" "}
                     {moment
                        .utc(order?.createdAt)
                        .utcOffset("+06:00")
                        .format("DD MMMM YYYY, h:mm a")}
                  </p>
               </div>
            </div>
            {permission && permission?.permission !== "View Only" && (
               <div className="dropdown dropdown-end">
                  <label tabIndex={0} className="m-1 flex cursor-pointer">
                     More actions
                     <svg
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg_375hu w-5"
                        focusable="false"
                        aria-hidden="true"
                     >
                        <path d="M13.098 8h-6.196c-.751 0-1.172.754-.708 1.268l3.098 3.432c.36.399 1.055.399 1.416 0l3.098-3.433c.464-.513.043-1.267-.708-1.267Z"></path>
                     </svg>
                  </label>
                  <ul
                     tabIndex={0}
                     className="dropdown-content p-2 shadow bg-base-100  dark:text-dark dark:bg-dark rounded-box w-60"
                  >
                     <li className="py-3 px-3 rounded-md hover:bg-gray-100 dark:text-white hover:dark:text-dark">
                        <button
                           onClick={() => handleEditOrder(order)}
                           className="flex items-center"
                        >
                           <svg
                              viewBox="0 0 20 20"
                              className="Polaris-Icon__Svg_375hu w-4 mr-4 "
                              focusable="false"
                              aria-hidden="true"
                           >
                              <path d="M7.5 2a1.5 1.5 0 0 0-1.5 1.5v9.5a1 1 0 0 0 1 1h9.5a1.5 1.5 0 0 0 1.5-1.5v-9a1.5 1.5 0 0 0-1.5-1.5h-9zm-4 4h.5v10h10v.5a1.5 1.5 0 0 1-1.5 1.5h-9a1.5 1.5 0 0 1-1.5-1.5v-9a1.5 1.5 0 0 1 1.5-1.5z"></path>
                           </svg>
                           <h3 className="text-sm capitalize">edit order</h3>
                        </button>
                     </li>
                     <li className="py-3 px-3 rounded-md hover:bg-gray-100 dark:text-white hover:dark:text-dark">
                        <Link
                           to={`/order-invoice/${order?._id}`}
                           className="flex items-center"
                        >
                           <svg
                              viewBox="0 0 20 20"
                              className="Polaris-Icon__Svg_375hu w-4 mr-4 "
                              focusable="false"
                              aria-hidden="true"
                           >
                              <path d="M7.5 2a1.5 1.5 0 0 0-1.5 1.5v9.5a1 1 0 0 0 1 1h9.5a1.5 1.5 0 0 0 1.5-1.5v-9a1.5 1.5 0 0 0-1.5-1.5h-9zm-4 4h.5v10h10v.5a1.5 1.5 0 0 1-1.5 1.5h-9a1.5 1.5 0 0 1-1.5-1.5v-9a1.5 1.5 0 0 1 1.5-1.5z"></path>
                           </svg>
                           <h3 className="text-sm capitalize">Order Invoice</h3>
                        </Link>
                     </li>
                     <li>
                        <PrintPackagingSlip data={order} icon={false} />
                     </li>
                     <li className="py-3 px-3 rounded-md hover:bg-gray-100 dark:text-white hover:dark:text-dark">
                        <button
                           onClick={() => {
                              updateStatus({
                                 orderStatus: "canceled",
                                 canceledAt: Date.now(),
                              })
                              enqueueSnackbar("Order canceled", {
                                 variant: "info",
                              })
                              window.location.href = "/canceled"
                           }}
                           size="md"
                           className=" hover:bg-gray-100 flex gap-3"
                        >
                           <svg
                              width="20px"
                              height="20px"
                              stroke-width="1.5"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              color="#000000"
                           >
                              <path
                                 d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
                                 stroke="#000000"
                                 stroke-width="1.5"
                                 stroke-linecap="round"
                                 stroke-linejoin="round"
                              ></path>
                           </svg>
                           Canceled
                        </button>
                     </li>
                  </ul>
                  {/* <CancelOrder ></CancelOrder> */}
               </div>
            )}
         </div>
         <div className="grid grid-cols-3 gap-4 ">
            <div className="col-span-2 relative">
               <SingleOrderProduct order={order} />
            </div>
            <div className="col-span-1 ">
               <div className=" text-sm px-5 mb-4 border-b bg-white dark:bg-dark dark:text-white dark:border-gray-600 w-full border rounded-lg p-5 shadow">
                  <h3 className="font-semibold m-0">Notes</h3>
                  <p className="m-0">{order?.notes}</p>
               </div>
               <OrderCustomerInfo customer={order?.customer} />
               {permission && permission?.permission !== "View Only" && (
                  <div className="flex items-center justify-end gap-3 mt-10">
                     {order?.orderStatus === "approved" ||
                     order?.orderStatus === "order on hold" ||
                     order?.orderStatus === "dispatch on hold" ? (
                        <>
                           <Button
                              onClick={() => {
                                 updateStatus({
                                    orderStatus: "dispatched",
                                    dispatchedAt: Date.now(),
                                 })
                                 enqueueSnackbar("Order Ready to dispatch", {
                                    variant: "info",
                                 })
                                 window.location.href = "/dispatch"
                              }}
                              size="md"
                              className="bg-custom_green hover:bg-custom_lightGreen"
                           >
                              Ready to dispatch
                           </Button>
                           {order?.orderStatus === "approved" && (
                              <Button
                                 onClick={() => {
                                    updateStatus({
                                       orderStatus: "order on hold",
                                       orderOnHoldAt: Date.now(),
                                    })
                                    enqueueSnackbar("Order On hold", {
                                       variant: "info",
                                    })
                                    window.location.href = "/orders"
                                 }}
                                 size="md"
                                 className="bg-red-800 hover:bg-red-900"
                              >
                                 Order On hold
                              </Button>
                           )}
                        </>
                     ) : order?.orderStatus?.includes("refund") ||
                       order?.orderStatus?.includes("return") ? (
                        <Button
                           onClick={() => handleEditOrder(order)}
                           size="md"
                           className="bg-custom_green hover:bg-custom_lightGreen "
                        >
                           Re Attempt
                        </Button>
                     ) : (
                        <Button
                           size="md"
                           className="bg-custom_green hover:bg-custom_lightGreen cursor-not-allowed"
                        >
                           {order?.orderStatus}
                        </Button>
                     )}
                  </div>
               )}
            </div>
         </div>
      </div>
   )
}

export default SingleOrder
