import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const academicSchoolApi = createApi({
   reducerPath: "academicSchoolApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["AcademicSchool"],
   endpoints: (builder) => ({
      getAcademicSchools: builder.query({
         query: (arg) => {
            return `api/academic-school/all`
         },
         providesTags: ["AcademicSchool"],
      }),
      createAcademicSchool: builder.mutation({
         query: (value) => ({
            url: "api/academic-school",
            method: "POST",
            body: value,
         }),
         invalidatesTags: ["AcademicSchool"],
      }),
      editAcademicSchool: builder.mutation({
         query: ({ id, ...rest }) => ({
            url: `api/academic-school/${id}`,
            method: "PATCH",

            body: rest,
         }),
         invalidatesTags: ["AcademicSchool"],
      }),
      deleteAcademicSchool: builder.mutation({
         query: (id) => ({
            url: `api/academic-school/${id}`,

            method: "DELETE",
         }),
         invalidatesTags: ["AcademicSchool"],
      }),
   }),
})

export const {
   useGetAcademicSchoolsQuery,
   useCreateAcademicSchoolMutation,
   useDeleteAcademicSchoolMutation,
   useEditAcademicSchoolMutation,
} = academicSchoolApi
