import { createBrowserRouter } from "react-router-dom"
import Main from "../layout/Main"
import Inventory from "../pages/Inventory/Inventory"
import ReportItemDetails from "../pages/Reports/ReportItemDetails"
import Reports from "../pages/Reports/Reports"
import AllOrders from "../pages/Orders/AllOrders/AllOrders"
import SingleOrder from "../pages/Orders/AllOrders/SingleOrder.jsx"
import DashboardPages from "../pages/DashboardPages/DashboardPages"
import Tips from "../pages/Tips/Tips"
import Notifications from "../pages/Notifications/Notifications"
import AllDrafts from "../pages/DraftOrder/AllDrafts"
import AcademicItemUpdate from "../pages/Inventory/ItemDetails/UpdateItem/AcademicItemUpdate"
import GeneralBooksItemUpdate from "../pages/Inventory/ItemDetails/UpdateItem/GeneralBooksItemUpdate"
import StationariesItemUpdate from "../pages/Inventory/ItemDetails/UpdateItem/StationariesItemUpdate"
import EditOrder from "../pages/Orders/AllOrders/Actions/EditOrder/EditOrder"
import OrderReturn from "../pages/Orders/AllOrders/Actions/OrderReturn/OrderReturn"
import AllCustomers from "../pages/Customers/AllCustomers"
import AddCustomer from "../pages/Customers/AddCustomer"
import AllDispatch from "../pages/Dispatch/AllDispatch"
import AllDelivery from "../pages/Delivery/AllDelivery"
import SingleDispatch from "../pages/Dispatch/SingleDispatch"
import SingleDelivery from "../pages/Delivery/SingleDelivery"
import SingleCustomer from "../pages/Customers/SingleCustomer"
import EditCustomer from "../pages/Customers/Actions/EditCustomer"
import AllEmployee from "../pages/Employee/AllEmployee"
import AddEmployee from "../pages/Employee/AddEmployee"
import SingleEmployee from "../pages/Employee/SingleEmployee"
import EditEmployee from "../pages/Employee/Actions/EditEmployee"
import AcademicView from "../pages/Inventory/ItemDetails/View/AcademicView"
import StationariesView from "../pages/Inventory/ItemDetails/View/StationariesView"
import GeneralBooksView from "../pages/Inventory/ItemDetails/View/GeneralBooksView"
import AllUsers from "../pages/Users/AllUsers"
import User from "../pages/Users/User"
import UserOrders from "../pages/Users/SignleUser/UserOrders/UserOrders"
import UserReviews from "../pages/Users/SignleUser/UserReviews/UserReviews"
import UserVouchers from "../pages/Users/SignleUser/UserVouchers/UserVouchers"
import UserPurchase from "../pages/Users/SignleUser/UserPurchase/UserPurchase"
import Frontend from "../pages/Users/Frontend/Frontend"
import Errorpage from "../components/Errorpage"
import PrivateRoute from "./PrivateRoute"
import Home from "../pages/Home/Home"
import Register from "../pages/user/Register"
import Login from "../pages/user/Login"
import Category from "../pages/category/category"
import CreateUpdateOrder from "../pages/Orders/Create_Update_Order"
import AllDelivered from "../pages/Delivered/AllDelivered.jsx"
import SingleDelivered from "../pages/Delivered/SingleDelivered.jsx"
import AllFullfilled from "../pages/Fullfilled/AllFullfilled.jsx"
import SingleFullfilled from "../pages/Fullfilled/SingleFullfilled.jsx"
import RefundAndReturn from "../pages/Refund&Return/RefundAndReturn"
import RefundReturnItem from "../pages/Refund&Return/RefundReturnItem"
import AcademicItems from "../pages/Inventory/ItemDetails/Items/AcademicItems.jsx"
import GeneralBooksItems from "../pages/Inventory/ItemDetails/Items/GeneralBooksItems.jsx"
import StationariesItems from "../pages/Inventory/ItemDetails/Items/StationariesItems.jsx"
import TodaysDeal from "../pages/Inventory/ItemDetails/Items/TodaysDeal.jsx"
import Trending from "../pages/Inventory/ItemDetails/Items/Trending.jsx"
import BookFair from "../pages/Inventory/ItemDetails/Items/BookFair.jsx"
import PopularNow from "../pages/Inventory/ItemDetails/Items/PopularNow.jsx"
import AddAcademicBooks from "../pages/Inventory/AddItems/AddAcademicBooks.jsx"
import AddGeneralBooks from "../pages/Inventory/AddItems/AddGeneralBooks.jsx"
import AddStationaries from "../pages/Inventory/AddItems/AddStationaries.jsx"
import AcademicSchool from "../pages/AcademicSchool/AcademicSchool.jsx"
import AccountSelect from "../pages/user/AccountSelect.jsx"
import Shipping from "../pages/Shipping/Shipping.jsx"
import Authors from "../pages/Author/Authors.jsx"
import SubWriters from "../pages/SubWriters/SubWriters.jsx"
import Brand from "../pages/Brand/Brand.jsx"
import ElectronicsItems from "../pages/Inventory/ItemDetails/Items/ElectronicsItems.jsx"
import AddElectronics from "../pages/Inventory/AddItems/AddElectronics.jsx"
import ElectronicsUpdate from "../pages/Inventory/ItemDetails/UpdateItem/ElectronicsUpdate.jsx"
import ElectronicsView from "../pages/Inventory/ItemDetails/View/ElectronicsView.jsx"
import OrderInvoice from "../pages/Orders/AllOrders/OrderInvoice.jsx"
import VerifyReviews from "../pages/VerifyReviews/VerifyReviews.jsx"
import TopBanner from "../pages/Banner/TopBanner.jsx"
import CanceledOrder from "../pages/CanceledOrder/CanceledOrder.jsx"
import SingleCanceledOrder from "../pages/CanceledOrder/SingleCanceledOrder.jsx"
import Search from "../components/Dashboard/MenuItem/Search/Search.jsx"
import Publication from "../pages/Publication/Publication.jsx"

export const router = createBrowserRouter([
   {
      path: "/",
      errorElement: <Errorpage></Errorpage>,
      element: <Main></Main>,
      children: [
         { path: "/", element: <Login /> },
         { path: "/user/auth/register", element: <Register /> },
         { path: "/user/auth/login", element: <Login /> },
         { path: "/user/account", element: <AccountSelect /> },
         { path: "/home", element: <Home /> },
         {
            path: "/inventory",
            element: (
               <PrivateRoute>
                  <Inventory />
               </PrivateRoute>
            ),
         },
         {
            path: "/academic-school",
            element: <AcademicSchool />,
         },
         {
            path: "/academic-books",
            element: <AcademicItems />,
         },
         {
            path: "/general-books",
            element: <GeneralBooksItems />,
         },
         {
            path: "/stationaries",
            element: <StationariesItems />,
         },
         {
            path: "/electronics",
            element: <ElectronicsItems />,
         },
         {
            path: "/todays-deal",
            element: <TodaysDeal />,
         },
         {
            path: "/trending-now",
            element: <Trending />,
         },
         {
            path: "/bookfair",
            element: <BookFair />,
         },
         {
            path: "/popular-now",
            element: <PopularNow />,
         },
         {
            path: "/academic-books/new",
            element: <AddAcademicBooks />,
         },
         {
            path: "/general-books/new",
            element: <AddGeneralBooks />,
         },
         {
            path: "/stationaries/new",
            element: <AddStationaries />,
         },
         {
            path: "/electronics/new",
            element: <AddElectronics />,
         },
         {
            path: "/authors",
            element: <Authors />,
         },
         {
            path: "/sub-writers",
            element: <SubWriters />,
         },
         {
            path: "/brand",
            element: <Brand />,
         },
         {
            path: "/publicaitons",
            element: <Publication />,
         },
         {
            path: "/reports",
            element: (
               <PrivateRoute>
                  <Reports />
               </PrivateRoute>
            ),
         },
         {
            path: "/top-banner",
            element: (
               <PrivateRoute>
                  <TopBanner />
               </PrivateRoute>
            ),
         },
         {
            path: "/reportItemDetails",
            element: <ReportItemDetails />,
         },

         {
            path: "/orders",
            element: (
               <PrivateRoute>
                  <AllOrders />
               </PrivateRoute>
            ),
         },
         {
            path: "/drafts",
            element: (
               <PrivateRoute>
                  <AllDrafts />
               </PrivateRoute>
            ),
         },
         {
            path: "/canceled",
            element: (
               <PrivateRoute>
                  <CanceledOrder />
               </PrivateRoute>
            ),
         },
         {
            path: "/canceled/:id",
            element: <SingleCanceledOrder />,
         },
         { path: "/createOrders", element: <CreateUpdateOrder /> },
         {
            path: "/dashboard",
            element: (
               <PrivateRoute>
                  <DashboardPages />
               </PrivateRoute>
            ),
         },
         {
            path: "/search",
            element: (
               <PrivateRoute>
                  <Search />
               </PrivateRoute>
            ),
         },
         {
            path: "/tips",
            element: (
               <PrivateRoute>
                  <Tips />
               </PrivateRoute>
            ),
         },
         { path: "/notifications", element: <Notifications /> },
         { path: "/order/:id", element: <SingleOrder /> },
         { path: "/update/:id", element: <CreateUpdateOrder /> },
         { path: "/draft/:id", element: <CreateUpdateOrder /> },
         {
            path: "/edit-academic/:id",
            element: <AcademicItemUpdate />,
         },
         {
            path: "/edit-general/:id",
            element: <GeneralBooksItemUpdate />,
         },
         {
            path: "/edit-stationary/:id",
            element: <StationariesItemUpdate />,
         },
         {
            path: "/edit-electronics/:id",
            element: <ElectronicsUpdate />,
         },

         { path: "/editOrder/:id", element: <EditOrder /> },
         { path: "/orderReturn", element: <OrderReturn /> },
         {
            path: "/customers",
            element: (
               <PrivateRoute>
                  <AllCustomers />
               </PrivateRoute>
            ),
         },
         { path: "/addCustomer", element: <AddCustomer /> },
         {
            path: "/dispatch",
            element: (
               <PrivateRoute>
                  <AllDispatch />
               </PrivateRoute>
            ),
         },
         { path: "/dispatch/:id", element: <SingleDispatch /> },
         {
            path: "/delivery",
            element: (
               <PrivateRoute>
                  <AllDelivery />
               </PrivateRoute>
            ),
         },
         { path: "/delivery/:id", element: <SingleDelivery /> },
         {
            path: "/delivered",
            element: (
               <PrivateRoute>
                  <AllDelivered />
               </PrivateRoute>
            ),
         },
         { path: "/delivered/:id", element: <SingleDelivered /> },
         {
            path: "/fullfilled",
            element: (
               <PrivateRoute>
                  <AllFullfilled />
               </PrivateRoute>
            ),
         },
         { path: "/fullfilled/:id", element: <SingleFullfilled /> },
         {
            path: "/refund-&-return",
            element: (
               <PrivateRoute>
                  <RefundAndReturn />
               </PrivateRoute>
            ),
         },
         { path: "/refund-&-return/:id", element: <RefundReturnItem /> },
         { path: "/shipping", element: <Shipping /> },
         { path: "/customer/:phone", element: <SingleCustomer /> },
         { path: "/editCustomer", element: <EditCustomer /> },
         {
            path: "/employee",
            element: (
               <PrivateRoute>
                  <AllEmployee />
               </PrivateRoute>
            ),
         },
         { path: "/addEmployee", element: <AddEmployee /> },
         { path: "/employee/:id", element: <SingleEmployee /> },
         { path: "/editEmployee/:id", element: <EditEmployee /> },
         { path: "/academic-view/:id", element: <AcademicView /> },
         {
            path: "/general-view/:id",
            element: <GeneralBooksView />,
         },
         {
            path: "/stationary-view/:id",
            element: <StationariesView />,
         },
         {
            path: "/electronics-view/:id",
            element: <ElectronicsView />,
         },
         {
            path: "/users",
            element: (
               <PrivateRoute>
                  <AllUsers />
               </PrivateRoute>
            ),
         },
         {
            path: "/reviews",
            element: <VerifyReviews />,
         },
         { path: "/users/:id", element: <User /> },
         { path: "/users/user/:id", element: <User /> },
         { path: "/user/order/:id", element: <UserOrders /> },
         { path: "/order-invoice/:id", element: <OrderInvoice /> },
         { path: "/users/reviews/:id", element: <UserReviews /> },
         {
            path: "/users/vouchers/:id",
            element: <UserVouchers />,
         },
         {
            path: "/user/purchase/:id",
            element: <UserPurchase />,
         },
         { path: "/frontend", element: <Frontend /> },
         {
            path: "/category",
            element: (
               <PrivateRoute>
                  <Category />
               </PrivateRoute>
            ),
         },
      ],
   },
])
