import React from "react"
import { useContext } from "react"
import { Outlet } from "react-router-dom"
import Navbar from "../components/Navbar"
import { DashboardContext } from "../context/DashboardProvider"
import Dashboard from "../components/Dashboard/Dashboard"
import Submenu from "../components/Dashboard/Submenu"
import { userAccount } from "../utils/utils"
import EvertaleDashboard from "../components/evertaleDashboard/EvertaleDashboard"

const IchLayout = () => {
   const {
      sidebarOpen,
      showSidebar,
      showDrawer,
      responsiveSidebar,
      employeeInfo,
   } = useContext(DashboardContext)

   return (
      <div className={`flex border-r`}>
         {userAccount === "evertale" ? <EvertaleDashboard /> : <Dashboard />}
         <div
            className={` ${
               sidebarOpen ? "w-64" : "w-6"
            } bg-white dark:bg-lightDark duration-200 inset-y-0 left-0 border-r dark:border-gray-600 relative translate-x-0 ${
               showSidebar && "relative -translate-x-0 z-50"
            } ${!showDrawer && "hidden"} ${
               !responsiveSidebar ? "hidden" : "block"
            } ${employeeInfo?.length == 0 && "hidden"}`}
         >
            <Submenu></Submenu>
         </div>
         <div
            className={`flex-1 w-full relative bg-[#f9fbfe] dark:bg-lightDark h-screen overflow-y-scroll custom-scrollbar `}
         >
            <Navbar></Navbar>
            <Outlet></Outlet>
         </div>
      </div>
   )
}

export default IchLayout
