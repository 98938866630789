import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./services/store"
import DashboardProvider from "./context/DashboardProvider"
import App from "./App"
import { SnackbarProvider } from "notistack"
import "rsuite/dist/rsuite.min.css"
import "./css/index.css"
import { Toaster } from "react-hot-toast"
const rootElement = document.getElementById("root")
const root = createRoot(rootElement)

root.render(
   <DashboardProvider>
      <Provider store={store}>
         <SnackbarProvider
            anchorOrigin={{
               vertical: "bottom",
               horizontal: "right",
            }}
         >
            <App />
         </SnackbarProvider>
         <Toaster />
      </Provider>
   </DashboardProvider>
)
