import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const academicApi = createApi({
   reducerPath: "academicApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["Academic"],
   endpoints: (builder) => ({
      getAcademics: builder.query({
         query: (arg) => {
            return `api/academic/all`
         },
         providesTags: ["Academic"],
      }),
      getAcademic: builder.query({
         query: (id) => `api/academic/one/${id}`,
         providesTags: ["Academic"],
      }),
      createAcademic: builder.mutation({
         query: (value) => ({
            url: "api/academic",
            method: "POST",
            body: value,
         }),
         invalidatesTags: ["Academic"],
      }),
      editAcademic: builder.mutation({
         query: ({ id, ...rest }) => ({
            url: `api/academic/${id}`,
            method: "PATCH",

            body: rest,
         }),
         invalidatesTags: ["Academic"],
      }),
      deleteAcademic: builder.mutation({
         query: (id) => ({
            url: `api/academic/${id}`,

            method: "DELETE",
         }),
         invalidatesTags: ["Academic"],
      }),
   }),
})

export const {
   useGetAcademicsQuery,
   useGetAcademicQuery,
   useCreateAcademicMutation,
   useDeleteAcademicMutation,
   useEditAcademicMutation,
} = academicApi
