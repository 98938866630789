import React, { useState } from "react"
import { enqueueSnackbar } from "notistack"

import {
   Button,
   Dialog,
   DialogHeader,
   DialogBody,
   DialogFooter,
} from "@material-tailwind/react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import AgGridTable from "../../components/AgGridTable"
import Select from "react-select"

import axios from "axios"
import { Avatar } from "rsuite"
import { useGetEmployeeQuery } from "../../services/employee"
import { employeeInfoFunc } from "../../utils/utils"
import {
   useCreateAcademicSchoolMutation,
   useDeleteAcademicSchoolMutation,
   useEditAcademicSchoolMutation,
   useGetAcademicSchoolsQuery,
} from "../../services/academicSchool"

const AcademicSchool = () => {
   const [name, setName] = useState("")
   const [description, setDescription] = useState("")
   const [schoolImage, setSchoolImage] = useState("")
   const [uploadSchoolImage, setUploadSchoolImage] = useState()
   const [seletedData, setSeletedData] = useState()
   const { data: academicSchools } = useGetAcademicSchoolsQuery()
   const [createAcademicSchool, { isLoading: isCreating }] =
      useCreateAcademicSchoolMutation()
   const [editAcademicSchool, { isLoading: isUpdating }] =
      useEditAcademicSchoolMutation()
   const [deleteAcademicSchool] = useDeleteAcademicSchoolMutation()
   const employeeFromLocal = JSON.parse(localStorage.getItem("ich-user"))
   const { data } = useGetEmployeeQuery(employeeFromLocal?.id)
   const permission = employeeInfoFunc(data, "inventory")
   const [selectedClass, setSelectedClass] = useState([])
   const [classType, setClassType] = useState([])

   const [open, setOpen] = React.useState(false)

   const classTypes = [
      { value: "Playgroup", label: "Playgroup" },
      { value: "Junior Playgroup", label: "Junior Playgroup" },
      { value: "Senior Playgroup", label: "Senior Playgroup" },
      { value: "Nursery", label: "Nursery" },
      { value: "Reception", label: "Reception" },
      { value: "KG 1", label: "KG 1" },
      { value: "KG 2", label: "KG 2" },
      { value: "Class 1", label: "Class 1" },
      { value: "Class 2", label: "Class 2" },
      { value: "Class 3", label: "Class 3" },
      { value: "Class 4", label: "Class 4" },
      { value: "Class 5", label: "Class 5" },
      { value: "Class 6", label: "Class 6" },
      { value: "Class 7", label: "Class 7" },
      { value: "Class 8", label: "Class 8" },
      { value: "Class 8 Edexcel", label: "Class 8 Edexcel" },
      { value: "Class 8 Cambridge", label: "Class 8 Cambridge" },
      { value: "Class 9 Edexcel", label: "Class 9 Edexcel" },
      { value: "Class 9 Cambridge", label: "Class 9 Cambridge" },
      { value: "Class 9-10", label: "Class 9-10" },
      { value: "Class 9-10 Cambridge", label: "Class 9-10 Cambridge" },
      { value: "Class 9-10 Edexcel", label: "Class 9-10 Edexcel" },
      { value: "Class 10 Edexcel", label: "Class 10 Edexcel" },
      { value: "Class 10 Cambridge", label: "Class 10 Cambridge" },
      { value: "A level", label: "A level" },
   ]

   const classTypeHandle = (e) => {
      setSelectedClass(e)
      let typeData = e.map((data) => {
         return data?.value
      })
      setClassType(typeData)
   }

   const handleOpen = (data) => {
      const classData = data?.schoolClass?.map((item) => {
         return { value: item, label: item }
      })
      setOpen(!open)
      setSeletedData(data)
      setSelectedClass(classData)
   }

   const handleFormSubmit = async (e) => {
      e.preventDefault()

      if (!name) {
         let errorMessage = !name ? "Please select School name!" : ""

         enqueueSnackbar(errorMessage, { variant: "error" })
      } else {
         try {
            await createAcademicSchool({
               name: name,
               image: uploadSchoolImage,
               description: description,
               schoolClass: classType,
            })
            enqueueSnackbar("School created", { variant: "default" })
            setName("")
            setDescription("")
            setSelectedClass([])
         } catch (error) {
            enqueueSnackbar(`Error creating School ${error}`, {
               variant: "error",
            })
            setName("")
            setDescription("")
         } finally {
         }
      }
   }

   const updateFormSubmit = async (e) => {
      e.preventDefault()

      try {
         await editAcademicSchool({
            id: seletedData?._id,
            name: name === "" ? seletedData?.name : name,
            image:
               uploadSchoolImage === ""
                  ? seletedData?.image
                  : uploadSchoolImage,
            description:
               description === "" ? seletedData?.description : description,
            schoolClass:
               classType?.length > 0 ? classType : seletedData?.schoolClass,
         })
         enqueueSnackbar("School updated", { variant: "default" })
         setOpen(!open)
         setName("")
         setDescription("")
         setSelectedClass([])
      } catch (error) {
         enqueueSnackbar("Error updating School", { variant: "error" })
         setOpen(!open)
         setName("")
         setDescription("")
         setSelectedClass([])
      }
   }
   const onschoolImageChangePdf = (e) => {
      setSchoolImage(e.target.files[0])
   }

   const onSubmitschoolImage = async (event) => {
      event.preventDefault()
      const formData = new FormData()
      formData.append("uploadImages", schoolImage)

      try {
         if (!schoolImage)
            return alert(
               "Please upload an image first then create this product!"
            )

         await axios
            .post("/api/image-upload", formData, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            })
            .then((res) => {
               setUploadSchoolImage(res.data[0])
               alert("School Image uploaded")
            })
            .catch((err) => {
               alert(err?.response?.data?.message)
            })
      } catch (err) {
         if (err.response.status === 500) {
            console.log(err)
         } else {
            console.log(err.response.data.msg)
         }
      }
   }

   const columnDef = [
      {
         headerName: "Image",
         field: "image",
         cellRenderer: (x) => (
            <Avatar src={x?.data?.image} alt={x?.data?.name} />
         ),
      },
      {
         headerName: "School Name",
         cellClass: "tableCell",
         field: "name",
      },
      {
         headerName: "School Description",
         cellClass: "tableCell",
         field: "description",
      },
      {
         headerName: "Actions",
         field: "_id",
         cellRenderer: (x) => (
            <div className="text-sm z-50 px-4">
               {permission && permission?.permission !== "View Only" ? (
                  <ul className="flex">
                     <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50">
                        <button
                           onClick={() => handleOpen(x?.data)}
                           className="flex items-center gap-3"
                        >
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 cursor-pointer text-green-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                           >
                              <path
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                                 d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              ></path>
                           </svg>
                        </button>
                     </li>
                     <li className="px-2 hover:bg-[#968a8a1e] rounded-xl bg-white py-3 z-50">
                        <button
                           onClick={() => {
                              let message = window.confirm(
                                 "Do you want to remove it?"
                              )
                              if (message === true) {
                                 deleteAcademicSchool(x?.data?._id)
                              }
                           }}
                           className="flex items-center gap-2 text-[#4F5366] text-sm"
                        >
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5 cursor-pointer text-red-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                           >
                              <path
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                                 d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              ></path>
                           </svg>
                        </button>
                     </li>
                  </ul>
               ) : (
                  <p className="py-3">N/A</p>
               )}
            </div>
         ),
      },
   ]

   return (
      <div className="p-4  bg-slate-800 md:bg-white  dark:bg-dark text-black ">
         <form onSubmit={handleFormSubmit}>
            <h2 className="text-xl font-semibold mb-4 text-custom_darkBlue">
               Add School Information
            </h2>
            <div className="w-72 my-4">
               <input
                  type="text"
                  className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="School name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
               />
            </div>
            <div className="w-72 my-4">
               <input
                  type="text"
                  className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="School Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
               />
            </div>

            <div className="w-72 my-4">
               <div className="w-full block appearance-none focus:bg-white focus:border-gray-500 bg-white border border-gray-300 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none">
                  <label htmlFor="schoolImage" className=" ">
                     School Image
                  </label>
                  <input
                     type="file"
                     id="file"
                     name="writersImageUpload"
                     onChange={onschoolImageChangePdf}
                  />
               </div>
               <button onClick={onSubmitschoolImage} className="CssBtn1">
                  <span className="text">
                     Select only jpg, png, and avif file!
                  </span>
                  <span className="mt-1.5">Add School Image</span>
               </button>
            </div>
            <div className="w-72 my-4 text-gray-600 ring-0">
               <Select
                  options={classTypes}
                  value={selectedClass}
                  onChange={classTypeHandle}
                  placeholder={"Class Type"}
                  isSearchable
                  noOptionsMessage={() => "no class found"}
                  isMulti
                  styles={{
                     placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        border: "none",
                        width: "300px",
                     }),
                  }}
               />
            </div>
            {permission && permission?.permission !== "View Only" && (
               <button
                  type="submit"
                  className={`px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 ${
                     isCreating ? "opacity-50 cursor-not-allowed" : "" // Disable the button when isCreating is true
                  }`}
               >
                  {isCreating ? "Loading..." : "Add"}
               </button>
            )}
         </form>
         <div className="w-full my-10">
            <h2 className="text-xl font-semibold mb-4">
               All School Information
            </h2>{" "}
            {academicSchools?.schools?.length == 0 ? (
               <p>No School data found</p>
            ) : (
               <AgGridTable
                  data={academicSchools?.schools}
                  columnDef={columnDef}
                  height="62vh"
               />
            )}
            <Dialog open={open} handler={handleOpen}>
               <DialogHeader>Update School</DialogHeader>
               <DialogBody divider>
                  <form onSubmit={updateFormSubmit}>
                     <div className="w-72 my-4">
                        <input
                           type="text"
                           className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                           placeholder={seletedData?.name || "School name"}
                           value={name}
                           onChange={(e) => setName(e.target.value)}
                        />
                     </div>
                     <div className="w-72 my-4">
                        <input
                           type="text"
                           className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                           placeholder={
                              seletedData?.description || "School Description"
                           }
                           value={description}
                           onChange={(e) => setDescription(e.target.value)}
                        />
                     </div>
                     <div className="w-72 my-4">
                        <div className="w-full block appearance-none focus:bg-white focus:border-gray-500 bg-white border border-gray-300 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none">
                           <label htmlFor="schoolImage" className=" ">
                              School Image
                           </label>
                           <input
                              type="file"
                              id="file"
                              name="writersImageUpload"
                              onChange={onschoolImageChangePdf}
                           />
                        </div>
                        <button
                           onClick={onSubmitschoolImage}
                           className="CssBtn1"
                        >
                           <span className="text">
                              Select only jpg, png, and avif file!
                           </span>
                           <span className="mt-1.5">Add School Image</span>
                        </button>
                     </div>
                     <div className="w-72 my-4 text-gray-600 ring-0">
                        <Select
                           options={classTypes}
                           value={selectedClass}
                           onChange={classTypeHandle}
                           placeholder={"Class Type"}
                           isSearchable
                           noOptionsMessage={() => "no class found"}
                           isMulti
                           styles={{
                              placeholder: (baseStyles, state) => ({
                                 ...baseStyles,
                                 border: "none",
                                 width: "300px",
                              }),
                           }}
                        />
                     </div>
                     <button
                        type="submit"
                        className={`px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 ${
                           isUpdating ? "opacity-50 cursor-not-allowed" : "" // Disable the button when isCreating is true
                        }`}
                     >
                        {isUpdating ? "Loading..." : "Update"}
                     </button>
                  </form>
               </DialogBody>
               <DialogFooter>
                  <Button
                     variant="text"
                     color="red"
                     onClick={() => {
                        setOpen(false)
                        setName("")
                        setDescription("")
                        setSelectedClass([])
                     }}
                     className="mr-1"
                  >
                     <span>Cancel</span>
                  </Button>
               </DialogFooter>
            </Dialog>
         </div>
      </div>
   )
}

export default AcademicSchool
