import React, { useEffect, useState } from "react"
import ContactCustomer from "./ContactCustomer"
import EditContactInfo from "./EditContactInfo"
import EditShippingAddress from "./EditShippingAddress"
import { useDispatch, useSelector } from "react-redux"
import CustomerTotalOrder from "./CustomerTotalOrder"
import { setACustomer } from "../../services/Features/customerSlice"

const GetCustomer = () => {
   const { customer } = useSelector((state) => state.customer)
   const dispatch = useDispatch()

   const [openContactInfoEdit, setOpenContactInfoEdit] = useState(false)
   const handleOpenContactInfoEdit = () => setOpenContactInfoEdit(true)
   const handleCloseContactInfoEdit = () => setOpenContactInfoEdit(false)

   const [openEditShpping, setOpenEditShpping] = useState(false)
   const handleOpenEditShpping = () => setOpenEditShpping(true)
   const handleCloseEditShpping = () => setOpenEditShpping(false)

   const [openContactCustomer, setOpenContactCustomer] = useState(false)
   const handleOpenContactCustomer = () => setOpenContactCustomer(true)
   const handleCloseContactCustomer = () => setOpenContactCustomer(false)

   const clearSelectedCustomer = () => {
      dispatch(setACustomer({}))
   }
   return (
      <div className="bg-white dark:bg-dark dark:border-gray-600 dark:text-white w-full border rounded-lg shadow">
         {/*customer*/}
         <div className="flex justify-between text-[15px] px-5 pt-5 ">
            <h3 className=" font-semibold ">Customer</h3>
            <button
               type="button"
               onClick={clearSelectedCustomer}
               className="text-[18px] mr-2 cursor-pointer"
            >
               ✕
            </button>
         </div>
         <div className="border-b px-5 py-3 mb-5 text-sm">
            <label className="text-[#576CBC] mb-1 cursor-pointer flex gap-0.5">
               <span>{customer?.contactInformation?.name}</span>
            </label>
            <CustomerTotalOrder customer={customer} />
         </div>
         {/* contact information */}
         <div className="flex justify-between text-[15px] px-5 border-b  pb-3 mb-5">
            <div className="text-sm">
               <h3 className="font-semibold">Contact information</h3>
               <div
                  onClick={handleOpenContactCustomer}
                  className="text-[#576CBC] mb-1 mt-3 cursor-pointer block"
               >
                  {customer?.contactInformation?.email}
               </div>
               <span>
                  {customer?.contactInformation?.phone || "No phone number"}
               </span>
               <ContactCustomer
                  open={openContactCustomer}
                  handleClose={handleCloseContactCustomer}
               />
            </div>
            <div className="flex flex-col items-center">
               <div
                  onClick={handleOpenContactInfoEdit}
                  className="text-[#576CBC] cursor-pointer"
               >
                  Edit
               </div>
               <button type="button">
                  <svg
                     viewBox="0 0 20 20"
                     className="Polaris-Icon__Svg_375hu w-5"
                     focusable="false"
                     aria-hidden="true"
                  >
                     <path d="M15 2a1 1 0 0 1 1 1v13.5a1.5 1.5 0 0 1-1.5 1.5h-9a1.5 1.5 0 0 1-1.5-1.5v-13.5a1 1 0 1 1 2 0v1a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-1a1 1 0 0 1 1-1zm-4 2h-2a1 1 0 1 1 0-2h2a1 1 0 1 1 0 2z"></path>
                  </svg>
               </button>
               <EditContactInfo
                  open={openContactInfoEdit}
                  handleClose={handleCloseContactInfoEdit}
                  customer={customer}
               />
            </div>
         </div>
         {/*Shipping address*/}
         <div className="flex justify-between text-[15px] px-5  pb-3 mb-5">
            <div className="text-sm">
               <h3 className="font-semibold">Shipping address</h3>
               <p>phone: {customer?.shippingAddress?.phone}</p>
               <p>District: {customer?.shippingAddress?.district}</p>
               <p>Thana: {customer?.shippingAddress?.thana || "N/A"}</p>
               <p>Area : {customer?.shippingAddress?.area}</p>
               <p>Address : {customer?.shippingAddress?.address}</p>
            </div>
            <div className="flex flex-col items-center">
               <div
                  onClick={handleOpenEditShpping}
                  className="text-[#576CBC] cursor-pointer"
               >
                  Edit
               </div>
               <button type="button">
                  <svg
                     viewBox="0 0 20 20"
                     className="Polaris-Icon__Svg_375hu w-5"
                     focusable="false"
                     aria-hidden="true"
                  >
                     <path d="M15 2a1 1 0 0 1 1 1v13.5a1.5 1.5 0 0 1-1.5 1.5h-9a1.5 1.5 0 0 1-1.5-1.5v-13.5a1 1 0 1 1 2 0v1a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-1a1 1 0 0 1 1-1zm-4 2h-2a1 1 0 1 1 0-2h2a1 1 0 1 1 0 2z"></path>
                  </svg>
               </button>
               <EditShippingAddress
                  open={openEditShpping}
                  handleClose={handleCloseEditShpping}
                  customer={customer}
               />
            </div>
         </div>
      </div>
   )
}

export default GetCustomer
