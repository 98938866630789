import { useSelector } from 'react-redux';
import {RouterProvider } from 'react-router-dom';
import { router } from './routes/Route';

function App() {
  const {mode} = useSelector(state=>state.darkMode)

  return (
    <div className={`bg-slate-100 font-poppins text-[#4F5366] dark:text-white ${mode==='true' ? 'dark' : undefined}`}>
        <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
