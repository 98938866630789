import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const publicationsApi = createApi({
   reducerPath: "PublicationsApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["Publications"],
   endpoints: (builder) => ({
      getPublications: builder.query({
         query: () => "api/publication",
         providesTags: ["Publications"],
      }),
      getAllPublications: builder.query({
         query: () => "api/publication/all",
         providesTags: ["Publications"],
      }),
      createPublication: builder.mutation({
         query: (data) => ({
            url: `api/publication`,
            method: "POST",
            body: data,
         }),
         invalidatesTags: ["Publications"],
      }),
      updatePublication: builder.mutation({
         query: ({ id, ...rest }) => ({
            url: `api/publication/${id}`,
            method: "PATCH",
            body: rest,
         }),
         invalidatesTags: ["Publications"],
      }),
      deletePublication: builder.mutation({
         query: (id) => ({
            url: `api/publication/${id}`,
            method: "DELETE",
         }),
         invalidatesTags: ["Publications"],
      }),
   }),
})

export const {
   useGetPublicationsQuery,
   useGetAllPublicationsQuery,
   useCreatePublicationMutation,
   useDeletePublicationMutation,
   useUpdatePublicationMutation,
} = publicationsApi
