import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const stationaryApi = createApi({
   reducerPath: "stationaryApi",
   baseQuery: fetchBaseQuery({ baseUrl: "/" }),
   tagTypes: ["stationaryApi"],
   endpoints: (builder) => ({
      getStationary: builder.query({
         query: () => `api/stationary/all`,
         providesTags: ["stationaryApi"],
      }),
      getOneStationary: builder.query({
         query: (id) => `api/stationary/one/${id}`,
         providesTags: ["stationaryApi"],
      }),
      editStationary: builder.mutation({
         query: ({ id, ...rest }) => ({
            url: `api/stationary/${id}`,
            method: "put",
            body: rest,
         }),
         invalidatesTags: ["stationaryApi"],
      }),
   }),
})

export const {
   useGetStationaryQuery,
   useGetOneStationaryQuery,
   useEditStationaryMutation,
} = stationaryApi
