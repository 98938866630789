import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"

const AgGridTable = ({ data, columnDef, height }) => {
   const defaultColDef = {
      shortable: true,
      flex: true,
      filter: true,
      floatingFilter: true,
   }

   return (
      <div
         className="ag-theme-alpine w-[100%] overflow-hidden"
         style={{ height: height, width: "100%" }}
      >
         <AgGridReact
            rowData={data}
            columnDefs={columnDef}
            defaultColDef={defaultColDef}
            domLayout="autoWidth"
            paginationAutoPageSize={10}
            pagination={true}
         ></AgGridReact>
      </div>
   )
}

export default AgGridTable
