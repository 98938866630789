import React, { useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { DashboardContext } from "../../context/DashboardProvider"
import { useGetRefundAndReturnOrdersQuery } from "../../services/order"
import moment from "moment"
import CreateOrderButton from "../../components/CreateOrderButton"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "react-toastify/dist/ReactToastify.css"
import { Exportfile } from "../../utils/Exportfile"
import AgGridTable from "../../components/AgGridTable"
import { userAccount } from "../../utils/utils"
import PrintPackagingSlip from "../../components/PrintPackging/PrintPackagingSlip"

const RefundAndReturn = () => {
   const [selectedDateOrder, setSelectedDateOrder] = useState([])
   const [dateRange, setDateRange] = useState([])
   const [startDate, endDate] = dateRange
   const [csData, setCsData] = useState([])
   const [orderAt, setOrderAt] = useState("updatedAt")
   const [status, setStatus] = useState("")

   const { data: result } = useGetRefundAndReturnOrdersQuery({
      userAccount,
      status,
   })

   const orders = result?.filter((order) => {
      const orderDate = order?.[`${orderAt}`] && moment(order?.[`${orderAt}`])
      const startDateInfo = startDate && moment(startDate)
      const endDateInfo = endDate && moment(endDate)

      if (!startDate && !endDate) {
         return true
      } else if (
         orderDate &&
         startDateInfo &&
         endDateInfo &&
         orderDate.isSameOrAfter(startDateInfo.startOf("day")) &&
         orderDate.isSameOrBefore(endDateInfo.endOf("day"))
      ) {
         return true
      }
      return false
   })

   const navigate = useNavigate()
   const { employeeInfo } = useContext(DashboardContext)
   const permission = employeeInfo?.find((data) => data?.permission)

   // Get selected date order for excel document
   async function findOrderByDate(startDate, endDate, orders) {
      let allOrder = []
      startDate = moment(startDate).format("DD-MM-YYYY")
      endDate = moment(endDate).format("DD-MM-YYYY")

      for (const order of orders) {
         const date =
            order?.updatedAt && moment(order?.updatedAt).format("DD-MM-YYYY")

         if (date >= startDate && date <= endDate) {
            allOrder.push(order)
         }
      }

      let tempEvents = []
      allOrder?.forEach((element) => {
         const productBarcode = element?.orderedProducts?.map(
            (item) => item?.barcode
         )
         const returnProduct = element?.returned?.returnedProducts?.map(
            (item) => item?.barcode
         )
         const redata = {
            "Order Id": element?.orderId,
            "Customer Name": element?.customer?.contactInformation?.name,
            Mobile: element?.customer?.contactInformation?.phone,
            Products: `${[...productBarcode]}`,
            "Order Value": element?.subTotal,
            "Shipping Cost": element?.paymentShipping?.shipping_cost,
            "Return Products": `${[...returnProduct]}` || "N/A",
            "Returning Shiping Cost":
               element?.returned?.returnOptions?.shippingCost || 0,
            "Refund Amount":
               element?.returned?.refundOptions?.refundAmount || 0,
            "Refund Method":
               element?.returned?.refundOptions?.refundMethod || "N/A",
            "Refund Reference Number":
               element?.returned?.refundOptions?.trNumber || "N/A",
            "Net Payment": element?.netPayment,
            Balance: element?.balance,
         }

         tempEvents.push(redata)
         setCsData(tempEvents)
      })
   }

   const columnDef = [
      {
         headerName: "ORDER ID",
         field: "orderId",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/refund-&-return/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params?.data?.orderId}
               </Link>
            )
         },
      },
      {
         headerName: "ORDER DATE",
         field: "date",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/refund-&-return/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {moment
                     .utc(params.data.createdAt)
                     .utcOffset("+06:00")
                     .format("DD-MM-YYYY")}
               </Link>
            )
         },
      },
      {
         headerName: "REFUNDED / REFUNDED DATE",
         field: "date",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/refund-&-return/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {moment
                     .utc(params.data.updatedAt)
                     .utcOffset("+06:00")
                     .format("DD-MM-YYYY")}
               </Link>
            )
         },
      },
      {
         headerName: "ORDER STATUS",
         field: "orderStatus",
         cellRenderer: function (params) {
            return (
               <div
                  onClick={() =>
                     navigate(`/refund-&-return/${params?.data?._id}`)
                  }
                  className="cursor-pointer px-4"
               >
                  <span
                     className={`px-4 py-1 !no-underline text-center rounded-full shadow-md capitalize text-sm font-medium
            ${
               params.data?.orderStatus === "returned"
                  ? "bg-[#D15865] text-[#6e2c32]"
                  : params.data?.orderStatus === "refunded"
                  ? "bg-[#FE4D4D] text-[#923a3a]"
                  : params.data?.orderStatus === "refunded & returned"
                  ? "bg-[#FA4848] text-[#9c3737]"
                  : params.data?.orderStatus === "return in progress"
                  ? "bg-[#D1ADB1] text-[#6e4247]"
                  : params.data?.orderStatus === "refund in progress"
                  ? "bg-[#FED9D9] text-[#a04545]"
                  : "bg-[#F98888] text-[#9b3434]"
            }`}
                  >
                     {params.data?.orderStatus || "-"}
                  </span>
               </div>
            )
         },
      },
      {
         headerName: "CUSTOMER",
         field: "customer.contactInformation.name",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/refund-&-return/${params?.data?._id}`}
                  className="px-4 block !no-underline capitalize"
               >
                  {params.data?.customer?.contactInformation?.name}
               </Link>
            )
         },
      },
      {
         headerName: "CUSTOMER PHONE",
         field: "customer.shippingAddress.phone",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/refund-&-return/${params?.data?._id}`}
                  className="px-4 block !no-underline capitalize"
               >
                  {params.data?.customer?.shippingAddress.phone}
               </Link>
            )
         },
      },
      {
         headerName: "ORDER TAKEN BY",
         field: "orderTakenBy",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/refund-&-return/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.orderTakenBy || "Website"}
               </Link>
            )
         },
      },
      {
         headerName: "TOTAL",
         field: "totalPrice",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/refund-&-return/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.totalPrice || "-"}
               </Link>
            )
         },
      },
      {
         headerName: "PAYMENT STATUS",
         field: "isPaid",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/refund-&-return/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.isPaid === true ? "Paid" : "Not paid"}
               </Link>
            )
         },
      },
      {
         headerName: "ORDER TYPE",
         field: "orderType",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/refund-&-return/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.orderType || "-"}
               </Link>
            )
         },
      },

      {
         headerName: "PAYMENT METHOD",
         field: "paymentMethod",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/refund-&-return/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.paymentMethod}
               </Link>
            )
         },
      },
      {
         headerName: "SHIPPING BY",
         field: "paymentShipping.shipping_carrier",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/refund-&-return/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.paymentShipping?.shipping_carrier || "-"}
               </Link>
            )
         },
      },
      {
         headerName: "SHIPPING TYPE",
         field: "paymentShipping.shipping_type",
         cellRenderer: function (params) {
            return (
               <Link
                  to={`/refund-&-return/${params?.data?._id}`}
                  className="px-4 block !no-underline"
               >
                  {params.data?.paymentShipping?.shipping_type} (
                  {params.data?.paymentShipping?.shipping_cost})
               </Link>
            )
         },
      },
      {
         headerName: "ACTION",
         field: "_id",
         cellRenderer: (params) => (
            <div className="text-[15px] z-50 px-4">
               {permission?.permission !== "View Only" ? (
                  <li>
                     <PrintPackagingSlip data={params.data} icon={true} />
                  </li>
               ) : (
                  "N/A"
               )}
            </div>
         ),
      },
   ]
   return (
      <section className="p-2 mb-5 m-2 sm:m-5 ">
         <div className="flex flex-col sm:flex-row gap-3 justify-between items-center py-5 dark:text-white">
            <h3 className="font-semibold text-xl">Refund & Return</h3>
            <div className="space-x-2 flex gap-1">
               <select
                  onChange={(e) => {
                     setOrderAt(e.target.value)
                     setDateRange([])
                  }}
                  className="dark:text-dark border border-gray-300 rounded-lg px-3 py-2 outline-none cursor-pointer hover:shadow-md duration-200"
               >
                  {/* <option value="">All Refund & Return Orders</option>
                  <option value="returned">Returned</option>
                  <option value="refunded">Refunded</option>
                  <option value="refunded & returned">
                     Refunded & Returned
                  </option>
                  <option value="return in progress">
                     Returned In Progress
                  </option>
                  <option value="refund in progress">
                     Refunded In Progress
                  </option>
                  <option value="refund & return in progress">
                     Refunded & Returned In Progress
                  </option> */}
                  <option value="createdAt">All Return & Refund</option>
                  <option value="returnedAt">Returned</option>
                  <option value="refundedAt">Refunded</option>
                  <option value="refundedReturnedAt">
                     Refunded & Returned
                  </option>
                  <option value="returnInProgressAt">
                     Returned In Progress
                  </option>
                  <option value="refundInProgressAt">
                     Rrefunded In Progress
                  </option>
                  <option value="refundReturnInProgressAt">
                     Refunded & Returned In Progress
                  </option>
               </select>
               {permission?.permission !== "View Only" && (
                  <>
                     <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                           setDateRange(update)
                        }}
                        onCalendarClose={() =>
                           findOrderByDate(startDate, endDate, orders)
                        }
                        isClearable={true}
                        className="date-picker  shadow-md hover:shadow-lg"
                        placeholderText="Select Date"
                        dateFormat={"dd/MM/yyyy"}
                     />
                     <Exportfile csvData={csData} fileName={status} />
                  </>
               )}

               {permission?.permission !== "View Only" && <CreateOrderButton />}
            </div>
         </div>
         <div className="w-full border rounded-lg p-5 bg-white dark:bg-dark dark:text-white ">
            <div className="flex items-center gap-5 pb-3 ">
               <h3 className="text-sm sm:text-base md:text-lg text-[#9191A1]">
                  Total Orders: {orders?.length}
               </h3>
            </div>

            <div className="flex gap-10 mb-3"></div>
            {orders?.length === 0 ? (
               <div className="text-red-600 flex items-center justify-center  w-full h-[60vh]">
                  No Products Available!
               </div>
            ) : (
               <AgGridTable data={orders} columnDef={columnDef} height="70vh" />
            )}
         </div>
      </section>
   )
}

export default RefundAndReturn
